import React, { useState, useEffect, useCallback } from "react";
import { instance } from "../../axios";
import {
  PageHeader,
  UiInput,
  UiButton,
  UiToggle,
  UiRichText,
  UiTextArea,
  UiRSelect,
  InLoader,
  UiImageUpload,
  UiSelect,
  BgLoader,
  OverlayLoader,
} from "../../ui";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { rupees } from "../../helper";
import * as yup from "yup";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MdDiscount } from 'react-icons/md'
import Range from '@atlaskit/range';
import Accordion from 'react-bootstrap/Accordion';


export default function Pricing() {
  let { action } = useParams();
  let navigate = useNavigate();
  const [state, setState] = useState({ count: 0 });
  const [loader, showLoader] = useState({});
  const [value, setValue] = useState(5);
  const [price, setPrice] = useState();
  const [coupon, setCoupon] = useState({});
  const [slabs, setSlabs] = useState([]);


  const [inner_loader, setInnerLoader] = useState(false);
  const [in_loader, setInLoader] = useState(false);
  const schema2 = yup.object().shape(
    {
      code: yup.string().required("Promo code is required").max(35, 'Maximum character limit is 35'),

    },
    []
  );
  const schema = yup.object().shape(
    {
      duration: yup.object().required("Select duration"),
      company: yup.string().required("Company name is required").max(60, 'Maximum character limit is 60'),
      pg: yup.string().nullable().required("Please select payment option"),
      gstin: yup
        .string()
        .nullable()
        .when("gstin", {
          is: (value) => value?.length,
          then: (rule) =>
            rule
              .matches(
                /^[0-9]{2}[0-9A-Za-z]{13}$/,
                "Enter valid GST number"
              )
              .required("Enter GST number"),
        }),
    },
    [["gstin", "gstin"]]
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    initPayment();

  };

  var initPayment = () => {
    setInnerLoader(true)
    var form = document.querySelector("#renewform");
    var data = new FormData(form);
    data.append('plan', value)
    data.append('type', 'subscription')
    data.append('action', action)
    instance({
      method: "post",
      url: "/init_transaction",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setInnerLoader(false)
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          handlePay(response.data.url)
        }
      })
      .catch(function (response) { });
  };

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({
    resolver: yupResolver(schema2),
  });
  const onSubmitHandler2 = async (data) => {
    checkCoupon();

  };


  var checkCoupon = () => {
    setInnerLoader(true)
    var form = document.querySelector("#couponform");
    var data = new FormData(form);
    instance({
      method: "post",
      url: "/coupon_check",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setInnerLoader(false)
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {

          setCoupon({ code: response.data.code, percent: response.data.percent })

        }
      })
      .catch(function (response) { });
  };
  const fetchData = () => {
    instance
      .get("/pricing?action=" + action)
      .then(function (response) {
        setState({ ...state, ...response.data });
        //setEntry(response.data.settings.service);
        setSlabs(response.data.slabs)
        reset(response.data.billing)
        setValue(response.data.billing.users)
      })
      .catch(function (error) { })
      .then(function () { });
  }



  useEffect(() => {
    fetchData()
  }, []);



  const processTotal = () => {
    var year = 1;
    if (w_duration) {
      if (w_duration.value != 'current') {
        var year = w_duration.value / 12;
      }
    }
    var subtotal = (price * value) * year;
    if (state.billing && action == 'upgrade') {
      subtotal = Math.round((((price * (value - state.billing.users))) / 365) * state.days_left)
    }
    var discount = 0;
    var last_discount = 0;
    if (coupon.percent) {
      discount = Math.round(subtotal * coupon.percent / 100)
    }
    if (state.last_plan_discount) {
      last_discount = Math.round(state.last_plan_discount)
    }

    var subtotal1 = subtotal - (discount + last_discount);
    if (action == 'upgrade') {
      subtotal1 = subtotal - (discount);

    }
    var tax = Math.round(subtotal1 * 18 / 100);
    var amount = subtotal1 + tax;
    setState({ ...state, amount: amount, tax: tax, subtotal: subtotal, discount: discount })
  }


  const w_duration = watch('duration');



  useEffect(() => {
    processTotal()
  }, [coupon]);
  useEffect(() => {
    processTotal()
  }, [w_duration]);
  useEffect(() => {

    processTotal()
  }, [price]);



  useEffect(() => {
    if (slabs) {
      const prices = slabs
      let shouldSkip = false;

      prices.forEach(element => {
        if (shouldSkip) {
          return;
        }
        if (element.users >= value) {
          //console.log(element)
          setPrice(element.price)
          processTotal()
          shouldSkip = true;
          return;
        }
      });
    }
  }, [value, slabs]);

  window.popupCallback = function (str) {
    setInLoader(true)
    instance
      .get("/verify_payment?id=" + str)
      .then(function (response) {
        setInLoader(false)
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          navigate('/account/')
        }
      })
  };

  //const pgModal = useModal('payment-gateway');

  const handlePay = (e) => {
    //pgModal.show({ id: e, type: 'signup' }).then((res) => {
    //if (res.status == 'paid') {
    //  navigate('/onboarding/' + res.token)
    //}
    //});
    setInnerLoader(true);
    const popname = window.open(e, "popname", "status=1,toolbar=0,resizable=0");


    if (popname) {
      popname.focus();
      const timer = setInterval(() => {
        if (popname.closed) {
          setInnerLoader(false);
          clearInterval(timer);
        }
      }, 1000);
    } else {
      // Fallback: Redirect the current window if pop-up was blocked
      setInnerLoader(false);
      window.location.href = e;
    }


  }

  return (
    <>
      <Helmet>
        <title>
          Pricing | Practive
        </title>
      </Helmet>
      <div>
        {state.plans ? (

          <div>

            <h1 className="text-center h3 mt-4 mb-2">{action == 'upgrade' ? 'Upgrade' : state.is_trial == 1 ? 'Buy Plan' : 'Renew'}</h1>



            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5 ">
                <OverlayLoader visible={inner_loader ? true : false} />
                <div className="card shadow-2-strong">
                  {in_loader && (<InLoader />)}
                  <div className="card-body">
                    <div className={`${(action == 'renew' && state.is_active == 1 && state.is_trial == 0) ? 'd-none' : ''}`}>
                      <h5>Users</h5>
                      <p className="text-secondary">Select required number of users</p>
                      <div className="text-center"><h5 className="text-primary">{value} users</h5></div>
                      <Range step={1} value={value} min={5} max={100} onChange={(value) => setValue(value)} />
                    </div>
                    <form id="couponform" onSubmit={handleSubmit2(onSubmitHandler2)} noValidate></form>
                    <form id="renewform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                      {action !== 'upgrade' ?
                        <Controller
                          name="duration"
                          control={control}
                          render={({ field }) => (

                            <UiRSelect
                              {...field}
                              required={true}

                              options={state.duration}
                              className=""
                              message={errors.duration?.message}
                              label="Duration"


                            />

                          )}
                        />

                        :
                        <input type="hidden" name="duration" value="current"/>
                      }


                      <Accordion className="mb-3">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header size="sm">Promo Code</Accordion.Header>
                          <Accordion.Body>
                            {coupon && coupon.code ? (
                              <div className="d-flex justify-content-between">
                                <div><MdDiscount className="text-primary" /> Promo code "<b>{coupon.code}</b>" is applied</div>
                                <div>
                                  <button className="btn btn-outline-danger btn-sm" onClick={() => setCoupon({})}>Remove</button>
                                </div>
                              </div>
                            ) : (

                              <div className="row">
                                <div className="col-9">

                                  <UiInput
                                    required={true}
                                    placeholder="Promo code"
                                    type="text"
                                    form="couponform"
                                    name="code"
                                    message={errors2.code?.message}
                                    {...register2("code")}
                                  />


                                </div>
                                <div className="col-3">
                                  <UiButton className="w-100 btn-outline-primary" form="couponform" title="Apply" />
                                </div>
                              </div>

                            )}
                          </Accordion.Body>
                        </Accordion.Item>

                      </Accordion>


                      <div className="mb-4 pb-4">
                        <div className="d-flex justify-content-between mb-2">
                          {action == 'upgrade' ?
                            <span className="text-secondary">Practive additional {value - state.billing.users} users for current {state.billing.users} users plan</span>
                            :
                            <span className="text-secondary">Practive {value} users subscription for {w_duration && w_duration.value} months</span>
                          }
                          <span>{rupees(state.subtotal)}</span>
                        </div>
                        {(state.last_plan_discount > 0 && action !== 'upgrade') && (
                          <div className="d-flex justify-content-between mb-2">
                            <span className="text-secondary">Previous Plan Amount</span>
                            <span>-{rupees(state.last_plan_discount)}</span>
                          </div>
                        )}
                        {state.discount > 0 && (
                          <div className="d-flex justify-content-between mb-2">
                            <span className="text-secondary">Discount</span>
                            <span>-{rupees(state.discount)}</span>
                          </div>
                        )}

                        <div className="d-flex justify-content-between mb-2">
                          <span className="text-secondary">GST ({state.tax_percent}%)</span>
                          <span>{rupees(state.tax)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2 fw-bold">
                          <span className="text-secondary">Final Amount</span>
                          <span>{rupees(state.amount)}</span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <UiInput
                            required={true}
                            label="Company Name"
                            type="text"
                            name="company"
                            message={errors.company?.message}
                            {...register("company")}
                          />

                        </div>
                        <div className="col-sm-6">

                          <UiInput

                            label="GSTIN"
                            type="text"
                            name="gstin"
                            message={errors.gstin?.message}
                            {...register("gstin")}
                          />

                        </div>
                      </div>

                      <div className="mb-4">
                          <label className="form-label">
                            Pay Using
                          </label>
                          <div className="d-flex">
                            <div className="form-check me-4">
                              <input type="radio" className="form-check-input" value="UPI" name="pg" id="upi_pg" {...register("pg")} />
                              <label className="form-check-label" htmlFor="upi_pg">UPI</label>
                            </div>
                            <div className="form-check">
                              <input type="radio" className="form-check-input" value='Others' name="pg" id="other_pg"  {...register("pg")} />
                              <label className="form-check-label" htmlFor="other_pg">Debit/Credit Card, NET Banking</label>
                            </div>
                          

                          </div>
                          {errors.pg?.message && <small className="text-danger">{errors.pg.message}</small>}
                        </div>

                      <input type="hidden" value={coupon && coupon.code ? coupon.code : ''} name="coupon" />
                      <UiButton loading={state["loader"]} className="w-100 btn-lg" title="Make Payment" />
                    </form>
                  </div>
                </div>
              </div>
            </div>






          </div>
        ) : <BgLoader />}
      </div>

    </>
  );
}
