import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const ChangePasswordProfile = NiceModal.create(
  ({ data }) => {
    const [state, setState] = useState({});
    const modal = useModal();


    const schema = yup.object().shape({
      cur_password: yup.string().required("Enter your current password"),
      password: yup
        .string()
        .required("Please enter password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ).max(30, 'Maximum character limit is 30'),
      password1: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "The password and its confirm are not the same"
        ).max(30, 'Maximum character limit is 30'),
    });
    var ChangePassword = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#changepassform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/change_pass_profile",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {

            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      ChangePassword();
      //reset();
    };


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    return (
      <BootstrapModal {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Change Password</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="changepassform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>

            <UiInput
              showicon={true}
              required={true}
              lcol="4"
              icol="8"
              label="Current Password"
              type="password"
              name="cur_password"
              message={errors.cur_password?.message}
              {...register("cur_password")}
            />
            <UiInput
              showicon={true}
              required={true}
              lcol="4"
              icol="8"
              label="New Password"
              type="password"
              name="password"
              message={errors.password?.message}
              {...register("password")}
            />
            <UiInput
              showicon={true}
              required={true}
              lcol="4"
              icol="8"
              label="Confirm Password"
              type="password"
              name="password1"
              message={errors.password1?.message}
              {...register("password1")}
            />


          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>

      </BootstrapModal>
    );
  }
);

export default ChangePasswordProfile;
