import React, { useState, useEffect, useRef } from "react";
import { instance } from "../../axios";
import {
  PageHeader,
  UiInput,
  UiButton,
  UiActioButton,
  UiRichText,
  UiTextArea,
  UiRSelect,
  PageError,
  UiSelect,
  BgLoader,
} from "../../ui";
import { Helmet } from "react-helmet";
import { rupees } from "../../helper";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsWhatsapp, BsEnvelope } from 'react-icons/bs'
import referImg from '../../img/refer.png'
import referImg1 from '../../img/refer1.png'


export default function ReferFriends() {
  const [bg_loader, setBGLoader] = useState(false);

  const [state, setState] = useState({ count: 0 });
  const [loader, showLoader] = useState({});

  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/refer")
      .then(function (response) {
        setBGLoader(false)
        setState({ ...state, ...response.data });
        //setEntry(response.data.settings.service);
      })
      .catch(function (error) { })
      .then(function () { });
  }
  useEffect(() => {
    fetchData()
  }, []);







  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        <title>
          Refer & Earn | Practive
        </title>
      </Helmet>
      <div>
        {state.settings ? (

          <div>
            {bg_loader == true ? <BgLoader /> : (
              <>

                <PageHeader title="Refer & Earn" parent="" link="/settings">

                </PageHeader>



                <div className="row d-flex justify-content-center">
                  <div className="col-12">
                    <div className="card card-default mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-8 order-sm-0 order-1">
                            <h2 className="fw-bold">Refer Your Friends. Earn Cash.</h2>
                            <p className="text-secondary">Refer your friends to signup using this promo code and you'll get cash up to {rupees(1250)} per referral.</p>
                            <div className="d-md-flex ">


                              <CopyToClipboard text={state.settings.code} >
                                <div className="d-flex mb-3">
                                  <div className="border px-3 rounded fs-3 fw-bold">
                                    {state.settings.code}
                                  </div>
                                  <UiActioButton className="btnpulse" title="Copy"></UiActioButton>
                                </div>
                              </CopyToClipboard>


                              <div className="mb-3">
                                <a className="btn btn-outline-success d-inline-flex me-3" href={`https://wa.me/?text=Hello, I'd like to invite you to try out Practive, an innovative office management software that's both user-friendly and feature-rich.
Use Promo Code: *${state.settings.code}* during checkout to unlock an exclusive 20% discount. https://bit.ly/3OSrbww`} target="_blank"><span className="sicon me-2"><BsWhatsapp /></span> WhatsApp</a>

                                <a className="btn btn-outline-primary d-inline-flex" href={`mailto:?subject=I%E2%80%99m%20inviting%20you%20to%20use%20Practive&body=Hello%2C%20I%E2%80%99m%20inviting%20you%20to%20use%20Practive%2C%20a%20simple%20and%20advanced%20office%20management%20software.%0APromo%20Code%20${state.settings.code}%0AJust%20enter%20it%2C%20and%20you%20will%20get%20a%2020%25%20discount.%0Ahttps%3A%2F%2Fbit.ly%2F3OSrbww`} target="_blank"><span className="sicon me-2"><BsEnvelope /></span> Email</a>
                              </div>
                            </div>
                            <p className="text-secondary">Your friends will also get a flat <b className="text-success">20% discount</b> using this promo code.</p>
                          </div>
                          <div className="col-sm-4 text-center">
                            <img style={{ maxWidth: '100%' }} src={referImg} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3 mb-4">
                        <div className="card bg-success text-white h-100">
                          <div className="card-body text-center d-flex align-items-center">
                            <div className="w-100">
                              <h3>Your Earning</h3>
                              <span className="fs-1 fw-bold">{rupees(state.settings.earning)}</span>
                              <div><a href="https://forms.gle/jKrsXKk3kti7xkZH7" target="_blank" className={`btn btn-light text-success my-2 ${state.settings.earning == 0 && 'disabled'}`} >Withdraw</a></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9 mb-4 align-items-center">
                        <div className="card card-default h-100">
                          <div className="card-body d-flex align-items-center">
                            <div className="w-100">
                              <img className="w-100" src={referImg1} />
                              <div className="row text-center">
                                <div className="col">
                                  <span>Refer Your Friends</span>
                                </div>
                                <div className="col">
                                  <span>Your Friends Buy Plan</span>
                                </div>
                                <div className="col">
                                  <span>You Earn Cash</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">

                      <div className="col-md-9 mb-4">
                        <div className="card card-default">
                          <div className="card-body">

                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Referrals</th>
                                  <th>Reward</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1 to 5</td>
                                  <td>₹500 per referral</td>
                                </tr>
                                <tr>
                                  <td>6 to 10</td>
                                  <td>₹750 per referral</td>
                                </tr>
                                <tr>
                                  <td>11 to 15</td>
                                  <td>₹1000 per referral</td>
                                </tr>
                                <tr>
                                  <td>16 and more</td>
                                  <td>₹1250 per referral</td>
                                </tr>
                              </tbody>
                            </table>
                            <small className="text-secondary">Example: If you have successfully referred 8 friends, you will get 8 x 750 = ₹6,000</small>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 mb-4">
                        <div className="card bg-warning h-100">
                          <div className="card-body text-center d-flex align-items-center">
                            <div className="w-100">
                              <h3>Your Total Referrals</h3>
                              <span className="fw-bold" style={{ fontSize: "80px" }}>{state.settings.total}</span>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b>Terms & Conditions</b>
                    <ul className="text-secondary">
                      <li>New users only. The person you’re referring can not have any existing subscription at Practive.</li>
                      <li>Subscription purchases only. Rewards are only eligible for first-time purchases of Practive software subscription for 12 months or longer.</li>
                      <li>The referred client does not refund and is active for at least 15 days.</li>
                      <li>Earnings can be withdrawn once your referred client completes a refund period of 15 days.</li>
                      <li>Earnings will be transferred to your bank account using NEFT/IMPS.</li>
                    </ul>


                  </div>

                </div>


              </>
            )}
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
}
