import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { PageHeader, PageError, UiTimeDisplay, UiAvatarName, UiButton,  BgLoader, TableZero } from "../../ui";
import * as yup from "yup"; import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

import { Uploader, DocList } from "../../uploader";
import { Helmet } from "react-helmet";

import { useQueryClient } from 'react-query';
import { rupees } from "../../helper";





export default function ViewExpense() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  const [vloader, showVLoader] = useState(false);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader({ ...loader, delete: true })
        instance
          .get("/delete_expense?id=" + id)
          .then(function (response) {
            showLoader({ ...loader, delete: false })
            if (response.data.status == 'success') {

              queryClient.invalidateQueries(['expenses'])
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }



  const fetchData = () => {
    instance
      .get("/view_expense?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });

      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, []);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  const BasicDetail = (props) => {
    const { label, val, ...other } = props;
    return (
      <>
        {val &&
          <div className="row mb-2">
            <div className="col-lg-4 text-muted">
              {label}
            </div>
            <div className="col-lg-8">
              <b {...other}></b>
            </div>
          </div>
        }
      </>
    )
  }





  return (
    <>
      <Helmet>
        <title>
          Expense | Practive
        </title>
      </Helmet>
      <div>
        {state.expense ? (
          <div>

            <PageHeader title="View Expense" parent="Expenes" link="/expenses">
              {state.can_edit && (<>
                <UiButton className="btn btn-outline-primary" onClick={() => navigate('/expense/' + id)} title="Edit" />
              </>
              )}
              {state.can_delete && (<>
                <UiButton className="btn btn-outline-danger ms-2" loading={loader.delete} onClick={() => handleDelete(id)} title="Delete" />
              </>
              )}

            </PageHeader>


            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="card card-default">
                  <div className="card-body">

                    <BasicDetail label="Date" val={state.expense.date} >{state.expense.date}</BasicDetail>
                    <BasicDetail label="Category" val={state.expense.category} >{state.expense.category}</BasicDetail>
                    <BasicDetail label="Client" val={state.expense.client} ><Link to={`/view-client/${state.expense.cust_id}`}>{state.expense.client}</Link></BasicDetail>
                    <BasicDetail label="Amount" val={rupees(state.expense.amount)} >{rupees(state.expense.amount)}</BasicDetail>
                    <BasicDetail label="Payment Mode" val={state.expense.mode} >{state.expense.mode}</BasicDetail>
                    <BasicDetail label="Notes" val={state.expense.notes} >{state.expense.notes}</BasicDetail>
                    <BasicDetail label="Billing Status" val={state.expense.is_billed} ><span className={`badge ${state.expense.is_billed}`}>{state.expense.is_billed}</span></BasicDetail>
                    <BasicDetail label="Invoice" val={state.expense.invoice_number} ><Link to={`/view-invoice/${state.expense.invoice_id}`}>View Invoice</Link></BasicDetail>
                  </div>
                </div>
                <div>
                  <div className="d-block text-secondary small">
                    <div className="d-inline-flex me-3 py-2 align-items-center">
                      <span className="me-2">Created by</span>
                      <UiAvatarName photo={state.expense.user_pic} name={state.expense.user_name} />
                    </div>
                    <div className="d-inline-flex py-2 align-items-center">
                      <span className="me-2">on</span>
                      <UiTimeDisplay time={state.expense.created_on} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card card-default">
                  <div className="card-header">Files</div>
                  <div className="card-body">
                    {state.files && state.files.length > 0 ? (
                      <DocList category="expense" setData={(e) => setState({ ...state, files: e })} data={state.files} can_edit={0} />
                    ) : (

                      <TableZero title="Files" />
                    )}
                  </div>
                </div>
              </div>

            </div>






          </div>
        ) : <BgLoader />}


      </div>
    </>
  );
}
