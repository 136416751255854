import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiInput, UiSelect, TableZero, UiDatePicker, UiButton, UiRichText, UiToggle, BgLoader, PageError, InLoader, OverlayLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { useStore } from "../state.js";
import {MyStopwatch} from "../timer.js"



const StopWatch = NiceModal.create(
  ({ id, data }) => {
    const [state, setState] = useState({});
    const [loader, showLoader] = useState(false);
    const modal = useModal();

    const { timerLoading, setTimerLoading } = useStore((state) => state);
    const { timerReload, setTimerReload } = useStore((state) => state);




    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    const fetchData = () => {
      showLoader(true)
      instance
        .get("/running_timers")
        .then(function (response) {
          setTimerLoading(response.data.is_running)
          setState({ ...state, ...response.data });
          showLoader(false)
        })
        .catch(function (error) { })
        .then(function () { });
    }

 

    const handleModalb = (id) => {

      setState({ ...state, modalb: id })
    }

    useEffect(() => {
      return () => {
        setTimerReload(Date.now());
      };
    }, []);

    useEffect(() => {

      fetchData()



    }, []);

    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>My Running Timer</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body >
          <div className="position-relative">
            

            {state.items && state.items.length > 0 ? (

              <>
              {loader &&
              <InLoader />}
                {state.items.map((val, i) => (
                  <div className="bg-light p-3 mb-3 rounded position-relative" key={i}>
                    <div className="d-flex">
                      <span className="flex-grow-1"><Link to={`/view-task/${val.task_id}`}>{val.title ? val.title +' | ' : ''}{val.service}{val.period && ' - ' + val.period}</Link>
                        <p className="mb-0">Client: {val.client}</p>
                      </span>
                      <MyStopwatch time={val.seconds} id={val.id} is_running={val.is_running} reloadPage={() => fetchData()}  setmodalb={(e) => handleModalb(e)} showLoader={(e) => showLoader(e)} zstateicon={(e)=>setTimerLoading(e)} />
                    </div>

                  </div>
                ))}
              </>
            ) : state.items && state.items.length == 0 ? <TableZero title="Timer(s)" /> : <BgLoader />}
          </div>
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default StopWatch;
