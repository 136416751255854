import React, { useState, useEffect } from "react";

import { useInView } from 'react-intersection-observer';

import { useNavigate, Link, useOutletContext } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Helmet } from "react-helmet";
import { instance } from "../../axios";
import { BgLoader, InLoader, PageHeader, TableZero, UiActioButton, UiDateRange, UiSelect, Avatar, UiPeriodSelect } from "../../ui";
import { useStore } from "../../state.js";
import { MdOutlinePending, MdSettings, MdOutlineAccessTime, MdOutlinePauseCircle, MdCheckCircleOutline, MdOutlineMoreVert } from 'react-icons/md'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CountUp from 'react-countup';
import 'chartjs-adapter-date-fns'
import avgtimeImg from '../../avg_time.svg'
import taskBG from '../../task.svg'
import billBG from '../../bill.svg'
import { ToDoList } from './todo_list.js'
import { requestForToken } from '../../firebase';
import { BsChevronDown, BsDash } from "react-icons/bs";
import { BsExclamationLg, BsPause, BsClockHistory } from "react-icons/bs";
import { IoCheckmarkOutline } from "react-icons/io5";



import {
  Chart as ChartJS, ArcElement, CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  LineElement,
  Title, Tooltip as Tooltip1, Legend
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import { getStorage, setStorage } from "../../helper";

import { Collapse, Button, Spinner } from 'react-bootstrap';
import { set } from "date-fns";
import { he } from "date-fns/locale";


const BoxItem = (props) => {
  let navigate = useNavigate();
  const { title, subtitle, value, color, attr } = props;
  return (
    <>

      <div className="card card-default c-pointer" onClick={() => navigate(`/tasks?status=Pending,Hold,In-Progress&due_date=${attr}`)}>
        <div className="card-body py-3">
          <div className="d-flex justify-content-between">
            <span className="fs-1 fw-bolder" style={{ color: color }}><CountUp end={value} /></span>
            <div className="text-end"><b className="fs-5">{title}</b><br></br><span className="text-secondary">{subtitle}</span></div>
          </div>
        </div>
      </div>

    </>
  )
}



export default function Home() {
  ChartJS.register(CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title, ArcElement, Tooltip1, Legend);
  const [astate, setAState] = useOutletContext();
  const admin_state = useStore((state) => state.admin);
  const [state, setState] = useState({});
  const [view, setView] = useState(astate.is_admin == 1 ? getStorage('default_role') ? getStorage('default_role') : 'Admin' : 'User');

  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const fetchData = () => {
    setLoader(true)
    instance
      .get("/db_matrics?view=" + view)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }






  const [height, setHeight] = useState(window.innerWidth > 800 ? window.innerHeight : 900);

  const updateHeight = () => {

    setHeight(window.innerWidth > 800 ? window.innerHeight : 900);
  };

  useEffect(() => {

    fetchData()

  }, [view]);


  useEffect(() => {

    requestForToken();

    updateHeight();

    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, []);











  return (
    <>
      <Helmet>
        <title>
          Dashboard | Practive
        </title>
      </Helmet>
      <div>

        <PageHeader noBack={true} title="Dashboard" >
          <div className="d-flex flex-sm-row flex-column align-items-sm-center">

            {(admin_state.is_admin && admin_state.is_admin == 1) ?
              <div className="d-flex align-items-center">
                <span className="d-lg-block me-2">View as</span>
                <UiSelect parentClass="mb-0" className="form-select-sm" value={view} onChange={(e) => { setView(e.target.value); setStorage('default_role', e.target.value) }} noBlank={true} name="view" options={['Admin', 'User']} />
              </div> : ''
            }


          </div>
        </PageHeader>
        <div className="s-box-scroll mb-4">
          <div className="row" style={{ height: '80px' }}>
            {state.stat1 && state.stat1.map((val, i) => (
              <div key={i} className="col"  >
                <BoxItem title={val.title} subtitle={val.subtitle} value={val.value} color={val.color} attr={val.attr} />
              </div>
            ))}



          </div>
        </div>
        <div className="row">
          <div className="col-md-4  mb-4">
            <ToDoList view={view} height={(height - 346) < 570 ? 606 : height - 346} />

          </div>



          <div className="col-md-8 mb-4">
            <TasksOverview view={view} is_admin={admin_state.is_admin} height={height} />
          </div>

        </div>

        {view == 'Admin' && <>
          <div className="row">
            <div className="col-md-3 mb-4">

              <div className="mb-4" style={{ height: '173px' }}>
                <div className="card text-bg-primary" >
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-2">
                      <p className="text-white text-opacity-75 text-truncate">Need to make invoice</p>

                      <img style={{ width: '38px', opacity: '0.2' }} src={billBG} />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col border-end border-white border-opacity-25">
                        <Link to="/unbilled-packages-report" className="text-white">
                          <span className="fs-2 fw-bolder"><CountUp end={state.packages} /></span>
                          <p className="text-white text-opacity-75 text-truncate mb-0">Packages</p>
                        </Link>
                      </div>
                      <div className="text-end col" >
                        <Link to="/unbilled-tasks-report" className="text-white">
                          <span className="fs-2 fw-bolder"><CountUp end={state.unbilled} /></span>
                          <p className="text-white text-opacity-75 text-truncate mb-0">Tasks</p>
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              {inView ? <NewClients view={view} /> : ''}


            </div>
            <div className="col-md-9">
              <div className="row" ref={ref}>
                <div className="col-md-6 mb-4" >
                  {inView ? <BestPerformer view={view} /> : ''}
                </div>
                <div className="col-md-6 mb-4">
                  {inView ? <Attendance view={view} /> : ''}

                </div>
              </div>
            </div>
          </div>
        </>
        }


        <div className="row">
          <div className="col-md-4">
            {inView ? <AvgHours period='Last 30 Days' view={view} height="395px" /> : ''}
          </div>
          {view == 'Admin' &&
            <div className="col-md-4">
              <div className="card mb-4" style={{ height: '395px' }}>
                <div className="card-header">Useful Links</div>
                <div className="card-body pt-3">
                  <Link className="d-block list1" to="/website-settings">
                    Website Settings
                  </Link>
                  <Link className="d-block list1" to="/account">
                    Renew/Upgrade Plan
                  </Link>
                  <Link className="d-block list1" to="/notification-settings">
                    Buy SMS
                  </Link>
                  <Link className="d-block list1" to="/notification-settings">
                    WhatsApp API
                  </Link>
                  <Link className="d-block list1" to="/website-promo">
                    Download Website Flyer
                  </Link>
                  <a href="https://help.practive.in" className="d-block list1" target="_blank">
                    Practive Support
                  </a>




                </div>

              </div>
            </div>
          }
          <div className="col-md-4">
            {inView ? <UpcomingHolidays height="395px" /> : ''}
          </div>

        </div>

      </div>
    </>
  );
}


export function ServiceItem(props) {
  const { id, status, period, count, title, parent_only, type, view, user } = props
  return (
    <>
      <Link to={`/tasks?service=${id}&status=${status}&period=${period}${(title && type == 'subtask') ? '&search=subtask: ' + title + '&task_type=Sub Task' : ''}${parent_only == 1 ? '&task_type=Parent Task' : ''}${view == 'User' ? '&users='+user : ''}`}>{count}</Link>
    </>
  )
}

export function TaskStatusCount(props) {
  let navigate = useNavigate();
  const { title, count, icon, period, parent_only, view, user } = props
  return (
    <div className="d-flex align-items-center mb-3 c-pointer action-hover rounded-pill" onClick={() => period ? navigate(`/tasks?status=${title}&period=${period}${parent_only == 1 ? '&task_type=Parent Task' : ''}${view == 'User' ? '&users='+user : ''}`) : null} style={{transition: 'all 0.2s ease-in-out'}}>
      <div className="bg-light d-flex justify-content-center align-items-center me-2 text-secondary" style={{ width: '50px', height: '32px', borderRadius: '22px', fontSize: '18px' }}>{icon}</div>
      <div><small className="text-secondary">{title}</small><b className="d-block"><CountUp end={count} /></b></div>
    </div>
  )
}
export function TasksOverview(props) {
  const { view, is_admin, height } = props;
  let navigate = useNavigate();
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedTab, setTab] = useState('Pending');
  const [itemDetails, setItemDetails] = useState({});
  const [loading, setLoading] = useState({});
  const [parent_only, setTaskCountSetting] = useState(is_admin == 1 ? getStorage('parent_only') ? 1 : 0 : 0);
  const [classic_view, setClassicView] = useState(getStorage('classic_view') ? 1 : 0);

  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const [period, setPeriod] = useState(getStorage('task_widget_period') ? getStorage('task_widget_period') : 'All Time');


  ChartJS.register(CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    PointElement,
    LineElement,
    Title, ArcElement, Tooltip1, Legend);


  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        grid: {
          display: false,
        },
        time: {
          unit: 'day',
        },
        ticks: {
          font: {
            size: 10,
          },
        },
        //display: false
      },
      y: {
        display: false
      }

    },

    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      title: {
        display: false,
      },
    },
  };
  const [optns, setOptns] = useState(options);


  const handleToggleCollapse = async (id) => {
    if (expandedRow === id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(id);
      if (!itemDetails[id]) {
        setLoading((prevState) => ({ ...prevState, [id]: true }));
        instance
          .get("/tasks_matrics?period=" + period + "&view=" + view + "&service=" + id)
          .then(function (response) {
            setLoading((prevState) => ({ ...prevState, [id]: false }));
            setItemDetails((prevState) => ({ ...prevState, [id]: response.data.items }));

            var arr = options;
            arr.scales.x.time.unit = response.data.type;
            setOptns(arr)

          })
          .catch(function (error) { })
          .then(function () { });


      }
    }
  };

  const changeTaskCountSetting = (e) => {
    setTaskCountSetting(e)
    setStorage('parent_only', e)
  }

  const changeClassicView = (e) => {
    setClassicView(e)
    setStorage('classic_view', e)
  }
  const changeTaskPeriod = (e) => {
    setPeriod(e)
    setStorage('task_widget_period', e)
  }


  const filteredServices = state.services && state.services.filter(service => {
    if (classic_view == 1) return true
    if (selectedTab === 'Pending') return service.pending > 0;
    if (selectedTab === 'Hold') return service.hold > 0;
    if (selectedTab === 'In-Progress') return service.in_progress > 0;
    if (selectedTab === 'Completed') return service.completed > 0;
    return false;
  });

  const fetchData = () => {
    setLoader(true)
    instance
      .get("/tasks_matrics?period=" + period + "&view=" + view)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, [period, view]);
  const task_donut_opt = {


    circumference: 360,
    cutout: '80%',
    plugins: {
      legend: {
        display: false,  // This hides the legend
      },
    },

  }


  return (


    <div className="card card-default">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <span>Tasks</span>

            <div>
              <a
                style={{ cursor: 'pointer' }}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MdOutlineMoreVert className="text-secondary c-pointer ms-2" />
              </a>
              <ul className="dropdown-menu dropdown-menu-start">
                {is_admin == 1 ?
                  <li>
                    <button className="dropdown-item" onClick={() => changeTaskCountSetting(parent_only == 1 ? 0 : 1)}>
                      {parent_only == 1 ? 'Show All Tasks Count' : 'Show Parent Tasks Only'}
                    </button>
                  </li>
                  : ''}
                <li>
                  <button className="dropdown-item" onClick={() => changeClassicView(classic_view == 1 ? 0 : 1)}>
                    {classic_view == 1 ? 'Default View' : 'Classic View'}
                  </button>
                </li>

              </ul>
            </div>

          </div>
          <div className="d-flex">
            <div>
              <div className="" style={{ maxWidth: '130px', minWidth: '130px' }}>

                <UiPeriodSelect
                  parentClass="mb-0"
                  onUpdate={(e) => changeTaskPeriod(e)}
                  value={period}
                  size="small"
                  name='date'

                />
              </div>
            </div>
            <UiActioButton title="Add" tooltip="New Task" action={() => navigate('/task/new')}></UiActioButton>
          </div>
        </div>
      </div>
      <div className="card-body pb-0">
        <div className="row">
          <div className="col-lg-7">
            {classic_view == 1 ?
              <div className="bg-light d-flex justify-content-center align-items-center rounded-pill" style={{ height: '50px' }}>
                <div className="container-fluid ">
                  <div className="row">
                    <div className="col-4 text-muted fw-semibold text-uppercase">
                      Service
                    </div>
                    <div className="col-2">
                      <OverlayTrigger overlay={<Tooltip >Pending</Tooltip>} >
                        <span className="fs-4 text-warning justify-content-center sicon"> <MdOutlinePending /></span>
                      </OverlayTrigger>

                    </div>
                    <div className="col-2">
                      <OverlayTrigger overlay={<Tooltip >Hold</Tooltip>} >
                        <span className="fs-4 text-muted justify-content-center sicon"> <MdOutlinePauseCircle /></span>
                      </OverlayTrigger>
                    </div>
                    <div className="col-2">
                      <OverlayTrigger overlay={<Tooltip >In-Progress</Tooltip>} >
                        <span className="fs-4 text-primary justify-content-center sicon"> <MdOutlineAccessTime /></span>
                      </OverlayTrigger>
                    </div>
                    <div className="col-2">
                      <OverlayTrigger overlay={<Tooltip >Completed</Tooltip>} >
                        <span className="fs-4 text-success justify-content-center sicon"><MdCheckCircleOutline /></span>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="tabs2 text-center d-flex">
                <span className={`tab-itm c-pointer flex-grow-1 ${selectedTab == 'Pending' && 'active'}`} onClick={() => setTab('Pending')} >Pending</span>
                <span className={`tab-itm c-pointer flex-grow-1 ${selectedTab == 'Hold' && 'active'}`} onClick={() => setTab('Hold')} >Hold</span>
                <span className={`tab-itm c-pointer flex-grow-1 ${selectedTab == 'In-Progress' && 'active'}`} onClick={() => setTab('In-Progress')} >In-Progress</span>
                <span className={`tab-itm c-pointer flex-grow-1 ${selectedTab == 'Completed' && 'active'}`} onClick={() => setTab('Completed')} >Completed</span>
              </div>
            }
            <div>
              <SimpleBar style={{ height: ((height - 380) < 570 ? 570 : (height - 380)) }}>
                <div className="position-relative" >
                  {loader == true && <InLoader />}
                  <div className="card-body1 pt-3">
                    {filteredServices && filteredServices.length > 0 ?
                      <>

                        {filteredServices.map((val, i) => (
                          <div className={`py-2 container-fluid ${i > 0 && 'border-top'}`} key={i}>


                            <div className="row" >

                              <div className={`${classic_view == 1 ? 'col-4' : 'col-6'}`}>

                                <p className="text-truncate text-start mb-0 " title={val.service}>
                                  {val.subtasks > 0 ?
                                    <button
                                      onClick={() => handleToggleCollapse(val.id)}
                                      aria-controls={`collapse-${val.id}`}
                                      aria-expanded={expandedRow === val.id}
                                      className="btn btn-default btn-sm border-0"
                                    >
                                      <span
                                        style={{
                                          display: 'inline-block',
                                          transition: 'transform 0.3s ease',
                                          transform: expandedRow === val.id ? 'rotate(180deg)' : 'rotate(0deg)',
                                        }}
                                      ><BsChevronDown className="text-secondary" /></span>
                                    </button>
                                    :
                                    <button
                                      disabled
                                      className="btn btn-default btn-sm border-0"
                                    >
                                      <BsDash className="text-secondary" />
                                    </button>
                                  }
                                  {val.service}</p>
                              </div>
                              {(classic_view == 1 || selectedTab == 'Pending') ?
                                <div className="col-2 text-center">
                                  <ServiceItem type="parent" id={val.id} status="Pending" period={period} count={parent_only == 1 ? val.pending_parent : val.pending} parent_only={parent_only} view={view} user={state.user} />
                                </div>
                                :
                                ''}
                              {(classic_view == 1 || selectedTab == 'Hold') ?
                                <div className="col-2 text-center">
                                  <ServiceItem type="parent" id={val.id} status="Hold" period={period} count={parent_only == 1 ? val.hold_parent : val.hold} parent_only={parent_only} view={view} user={state.user} />
                                </div>
                                : ''}
                              {(classic_view == 1 || selectedTab == 'In-Progress') ?
                                <div className="col-2 text-center">
                                  <ServiceItem type="parent" id={val.id} status="In-Progress" period={period} count={parent_only == 1 ? val.in_progress_parent : val.in_progress} parent_only={parent_only} view={view} user={state.user} />
                                </div>
                                : ''}
                              {(classic_view == 1 || selectedTab == 'Completed') ?
                                <div className="col-2 text-center">
                                  <ServiceItem type="parent" id={val.id} status="Completed" period={period} count={parent_only == 1 ? val.completed_parent : val.completed} parent_only={parent_only} view={view} user={state.user} />
                                </div>
                                : ''}

                              {classic_view == 1 ? ''
                                :
                                <div className="col-4">
                                  <div className="progress" style={{ height: '5px', marginTop: '8px' }}>
                                    {parent_only == 1 ?
                                      <div className="progress-bar" role="progressbar" style={{ width: (selectedTab == 'Pending' ? val.pending_parent_p : selectedTab == 'Hold' ? val.hold_parent_p : selectedTab == 'In-Progress' ? val.in_progress_parent_p : selectedTab == 'Completed' ? val.completed_parent_p : 0) + "%" }} aria-valuenow={selectedTab == 'Pending' ? val.pending_parent_p : selectedTab == 'Hold' ? val.hold_parent_p : selectedTab == 'In-Progress' ? val.in_progress_parent_p : selectedTab == 'Completed' ? val.completed_parent_p : 0} aria-valuemin="0" aria-valuemax="100"></div>
                                      :

                                      <div className="progress-bar" role="progressbar" style={{ width: (selectedTab == 'Pending' ? val.pending_p : selectedTab == 'Hold' ? val.hold_p : selectedTab == 'In-Progress' ? val.in_progress_p : selectedTab == 'Completed' ? val.completed_p : 0) + "%" }} aria-valuenow={selectedTab == 'Pending' ? val.pending_p : selectedTab == 'Hold' ? val.hold_p : selectedTab == 'In-Progress' ? val.in_progress_p : selectedTab == 'Completed' ? val.completed_p : 0} aria-valuemin="0" aria-valuemax="100"></div>
                                    }
                                  </div>
                                </div>
                              }
                            </div>

                            <Collapse in={expandedRow === val.id} className="ps-0">
                              <div id={`collapse-${val.id}`} style={{ paddingLeft: '20px' }}>

                                {loading[val.id] ? (
                                  <BgLoader size="small" />
                                ) : (
                                  <div className="" >
                                    {(itemDetails[val.id] && itemDetails[val.id].length > 0) ? itemDetails[val.id].map((subItem, i) => (
                                      <div className="border-top py-2 bg-light" key={i}>
                                        <div className="row">
                                          <div className={`${classic_view == 1 ? 'col-4' : 'col-6'}`}>

                                            <p className="text-truncate text-start mb-0 ps-2" title={subItem.title}>{subItem.title}</p>
                                          </div>
                                          {(classic_view == 1 || selectedTab == 'Pending') ?
                                            <div className="col-2 text-center">
                                              <ServiceItem type="subtask" id={val.id} status="Pending" period={period} title={subItem.title} count={subItem.pending} view={view} user={state.user} />
                                            </div>
                                            : ''}
                                          {(classic_view == 1 || selectedTab == 'Hold') ?
                                            <div className="col-2 text-center">
                                              <ServiceItem type="subtask" id={val.id} status="Hold" period={period} title={subItem.title} count={subItem.hold} view={view} user={state.user} />
                                            </div>
                                            : ''}
                                          {(classic_view == 1 || selectedTab == 'In-Progress') ?
                                            <div className="col-2 text-center">
                                              <ServiceItem type="subtask" id={val.id} status="In-Progress" period={period} title={subItem.title} count={subItem.in_progress} view={view} user={state.user} />
                                            </div>
                                            : ''}
                                          {(classic_view == 1 || selectedTab == 'Completed') ?
                                            <div className="col-2 text-center">
                                              <ServiceItem type="subtask" id={val.id} status="Completed" period={period} title={subItem.title} count={subItem.completed} view={view} user={state.user} />
                                            </div>
                                            : ''}
                                        </div>
                                      </div>
                                    ))
                                      :
                                      <p className="text-secondary my-2 text-center">No subtasks found.</p>
                                    }
                                  </div>
                                )}
                              </div>
                            </Collapse>
                          </div>

                        ))}
                      </>
                      : filteredServices && filteredServices == 0 && <TableZero title="Tasks" />}

                  </div>
                </div>
              </SimpleBar>
            </div>
          </div>

          <div className="col-lg-5  mb-4 mt-md-0 mt-4 position-relative">
            <div className="" >
              {loader == true && <InLoader />}
              <h2 className="fs-5 fw-semibold mb-3">Task Summary</h2>
              <div className="row">
                <div className="col-7">
                  {state.task_status &&
                    <div>
                      <TaskStatusCount title="Pending" count={parent_only == 1 ? state.task_status.pending_parent : state.task_status.pending} icon={<BsExclamationLg className="text-warning"/>} period={period} parent_only={parent_only} view={view} user={state.user}/>
                      <TaskStatusCount title="Hold" count={parent_only == 1 ? state.task_status.hold_parent : state.task_status.hold} icon={<BsPause />} period={period} parent_only={parent_only} view={view} user={state.user}/>
                      <TaskStatusCount title="In-Progress" count={parent_only == 1 ? state.task_status.in_progress_parent : state.task_status.in_progress} icon={<BsClockHistory className="text-primary"/>} period={period} parent_only={parent_only} view={view} user={state.user}/>
                      <TaskStatusCount title="Completed" count={parent_only == 1 ? state.task_status.completed_parent : state.task_status.completed} icon={<IoCheckmarkOutline className="text-success" />} period={period} parent_only={parent_only} view={view} user={state.user} />
                    </div>
                  }
                </div>
                <div className="col-5 pt-5">
                  {state.status_data && state.services.length > 0 &&
                    <div className="d-flex justify-content-center">

                      <Doughnut data={parent_only == 1 ? state.status_data_parent : state.status_data} options={task_donut_opt} />

                    </div>
                  }
                </div>
              </div>


              {state.status_data && state.services.length == 0 && <TableZero title="Data" />}

            </div>
            <div className="my-4">
              <div className="bg-primary bg-opacity-10 rounded-pill px-4 py-2 c-pointer" onClick={() => navigate(`/tasks?status=Pending,Hold,In-Progress&period=${period}${parent_only == 1 ? '&task_type=Parent Task' : ''}${view == 'User' ? '&users='+state.user : ''}`)}>
                <div className="d-flex justify-content-between align-items-center">

                  <span className="text-secondary text-truncate">Need to complete tasks</span>
                  <span className="fs-3 fw-bolder"><CountUp end={parent_only == 1 ? state.uncompleted_parent : state.uncompleted} /></span>
                </div>




              </div>
            </div>
            <h2 className="fs-5 fw-semibold mb-3">Performance</h2>
            <div>

              {state.items1 &&
                <Line options={optns} data={state.items1} />
              }
            </div>

          </div>
        </div >
      </div>
    </div>


  )
}




export function BestPerformer(props) {
  let navigate = useNavigate();

  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const [period, setPeriod] = useState(getStorage('bp_widget_period') ? getStorage('bp_widget_period') : 'This Month');


  const fetchData = () => {
    setLoader(true)
    instance
      .get("/best_performer?period=" + period)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, [period]);

  const changePeriod = (e) => {
    setPeriod(e)
    setStorage('bp_widget_period', e)
  }


  return (
    <div className="card card-default">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <span>Best Performer</span>
          <div className="d-flex align-items-center">
            <div className="" style={{ minWidth: '122px' }}>

              <UiDateRange
                parentClass="mb-0"
                onUpdate={(e) => changePeriod(e)}
                value={period}
                name='date'
                size="small"
              />
            </div>
            <UiActioButton title="Go" className="fs-4" tooltip="Employees Report" action={() => navigate("/employees-report")}></UiActioButton>
          </div>
        </div>
      </div>
      <SimpleBar style={{ height: 473 }}>
        <div className="position-relative">
          {loader == true && <InLoader />}
          <div className="card-body">
            {state.items && state.items.length > 0 ?
              <>
                <div className="d-flex mb-2 text-uppercase fw-semibold text-muted">
                  <div className="col-6">
                    User
                  </div>
                  <div className="col text-center text-truncate">
                    Total

                  </div>
                  <div className="col text-center text-truncate">
                    Completed
                  </div>
                  <div className="col  text-center text-truncate">
                    %
                  </div>

                </div>

                {state.items.map((val, i) => (
                  <div className="d-flex border-top align-items-center py-2" key={i}>
                    <div className="col-6">
                      <span className="d-inline-flex align-items-center  mt-1">
                        <OverlayTrigger overlay={<Tooltip >{val.name}</Tooltip>} >
                          <span className="me-2 d-flex">
                            <Avatar name={val.name} className="bg-secondary d-inline-flex " src={val.photo} size="30" textSizeRatio={3} round={true} />
                          </span>
                        </OverlayTrigger>
                        <span>{val.name}<br></br> <small className="text-secondary">{val.role}</small></span>

                      </span>
                    </div>
                    <div className="col text-center"><Link to={`/tasks?users=${val.id}&date=${period}`}>{val.total}</Link></div>
                    <div className="col text-center"><Link to={`/tasks?users=${val.id}&status=Completed&date=${period}`}>{val.completed}</Link></div>
                    <div className="col text-center text-secondary">{Math.round(val.completed / val.total * 100)}%</div>
                  </div>

                ))}
              </>
              : state.items && state.items.length == 0 && <TableZero title="Tasks" />}

          </div>
        </div>
      </SimpleBar>
    </div>
  )
}



export function Attendance(props) {
  let navigate = useNavigate();

  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const [period, setPeriod] = useState(getStorage('att_widget_period') ? getStorage('att_widget_period') : 'This Month');


  const fetchData = () => {
    setLoader(true)
    instance
      .get("/attendance_widget?period=" + period)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, [period]);


  const changePeriod = (e) => {
    setPeriod(e)
    setStorage('att_widget_period', e)
  }

  return (
    <div className="card card-default">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <span>Attendance</span>
          <div className="d-flex align-items-center">
            <div className="" style={{ minWidth: '122px' }}>

              <UiDateRange
                parentClass="mb-0"
                onUpdate={(e) => changePeriod(e)}
                value={period}
                name='date'
                size="small"
              />
            </div>
            <UiActioButton title="Go" className="fs-4" tooltip="Attendance Report" action={() => navigate("/attendance-report")}></UiActioButton>
          </div>
        </div>
      </div>
      <SimpleBar style={{ height: 473 }}>
        <div className="position-relative ">
          {loader == true && <InLoader />}
          <div className="card-body">
            {state.items && state.items.length > 0 ?
              <>
                <div className="d-flex mb-2 text-uppercase fw-semibold text-muted">
                  <div className="col-6">
                    User
                  </div>
                  <div className="col text-center text-truncate">
                    Present

                  </div>
                  <div className="col text-center text-truncate">
                    Absent
                  </div>
                  <div className="col text-center text-truncate">
                    %
                  </div>

                </div>

                {state.items.map((val, i) => (
                  <div className="d-flex border-top align-items-center py-2" key={i}>
                    <div className="col-6">
                      <span className="d-inline-flex align-items-center  mt-1">
                        <OverlayTrigger overlay={<Tooltip >{val.name}</Tooltip>} >
                          <span className="me-2 d-flex">
                            <Avatar name={val.name} className="bg-secondary d-inline-flex " src={val.photo} size="30" textSizeRatio={3} round={true} />
                          </span>
                        </OverlayTrigger>
                        <span>{val.name}<br></br> <small className="text-secondary">{val.role}</small></span>

                      </span>
                    </div>
                    <div className="col text-secondary text-center">{val.present}</div>
                    <div className="col text-secondary text-center">{val.absent}</div>
                    <div className="col text-secondary text-center">{Math.round(parseInt(val.present) / (parseInt(val.present) + parseInt(val.absent)) * 100)}%</div>
                  </div>

                ))}
              </>
              : state.items && state.items.length == 0 && <TableZero title="Tasks" />}

          </div>
        </div>
      </SimpleBar>
    </div>
  )
}





export function NewClients(props) {
  let navigate = useNavigate();

  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const [period, setPeriod] = useState(getStorage('clients_widget_period') ? getStorage('clients_widget_period') : 'This Month');


  const fetchData = () => {
    setLoader(true)
    instance
      .get("/new_client_count?period=" + period)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, [period]);


  const changePeriod = (e) => {
    setPeriod(e)
    setStorage('clients_widget_period', e)
  }
  const client_donut_opt = {

    rotation: -90,
    circumference: 180,

  }


  return (
    <div className="card card-default" style={{ height: '353px' }}>
      {loader == true && <InLoader />}
      <div className="card-body p-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="fs-5 mb-0 fw-semibold">New Clients {state.new_clients > 0 && <span className="badge bg-primary">{state.new_clients}</span>}</h2>
          <div className="d-flex align-items-center">
            <div className="" style={{ minWidth: '30px' }}>

              <UiDateRange
                parentClass="mb-0"
                onUpdate={(e) => changePeriod(e)}
                value={period}
                name='date'
                size="xs"
              />
            </div>
          </div>
        </div>

        {state.client_data && state.client_data.labels.length > 0 &&
          <div className="d-flex justify-content-center" style={{ height: '263px' }}>
            <Doughnut options={client_donut_opt} data={state.client_data} />
          </div>
        }
        {state.client_data && state.client_data.labels.length == 0 && <TableZero title="Data" />}
      </div>
    </div>
  )
}



export function TaskLineChart(props) {
  const { view } = props
  let navigate = useNavigate();
  const [period, setPeriod] = useState(getStorage('tp_widget_period') ? getStorage('tp_widget_period') : 'This Month');

  ChartJS.register(CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    PointElement,
    LineElement,
    Title, ArcElement, Tooltip1, Legend);

  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        grid: {
          display: false,
        },
        time: {
          unit: 'day',
        },
      },

    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      title: {
        display: false,

      },
    },
  };
  const [optns, setOptns] = useState(options);

  const fetchData = () => {
    setLoader(true)
    instance
      .get("/tasks_performance?period=" + period + "&view=" + view)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });

        if (response.data.type) {

          var arr = options;
          arr.scales.x.time.unit = response.data.type;
          setOptns(arr)
        }

      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, [period, view]);


  const changePeriod = (e) => {
    setPeriod(e)
    setStorage('tp_widget_period', e)
  }


  return (
    <div className="card card-default" style={{ height: '550px' }}>
      <div className="card-header"><div className="d-flex justify-content-between">
        <span>Performance</span>
        <div className="d-flex align-items-center">
          <div className="" style={{ minWidth: '122px' }}>

            <UiDateRange
              parentClass="mb-0"
              onUpdate={(e) => changePeriod(e)}
              value={period}
              name='date'
              size="small"
            />
          </div>
        </div>
      </div></div>

      {loader == true && <InLoader />}
      <div className="card-body">
        {state.items &&
          <Line options={optns} data={state.items} />}


      </div>
    </div>
  )

}



export function AvgHours(props) {

  const { period, view, height } = props


  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  }

  const fetchData = () => {
    setLoader(true)
    instance
      .get("/avg_task_time?period=" + period + "&view=" + view)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, [period, view]);



  return (
    <div className="card card-default text-bg-warning mb-4" style={{ height: height }}>


      {loader == true && <InLoader />}
      <div className="card-body" style={{ backgroundImage: `url(${avgtimeImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom,center', backgroundSize: '80%' }}>

        {state.avg_hours && state.avg_hours > 0 ?
          <>
            <h3 className="fw-bold fs-1">{toHoursAndMinutes(state.avg_hours).hours}:{toHoursAndMinutes(state.avg_hours).minutes} Hours </h3>
            <p className="text-dark text-opacity-75">Avg. time spent to complete task in last 30 days.</p>
          </> : <h3 className="fw-light">No Data</h3>
        }

      </div>
    </div>
  )
}




export function UpcomingHolidays(props) {

  const { period, view, height, sheight } = props


  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);


  const fetchData = () => {
    setLoader(true)
    instance
      .get("/upcoming_holidays")
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, []);



  return (
    <div className="card card-default" style={{ height: height }}>
      <div className="card-header">
        Upcoming Holidays
      </div>

      {loader == true && <InLoader />}
      <div className="card-body pt-3">
        <SimpleBar style={{ height: sheight }}>
          {state.items && state.items.length == 0 && <TableZero title="Data" />}
          {state.items && state.items.map((val, i) => (



            <div className="d-flex list1 justify-content-between" key={i}>
              <span className="text-truncate">{val.title}</span>
              <span className="text-secondary" style={{ minWidth: '80px' }}>{val.date}</span>

            </div>

          ))
          }
        </SimpleBar>

      </div>
    </div>
  )
}




