import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiSelect, UiRSelect, BgLoader, InLoader, UiDateRange,  UiActioButton, PageError, UiInput, UiClientSelect } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { rupees } from "../../helper";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


export default function ExpensesReport() {
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.clients);
  const setZState = useStore((state) => state.setClients);
  const [pagenum, setPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);


  const schema = yup.object().shape(
    {


    }
  );


  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    watch,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });



  const onSubmitHandler = async (data) => {

    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate("/expenses-report?" + u, { replace: true })
    } else {
      navigate("/expenses-report?" + u)
    }


  };



  const [loadedOptions, setOptions] = useState({});


  const client_w = watch('client');
  useEffect(() => {
    if (client_w == null) {
      setOptions({})
    }
  }, [client_w]);




  const headers = [
    { label: "Date", key: "date" },
    { label: "Category", key: "category" },
    { label: "Notes", key: "notes" },
    { label: "Client", key: "name" },
    { label: "File No.", key: "file" },
    { label: "Group", key: "group_name" },
    { label: "Payment Mode", key: "mode" },
    { label: "Amount", key: "amount" },
    { label: "Billing Status", key: "is_billed" },
    { label: "Invoice No.", key: "invoice_number" },



  ];





  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});


  const exportModal = useModal('export-all');
  const exportAll = () => {
    exportModal.show({ type: '/expenses_report', filter: window.location.search, header: headers, file: 'expenses-report.csv' }).then((res) => {
    });
  };



  async function fetchData(filter) {

    const { data } = await instance.get(
      "/expenses_report" + filter
    );

    var dataa = data;
    return dataa;
  }

  const defaultValues = {
    search: null,
    client: null,
    date: null,
    category: null,
    mode: null,
    user: null,
    is_billed: null,
    client_expense: null
  };

  const clearFilter = () => {
    reset(defaultValues)
    navigate("/expenses-report", { replace: true })
  }


  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);


  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);


  const columns = [
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
      sortField: 'date',
      id: 'date',
      width: '120px',
    },
    {
      name: 'Category',
      sortable: true,
      sortField: 'category',
      id: 'category',
      selector: row => row.category,
      cell: row => (<span className="text-truncate" title={row.category}><Link to={`/view-expense/${row.id}`}><b>{row.category}</b></Link></span>),
      width: '180px',
    },
    {
      name: 'Mode',
      sortable: true,
      sortField: 'mode',
      id: 'mode',
      selector: row => row.mode,
      width: '120px',
      cell: row => (<span className="text-truncate" title={row.mode}>{row.mode}</span>),
    },
    {
      name: 'Notes',
      selector: row => row.notes,
      cell: row => (<span className="text-truncate" title={row.notes}>{row.notes}</span>),
      width: '200px',
    },
    {
      name: 'Client',
      sortable: true,
      sortField: 'name',
      id: 'name',
      selector: row => row.name,
      width: '180px',
      cell: row => (<span className="text-truncate" title={row.name}><Link to={`/view-client/${row.cust_id}`}>{row.name}</Link></span>)
    },
    {
      name: 'File No.',
      selector: row => row.file,
      width: '140px'
    },
    {
      name: 'Group',
      selector: row => row.group_name,
      cell: row => (<span className="text-truncate" title={row.group_name}>{row.group_name}</span>),
      width: '160px',
    },


    {
      name: 'Amount',
      sortable: true,
      sortField: 'amount',
      id: 'amount',
      selector: row => row.amount,
      cell: row => (<span>{rupees(row.amount)}</span>),
      width: '150px',
    },

    {
      name: 'Billing',
      sortable: true,
      sortField: 'is_billed',
      id: 'is_billed',
      selector: row => row.is_billed,
      cell: row => (<span>{row.is_billed}</span>),
      width: '100px',
    },

    {
      name: 'Invoice No.',
      sortable: true,
      sortField: 'invoice_number',
      id: 'invoice_number',
      selector: row => row.invoice_number,
      cell: row => (<span>{row.invoice_number}</span>),
      width: '140px',
    },


    {
      name: 'Created by',
      selector: row => row.creator_name,
      cell: row => (<span className="text-truncate" title={row.creator_name}>{row.creator_name}</span>),
      width: '160px',
    },

  ];






  async function fetchData1(id) {

    const { data } = await instance.get(
      "/filters?q=" + id
    );
    var dataa = data;
    return dataa;
  }
  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate('/expenses-report?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate('/expenses-report?' + currentUrlParams.toString())
      }


    }
  };

  const handleSort = (column, sortDirection) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('sort_by', column.sortField);
    currentUrlParams.set('sort', sortDirection);
    if (location.search) {
      navigate('/expenses-report?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/expenses-report?' + currentUrlParams.toString())
    }

  };


  const { data, error, isError, isLoading, isFetching } = useQuery(["expenses_report", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });

  const { data: f_data, isLoading: f_is_loading } = useQuery(["filters", 'UEM'], () => fetchData1('UEM'), {
    keepPreviousData: true,
  });

  useEffect(() => {

    if (f_data) {
      const defaultValues1 = {
        search: filter.search,
        date: filter.date,
        is_billed: filter.is_billed,
        client_expense: filter.client_expense,
        client: filter.client && ({ value: parseInt(filter.client.split('::')[0]), label: filter.client.split('::')[1] }),
        category: f_data.expense_categories && filter.category ? f_data.expense_categories.filter(({ value }) => filter.category.split(',').includes(value.toString())) : null,
        mode: f_data.payment_modes && filter.mode ? f_data.payment_modes.filter(({ value }) => filter.mode.split(',').includes(value.toString())) : null,
        user: f_data.users && filter.user ? f_data.users.filter(({ value }) => filter.user.split(',').includes(value.toString())) : null,
      };
      reset(defaultValues1)
    }
  }, [f_data, filter]);


  if (isLoading) {
    return <BgLoader />;
  }
  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Expenses Report | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Expenses Report" >
            {data.items &&
              <div className="btn-group1">
                <a style={{ cursor: 'pointer' }} data-bs-toggle="dropdown" aria-expanded="false" className={`btn btn-outline-primary d-inline-flex me-2 ${data.items.length == 0 && 'disabled'}`}>Export</a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <CSVLink data={data.items} filename={"expenses-report.csv"} headers={headers} className="dropdown-item" >Export view</CSVLink>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={() => exportAll()}>
                      Export all
                    </button>
                  </li>
                </ul>
              </div>

            }

          </PageHeader>


          <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            {f_is_loading == true && <InLoader />}
            {f_data && (
              <div className="p-3 bg-light rounded mb-4 ">

                <div className="row">


                  <div className="col">
                    <label className="form-label">Date</label>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <UiInput
                      label="Search"
                      type="text"

                      name="search"

                      {...register("search")}
                    />
                  </div>


                  <div className={`col-md-3`}>
                    <Controller
                      name="category[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.expense_categories}
                          className=""
                          isMulti

                          label="Category"
                          {...field}
                        />

                      )}
                    />

                  </div>




                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="mode[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.payment_modes}
                          className=""
                          isMulti

                          label="Payment Mode"
                          {...field}
                        />

                      )}
                    />
                  </div>


                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="user[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.users}
                          className=""
                          isMulti

                          label="Created By"
                          {...field}
                        />

                      )}
                    />
                  </div>


                  <div className={`col-md-2 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="client"
                      control={control}
                      render={({ field }) => (

                        <UiClientSelect
                          {...field}
              
                          className=""
                          message={errors.client?.message}
                          label="Client"
                        />

                      )}
                    />
                  </div>

                  <div className={`col-md-2 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiSelect
                      label="Billing Status"
                      options={['Billed', 'Unbilled']}
                      //defaultValue={val.value}
                      name="is_billed"

                      {...register('is_billed')}
                    />
                  </div>

                  <div className={`col-md-2 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiSelect
                      label="Client Expense"
                      options={['Yes', 'No']}
                      //defaultValue={val.value}
                      name="client_expense"

                      {...register('client_expense')}
                    />
                  </div>
                  <div className={`col ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  </div>


                  <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '200px' }}>
                    <div className="d-flex justify-content-end">
                      {zstate.more_filter == true ? (
                        <UiActioButton type="button" className="fs-3 text-dark" title="Less" tooltip="Hide More Filter" action={() => setZState({ ...zstate, more_filter: false })} />
                      ) : (

                        <UiActioButton type="button" className="fs-3 text-dark" title="More" tooltip="More Filter" action={() => setZState({ ...zstate, more_filter: true })} />
                      )}

                      <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                      <UiActioButton type="submit" className="fs-3" tooltip="Apply Filter" title="Go" />
                    </div>

                  </div>
                </div>



              </div>
            )}
          </form>


          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="p-3 rounded bg-light border-dashed h-100 d-flex align-items-end">
                <div>
                  <span className="fs-3 lh-1">{rupees(data.stats.total)}</span>
                  <span className="mt-2 fw-semibold d-block text-muted">Total Amount</span>
                </div>
              </div>
            </div>
          </div>


          <div className="card card-default position-relative">
            {isFetching && <InLoader />}
            <div className="card-body fcolumn tablecard">

              <DataTable
                columns={columns}
                striped={true}
                pagination
                paginationServer
                paginationPerPage="20"
                paginationDefaultPage={pagenum}
                paginationResetDefaultPage={true}
                paginationTotalRows={data.total}
                clearSelectedRows={toggleCleared}
                onSort={handleSort}
                sortServer
                persistTableHead
                defaultSortFieldId={parsed.sort_by ? parsed.sort_by : null}
                defaultSortAsc={parsed.sort == 'desc' ? false : true}
                disabled={tableDisable}
                paginationComponentOptions={{ noRowsPerPage: true }}
                data={data.items}
                onChangePage={handlePageChange}
                noDataComponent={<TableZero title="Expenses" />}

              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}
