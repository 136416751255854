import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, Toastcontent, UiButton, PageError, UiRSelect, BgLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const BulkServiceAssign = NiceModal.create(
  ({ client_id }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      // day: yup.string().required("Select day of month"),

    });






    const {
      register,
      handleSubmit,
      control,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#assignservicesform");
      var data = new FormData(form);
      data.append('status', 2)

      instance({
        method: "post",
        url: "/assign_service",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task" />, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task" />, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };




    const fetchData = () => {
      instance
        .get("/client_services?id=" + client_id)
        .then(function (response) {
          setState({ ...state, ...response.data });
          if (response.data.status == 'success') {

            var slctd = response.data.services.filter((x) => x.selected == 1);
            reset({ services: slctd });
          } else {
            toast(response.data.msg, { type: "error" });
          }


        })
        .catch(function (error) { })
        .then(function () { });

    }

    useEffect(() => {
      fetchData();
    }, []);



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    const watchReplace = watch("replace");

    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Assign Services</BootstrapModal.Title>
        </BootstrapModal.Header>
        {state.status == 'success' ? (
          <form id="assignservicesform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <BootstrapModal.Body>

              <UiToggle
                id="servicereplace"
                name="replace"
                title="Unassign services that are currently assigned"
                {...register("replace")}
              />

              {watchReplace && <div className="alert alert-warning">Note: Services that were assigned with the package will not be unassigned.</div>}

              <Controller
                name="services[]"
                control={control}
                render={({ field }) => (

                  <UiRSelect
                    {...field}
                    className=""
                    isMulti
                    message={errors.services?.message}
                    label="Services"
                    options={state.services}
                    closeMenuOnSelect={false}

                  />

                )}
              />



              {client_id && (<input type="hidden" value={client_id} name="cust_id"></input>)}



            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Save" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>
        ) : state.status == 'error' ? <PageError msg={state.msg} code={state.code} /> : <BgLoader />}
      </BootstrapModal>
    );
  }
);

export default BulkServiceAssign;
