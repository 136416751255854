import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { UiSelect, UiToggle, UiInput, UiButton, UiTextArea, UiRichText, UiRSelect, BgLoader, PageHeader, PageError } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from "react-helmet";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



export default function GeneralNotification() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});

  const schema = yup.object().shape({

    whatsapp_status: yup.bool(),
    email_status: yup.bool(),
    sms: yup.string(),
    id: yup.string(),

    time: yup.string().nullable().when('id', {
      is: (a) => a == 'birthday' || a == 'payment-reminder' || a == 'dsc-reminder',
      then: yup.string().nullable().required("Select notification time")
    }),

    send_before: yup.string().nullable().when('id', {
      is: (a) => a == 'dsc-reminder',
      then: yup.string().nullable().required("Select no. of days")
    }),

    frequency: yup.string().nullable().when('id', {
      is: "payment-reminder",
      then: yup.string().nullable().required("Select notification frequency")
    }),

    day: yup.array().nullable().when(["id", "frequency"], {
      is: (a, b) => a == "payment-reminder" && b == 'Weekly',
      then: yup.array().nullable().min(1, 'Select day').required("Select day")
    }),

    month_day: yup.array().nullable().when(["id", "frequency"], {
      is: (a, b) => a == "payment-reminder" && b == 'Monthly',
      then: yup.array().nullable().min(1, 'Select day').required("Select day")
    }),

    sms_template: yup.string().nullable().when(["sms_status", "sms"], {
      is: (a, b) => a == true && b == 1,
      then: yup.string().nullable().required("Select SMS template")
    }),
    whatsapp_template: yup.string().nullable().when("whatsapp_status", {
      is: true,
      then: yup.string().nullable().required("Select WhatsApp template")
    }),



    sms_text: yup.string().nullable().when(["sms_status", "sms"], {
      is: (a, b) => a == true && b == 2,
      then: yup.string().nullable().required("SMS template is required").max(800, 'Maximum character limit is 800')
    }),
    sms_dlt_id: yup.string().nullable().when(["sms_status", "sms"], {
      is: (a, b) => a == true && b == 2,
      then: yup.string().nullable().required("DLT template ID is required").max(20, 'Maximum character limit is 20')
    }),

    sms_lang: yup.string().nullable().when(["sms_status", "sms"], {
      is: (a, b) => a == true && b == 2,
      then: yup.string().nullable().required("Select SMS language")
    }),




    email_template: yup.string().nullable().when('email_status', {
      is: true,
      then: yup.string().nullable().required("Email template is required").max(3000, 'Maximum character limit is 3000')
    }),
    email_subject: yup.string().nullable().when('email_status', {
      is: true,
      then: yup.string().nullable().required("Email subject is required").max(100, 'Maximum character limit is 100')
    }),

  });
  var handleSave = (e) => {
    setState({ ...state, loader: true });
    var form = document.querySelector("#gennotificationfrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/update_general_notification",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['general-notifications'])
          toast(response.data.msg, { type: "success" });
        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    resetField,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave();
  };




  const fetchData = () => {
    instance
      .get("/general_notification?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });

        reset(response.data.notification)



      })
      .catch(function (error) { })
      .then(function () { });

  }
  const userModal = useModal('select-template');

  const showTemplates = (row, row1, type) => {

    userModal.show({ data: row, active: row1, type: type }).then((res) => {
      if (type == 'sms') {
        setState({ ...state, notification: { ...state.notification, sms_template: res.newid } });
        resetField("sms_template", { defaultValue: res.newid })
      } else {
        setState({ ...state, notification: { ...state.notification, whatsapp_template: res.newid } });
        resetField("whatsapp_template", { defaultValue: res.newid })
      }
    });

  }

  const VariableCopy = (props) => {
    const { items } = props;
    return (
      <>
        {items.map((val, ix) => (
          <OverlayTrigger key={ix} overlay={<Tooltip >Click to copy</Tooltip>} >

            <CopyToClipboard className="btn btn-light me-2 btnpulse" text={val}>
              <span>{val}</span>
            </CopyToClipboard>

          </OverlayTrigger>
        ))}
      </>
    )
  }

  useEffect(() => {
    fetchData();

  }, []);



  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const weekdays = [];

  weekday.map(WeekGen);

  function WeekGen(val, index, array) {
    return weekdays.push({ label: val, value: val });
  }
  const monthday = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th', '20th', '21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th', 'Last Day'];

  const monthdays = [];

  monthday.map(monthGen);

  function monthGen(val, index, array) {
    return monthdays.push({ label: val, value: val });
  }


  const fifteenDays = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];






  const two = [{ value: '0', label: "English" }, { value: '1', label: "Unicode" }];
  const times = ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];

  const watchFreq = watch("frequency"); // you can supply default value as second argument


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      <Helmet>
        {state.notification && (
          <title>
            {state.notification.title} | Notifications | Practive
          </title>
        )}
      </Helmet>
      <div>
        {state.notification ? (
          <div>

            <PageHeader title={state.notification.title} parent="General Notifications" link="/general-notifications">

            </PageHeader>


            <form id="gennotificationfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>

              {id == 'birthday' && (
                <div className="card card-default mb-4">
                  <div className="card-header">
                    Notification Time
                  </div>
                  <div className="card-body">
                    <div className="col-sm-3">
                      <UiSelect

                        label="Time"
                        name="time"
                        required={true}
                        options={times}
                        message={errors.time?.message}
                        {...register("time")}
                      />
                    </div>
                  </div>
                </div>
              )}


              {id == 'payment-reminder' && (
                <div className="card card-default mb-4">
                  <div className="card-header">
                    Reminder Frequency
                  </div>
                  <div className="card-body">
                    <div className="row">

                      <div className="col-md-4">
                        <UiSelect
                          required={true}
                          label="Select Frequency"
                          name="frequency"
                          options={['Daily', 'Weekly', 'Monthly']}
                          message={errors.frequency?.message}
                          {...register("frequency")}
                        />
                      </div>
                      {watchFreq == 'Weekly' && (
                        <div className="col-md-4">
                          <Controller
                            name="day[]"
                            control={control}
                            render={({ field }) => (

                              <UiRSelect
                                {...field}
                                required={true}
                                isMulti
                                options={weekdays}
                                className=""
                                message={errors.day?.message}
                                label="Select Day"
                              />

                            )}
                          />
                        </div>
                      )}
                      {watchFreq == 'Monthly' && (
                        <div className="col-md-4">
                          <Controller
                            name="month_day[]"
                            control={control}
                            render={({ field }) => (

                              <UiRSelect
                                {...field}
                                isMulti
                                required={true}
                                options={monthdays}
                                className=""
                                message={errors.month_day?.message}
                                label="Select Day"
                              />

                            )}
                          />
                        </div>
                      )}
                      <div className="col-md-4">
                        <UiSelect
                          label="Time"
                          name="time"
                          required={true}
                          options={times}
                          message={errors.time?.message}
                          {...register("time")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}


              {id == 'dsc-reminder' && (
                <div className="card card-default mb-4">
                  <div className="card-header">
                    Reminder Condition
                  </div>
                  <div className="card-body">
                    <div className="row">


                      <div className="col-md-4">
                        <UiSelect
                          label="Send before expiry (No. of days)"
                          name="send_before"
                          required={true}
                          options={fifteenDays}
                          message={errors.send_before?.message}
                          {...register("send_before")}
                        />

                      </div>

                      <div className="col-md-4">
                        <UiSelect
                          label="Time"
                          name="time"
                          required={true}
                          options={times}
                          message={errors.time?.message}
                          {...register("time")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-6">

                  <div className="card card-default mb-4">
                    <div className="card-header">
                      SMS
                    </div>
                    <div className="card-body">
                      <UiToggle
                        id="sms"
                        name="sms_status"
                        title="Enable SMS Notification"
                        {...register("sms_status")}
                      />
                      <input type="hidden" name="id" {...register("id")} />
                      <input type="hidden" name="sms"  {...register("sms")} />
                      {state.notification.sms == 1 ? (
                        <div>
                          <div className="mb-4 ">
                            <label className="form-label fs-6 fw-bolder text-dark d-block">Template</label>


                            {state.notification.sms_template > 0 ? (
                              <>

                                <div className="p-2 border rounded">{state.sms_templates[state.notification.sms_template].template}</div>

                                <a className="btn btn-sm btn-secondary mt-3" onClick={() => showTemplates(state.sms_templates, state.notification.sms_template, 'sms')}>Change Template</a>
                              </>
                            ) : (
                              <>
                                <a className="btn btn-sm btn-secondary" onClick={() => showTemplates(state.sms_templates, state.notification.sms_template, 'sms')}>Select Template</a>
                              </>
                            )}
                            {errors.sms_template && (<div className="invalid-feedback d-block">{errors.sms_template?.message}</div>)}
                          </div>
                          <input type="hidden" name="sms_template" {...register("sms_template")} />

                        </div>
                      ) : (
                        <div>
                          <UiTextArea
                            label="Template"
                            name="sms_text"
                            placeholder="Type your message"
                            message={errors.sms_text?.message}
                            {...register("sms_text")}
                          />
                          {state.shortcodes && (
                            <div className="mb-3">
                              <b>Variables - </b>
                              <VariableCopy items={state.shortcodes} />

                            </div>
                          )}
                          <div className="row">
                            <div className="col-sm-6">
                              <UiSelect
                                label="Language"
                                name="sms_lang"
                                options={two}
                                message={errors.sms_lang?.message}
                                {...register("sms_lang")}
                              />
                            </div>
                            <div className="col-sm-6">
                              <UiInput
                                label="DLT Template ID"
                                type="text"
                                name="sms_dlt_id"
                                message={errors.sms_dlt_id?.message}
                                {...register("sms_dlt_id")}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-default mb-4">
                    <div className="card-header">
                      WhatsApp
                    </div>
                    <div className="card-body">
                      <UiToggle
                        id="wp"
                        name="whatsapp_status"
                        title="Enable WhatsApp Notification"
                        {...register("whatsapp_status")}
                      />

                      <div>
                        <div className="mb-4 ">
                          <label className="form-label fs-6 fw-bolder text-dark d-block">Template</label>


                          {state.notification.whatsapp_template > 0 ? (
                            <>

                              <div className="p-2 border rounded">{state.wa_templates[state.notification.whatsapp_template].template}</div>

                              <a className="btn btn-sm btn-secondary mt-3" onClick={() => showTemplates(state.wa_templates, state.notification.whatsapp_template, 'whatsapp')}>Change Template</a>
                              <div className="mt-3 mb-3">
                                <UiInput
                                  label="Message Body Variables"
                                  type="text"
                                  parentClass="mb-1"
                                  name="wa_vars"
                                  message={errors.wa_vars?.message}
                                  {...register("wa_vars")}
                                />
                                <small>Enter required variables in WhatsApp message template. (Use comma in case of multiple variables)<br></br></small>
                                <small>Example: {`{CLIENT_NAME}, {YOUR_COMPANY_NAME}`}</small>
                              </div>
                              {state.shortcodes && (
                                <div className="mb-3">
                                  <b>Variables - </b>
                                  <VariableCopy items={state.shortcodes} />

                                </div>
                              )}

                            </>
                          ) : (
                            <>
                              <a className="btn btn-sm btn-secondary" onClick={() => showTemplates(state.wa_templates, state.notification.whatsapp_template, 'whatsapp')}>Select Template</a>
                            </>
                          )}
                          {errors.whatsapp_template && (<div className="invalid-feedback d-block">{errors.whatsapp_template?.message}</div>)}
                        </div>
                        <input type="hidden" name="whatsapp_template" {...register("whatsapp_template")} />

                      </div>

                    </div>
                  </div>

                </div>
              </div>
              <div className="card card-default mb-4">
                <div className="card-header">
                  Email
                </div>
                <div className="card-body">
                  <UiToggle
                    id="email"
                    name="email_status"
                    title="Enable Email Notification"
                    {...register("email_status")}
                  />

                  <div>
                    <UiInput
                      label="Email Subject"
                      type="text"
                      name="email_subject"
                      message={errors.email_subject?.message}
                      {...register("email_subject")}
                    />
                    <Controller
                      name="email_template"
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiRichText

                          onChange={onChange}
                          value={value}
                          ref={ref}
                          label="Email Template"
                          placeholder="Type your message"
                          name="email_template"
                          message={errors.email_template?.message}
                        />
                      )}
                    />
                    {state.shortcodes && (
                      <div className="mb-3">
                        <b>Variables - </b>
                        <VariableCopy items={state.shortcodes} />

                      </div>
                    )}


                  </div>

                </div>
              </div>

              <UiButton loading={state["loader"]} title="Save" />
            </form>

          </div>

        ) : <BgLoader />}
      </div>
    </>
  );
}
