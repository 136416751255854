import React, { forwardRef, useState, useEffect } from "react";
import { format, isValid, parse } from 'date-fns';
import { useStopwatch } from 'react-timer-hook';
import { MdPlayArrow, MdPause, MdStop, MdClose } from 'react-icons/md'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { toast } from "react-toastify";
import { instance } from "./axios";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";

export function MyStopwatch(props) {
  const [loader, showLoader] = useState(false);
  const time = new Date();
  time.setSeconds(time.getSeconds() + props.time);

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
    restart
  } = useStopwatch({ autoStart: props.is_running, offsetTimestamp: time });


  const updateTimer = (id, act) => {
    props.showLoader(true)
    showLoader(true)
    var data = new FormData();
    data.append('id', id);
    data.append('action', act);

    instance({
      method: "POST",
      url: "/update_timer",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        props.showLoader(false)
        showLoader(false)
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          if (act == 'pause') {
            pause()
            props.zstateicon(false)
          } else if (act == 'resume') {
            start()
            props.zstateicon(true)
          } else {
            props.reloadPage(true)
          }

          if(response.data.timer_stop == 1){
            props.zstateicon(false)
          }
        }
      })
      .catch(function (response) { });
  }

  const stoptimerModal = useModal('stop-timer');

  const stop_timer = (e) => {
    props.setmodalb(true)
    stoptimerModal.show({ data: props }).then((res) => {
      props.reloadPage(true)
      props.setmodalb(false)

    });
  }


  return (
    <div className={`d-flex align-items-center border px-2 rounded bg-white position-relative`} style={{height:'38px'}}>

      <div className="fs-5 me-2" style={{ width: '76px' }}>
        {days > 0 && <><span>{days}</span>:</>}<span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span>
      </div>
      {isRunning ?
        <button className="btn btn-light btn-sm text-warning" onClick={() => updateTimer(props.id, 'pause')}><MdPause /></button>
        : <button className="btn btn-light btn-sm text-primary" onClick={() => updateTimer(props.id, 'resume')}><MdPlayArrow /></button>}
      <button className="btn btn-light btn-sm text-danger" onClick={() => stop_timer()}><MdStop /></button>
      <button className="btn btn-light btn-sm text-secondary" onClick={() => updateTimer(props.id, 'remove')}><MdClose /></button>
    </div>
  );
}