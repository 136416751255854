import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { Toastcontent, UiTextArea, UiButton } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const TaskDescription = NiceModal.create(
  ({ task_id, description }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
     // day: yup.string().required("Select day of month"),
     task_desc: yup.string().max(1000, 'Maximum character limit is 1000'),

    });



    


    const {
      register,
      handleSubmit,
      control,
      getValues,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#taskddform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_task_description",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task"/>, { type: "error"});
          }
          if (response.data.status == "success") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task"/>, { type: "success"});
            modal.resolve({ resolved: true, description:getValues("task_desc") });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };





    useEffect(() => {
      reset({task_desc:description})
    }, [description]);

  
    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    

    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Description</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="taskddform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <BootstrapModal.Body>
          
        

        <UiTextArea
                
                    label="Task Description"
                    name="task_desc"
                    message={errors.task_desc?.message}
                    {...register("task_desc")}
                  />



            {task_id && (<input type="hidden" value={task_id} name="id"></input>)}





        </BootstrapModal.Body>
        <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default TaskDescription;
