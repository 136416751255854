import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { PageHeader, UiButton, TableZero, UiTFind, BgLoader, UiActioButton, InLoader, PageError } from "../../ui";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Helmet } from "react-helmet";
import { GoPlus } from "react-icons/go";

export default function DocTypes() {
  let navigate = useNavigate();
  const [bg_loader, setBGLoader] = useState(false);
  const userModal = useModal('document-type');
  const location = useLocation();
  const [state, setState] = useState({});
  const [filteredItems, setFilteredItems] = useState({});
  const showAddModal = (row) => {

    userModal.show({ data: row }).then((res) => {
      fetchData();
    });



  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setBGLoader(true)
        instance
          .get("/update_document_type?delete=" + id)
          .then(function (response) {
            setBGLoader(false)
            if (response.data.status == 'success') {
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }

            fetchData();

          })

      }
    })


  }

  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/document_types")
      .then(function (response) {
        setBGLoader(false)
        setState({ ...state, ...response.data });
        const filteredItems1 = response.data.items.filter(
          item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(filteredItems1);

      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, []);

  const [filterText, setFilterText] = React.useState('');

  useEffect(() => {
    if (state.items) {
      const filteredItems1 = state.items.filter(
        item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);
  const columns = [
    {
      name: 'Title',
      sortable: true,
      selector: row => row.title,
      cell: row => (<b className="text-dark">{row.title}</b>)
    },

    {
      name: '',
      selector: row => row.id,
      width:(window.innerWidth > 768 ? '0px':'130px'),
      cell: row => (
        <div className="tableaction">
          <UiActioButton title="Edit" action={()=>showAddModal(row)}/>
          <UiActioButton title="Delete" action={()=>handleDelete(row.id)}/>
       
        </div>
       
      )
    },
  ];



  
  if(state.status == 'error'){
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        <title>
          Document Types | Practive
        </title>
      </Helmet>
      <div>
        {state.items ? (
          <div>
            <PageHeader title="Document Types" parent="Settings" link="/settings">
              <UiButton className="btn btn-primary" onClick={() => showAddModal()} title="New" icon="add"></UiButton>

            </PageHeader>

            <div className="card card-default position-relative">
              {bg_loader == true && <InLoader />}
              <div className="card-body">


                <div className="">
                  <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
                </div>
                </div>
                <div className="card-body pt-0 tablecard">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  noDataComponent={<TableZero title="Types" />}
                />

              </div>
            </div>
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
}
