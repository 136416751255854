import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { rupees, setStorage, getStorage } from "../../helper";
import { UiToggle, PageHeader, UiInput, UiButton, UiTextArea, UiDatePicker, UiRSelect, PageError, UiActioButton, BgLoader, UiClientSelect } from "../../ui";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import addDays from 'date-fns/addDays'
import { format, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';
import queryString from 'query-string';
import { Helmet } from "react-helmet";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import { BsInfoCircle } from 'react-icons/bs';
import { MdOutlineDragHandle } from "react-icons/md";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { FaSquare } from "react-icons/fa";


import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

const SortableContainer = sortableContainer(({ children }) => {
  return <div className="">{children}</div>;
});

const DragHandle = sortableHandle(() => <span className="h2 pe-2 mb-0 text-secondary"><MdOutlineDragHandle /></span>);

const SortableItem = sortableElement((value) => {

  return (
    <div className="d-flex pitable bg-white" style={{ minWidth: '800px' }} {...value} />

  )
});




export default function Invoice() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [itmNum, setItmNum] = useState(0);
  const [unbilled_exp, setUnbilledExp] = useState(0);

  const [bg_loader, setBGLoader] = useState(false);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const schema = yup.object().shape({


    date: yup.string().required("Select invoice date"),
    duedate: yup.string().required("Select invoice due date"),
    invoice_id: yup.string().nullable().required("Enter invoice number").max(20, 'Maximum character limit is 20'),
    term: yup.object().nullable().required("Select payment term"),
    client: yup.object().nullable().required("Select client"),
    entity: yup.object().nullable().required("Select billing entity"),




    task: yup.array().of(
      yup.object().shape({
        selected: yup.bool(),

        description: yup.string().nullable(),
        amount: yup.string().nullable().when('selected', {
          is: true,
          then: (rule) => rule
            .typeError('Amount must be a number')
            .min(0, 'Negative value not allowed')
            .required('Amount is required')

        }),

        title: yup.string().nullable().when('selected', {
          is: true,
          then: (rule) => rule
            .required('Item title is required')

        }),

        price: yup.string().nullable().when('selected', {
          is: true,
          then: (rule) => rule
            .typeError('Price must be a number')
            .matches(/^[0-9.]+$/, "Price must be a number")
            .min(0, 'Negative value not allowed')
            .required('Price is required')

        }),

        sac: yup.string().nullable().when('sac', {
          is: (value) => value?.length,
          then: (rule) => rule
            .typeError('SAC must be a number')
            .matches(/^[0-9.]+$/, "SAC must be a number")
            .min(6, 'Enter 6 digit SAC')
            .min(6, 'Enter 6 digit SAC')
            .required('SAC is required')

        }),

        discount: yup.string().nullable().when('discount', {
          is: (value) => value?.length,
          then: (rule) => rule
            .matches(/^[0-9.]+$/, "Enter discount amount")
            .min(0, 'Negative value not allowed')
            .test(
              'More than price amount',
              'Discount amount must not be greater than price amount',
              function (value) {
                const { price } = this.parent;
                if (value > parseFloat(price)) {
                  return false;
                } else {
                  return true
                }
              }
            )

        })
      }, [["discount", "discount"], ["sac", "sac"]]),
    ),



  });


  const expModal = useModal('invoice-expenses');

  const showUnbilledExp = (id) => {
    var added_exp = []
    var arr = state.items.slice();
    arr.forEach(element => {
      if (element.type == 'expense' && element.selected == 1) {
        added_exp.push(element.id)
      }
    })
    expModal.show({ id: id, selected: added_exp }).then((res) => {


      if (res.resolved == true) {
        var arr = state.items.slice();
        var num = parseInt(itmNum) + 1
        var total = parseInt(unbilled_exp) - parseInt(res.data.length)
        setUnbilledExp(total)
        setItmNum(num);
    
        arr = [...arr, ...res.data]
       
        updateFinalDiscount(state.discount, arr)
        setTimeout(function () {
          resetField("task", { defaultValue: arr })
        }, 100)
        
      }
    });
  }


  var handleSave = (e) => {
    setState({ ...state, loader: true });


    instance({
      method: "post",
      url: "/update_invoice",
      data: e,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['invoices'])
          toast(response.data.msg, { type: "success" });
          if (response.data.id) {
            navigate("/view-invoice/" + response.data.id)
          }

        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    getValues,
    resetField,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave(data);
  };


  const fetchData = () => {
    var pay_term = null;
    if (getStorage("pay_term")) {
      pay_term = getStorage("pay_term")
    }
    instance
      .get("/get_invoice?id=" + id + "&term=" + pay_term)
      .then(function (response) {
        setState({ ...state, items: null, total: 0, roundoff: 0, discount: 0, subtotal: 0, igst: 0, lgst: 0, ...response.data });
        if (response.data.status == 'success') {

          if (parsed.client) {
            reset({ ...response.data.invoice, client: { value: parsed.client.split('::')[0], label: parsed.client.split('::')[1] } })
          } else {
            reset(response.data.invoice)
          }

        } else {
          toast(response.data.msg, { type: "error" });
        }

      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {
    fetchData();

  }, [location]);

  useEffect(() => {
    return () => {
    }
  }, [location]);


  useEffect(() => {
    reset(state.client)
  }, [state.client]);

  const [loadedOptions, setOptions] = useState({});


  const client_w = watch('client');
  useEffect(() => {
    if (client_w == null) {
      setOptions({})
    }
  }, [client_w]);



  const loadTasks = (inputValue, entity) => {
    var ent_id = '';
    if (entity && entity.value) {
      ent_id = entity.value;
    }
    if (inputValue != null) {
      setBGLoader(true)
      setState({ ...state, items: null })
      instance
        .get("/fetch_invoice_tasks?id=" + inputValue.value + "&invoice=" + id + "&entity=" + ent_id)
        .then(function (response) {
          setBGLoader(false)
          if (response.data.status == 'success') {
            //setState({ ...state, items: response.data.items, total: response.data.total, tload: inputValue.value, cust_state: response.data.state })
            setState({ ...state, ...response.data })
            setUnbilledExp(response.data.unbilled_exp)

          } else {
            toast(response.data.msg, { type: "error" });
          }


        })

    }

  };




  const selectTask = (id, act) => {
    var action = 0;
    if (act == 1) {
      action = 0;
    } else {
      action = 1;
    }
    var arr = state.items.slice();

    arr[id].selected = action;



    updateFinalDiscount(state.discount, arr)
  }

  const updateFinalDiscount = (amt, arrr, ent_switch) => {
    var amt = amt
    if (amt < 1) {
      amt = 0
    }
    var arr = state.items.slice();
    if (arrr) {
      arr = arrr
    }
    var arr1 = arr;
    arr1 = arr1.filter(x => x.selected == 1);

    var sum = 0;
    var total = 0;
    var subtotal = 0;
    var sum_discount = 0;
    var igst = 0;
    var lgst = 0;
    var roundoff = 0;


    for (let i = 0; i < arr1.length; i++) {
      //sum += parseInt(arr1[i]['amount']); 
      var price = parseFloat(arr1[i]['price']);
      if (price > 0) {
        sum += price;
      }
    }
    var slctd = 0
    for (let i = 0; i < arr.length; i++) {

      if (arr[i]['selected'] == 1) {
        slctd++
        var price = parseFloat(arr[i]['price']);

        var gst = 0;
        if (state.is_gst == 1) {
          gst = parseInt(arr[i]['gst']);
        }
        var discount = 0;

        if (state.line_discount == 1) {
          if (!arr[i]['discount']) {
            discount = 0
          } else {
            discount = parseFloat(arr[i]['discount'])

          }
        } else {
          discount = parseFloat(parseFloat(amt) * parseFloat(price) / parseFloat(sum)).toFixed(0);
          if (arr1.length == slctd) {
            discount = parseFloat(parseFloat(amt) - parseFloat(sum_discount))
          }

          arr[i]['discount'] = discount
        }

        if (isNaN(discount)) {
          discount = 0
        }


        var amt1 = price - discount;
        var amt_final = amt1 == 0 ? 0 : parseFloat(parseFloat(amt1) + parseFloat(amt1 * gst / 100)).toFixed(2)
        arr[i]['amount'] = isNaN(amt_final) ? '' : amt_final

        var amtt = isNaN(amt_final) ? 0 : parseFloat(amt_final)
        var itm_lgst = parseFloat((amtt * (gst / (100 + gst))) / 2).toFixed(2)
        var itm_igst = parseFloat((amtt * (gst / (100 + gst)))).toFixed(2)
        igst += parseFloat(itm_igst);
        lgst += parseFloat(itm_lgst);
        sum_discount += parseFloat(discount);
        total += amtt;
      }
    }

    if (state.line_discount == 0 || ent_switch) {
      resetField("task", { defaultValue: arr })
    }
    if (state.line_discount == 1) {
      resetField("discount", { defaultValue: sum_discount })
    }

    if (total) {
      roundoff = (Math.round(total) - total).toFixed(2)
      total = Math.round(total)
    }
    setState({ ...state, items: arr, total: total, roundoff: roundoff, discount: parseFloat(sum_discount).toFixed(2), subtotal: parseFloat(sum).toFixed(2), igst: parseFloat(igst).toFixed(2), lgst: parseFloat(lgst).toFixed(2) })
  }


  const updateAmount = (id, amt) => {

    var arr = state.items.slice();
    const crnt = arr.filter(x => x.id == id)[0];

    var gst = 0;
    if (state.is_gst == 1) {
      gst = parseInt(crnt.gst);
    }
    var discount = parseFloat(crnt.discount);
    if (!crnt.discount) {
      discount = 0;
    }

    crnt.amount = amt;

    var amt_final = parseFloat((amt - amt * (gst / (100 + gst))) + discount).toFixed(2);
    crnt.price = isNaN(amt_final) ? '' : amt_final



    resetField("task", { defaultValue: arr })
    updateFinalDiscount(state.discount, arr)


  }

  const updatePrice = (id, amt) => {

    var arr = state.items.slice();
    const crnt = arr.filter(x => x.id == id)[0];
    var gst = 0;
    if (state.is_gst == 1) {
      gst = parseInt(crnt.gst);
    }

    var discount = parseFloat(crnt.discount);
    if (!crnt.discount) {
      discount = 0;
    }
    crnt.price = amt;
    var amt1 = parseFloat(amt) - parseFloat(discount);
    var amt_final = amt1 == 0 ? 0 : parseFloat(parseFloat(amt1) + parseFloat(amt1 * gst / 100)).toFixed(2)
    crnt.amount = isNaN(amt_final) ? '' : amt_final

    resetField("task", { defaultValue: arr })
    updateFinalDiscount(state.discount, arr)
  }

  const updateTitle = (id, val) => {

    var arr = state.items.slice();
    const crnt = arr.filter(x => x.id == id)[0];

    crnt.title = val;

    setState({ ...state, items: arr })
  }

  const updateDescription = (id, val) => {

    var arr = state.items.slice();
    const crnt = arr.filter(x => x.id == id)[0];

    crnt.description = val;

    setState({ ...state, items: arr })
  }

  const updateSac = (id, val) => {

    var arr = state.items.slice();
    const crnt = arr.filter(x => x.id == id)[0];

    crnt.sac = val;

    setState({ ...state, items: arr })
  }



  const updateTax = (id, amt) => {
    var arr = state.items.slice();
    const crnt = arr.filter(x => x.id == id)[0];
    arr.filter(x => x.id == id)[0].gst = amt;
    setState({ ...state, items: arr })
    updatePrice(id, crnt.price)
  }

  const updateDiscount = (id, amt) => {
    var arr = state.items.slice();
    const crnt = arr.filter(x => x.id == id)[0];
    arr.filter(x => x.id == id)[0].discount = amt;
    setState({ ...state, items: arr })
    updatePrice(id, crnt.price)
  }



  const termWatch = watch("term");
  const dateWatch = watch("date");
  const clientWatch = watch("client");
  const entityWatch = watch("entity");


  useEffect(() => {

    updateTerm()
  }, [termWatch, dateWatch]);

  useEffect(() => {
    if (entityWatch) {

      setState({ ...state, invoice: { ...state.invoice, invoice_id: entityWatch.invoice_id, state: entityWatch.state }, is_gst: entityWatch.is_gst })

      resetField('invoice_id', { defaultValue: entityWatch.invoice_id })
      if (entityWatch.is_default == 0) {
        if (clientWatch) {
          //updateFinalDiscount(state.discount)
          //resetField("task", { defaultValue: [] })
          //loadTasks(clientWatch, entityWatch)
        }
        // setState({...state,invoice:{...state.invoice,invoice_id:state.invoice.invoice_id_def}})
        //resetField('invoice_id',{defaultValue:state.invoice.invoice_id_def})
      } else {

        //loadEntity(entityWatch.value)
      }
    }
  }, [entityWatch]);

  useEffect(() => {

    loadTasks(clientWatch, entityWatch)
  }, [clientWatch]);



  useEffect(() => {
    if ((state.is_gst == 1 || state.is_gst == 0) && state.items) {
      updateFinalDiscount(0, null, 1)
    }
  }, [state.is_gst]);

  useEffect(() => {
    if (state.tload) {
      resetField("task", { defaultValue: state.items })
    }
  }, [state.tload]);







  const onSortEnd = ({ oldIndex, newIndex }) => {
    var itms = state.items.slice();
    var newarr = arrayMove(itms, oldIndex, newIndex)

    setState({ ...state, items: newarr })
    resetField("task", { defaultValue: newarr })

  };


  const updateTerm = () => {

    var vals = getValues();
    if (vals.date && vals.term && vals.term.value >= 0) {
      var term = vals.term.value;

      var dued = format(addDays(parse(vals.date, "dd-MM-yyyy", new Date()), term), 'dd-MM-yyyy');

      resetField('duedate', { defaultValue: dued })
    }


  }


  const addnewItm = () => {
    var arr = state.items.slice();
    var num = parseInt(itmNum) + 1

    setItmNum(num);
    arr.push({ 'task': '', 'id': num, 'gst': 18, 'period': '', 'type': 'custom', 'selected': 1, 'amount': '', 'sac': '', 'description': '' })
    setState({ ...state, items: arr })
    resetField("task", { defaultValue: arr })
  }
  const deleteItm = (id) => {
    var arr = state.items.slice();
    //arr = arr.filter(item => item.id !== id)
    if (arr[id].isSaved == 0 && arr[id].type == 'expense') {
      setUnbilledExp(parseInt(unbilled_exp) + 1)
    }
    arr.splice(id, 1)
    //arr[id].selected = false;
    //setState({ ...state, items: arr })
    resetField("task", { defaultValue: arr })
    updateFinalDiscount(state.discount, arr)



  }

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      <Helmet>
        <title>
          {id == 'new' ? 'New' : 'Edit'} Invoice | Practive
        </title>
      </Helmet>
      <div>
        {state.status ? (
          <div>
            <PageHeader title={`${id == 'new' ? 'New' : 'Edit'} Invoice`} parent="Invoices" link="/invoices">

            </PageHeader>



            <form id="inovicefrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate={"novalidate"}>

              <div className="card card-default mb-4">

                <div className="card-body">

                  <div className="row">

                    <div className="col-md-6" >
                      <div style={{ maxWidth: '400px' }}>

                        <Controller
                          name="entity"
                          control={control}

                          render={({ field }) => (

                            <UiRSelect

                              required={true}
                              {...field}
                              options={state.entities}
                              className=""
                              isDisabled={id == 'new' ? false : true}
                              message={errors.entity?.message}
                              label="Billing Entity"

                            />

                          )}
                        />


                        <Controller
                          name="client"
                          control={control}

                          render={({ field }) => (

                            <UiClientSelect
                              {...field}
                              required={true}
                              onChange={event => {
                                field.onChange(event);

                              }}
                              className=""
                              message={errors.client?.message}
                              label="Client"

                            />

                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Controller

                        name="date"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiDatePicker
                            lcol={4}
                            icol={8}
                            required={true}
                            label="Date"
                            onChange={onChange}
                            // maxDate={parse(duedateWatch, "dd-MM-yyyy", new Date())}
                            default_val={value}
                            message={errors.date?.message}
                            dateFormat="dd-MM-yyyy"
                            ref={ref}
                            name={name}
                          />
                        )}
                      />
                      <UiInput
                        lcol={4}
                        icol={8}
                        label="Invoice No."
                        name="invoice_id"
                        required={true}
                        {...register("invoice_id")}
                        message={errors.invoice_id?.message}
                        disabled={state.invoice.invoice_id ? true : false}
                      />
                      <Controller
                        name="term"
                        control={control}

                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (

                          <UiRSelect
                            lcol={4}
                            icol={8}
                            required={true}

                            onChange={(e) => { onChange(e); setStorage("pay_term", e.label ? e.label : '') }}
                            ref={ref}
                            name={name}
                            defaultValue={value}
                            options={state.terms}
                            className=""

                            message={errors.term?.message}
                            label="Payment Term"

                          />

                        )}
                      />
                      <Controller
                        name="duedate"
                        control={control}

                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiDatePicker
                            lcol={4}
                            icol={8}
                            required={true}
                            label="Due Date"
                            onChange={onChange}
                            default_val={value}
                            message={errors.duedate?.message}
                            dateFormat="dd-MM-yyyy"
                            minDate={parse(dateWatch, "dd-MM-yyyy", new Date())}
                            ref={ref}
                            name={name}
                            disabled={termWatch && termWatch.value == 'custom' ? 0 : 1}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <input type="hidden" defaultValue={id} name="id" {...register("id")} />

                  <div className="d-flex justify-content-between">
                    <h2 className="fs-4">Invoice Items</h2>
                    <div className="d-flex align-items-center text-secondary small"><FaSquare className="text-primary me-1"/> Task  <FaSquare className="text-success me-1 ms-3"/> Expense  <FaSquare className="text-info me-1 ms-3"/> Package</div>
                  </div>
                  <div className="overflow-auto w-100" >
                    <div className="d-flex pitable pitable_header" style={{ minWidth: '800px' }}>
                      <div className="col1 border border-end-0 border-start-0" style={{ width: '50px' }}>

                      </div>
                      <div className="col border border-end-0" style={{ minWidth: '250px' }}>
                        Particulars
                      </div>
                      <div className="col1 border border-end-0 text-center" style={{ width: '65px' }}>
                        Select
                      </div>
                      <div className="col1 border border-end-0" style={{ width: '110px' }}>
                        Amount (₹)
                      </div>
                      <div className="col1 border border-end-0" style={{ width: '110px' }}>
                        Discount (₹)
                      </div>
                      {state.is_gst == 1 && <>
                        <div className="col1 border border-end-0" style={{ width: '80px' }}>
                          GST (%)
                        </div>
                      </>}
                      <div className="col1 border border-end-0" style={{ width: '130px' }}>
                        Total Amount (₹)
                      </div>

                    </div>



                    {state.items && state.items.length > 0 ? (




                      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                        {state.items && state.items.map((val, i) => (
                          <SortableItem key={i} index={i}  >
                            <div className={`col1 border border-end-0 border-start-0 type_${val.type}`} style={{ width: '50px' }}>
                              <DragHandle />
                            </div>
                            <div className="col border border-end-0" style={{ minWidth: '250px' }}>

                              {(val.type == 'custom' || val.type == 'expense') ?
                                <div className="row g-0">

                                  <div className="col-8 pe-2">
                                    {val.type == 'custom' ?
                                      <UiInput
                                        parentClass="mb-2"
                                        className="form-control-sm" placeholder="Item"
                                        name={`task[${i}].title`}
                                        message={errors.task?.[i]?.title?.message}
                                        disabled={val.selected == 1 ? false : true}
                                        {...register(`task.[${i}].title`, { onChange: (e) => { updateTitle(val.id, e.target.value) } })}
                                      />
                                      : <span>{val.title}</span>}
                                  </div>
                                  <div className="col-4">
                                    <UiInput
                                      parentClass="mb-2"
                                      className="form-control-sm" placeholder="SAC"
                                      name={`task[${i}].sac`}
                                      message={errors.task?.[i]?.sac?.message}
                                      disabled={val.selected == 1 ? false : true}
                                      {...register(`task.[${i}].sac`, { onChange: (e) => { updateSac(val.id, e.target.value) } })}
                                    />
                                  </div>
                                </div>
                                :
                                <div className="d-flex justify-content-between"><span className="mb-2 d-block">{val.task ? val.task : val.title} {val.period && ('(' + val.period + ')')}</span><div><span className={`ms-2 badge ${val.status}`}>{val.status}</span></div></div>
                              }

                              <UiTextArea
                                parentClass="mb-0"
                                className="form-control-sm" placeholder="Description"
                                name={`task[${i}].description`}
                                message={errors.task?.[i]?.description?.message}
                                disabled={val.selected == 1 ? false : true}
                                {...register(`task.[${i}].description`, { onChange: (e) => { updateDescription(val.id, e.target.value) } })}
                              />


                            </div>
                            <div className="col1 border border-end-0 text-center" style={{ width: '65px' }}>
                              {(val.type == 'custom' || val.type == 'expense') ?
                                <>
                                  <UiActioButton title="Delete" className="ms-1" action={() => deleteItm(i)} />

                                </>
                                :

                                <div className="form-check d-flex justify-content-center">
                                  <input className="form-check-input" name={`task[${i}].selected`} type="checkbox" value="1"  {...register(`task.[${i}].selected`)} onChange={() => selectTask(i, val.selected)}

                                  />

                                </div>
                              }

                            </div>
                            <div className="col1 border border-end-0" style={{ width: '110px' }}>

                              <UiInput name={`task[${i}].price`} {...register(`task.[${i}].price`)} parentClass="mb-0" message={errors.task?.[i]?.price?.message} disabled={val.selected == 1 ? false : true} onChange={(e) => updatePrice(val.id, e.target.value)} title={errors.task?.[i]?.price?.message} noerror={true} readOnly={val.type == 'expense' ? true : false} className={val.type == 'expense' ? 'border-0' : ''} />
                            </div>

                            <div className="col1 border border-end-0" style={{ width: '110px' }}>
                              <OverlayTrigger overlay={state.line_discount == 1 ? <div></div> : <Tooltip >To apply item wise discount, enable item wise discount option in billing settings.</Tooltip>} >
                                <UiInput name={`task[${i}].discount`} {...register(`task.[${i}].discount`)} parentClass="mb-0" message={errors.task?.[i]?.discount?.message} disabled={val.selected == 1 ? false : true} onChange={(e) => updateDiscount(val.id, e.target.value)} readOnly={state.line_discount == 1 ? false : true} noerror={true} title={errors.task?.[i]?.discount?.message} />
                              </OverlayTrigger>
                            </div>
                            {state.is_gst == 1 && <>



                              <div className="col1 border border-end-0" style={{ width: '80px' }}>
                                <UiInput type="number" icon="percent" name={`task[${i}].gst`} {...register(`task.[${i}].gst`)} parentClass="mb-0" message={errors.task?.[i]?.gst?.message} disabled={val.selected == 1 ? false : true} onChange={(e) => updateTax(val.id, e.target.value)} noerror={true} title={errors.task?.[i]?.gst?.message} />
                              </div>
                            </>}
                            <div className="col1 border border-end-0" style={{ width: '130px' }}>
                              <UiInput name={`task[${i}].amount`} {...register(`task.[${i}].amount`)} parentClass="mb-0" message={errors.task?.[i]?.amount?.message} disabled={val.selected == 1 ? false : true} onChange={(e) => updateAmount(val.id, e.target.value)} noerror={true} title={errors.task?.[i]?.amount?.message} readOnly={val.type == 'expense' ? true : state.line_discount == 1 ? false : true} className={val.type == 'expense' ? 'border-0' : state.line_discount == 1 ? '' : 'border-0'} />
                            </div>
                          </SortableItem>
                        ))}
                      </SortableContainer>



                    ) : state.items && state.items.length == 0 ? (
                      <div className="row" >
                        <div className="col text-center bg-light py-4">
                          There are no unbilled tasks
                        </div>
                      </div>
                    ) : <>
                      {bg_loader == true ? <BgLoader /> : (
                        <div className="row" >
                          <div className="col text-center bg-light py-4">
                            Select a client to get unbilled task(s)
                          </div>
                        </div>
                      )}
                    </>}


                  </div>
                  <div className="text-center my-3">
                    <UiButton type="button" className="btn btn-outline-success" onClick={() => addnewItm()} title="Add Item" icon="add" disabled={state.items ? false : true}> </UiButton>
                  </div>

                  <div className="row">
                    <div className="col-md-8">
                      {state.items ?
                        <div className="border border-warning p-3 rounded mb-3" style={{ maxWidth: "300px" }}>
                          <p>{unbilled_exp ? unbilled_exp + ' expenses is not billed' : 'No unbilled expenses'}</p>
                          <UiButton type="button" className="btn btn-outline-success" onClick={() => { showUnbilledExp(clientWatch.value); }} disabled={unbilled_exp ? false : true} title="Add Expenses" icon="add" > </UiButton>


                        </div>
                        : ''}
                    </div>

                    <div className="col-md-4">
                      <div className="bg-light p-3 rounded">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="text-secondary">Subtotal</div>
                          <UiInput icon="inr" className="border-0" style={{ width: '140px' }} value={state.subtotal} parentClass="mb-0" readOnly />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="text-secondary">Discount</div>
                          <OverlayTrigger overlay={state.line_discount == 1 ? <Tooltip >To apply total discount, disable item wise discount option in billing settings.</Tooltip> : <div></div>} >
                            <UiInput icon="inr" name={`discount`} {...register(`discount`)} style={{ width: '140px' }} parentClass="mb-0" defaultValue={state.discount} message={errors.discount?.message} onChange={(e) => updateFinalDiscount(e.target.value)} disabled={state.line_discount == 1 ? true : false} />
                          </OverlayTrigger>
                        </div>
                        {state.invoice.state && state.cust_state && state.is_gst == 1 && (<>
                          {state.invoice.state == state.cust_state ? <>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <div className="text-secondary">CGST</div>
                              <UiInput icon="inr" style={{ width: '140px' }} parentClass="mb-0" className="border-0" value={state.lgst} readOnly />
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <div className="text-secondary">SGST</div>
                              <UiInput icon="inr" style={{ width: '140px' }} parentClass="mb-0" className="border-0" value={state.lgst} readOnly />
                            </div>
                          </> : <>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <div className="text-secondary">IGST</div>
                              <UiInput icon="inr" style={{ width: '140px' }} parentClass="mb-0" className="border-0" value={state.igst} readOnly />
                            </div>
                          </>
                          }

                        </>)
                        }
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="text-secondary">Round Off</div>
                          <UiInput icon="inr" style={{ width: '140px' }} parentClass="mb-0" className="border-0" value={state.roundoff} readOnly />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fw-bold">Total Amount</div>
                          <UiInput icon="inr" className="border-0" value={state.total} style={{ width: '140px' }} parentClass="mb-0" readOnly />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <UiButton loading={state["loader"]} title="Save" disabled={state.total < 1 ? "1" : '0'} />
            </form>

          </div>

        ) : <BgLoader />}
      </div>
    </>
  );
}
