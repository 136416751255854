import React, { useState, useEffect, useCallback } from "react";
import {BsFillExclamationTriangleFill} from 'react-icons/bs'
import {Link} from 'react-router-dom'

export default function NoMatch() {




  return (
    <div className="text-center mt-5">
      <span className="text-warning" style={{fontSize:'60px'}}><BsFillExclamationTriangleFill/></span>
      <h2>Page not found!</h2>
      <p className="mt-3">
        <Link className="btn btn-secondary" to="/">Go to the dashboard</Link>
      </p>
    </div>
  );
}
