import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiRSelect, BgLoader, InLoader, UiActioButton, PageError, UiInput } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import { AiOutlineMore } from 'react-icons/ai'
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { rupees } from "../../helper";
import { toast } from "react-toastify";


export default function ClientsReport() {
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.clients);
  const setZState = useStore((state) => state.setClients);
  const [pagenum, setPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);


  const schema = yup.object().shape(
    {
      search: yup.string(),

    }
  );


  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { isDirty } = formState;


  const onSubmitHandler = async (data) => {

    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate("/clients-report?" + u, { replace: true })
    } else {
      navigate("/clients-report?" + u)
    }


  };

  const exportModal = useModal('export-all');
  const exportAll = (cols) => {
    exportModal.show({ type: '/clients_report', filter: window.location.search, header: cols, file: 'client-report.csv' }).then((res) => {
    });
  };


  const headers = [

    { label: "Name", key: "name" },
    { label: "File No.", key: "file" },
    { label: "Type", key: "type" },
    { label: "Mobile", key: "mobile" },
    { label: "Sec. Mobile", key: "mobile_2" },
    { label: "Email", key: "email" },
    { label: "Group", key: "group_name" },
    { label: "Status", key: "status" },
    { label: "Address", key: "address" },
    { label: "City", key: "city" },
    { label: "Pincode", key: "pincode" },
    { label: "State", key: "state" },
    { label: "Availed Services", key: "services" },
    { label: "Recurring Services", key: "rec_services" },
    { label: "Pending Tasks", key: "pending" },
    { label: "Hold Tasks", key: "hold" },
    { label: "In-Progress Tasks", key: "in_progress" },
    { label: "Completed Tasks", key: "completed" },
    { label: "Total Tasks", key: "total" },
    { label: "Created on", key: "date" },
    { label: "DOB", key: "dob" },
    { label: "Tags", key: "tags" },
    { label: "Users", key: "users" },
    { label: "PAN", key: "pan" },
    { label: "GSTIN", key: "gst" },
    { label: "Auto Billing", key: "auto_billing" },
    { label: "Balance", key: "balance" }


  ];


  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});



  async function fetchData(filter) {

    const { data } = await instance.get(
      "/clients_report" + filter
    );
    var dataa = data;

    var columns1 = columns;
    var headers1 = headers;
    if (data.cfs && Array.isArray(data.cfs)) {
      data.cfs.forEach(element => {
        columns1.push({ name: element.title, selector: row => row[element.id] })
        headers1.push({ label: element.title, key: element.id })
      });
    }
    dataa.cols = columns1
    dataa.cols1 = headers1
    
    return dataa;
  }

  const defaultValues = {
    search: null,
    type: null,
    tag: null,
    users: null,
    service: null,
    recservice: null,
    group: null,
  };

  const clearFilter = () => {
    reset(defaultValues)
    navigate("/clients-report", { replace: true })
  }


  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);


  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);


  const columns = [


    {
      name: 'Name',
      width:'170px',
      selector: row => row.name,
      cell: row => (<Link to={`/view-client/${row.id}`}><b>{row.name}</b></Link>),
      sortable: true,
      sortField: 'name',
      id: 'name'

    },
    {
      name: 'File No.',
      width: '100px',
      sortable: true,
      sortField: 'file',
      id: 'file',
      selector: row => row.file,
      cell: row => (<span className="text-truncate" title={row.file}>{row.file}</span>)
    },
    {
      name: 'Type',
      sortable: true,
      sortField: 'type',
      id: 'type',
      selector: row => row.type,
      cell: row => (<span className="text-truncate" title={row.type}>{row.type}</span>)
    },

    {
      name: 'Mobile',
      sortable: true,
      sortField: 'mobile',
      width: '150px',
      id: 'mobile',
      selector: row => row.mobile,

    },
    {
      name: 'Sec. Mobile',
      width: '150px',
      selector: row => row.mobile_2,
    },
    {
      name: 'Email',
      width: '250px',
      selector: row => row.email,
    },
    {
      name: 'Group',
      width: '200px',
      selector: row => row.group_name,
    },
    {
      name: 'Status',
      selector: row => row.status,
    },
    {
      name: 'Address',
      width: '200px',
      selector: row => row.address,
      cell: row => (<span className="text-truncate" title={row.address}>{row.address}</span>)

    },
    {
      name: 'City',
      width: '140px',
      selector: row => row.city,
      cell: row => (<span className="text-truncate" title={row.city}>{row.city}</span>)

    },
    {
      name: 'Pincode',
      width: '100px',
      selector: row => row.pincode,
      cell: row => (<span className="text-truncate" title={row.pincode}>{row.pincode}</span>)

    },
    {
      name: 'State',
      width: '140px',
      selector: row => row.state,
      cell: row => (<span className="text-truncate" title={row.state}>{row.state}</span>)

    },
    {
      name: 'Availed Services',
      width: '200px',
      selector: row => row.services,
      cell: row => (<span className="text-truncate" title={row.services}>{row.services}</span>)

    },
    {
      name: 'Recurring Services',
      width: '200px',
      selector: row => row.rec_services,
      cell: row => (<span className="text-truncate" title={row.rec_services}>{row.rec_services}</span>)

    },
    {
      name: 'Pending Tasks',
      selector: row => row.pending,
    },
    {
      name: 'Hold Tasks',
      selector: row => row.hold,
    },
    {
      name: 'In-Progress Tasks',
      selector: row => row.in_progress,
    },
    {
      name: 'Completed Tasks',
      selector: row => row.completed,
    },
    {
      name: 'Total Tasks',
      selector: row => row.total,
    },
    {
      name: 'Date',
      selector: row => row.date,
    },
    {
      name: 'DOB',
      selector: row => row.dob,
    },
    {
      name: 'Tags',
      width: '200px',
      selector: row => row.tags,
      cell: row => (<span className="text-truncate" title={row.tags}>{row.tags}</span>)
    },
    {
      name: 'Users',
      width: '200px',
      selector: row => row.users,
      cell: row => (<span className="text-truncate" title={row.users}>{row.users}</span>)
    },
    {
      name: 'PAN',
      selector: row => row.pan,
    },
    {
      name: 'GSTIN',
      width: '200px',
      selector: row => row.gst,
    },
    {
      name: 'Auto Billing',
      selector: row => row.auto_billing,
    },
    {
      name: 'Balance',
      selector: row => row.balance,
      cell: row => (<span>{rupees(row.balance)}</span>)
    },


  ];
  async function fetchData1(id) {

    const { data } = await instance.get(
      "/filters?q=" + id
    );
    var dataa = data;
    return dataa;
  }
  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate('/clients-report?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate('/clients-report?' + currentUrlParams.toString())
      }


    }
  };

  const handleSort = (column, sortDirection) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('sort_by', column.sortField);
    currentUrlParams.set('sort', sortDirection);
    if (location.search) {
      navigate('/clients-report?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/clients-report?' + currentUrlParams.toString())
    }

  };


  const { data, error, isError, isLoading, isFetching } = useQuery(["clients_report", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });

  const { data: f_data, isLoading: f_is_loading } = useQuery(["filters", 'G1TSU1'], () => fetchData1('G1TSU1'), {
    keepPreviousData: true,
  });
  const clearSelection = () => {
    setToggleCleared(!toggleCleared)
    setState1({ selected: [] })
  }
  useEffect(() => {

    if (f_data) {
      const defaultValues1 = {
        search: filter.search,
        type: f_data.types && filter.type ? f_data.types.filter(({ value }) => filter.type.split(',').includes(value)) : null,
        tag: f_data.tags && filter.tag ? f_data.tags.filter(({ value }) => filter.tag.split(',').includes(value.toString())) : null,
        service: f_data.services && filter.service ? f_data.services.filter(({ value }) => filter.service.split(',').includes(value.toString())) : null,
        recservice: f_data.rec_services && filter.recservice ? f_data.rec_services.filter(({ value }) => filter.recservice.split(',').includes(value.toString())) : null,
        group: f_data.groups && filter.group ? f_data.groups.filter(({ value }) => filter.group.split(',').includes(value.toString())) : null,
        users: f_data.users && filter.users ? f_data.users.filter(({ value }) => filter.users.split(',').includes(value.toString())) : null,
      };
      reset(defaultValues1)
    }
  }, [f_data, filter]);


  if (isLoading) {
    return <BgLoader />;
  }
  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Clients Report | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Clients Report" >
            {data.items &&
              <div className="btn-group1">
                <a style={{ cursor: 'pointer' }} data-bs-toggle="dropdown" aria-expanded="false" className={`btn btn-outline-primary d-inline-flex me-2 ${data.items.length == 0 && 'disabled'}`}>Export</a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <CSVLink data={data.items} filename={"clients-report.csv"} headers={data.cols1} className="dropdown-item" >Export view</CSVLink>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={() => exportAll(data.cols1)}>
                      Export all
                    </button>
                  </li>
                </ul>
              </div>

            }

          </PageHeader>


          <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            {f_is_loading == true && <InLoader />}
            {f_data && (
              <div className="p-3 bg-light rounded mb-4 ">

                <div className="row">

                  <div className="col">
                    <UiInput
                      label="Search"
                      type="text"

                      name="search"

                      {...register("search")}
                    />
                  </div>



                  <div className="col-md-3">
                    <Controller
                      name="recservice[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect

                          options={f_data.rec_services}
                          className=""
                          isMulti
                          message={errors.recservice?.message}
                          label="Recurring Service"
                          {...field}
                        />

                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <Controller
                      name="service[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect

                          options={f_data.services}
                          className=""
                          isMulti
                          message={errors.service?.message}
                          label="Availed Service"
                          {...field}
                        />

                      )}
                    />
                  </div>



                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="type[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect

                          options={f_data.types}
                          className=""
                          isMulti
                          message={errors.type?.message}
                          label="Type"
                          {...field}
                        />

                      )}
                    />
                  </div>





                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="tag[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.tags}
                          className=""
                          isMulti
                          label="Tag"
                          {...field}
                        />

                      )}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="group[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.groups}
                          className=""
                          isMulti
                          message={errors.group?.message}
                          label="Group"
                          {...field}
                        />

                      )}
                    />
                  </div>


                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="users[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.users}
                          className=""
                          isMulti
                          message={errors.users?.message}
                          label="User"
                          {...field}
                        />

                      )}
                    />
                  </div>


                  <div className={`col ${zstate.more_filter == true ? '' : 'd-none'}`}>

                  </div>






                  <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '200px' }}>
                    <div className="d-flex justify-content-end">
                      {zstate.more_filter == true ? (
                        <UiActioButton type="button" className="fs-3 text-dark" title="Less" tooltip="Hide More Filter" action={() => setZState({ ...zstate, more_filter: false })} />
                      ) : (

                        <UiActioButton type="button" className="fs-3 text-dark" title="More" tooltip="More Filter" action={() => setZState({ ...zstate, more_filter: true })} />
                      )}

                      <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                      <UiActioButton type="submit" className="fs-3" tooltip="Apply Filter" title="Go" />
                    </div>

                  </div>
                </div>



              </div>
            )}
          </form>


          <div className="card card-default position-relative">
            {isFetching && <InLoader />}
            <div className="card-body fcolumn tablecard">

              <DataTable
                columns={data.cols}
                pagination
                striped={true}
                paginationServer
                paginationPerPage="20"
                paginationDefaultPage={pagenum}
                paginationResetDefaultPage={true}
                paginationTotalRows={data.total}
                clearSelectedRows={toggleCleared}
                onSort={handleSort}
                sortServer
                persistTableHead
                defaultSortFieldId={parsed.sort_by ? parsed.sort_by : null}
                defaultSortAsc={parsed.sort == 'desc' ? false : true}
                disabled={tableDisable}
                paginationComponentOptions={{ noRowsPerPage: true }}
                data={data.items}
                onChangePage={handlePageChange}
                noDataComponent={<TableZero title="Clients" />}
              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}
