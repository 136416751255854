import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";


import { ConfigProvider } from 'antd';



import {
  Routes,
  Route,
  BrowserRouter,

} from "react-router-dom";
import App from "./App";
import NiceModal from '@ebay/nice-modal-react';
ReactGA.initialize('G-P5BZ0SV37S');

ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 10000,
    },
  },
});
Sentry.init({
  dsn: "https://b9a195f335d64aae897bf7cf499c0e3b@o4504897968930816.ingest.sentry.io/4504897970176000",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#1A73E8',
        fontFamily: 'Noto Sans',
        fontSize: 13,
        fontWeightStrong: 500
      },
      "components": {
        "Select": {
          "controlHeightLG": 38,
          "fontSizeIcon":15,
          "colorIcon":'var(--bs-gray)',
          "colorBgContainerDisabled":'var(--bs-gray-200)'
        },
        "Table": {
          "colorText": "var(--bs-body-color)",
          "colorTextHeading": "var(--bs-gray)"
        },
        "Pagination": {
          "colorText": "var(--bs-gray)",
        }
        
      }
    }}
  >
    <QueryClientProvider client={queryClient}>
      <ToastContainer position="top-center" autoClose={2500} transition={Flip} hideProgressBar={true} theme="light" />


      <BrowserRouter>
        <NiceModal.Provider>
          <App />
        </NiceModal.Provider>
      </BrowserRouter>


    </QueryClientProvider>
  </ConfigProvider>
);