import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const Group = NiceModal.create(
  ({ data }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      group_name: yup.string().required("Enter group name").max(30,'Maximum character limit is 30'),

    });
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#grpform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_group",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true, group:response.data.group });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
    useEffect(() => {


      if (data) {

        reset({ group_name: data.group_name });
        setState({ ...state, editid: data.id });
      }

    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Group" : "New Group"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="grpform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <BootstrapModal.Body>
          
            <UiInput
              label="Group Name"
              type="text"
              required={true}
              name="group_name"
              message={errors.group_name?.message}
              {...register("group_name")}
            />

        


            {state.editid && (<input type="hidden" value={state.editid} name="id"></input>)}



          
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default Group;
