import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BgLoader, UiButton, UiInput, UiRSelect, UiSelect, UiTextArea, OverlayLoader } from "../../ui";
import { LoginUser, setStorage } from "../../helper";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import logoimg from "../../practive.svg";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { rupees } from "../../helper"
import { BsCheckCircle } from 'react-icons/bs'


const Onboarding = ({ ...props }) => {
  let navigat = useNavigate();
  const location = useLocation();
  const [inner_loader, setInnerLoader] = useState(false);
  let { id } = useParams();
  const schema = yup.object().shape(
    {
      username: yup.string().required("Enter login username").max(30, 'Maximum character limit is 30'),
      password: yup.string().required("Please enter password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),

      password1: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "The password and its confirm are not the same"
        ),
    },
    []
  );
  const [state, setState] = useState({});




  var handleSave = () => {
    setInnerLoader(true)
    var form = document.querySelector("#setupuserform");
    var data = new FormData(form);
    instance({
      method: "post",
      url: "/onboarding",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setInnerLoader(false)
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          setState({ ...state, status: 'done' })
        }
      })
      .catch(function (response) { });
  };



  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave();

  };




  useEffect(() => {


    instance
      .get("/onboarding_form?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });



      })
      .catch(function (error) { })
      .then(function () { });



  }, []);


  const checkUsername = (val) => {
    instance
      .get("/username_validate?username=" + val + "&id=" + id)
      .then(function (response) {
        if (response.data.status == 'error') {
          setError('username', { message: response.data.msg })
        } else {
          clearErrors('username');
        }
      })

  }


  return (
    <>
      <Helmet>
        <title>
          Onboarding | Practive
        </title>

      </Helmet>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5 ">
            <div className="mb-4 text-center">
              <img className="loginlogo" src={logoimg} />
            </div>
            <OverlayLoader visible={inner_loader ? true : false} />
            {state.status ?
              <div className="card shadow-2-strong">
                <div className="card-body">

                  {state.status == 'success' ?
                    <>
                      <h3 className="mb-5">Setup user account</h3>
                      <form id="setupuserform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                        <UiInput

                          label="Username"
                          type="text"
                          required={true}
                          name="username"
                          disabled={state.user ? 'disabled' : ""}
                          message={errors.username?.message}
                          {...register("username", { onBlur: (e) => { checkUsername(e.target.value) } })}
                        />


                        <div className="row">
                          <div className="col-sm-6">
                            <UiInput
                              showicon={true}
                              required={true}
                              label="New Password"
                              type="password"
                              name="password"
                              message={errors.password?.message}
                              {...register("password")}
                            />

                          </div>
                          <div className="col-sm-6">

                            <UiInput
                              showicon={true}
                              required={true}
                              label="Confirm Password"
                              type="password"
                              name="password1"
                              message={errors.password1?.message}
                              {...register("password1")}
                            />

                          </div>
                        </div>

                        <input type="hidden" value={id} name="id" />

                        <UiButton loading={state["loader"]} className="w-100 btn-lg" title="Submit" />
                      </form>
                    </>
                    : state.status == 'done' ?
                      <div className="text-center">
                        <BsCheckCircle className="text-success" style={{ fontSize: '80px' }} />
                        <h3 className="mb-4 mt-2">Setup Completed</h3>
                        <Link to="/login" className="btn btn-primary mt-3">Login to Practive Dashboard</Link>
                      </div>
                      : <div className="alert alert-warning">{state.msg}</div>}
                </div>
              </div>
              :
              <BgLoader />}


          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
