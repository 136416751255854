import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { UiSelect, UiToggle, UiInput, UiButton, UiTextArea, UiRichText, PageHeader, BgLoader, OverlayLoader, PageError } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from "react-helmet";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { BsArrowReturnRight } from 'react-icons/bs'



export default function TaskNotification() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);

  const schema = yup.object().shape({

    whatsapp_status: yup.bool(),
    email_status: yup.bool(),
    sms: yup.string(),
    id: yup.string(),

    time: yup.string().nullable().required("Select notification time"),
    send_on: yup.string().nullable().required("Select value"),
    service_id: yup.string().nullable().required("Select service"),
    task_status: yup.string().nullable().required("Select task status"),



    day: yup
      .string()
      .matches(/^[0-9]+$/, "Enter days")
      .min(1, "Enter days")
      .max(3, "Enter days"),

    sms_template: yup.string().nullable().when(["sms_status", "sms"], {
      is: (a, b) => a == true && b == 1,
      then: yup.string().nullable().required("Select SMS template")
    }),



    sms_text: yup.string().nullable().when(["sms_status", "sms"], {
      is: (a, b) => a == true && b == 2,
      then: yup.string().nullable().required("SMS template is required").max(800, 'Maximum character limit is 800')
    }),
    sms_dlt_id: yup.string().nullable().when(["sms_status", "sms"], {
      is: (a, b) => a == true && b == 2,
      then: yup.string().nullable().required("DLT template ID is required").max(20, 'Maximum character limit is 20')
    }),

    sms_lang: yup.string().nullable().when(["sms_status", "sms"], {
      is: (a, b) => a == true && b == 2,
      then: yup.string().nullable().required("Select SMS language")
    }),

    whatsapp_template: yup.string().nullable().when("whatsapp_status", {
      is: true,
      then: yup.string().nullable().required("Select WhatsApp template")
    }),

    email_template: yup.string().nullable().when('email_status', {
      is: true,
      then: yup.string().nullable().required("Email template is required").max(3000, 'Maximum character limit is 3000')
    }),
    email_subject: yup.string().nullable().when('email_status', {
      is: true,
      then: yup.string().nullable().required("Email subject is required").max(100, 'Maximum character limit is 100')
    }),

  });
  var handleSave = (e) => {
    setState({ ...state, loader: true });
    var form = document.querySelector("#tasknotificationfrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/update_task_notification",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['task-notifications'])
          toast(response.data.msg, { type: "success" });
          //if (id == 'new') {
          //navigate("/task-notification/" + response.data.id, { replace: true })
          //}
          navigate("/task-notifications/")

        }
      })
      .catch(function (response) { });
  };

  const VariableCopy = (props) => {
    const { items } = props;
    return (
      <>
        {items.map((val, ix) => (
          <OverlayTrigger key={ix} overlay={<Tooltip >Click to copy</Tooltip>} >
            <CopyToClipboard text={val}>
              <span type="button" className="btn btn-light me-2 btnpulse">{val}</span>
            </CopyToClipboard>
          </OverlayTrigger>
        ))}
      </>
    )
  }


  const {
    register,
    handleSubmit,
    setError,
    watch,
    resetField,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave();
  };


  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader(true)
        instance
          .get("/update_task_notification?delete=" + id)
          .then(function (response) {
            showLoader(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['task-notifications']);
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }

  const fetchData = () => {
    instance
      .get("/task_notification?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });
        reset(response.data.notification)
        if (response.data.status == 'success') {

        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
      .catch(function (error) { })
      .then(function () { });

  }
  const userModal = useModal('select-template');

  const showTemplates = (row, row1, type) => {

    userModal.show({ data: row, active: row1, type: type }).then((res) => {
      if (type == 'sms') {
        setState({ ...state, notification: { ...state.notification, sms_template: res.newid } });
        resetField("sms_template", { defaultValue: res.newid })
      } else {
        setState({ ...state, notification: { ...state.notification, whatsapp_template: res.newid } });
        resetField("whatsapp_template", { defaultValue: res.newid })
      }
    });

  }



  useEffect(() => {
    fetchData();

  }, []);



  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  const two = [{ value: '0', label: "English" }, { value: '1', label: "Unicode" }];
  const times = ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];


  return (
    <>
      <Helmet>
        <title>
          {id == 'new' ? 'New' : 'Edit'} Notification | Practive
        </title>
      </Helmet>
      <div>
        {state.notification ? (
          <div>
            <PageHeader title={`${id == 'new' ? 'New' : 'Edit'} Notification`} parent="Task Notifications" link="/task-notifications">
              {id !== 'new' && <button className="btn btn-outline-danger" onClick={() => handleDelete(id)}>Delete</button>}
            </PageHeader>


            <form id="tasknotificationfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>

              <div className="card card-default mb-4">
                <div className="card-header">
                  Notification Setting
                </div>
                <div className="card-body">
                  <p className="alert alert-info">Notification will be sent to clients when the following conditions are met.</p>
                  <p className="fw-semibold mb-2">Condition:</p>
                  <div className="table-responsive">
                    <div className="d-inline-flex rounded bg-light px-3 pt-4">
                      <span className="pe-2" style={{ paddingTop: '9px', width: '50px' }}>Taks is</span>
                      <div style={{ width: '200px' }}>
                        <UiSelect
                          placeholder="Select task"
                          name="service_id"
                          options={state.services}
                          message={errors.service_id?.message}
                          {...register("service_id")}
                        />
                      </div>
                      <span className="px-2" style={{ paddingTop: '9px', width: '110px' }}> and status is</span>
                      <div style={{ width: '150px' }}>
                        <UiSelect
                          placeholder="Select status"
                          name="task_status"
                          options={state.task_status}
                          message={errors.task_status?.message}
                          {...register("task_status")}
                        />
                      </div>
                    </div>

                    <div className="d-flex">
                      <span className="fs-1 text-muted"><BsArrowReturnRight /></span>
                      <div className="d-flex rounded bg-light px-3 pt-4 mt-3 ms-2">
                        <span className="pe-2" style={{ paddingTop: '9px', width: '135px' }}>Send notifications at</span>
                        <div style={{ minWidth: '100px' }}>
                          <UiSelect
                            placeholder="Time"
                            name="time"
                            options={times}
                            message={errors.time?.message}
                            {...register("time")}
                          />
                        </div>
                        <div className="mx-2">
                          <div style={{ minWidth: '100px' }}>
                            <UiSelect

                              noBlank={true}
                              name="send_on"
                              options={['before', 'after']}
                              message={errors.send_on?.message}
                              {...register("send_on")}
                            />
                          </div>
                        </div>
                        <div style={{ width: '70px' }}>
                          <UiInput
                            placeholder="Enter days"
                            type="number"
                            name="day"
                            message={errors.day?.message}
                            {...register("day")}
                          />
                        </div>
                        <span className="px-2" style={{ paddingTop: '9px', minWidth: '120px' }}> days from the task due date.</span>
                      </div>
                    </div>
                  </div>




                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="card card-default mb-4">
                    <div className="card-header">
                      SMS
                    </div>
                    <div className="card-body">
                      <UiToggle
                        id="sms"
                        name="sms_status"
                        title="Enable SMS Notification"
                        {...register("sms_status")}
                      />
                      <input type="hidden" name="id" value={id} />
                      <input type="hidden" name="sms"  {...register("sms")} />
                      {state.notification.sms == 1 ? (
                        <div>
                          <div className="mb-4 ">
                            <label className="form-label fs-6 fw-bolder text-dark d-block">Template</label>


                            {state.notification.sms_template && state.notification.sms_template > 0 ? (
                              <>

                                <div className="p-2 border rounded">{state.sms_templates[state.notification.sms_template].template}</div>

                                <a className="btn btn-sm btn-secondary mt-3" onClick={() => showTemplates(state.sms_templates, state.notification.sms_template, 'sms')}>Change Template</a>
                              </>
                            ) : (
                              <>
                                <a className="btn btn-sm btn-secondary" onClick={() => showTemplates(state.sms_templates, '', 'sms')}>Select Template</a>

                              </>
                            )}
                            {errors.sms_template && (<div className="invalid-feedback d-block">{errors.sms_template?.message}</div>)}
                          </div>
                          <input type="hidden" name="sms_template" {...register("sms_template")} />

                        </div>
                      ) : (
                        <div>
                          <UiTextArea
                            label="Template"
                            name="sms_text"
                            placeholder="Type your message"
                            message={errors.sms_text?.message}
                            {...register("sms_text")}
                          />
                          {state.shortcodes && (
                            <div className="mb-3">
                              <b>Variables - </b>
                              <VariableCopy items={state.shortcodes} />

                            </div>
                          )}
                          <div className="row">
                            <div className="col-md-6">
                              <UiSelect
                                label="Language"
                                name="sms_lang"
                                options={two}
                                message={errors.sms_lang?.message}
                                {...register("sms_lang")}
                              />
                            </div>
                            <div className="col-md-6">
                              <UiInput
                                label="DLT Template ID"
                                type="text"
                                name="sms_dlt_id"
                                message={errors.sms_dlt_id?.message}
                                {...register("sms_dlt_id")}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-default mb-4">
                    <div className="card-header">
                      WhatsApp
                    </div>
                    <div className="card-body">
                      <UiToggle
                        id="wp"
                        name="whatsapp_status"
                        title="Enable WhatsApp Notification"
                        {...register("whatsapp_status")}
                      />

                      <div>
                        <div className="mb-4 ">
                          <label className="form-label fs-6 fw-bolder text-dark d-block">Template</label>


                          {state.notification.whatsapp_template > 0 ? (
                            <>

                              <div className="p-2 border rounded">{state.wa_templates[state.notification.whatsapp_template].template}</div>

                              <a className="btn btn-sm btn-secondary mt-3" onClick={() => showTemplates(state.wa_templates, state.notification.whatsapp_template, 'whatsapp')}>Change Template</a>
                              <div className="mt-3 mb-3">
                                <UiInput
                                  label="Message Body Variables"
                                  type="text"
                                  parentClass="mb-1"
                                  name="wa_vars"
                                  message={errors.wa_vars?.message}
                                  {...register("wa_vars")}
                                />
                                <small>Enter required variables in WhatsApp message template. (Use comma in case of multiple variables)<br></br></small>
                                <small>Example: {`{CLIENT_NAME}, {YOUR_COMPANY_NAME}`}</small>
                              </div>
                              {state.shortcodes && (
                                <div className="mb-3">
                                  <b>Variables - </b>
                                  <VariableCopy items={state.shortcodes} />

                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <a className="btn btn-sm btn-secondary" onClick={() => showTemplates(state.wa_templates, '', 'whatsapp')}>Select Template</a>
                            </>
                          )}
                          {errors.whatsapp_template && (<div className="invalid-feedback d-block">{errors.whatsapp_template?.message}</div>)}
                        </div>
                        <input type="hidden" name="whatsapp_template" {...register("whatsapp_template")} />

                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <div className="card card-default mb-4">
                <div className="card-header">
                  Email
                </div>
                <div className="card-body">
                  <UiToggle
                    id="email"
                    name="email_status"
                    title="Enable Email Notification"
                    {...register("email_status")}
                  />

                  <div>
                    <UiInput
                      label="Email Subject"
                      type="text"
                      name="email_subject"
                      message={errors.email_subject?.message}
                      {...register("email_subject")}
                    />
                    <Controller
                      name="email_template"
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiRichText

                          onChange={onChange}
                          value={value}
                          ref={ref}
                          label="Email Template"
                          name="email_template"
                          message={errors.email_template?.message}
                        />
                      )}
                    />
                    {state.shortcodes && (
                      <div className="mb-3">
                        <b>Variables - </b>
                        <VariableCopy items={state.shortcodes} />

                      </div>
                    )}


                  </div>

                </div>
              </div>

              <UiButton loading={state["loader"]} title="Save" />
            </form>

          </div>

        ) : <BgLoader />}
      </div>
      <OverlayLoader visible={loader} />
    </>
  );
}
