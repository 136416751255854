import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiTFind, PageError, InLoader, Avatar, BgLoader } from "../../ui";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { Helmet } from "react-helmet";



export default function Users() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [filteredItems, setFilteredItems] = useState({});
  const [loader, showLoader] = useState(false);

  const fetchData = () => {
    showLoader(true)
    instance
      .get("/users")
      .then(function (response) {
        showLoader(false)
        setState({ ...state, ...response.data });
        const filteredItems1 = response.data.users.filter(
          item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(filteredItems1);
      })
      .catch(function (error) { })
      .then(function () { });

  }






  const [filterText, setFilterText] = React.useState('');


  const columns = [
    {
      name: 'Photo',
      width: '100px',
      selector: row => row.photo,
      cell: row => (<span className="my-2"><Avatar src={row.photo ? row.photo : 'https://practive.s3.ap-south-1.amazonaws.com/placeholder.jpeg'} className="bg-secondary" size="50" /></span>),


    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      cell: row => (<Link to={row.current ? '/profile' : `/user/${row.id}`}><b>{row.name}</b></Link>)
    },
    {
      name: 'Role',
      selector: row => row.role,
      sortable: true,
    },
    {
      name: 'Username',
      selector: row => row.username,
      sortable: true,
      cell: row => (<b>{row.username}</b>)
    },
    {
      name: 'Mobile',
      sortable: true,
      selector: row => row.mobile,
    },
    {
      name: 'Email',
      sortable: true,
      selector: row => row.email,
      cell: row => (<span className="text-truncate" title={row.email}>{row.email}</span>)
    },
    {
      name: 'Status',
      sortable: true,
      selector: row => row.status,
      cell: row => (<>
        {row.status == 'Active' ? <span className="text-success">Active</span> : <span className="text-muted">Inactive</span>}
      </>)
    },


  ];



  useEffect(() => {
    if (state.users) {
      const filteredItems1 = state.users.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);


  useEffect(() => {
    fetchData();
  }, []);

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      <Helmet>
        <title>
          Users | Practive
        </title>
      </Helmet>
      <div>
        {state.users ?
          <div>
            <PageHeader title="Users" >
              <UiButton className="btn btn-primary" onClick={() => navigate("/user/new")} title="New" icon="add"></UiButton>

            </PageHeader>

            <div className="card card-default position-relative">
              {loader && <InLoader />}
              <div className="card-body">


                <div className="">
                  <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
                </div>
              </div>
              <div className="card-body pt-0 tablecard">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  noDataComponent={<TableZero title="Users" />}
                />

              </div>
            </div>
          </div>
          : <BgLoader />}
      </div>
    </>
  );
}
