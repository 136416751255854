import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const Portal = NiceModal.create(
  ({ data }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      title: yup.string().required("Enter portal name").max(30,'Maximum character limit is 30'),
      url: yup.string().url("Enter valid URL").required("Enter portal login link"),

    });
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveCF();
    };
    var SaveCF = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#portalfrm");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_portal",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true, saved: response.data.saved });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
    useEffect(() => {


      if (data) {

        reset({ title: data.title, url: data.url });
        setState({ ...state, editid: data.id });
      }

    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);



    return (
      <BootstrapModal {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Portal" : "New Portal"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="portalfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>

            <UiInput
            required={true}
              label="Portal Name"
              type="text"
              name="title"
              message={errors.title?.message}
              {...register("title")}
            />

            <UiInput
            required={true}
              label="Portal Login Link (URL)"
              type="text"
              name="url"
              message={errors.url?.message}
              {...register("url")}
            />



            {state.editid && (<input type="hidden" value={state.editid} name="id"></input>)}







          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>

      </BootstrapModal>
    );
  }
);

export default Portal;
