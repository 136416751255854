import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiSelect, TableZero, UiInput, UiButton, UiRSelect, UiToggle, BgLoader, PageError, InLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { format, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';
import { useNavigate, useLocation } from "react-router-dom";
import { DocList } from "../uploader";



const DocReqList = NiceModal.create(
  ({ id, cust_id, name, bgColor, note = "", period }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const [loader, showLoader] = useState(false);
    const modal = useModal();



    const fetchData = () => {
      var periodq = '';
      if (period) {
        periodq = "&period=" + period;
      }
      instance
        .get("/doc_req_files?id=" + id + periodq)
        .then(function (response) {
          setState({ ...state, ...response.data });
          if (response.data.status == 'success') {

          } else {
            toast(response.data.msg, { type: "error" });
          }

        })
        .catch(function (error) { })
        .then(function () { });

    }


    useEffect(() => {
      fetchData()
    }, []);



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);




    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Documents</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {state.documents && state.documents.length > 0 ? (
            <DocList category="collection" setData={(e) => setState({ ...state, documents: e })} data={state.documents} can_edit={true} />
          ) : state.documents && state.documents.length == 0 ? <TableZero title="Files" /> : <BgLoader />}
        </BootstrapModal.Body>
      </BootstrapModal>
    );
  }
);

export default DocReqList;
