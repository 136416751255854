import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { UiDatePicker, PageHeader, UiActioButton, UiRSelect, BgLoader, InLoader, Avatar, PageError } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import { MdEdit, MdDelete, MdAccessTime } from "react-icons/md";
import { getTime } from "date-fns";
import { NavLink, useNavigate, useParams, Link, Outlet, useOutletContext } from "react-router-dom";
import { IoIosArrowDown } from 'react-icons/io'

export function AttendanceDaily() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.tasks);
  const setZState = useStore((state) => state.setTasks);
  const [inner_loader, setInnerLoader] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [pstate, setPState] = useOutletContext();


  const schema = yup.object().shape(
    {
      date: yup.string().required('Select date'),


    }
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    resetField,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {


    setState({ ...state, date: data.date, updated: Date.now() })

  };


  const [state, setState] = useState({ date: '' });

  const attnModal = useModal('add-attendance');


  const showAddModal = (id, vals) => {

    attnModal.show({ data: vals, id: id, check_in_out: state.check_in_out }).then((res) => {
      fetchData();
    });

  }

  const selfAttendance = (action) => {
    setInnerLoader(true)
    instance
      .get("/self_attendance?action=" + action)
      .then(function (response) {
        setInnerLoader(false)
        fetchData()
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
      })
      .catch(function (error) { })
      .then(function () { });
  }


  const fetchData = () => {
    setInnerLoader(true)
    instance
      .get("/attendance_daily?date=" + state.date)
      .then(function (response) {
        setInnerLoader(false)
        setState({ ...state, ...response.data, loader: false });
        setCheckedState([])
        setPState({ bulk: response.data.mark_all })
        if (response.data.today) {
          reset({ ...getValues, date: response.data.today })
        }
      })
      .catch(function (error) { })
      .then(function () { });
  }
  useEffect(() => {
    if (pstate.bulk_update) {
      fetchData();
    }
  }, [pstate.bulk_update]);

  useEffect(() => {
    if (state.updated) {
      setState({ ...state, loader: true })
      fetchData();
    }
  }, [state.updated]);


  useEffect(() => {
    fetchData();
  }, []);


  const selectMultiple = (e, id) => {
    const arr = checkedState.slice()
    if (e.target.checked) {

      if (arr.indexOf(id) === -1) {
        arr.push(id);

      }
    } else {
      var index = arr.indexOf(id);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    setCheckedState(arr)

  }


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>

      <div>
        <div>





          {state.items ? (
            <div>
              <form id="attendancefilterfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                <div className="p-3 bg-light rounded mb-4 ">
                  <div className="row">


                    <div className="col-sm-3">
                      <Controller
                        name="date"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiDatePicker
                            label="Date"

                            onChange={onChange}
                            default_val={value}
                            message={errors.date?.message}
                            dateFormat="dd-mm-yyyy"


                            ref={ref}
                            name={name}
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-1" style={{ paddingTop: '28px', minWidth: '50px' }}>
                      <div className="d-flex justify-content-end">


                        <UiActioButton type="submit" loading={state["loader"]} className="fs-3" tooltip="Apply Filter" title="Go" />
                      </div>

                    </div>


                  </div>
                </div>


              </form>

              <div className="card bg-transparent card-defaul1">
                <div className="card-body1 p-0">
                  <div className="position-relative">
                    {inner_loader && <InLoader />}
                    <span className="badge bg-primary fs-6">{state.day}</span>
                    {state.holiday && <span className="badge bg-warning text-dark ms-2 fs-6">{state.holiday}</span>}

                    {state.items.length > 1 && (
                      <div className="row">
                        <div className="col-6 col-sm-2 mt-3">
                          <div className="p-3 rounded bg-light border-dashed">
                            <span className="fs-4">{state.present}</span>
                            <p className="text-success mb-0 mt-2">Present</p>
                          </div>
                        </div>

                        <div className="col-6 col-sm-2 mt-3">
                          <div className="p-3 rounded bg-light border-dashed">
                            <span className="fs-4">{state.half_day}</span>
                            <p className="text-success mb-0 mt-2">Half Day</p>
                          </div>
                        </div>

                        <div className="col-6 col-sm-2 mt-3">
                          <div className="p-3 rounded bg-light border-dashed">
                            <span className="fs-4">{state.overtime}</span>
                            <p className="text-success mb-0 mt-2">Overtime</p>
                          </div>
                        </div>

                        <div className="col-6 col-sm-2 mt-3">
                          <div className="p-3 rounded bg-light border-dashed">
                            <span className="fs-4">{state.absent}</span>
                            <p className="text-danger mb-0 mt-2">Absent</p>
                          </div>
                        </div>

                        <div className="col-6 col-sm-2 mt-3">
                          <div className="p-3 rounded bg-light border-dashed">
                            <span className="fs-4">{state.leave}</span>
                            <p className="text-danger mb-0 mt-2">Leave</p>
                          </div>
                        </div>

                        <div className="col-6 col-sm-2  mt-3">
                          <div className="p-3 rounded bg-light border-dashed">
                            <span className="fs-4">{state.paid_leave}</span>
                            <p className="text-danger mb-0 mt-2">Paid Leave</p>
                          </div>
                        </div>


                      </div>
                    )}
                    <div className="mt-3">

                      {checkedState.length > 0 && (
                        <div className="bulkaction">
                          <div>

                            <button className="btn btn-outline-primary btn-sm" onClick={() => showAddModal(checkedState.toString(), { date: state.date })}>Mark Attendance</button>
                          </div>
                        </div>
                      )}

                      {state.items && state.items.map((vals, is) => (
                        <div className="mb-2 bg-white rounded p-3" key={is}>
                          <div className="row">
                            <div className="col-sm-6 d-flex align-items-center">
                              {vals.can_edit == 1 && state.mark_all == true &&
                                <input type="checkbox" className="form-check-input" checked={checkedState.indexOf(vals.id) >= 0 ? true : false} onChange={(e) => selectMultiple(e, vals.id)}></input>
                              }
                              <span className="d-inline-block ms-2">
                                <Avatar name={vals.name} className="bg-secondary" src={vals.photo} size="30" />
                              </span>
                              <span className="ms-2">{vals.name}</span>
                            </div>
                            <div className="col-sm-6">

                              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between">


                                <span className="small text-muted me-3 my-2 my-sm-0">Check In - <b>{vals.in_time ? vals.in_time : '−−'}</b>  |  Check Out - <b>{vals.out_time ? vals.out_time : '−−'}</b></span>

                                {state.self == true ? <>
                                  {vals.title &&
                                    <span className={`border bg-white px-2 py-1 rounded ${vals.type == 'Present' ? 'text-success' : 'text-danger'}`}>{vals.title}</span>
                                  }
                                  {state.self_mark == true && (
                                    <>
                                      {vals.in_time == null && vals.type == null && <button className="btn btn-success" onClick={() => selfAttendance('in')}>Check In</button>}
                                      {vals.in_time && vals.out_time == null && <button className="btn btn-warning" onClick={() => selfAttendance('out')}>Check Out</button>}
                                    </>
                                  )}
                                </> : <>
                                  {vals.title ? <>
                                    {vals.can_edit == true ? (


                                      <button className={`border bg-white px-2 py-1 rounded ${vals.type == 'Present' ? 'text-success' : 'text-danger'}`} onClick={() => showAddModal(vals.id, vals)}>{vals.title}<span className="ms-2"><IoIosArrowDown /></span></button>

                                    ) : <div className={`border px-2 py-1 rounded ${vals.type == 'Present' ? 'text-success' : 'text-danger'}`}>{vals.title}</div>}

                                  </>
                                    : <button className="btn btn-outline-primary btn-sm" onClick={() => showAddModal(vals.id, vals)} disabled={vals.can_edit == true ? false : true}>Mark Attendance</button>}
                                </>}
                              </div>


                            </div>
                          </div>



                        </div>
                      ))}



                    </div>

                  </div>
                </div>
              </div>
            </div>
          ) : <BgLoader />}
        </div>
      </div>
    </>
  );
}



export function AttendanceList() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.tasks);
  const setZState = useStore((state) => state.setTasks);
  const [inner_loader, setInnerLoader] = useState(false);
  const [pstate, setPState] = useOutletContext();

  const schema = yup.object().shape(
    {
      month: yup.string().required('Select month & year'),
      user: yup.object(),

    }
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    resetField,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {

    var usr_val = null;
    if (data.user && data.user.value) {
      usr_val = data.user.value;
    }
    setState({ ...state, user: usr_val, month: data.month, updated: Date.now() })

  };


  const [state, setState] = useState({});

  const attnModal = useModal('add-attendance');


  const showAddModal = (id, vals) => {

    attnModal.show({ data: vals, id: id, check_in_out: state.check_in_out }).then((res) => {
      fetchData();
    });

  }





  const fetchData = () => {
    setInnerLoader(true)
    instance
      .get("/attendance_list?user=" + state.user + "&month=" + state.month)
      .then(function (response) {
        setInnerLoader(false)
        setState({ ...state, ...response.data, loader: false });
        setPState({ bulk: response.data.mark_all })
        if (response.data.month) {
          reset({ ...getValues, month: response.data.month })
        }
      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {
    if (state.updated) {
      setState({ ...state, loader: true })
      fetchData();
    }
  }, [state.updated]);


  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (pstate.bulk_update) {
      fetchData();
    }
  }, [pstate.bulk_update]);

  const handleDelete = (id, date) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setState({ ...state, loader: true })
        instance
          .get("/delete_attendance?id=" + id + "&date=" + date)
          .then(function (response) {
            setState({ ...state, loader: false })
            if (response.data.status == 'success') {

              fetchData();
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }



  return (
    <>

      <div>
        <div>





          {state.items ? (
            <div>
              <form id="attendancefilterfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                <div className="p-3 bg-light rounded mb-4 ">
                  <div className="row">
                    {state.mark_all == true &&
                      <div className="col">
                        <Controller
                          name="user"
                          control={control}
                          render={({ field }) => (

                            <UiRSelect
                              {...field}
                              options={state.users}
                              defaultValue={state.users[0]}
                              className=""
                              message={errors.user?.message}
                              label="User"
                            />

                          )}
                        />
                      </div>
                    }
                    <div className="col-sm-3">
                      <Controller
                        name="month"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiDatePicker
                            label="Month & Year"
                            onChange={onChange}
                            default_val={value}
                            message={errors.month?.message}
                            dateFormat="MM-yyyy"
                            showMonthYearPicker

                            ref={ref}
                            name={name}
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-1" style={{ paddingTop: '28px', minWidth: '50px' }}>
                      <div className="d-flex justify-content-end">


                        <UiActioButton type="submit" loading={state["loader"]} className="fs-3" tooltip="Apply Filter" title="Go" />
                      </div>

                    </div>


                  </div>
                </div>


              </form>

              <div className="card bg-transparent card-defaul1">
                <div className="card-body1 p-0">
                  <div className="position-relative">
                    {inner_loader && <InLoader />}
                    {state.items.length > 1 ?
                      <div className="table-responsive showscroll">

                        <table className="table table-striped-columns fstable attendance_table" >
                          <thead>
                            <tr>
                              <th>
                                User
                              </th>
                              {state.column && state.column.map((val, i) => (
                                <th key={i} className={`${val.today == 1 && 'border-top border-4 shadow-lg bg-light'}`}>
                                  {val.holiday &&
                                    <div className="badge bg-warning fw-200" style={{ width: '84px' }}>
                                      <small className="d-block text-truncate">{val.holiday}</small>
                                    </div>
                                  }
                                  <div className={`fs-4 ${(val.weekend == true || val.holiday) && 'text-danger'}`}>{val.day}</div>
                                  <span className={`fs-6 fw-lighter text-grey ${(val.weekend == true || val.holiday) && 'text-danger'}`}>{val.vaar}</span>


                                </th>
                              ))}
                              <th style={{ minWidth: '120px' }}>Payable Days</th>
                              <th style={{ minWidth: '120px' }}>Present (1)</th>
                              <th style={{ minWidth: '120px' }}>Half Day (0.5)</th>
                              <th style={{ minWidth: '120px' }}>Overtime (1.5)</th>
                              <th style={{ minWidth: '120px' }}>Paid Leave</th>
                              <th style={{ minWidth: '120px' }}>Leave</th>
                              <th style={{ minWidth: '120px' }}>Absent </th>
                              <th style={{ minWidth: '120px' }}>Holiday</th>
                              <th style={{ minWidth: '120px' }}>Weekend</th>

                              <th style={{ minWidth: '120px' }}>Total Hours</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state.items && state.items.map((val, i) => (
                              <tr key={i}>
                                <td>{val.name}</td>
                                {val.days && val.days.map((vals, is) => (
                                  <td key={is}>
                                    <div className={`att_day ratio ratio-1x1 ${vals.type == 'Present' ? 'present' : 'absent'}`}>
                                      <div className="p-2">
                                        <p> {vals.title}</p>
                                        {(vals.in_time || vals.out_time) && (<>
                                          <span className="text-sm">{vals.in_time + ' - ' + (vals.out_time ? vals.out_time : '−−')}</span>
                                        </>)}


                                        <div className="att_btn d-none">
                                          {vals.can_edit == true && (
                                            <button className="btn btn-success btn-sm m-1" onClick={() => showAddModal(val.id, vals)}><MdEdit /></button>
                                          )}
                                          {(vals.can_delete == true && vals.title) && (
                                            <button className="btn btn-danger btn-sm m-1" onClick={() => handleDelete(val.id, vals.date)}><MdDelete /></button>
                                          )}
                                        </div>

                                      </div>
                                    </div>
                                  </td>
                                ))}
                                <td className="p-2 fw-bold">{val.total_days}</td>
                                <td className="p-2 fw-bold">{val.present_days}</td>
                                <td className="p-2 fw-bold">{val.half_days}</td>
                                <td className="p-2 fw-bold">{val.overtime_days}</td>
                                <td className="p-2 fw-bold">{val.paid_leave_days}</td>
                                <td className="p-2 fw-bold">{val.leave_days}</td>
                                <td className="p-2 fw-bold">{val.absent_days}</td>
                                <td className="p-2 fw-bold">{val.holiday_days}</td>
                                <td className="p-2 fw-bold">{val.weekend_days}</td>

                                <td className="p-2 fw-bold">{val.total_hours}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      :
                      <div>
                        <div className="row">



                          <div className="col mt-3">
                            <div className="p-3 rounded bg-light border-dashed">
                              <span className="fs-4">{state.items[0].present_days}</span>
                              <p className="text-success mb-0 mt-2">Present (1)</p>
                            </div>
                          </div>
                          <div className="col mt-3">
                            <div className="p-3 rounded bg-light border-dashed">
                              <span className="fs-4">{state.items[0].half_days}</span>
                              <p className="text-success mb-0 mt-2">Half Day (0.5)</p>
                            </div>
                          </div>
                          <div className="col mt-3">
                            <div className="p-3 rounded bg-light border-dashed">
                              <span className="fs-4">{state.items[0].overtime_days}</span>
                              <p className="text-success mb-0 mt-2">Overtime (1.5)</p>
                            </div>
                          </div>



                          <div className="col mt-3">
                            <div className="p-3 rounded bg-light border-dashed">
                              <span className="fs-4">{state.items[0].weekend_days}</span>
                              <p className="text-warning mb-0 mt-2">Weekend</p>
                            </div>
                          </div>
                          <div className="col mt-3">
                            <div className="p-3 rounded bg-light border-dashed">
                              <span className="fs-4">{state.items[0].holiday_days}</span>
                              <p className="text-warning mb-0 mt-2">Holiday</p>
                            </div>
                          </div>

                          <div className="col mt-3">
                            <div className="p-3 rounded bg-light border-dashed">
                              <span className="fs-4">{state.items[0].absent_days}</span>
                              <p className="text-danger mb-0 mt-2">Absent</p>
                            </div>
                          </div>

                          <div className="col mt-3">
                            <div className="p-3 rounded bg-light border-dashed">
                              <span className="fs-4">{state.items[0].leave_days}</span>
                              <p className="text-danger mb-0 mt-2">Leave</p>
                            </div>
                          </div>

                          <div className="col mt-3">
                            <div className="p-3 rounded bg-light border-dashed">
                              <span className="fs-4">{state.items[0].paid_leave_days}</span>
                              <p className="text-danger mb-0 mt-2">Paid Leave</p>
                            </div>
                          </div>




                        </div>
                        <div className="overflow-auto">
                          <div className="month_cal_view">
                            <div className="row mt-4 text-muted">

                              <div className="col calendarentry text-uppercase">Sunday</div>
                              <div className="col calendarentry text-uppercase">Monday</div>
                              <div className="col calendarentry text-uppercase">Tuesday</div>
                              <div className="col calendarentry text-uppercase">Wednesday</div>
                              <div className="col calendarentry text-uppercase">Thursday</div>
                              <div className="col calendarentry text-uppercase">Friday</div>
                              <div className="col calendarentry text-uppercase">Saturday</div>

                            </div>
                            <div className="row">
                              {[...Array(parseInt(state.cal_start))].map((val, i) => (
                                <div className="col calendarentry mt-3">
                                </div>
                              ))}
                              {state.items[0].days && state.items[0].days.map((vals, is) => (
                                <div className="col calendarentry mt-3" key={is}>
                                  <div className={`att_day w-100 ratio ratio-1x1 border-0 ${vals.type == 'Present' ? 'present' : 'absent'} ${(vals.weekend == true || vals.holiday) ? 'bg-opacity-10 bg-danger' : ''}`}>
                                    <div className="p-2">
                                      <span className="fs-5">{vals.day}</span>
                                      <p> {vals.title}</p>
                                      <p className="text-danger text-truncate">{vals.holiday}</p>
                                      {(vals.in_time || vals.out_time) && (<>
                                        <span className="text-sm">{vals.in_time + ' - ' + (vals.out_time ? vals.out_time : '−−')}</span>
                                      </>)}



                                      <div className="att_btn d-none">
                                        {vals.can_edit == true && (
                                          <button className="btn btn-success btn-sm m-1" onClick={() => showAddModal(state.items[0].id, vals)}><MdEdit /></button>
                                        )}
                                        {(vals.can_delete == true && vals.title) && (
                                          <button className="btn btn-danger btn-sm m-1" onClick={() => handleDelete(state.items[0].id, vals.date)}><MdDelete /></button>
                                        )}
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : <BgLoader />}
        </div>
      </div>
    </>
  );
}


export default function AttendanceDashboard() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});

  const attnModal = useModal('add-attendance');


  const showAddModal = (id, vals) => {

    attnModal.show({ bulk: true }).then((res) => {
      setState({ ...state, bulk_update: Date.now() })
    });

  }




  return (
    <>
      <Helmet>
        <title>
          Attendance | Practive
        </title>
      </Helmet>
      <div>

        <div>

          <PageHeader title="Attendance">
            {state.bulk == true &&
              <button className="btn btn-primary" onClick={() => showAddModal()}>Bulk Attendance</button>
            }
          </PageHeader>




          <div className="tabs mb-3">
            <NavLink to="" end className="tab-itm">Daily</NavLink>
            <NavLink to="monthly" className="tab-itm">Monthly</NavLink>

          </div>

          <Outlet context={[state, setState]} />
        </div>




      </div>
    </>
  );
}

