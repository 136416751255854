import React, { useState, useEffect, useCallback } from "react";
import { instance } from "../../axios";
import {
  PageHeader,
  UiInput,
  UiButton,
  UiToggle,
  UiRichText,
  UiTextArea,
  UiImageUpload,
  OverlayLoader,
  UiSelect,
  BgLoader,
  PageError
} from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useQuery, useQueryClient, select } from "react-query";
import { useParams, useNavigate } from 'react-router'
import Swal from "sweetalert2";



export default function BillingEntity() {
  const [show, setShow] = useState(false);
  const [logo, setLogo] = useState(null);
  const [sign, setSign] = useState(null);
  const [modal, setModal] = useState();
  const [ovloader, showovLoader] = useState(false);
  const queryClient = useQueryClient();
  let { id } = useParams();
  const schema = yup.object().shape(
    {
      company: yup.string().required("Company name is required").max(80, 'Maximum character limit is 80'),
      title: yup.string().required("Entity name is required").max(30, 'Maximum character limit is 30'),
      address: yup.string().nullable().required("Address is required"),
      mobile: yup.string().nullable().when('mobile', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(/^[0-9]+$/, "Enter 10 digit mobile number")
          .min(10, "Enter 10 digit mobile number")
          .max(10, "Enter 10 digit mobile number"),
      }),
      email: yup.string().nullable().when('email', {
        is: (value) => value?.length,
        then: (rule) => rule.email("Enter valid email address")

      }),


      state: yup.string().required("State is required"),
      invoice_heading: yup.string().nullable().required("Invoice heading is required").max(20, 'Maximum character limit is 20'),
      receipt_heading: yup.string().nullable().required("Receipt heading is required").max(20, 'Maximum character limit is 20'),
      quote_heading: yup.string().nullable().required("Quotation heading is required").max(20, 'Maximum character limit is 20'),
      invoice_auto_num: yup.bool(),
      receipt_auto_num: yup.bool(),
      quote_auto_num: yup.bool(),

      invoice_number: yup
        .string().nullable()
        .when("invoice_auto_num", {
          is: true,
          then: (rule) =>
            rule
              .matches(/^[0-9]+$/, "Enter invoice number")
              .min(1, "Enter invoice number")
              .test(
                'Is positive?',
                'The number must be greater than 0',
                (value) => value > 0
              )
              .max(7, "Enter invoice number (max: 7 digit)"),
        }),
      quote_number: yup
        .string().nullable()
        .when("quote_auto_num", {
          is: true,
          then: (rule) =>
            rule
              .matches(/^[0-9]+$/, "Enter quotation number")
              .min(1, "Enter quotation number")
              .test(
                'Is positive?',
                'The number must be greater than 0',
                (value) => value > 0
              )
              .max(7, "Enter quotation number (max: 7 digit)"),
        }),
      invoice_prefix: yup.string().nullable().max(15, 'Maximum character limit is 15'),
      receipt_prefix: yup.string().nullable().max(15, 'Maximum character limit is 15'),
      quote_prefix: yup.string().nullable().max(15, 'Maximum character limit is 15'),
      receipt_number: yup
        .string().nullable()
        .when("receipt_auto_num", {
          is: true,
          then: (rule) =>
            rule
              .matches(/^[0-9]+$/, "Enter receipt number")
              .min(1, "Enter receipt number")
              .test(
                'Is positive?',
                'The number must be greater than 0',
                (value) => value > 0
              )
              .max(7, "Enter receipt number (max: 7 digit)"),
        }),

      gstin: yup
        .string()
        .nullable()
        .when("gstin", {
          is: (value) => value?.length,
          then: (rule) =>
            rule
              .matches(
                /^[0-9]{2}[0-9A-Za-z]{13}$/,
                "Enter valid GST number"
              )
              .required("Enter GST number"),
        }),
    },
    [["gstin", "gstin"], ["mobile", "mobile"], ["email", "email"]]
  );
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  useEffect(() => {
    instance
      .get("/billing_setting?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });
        setLogo(response.data.billing_settings.logo);
        setSign(response.data.billing_settings.sign);
      })
      .catch(function (error) { })
      .then(function () { });
  }, []);

  useEffect(() => {
    reset(state.billing_settings);
  }, [state]);
  let navigate = useNavigate();
  var SaveProfile = (e) => {
    showLoader({ ...loader, loader: true });
    var form = document.querySelector("#billsettingfrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/update_billing_entity",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader({ ...loader, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {

          toast(response.data.msg, { type: "success" });
          if (id == 'new') {
            navigate(-1, { replace: true })
          }
        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    SaveProfile();
    //reset();
  };

  const setFile = (e) => {
    console.log(e);
  };



  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showovLoader(true)
        instance
          .get("/update_billing_entity?delete=" + id)
          .then(function (response) {
            showovLoader(false)
            if (response.data.status == 'success') {
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }



  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }
  const watchAutoInvoice = watch('invoice_auto_num');
  const watchAutoReceipt = watch('receipt_auto_num');
  const watchAutoQuote = watch('quote_auto_num');
  return (
    <>
      <Helmet>
        <title>
          Billing Settings | Practive
        </title>
      </Helmet>
      <div>
        {state.billing_settings ? (
          <div>
            <PageHeader title={`${id == 'new' ? 'New ' : ''}Billing Entity`} parent="Billing Settings" link="/billing-settings">
              {id !== 'new' &&
                <button className="btn btn-outline-danger" disabled={state.billing_settings.is_primary == 1 ? true : false} onClick={() => handleDelete(id)}>Delete</button>
              }
            </PageHeader>
            <form id="billsettingfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
              <div className="card card-default mb-4">
                <div className="card-body">
                  <UiInput
                    required={true}
                    label="Title"
                    type="text"
                    name="title"
                    message={errors.title?.message}
                    {...register("title")}
                  />
                </div>
              </div>

              <div className="card card-default mb-4">
                <div className="card-header">Company Settings</div>
                <div className="card-body">




                  <UiImageUpload
                    lcol="2"
                    icol="10"
                    label="Logo"
                    name="logo"
                    file={logo}
                    setFile={setLogo}
                    onClear={() => setLogo(null)}
                    width={420}
                    height={180}
                    ratio="ratio-21x9"
                  />


                  <div className="row">
                    <div className="col-md-7">
                      <UiInput
                        required={true}
                        label="Company Name"
                        type="text"
                        name="company"
                        message={errors.company?.message}
                        {...register("company")}
                      />
                    </div>
                    <div className="col-md-5">
                      <UiInput

                        label="GSTIN"
                        type="text"
                        name="gstin"
                        message={errors.gstin?.message}
                        {...register("gstin")}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <UiInput
                        label="Mobile No."
                        type="text"
                        name="mobile"
                        message={errors.mobile?.message}
                        {...register("mobile")}
                      />
                    </div>
                    <div className="col-md-6">
                      <UiInput
                        label="Email"
                        type="email"
                        name="email"
                        message={errors.email?.message}
                        {...register("email")}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-7">
                      <UiTextArea
                        required={true}
                        label="Company Address"
                        name="address"
                        message={errors.address?.message}
                        {...register("address")}
                      />
                    </div>
                    <div className="col-md-5">
                      <UiSelect
                        required={true}
                        label="State"
                        name="state"
                        options={state.states}
                        message={errors.state?.message}
                        {...register("state")}
                      />
                    </div>
                  </div>





                  <UiToggle
                    id="gstin"
                    name="is_gst"
                    title="Charge GST in Invoice"
                    {...register("is_gst")}
                  />
                  <UiImageUpload
                    lcol="2"
                    icol="10"
                    label="Signature"
                    name="sign"
                    file={sign}
                    setFile={setSign}
                    onClear={() => setSign(null)}
                    width={420}
                    height={180}
                    ratio="ratio-21x9"
                  />

                </div>
              </div>

              <div className="card card-default mb-4">
                <div className="card-header">Invoice Settings</div>
                <div className="card-body">
                  <UiInput
                    lcol="2"
                    icol="10"
                    max_width="500px"
                    required={true}
                    label="Invoice Heading"
                    type="text"
                    name="invoice_heading"
                    message={errors.invoice_heading?.message}
                    {...register("invoice_heading")}
                  />

                  <div className="row">
                    <div className="col-sm-2">
                      <label className="form-label ">
                        Invoice No.
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <UiToggle
                        id="autoinvoice"
                        name="invoice_auto_num"
                        value="1"
                        title="Auto generate invoice number"
                        {...register("invoice_auto_num")}
                      />

                      <div className={`row ${!watchAutoInvoice && 'd-none'}`}>
                        <div className="col-sm-2 col-5">
                          <UiInput
                            label="Prefix"
                            placeholder="Prefix"
                            type="text"
                            name="invoice_prefix"
                            message={errors.invoice_prefix?.message}
                            {...register("invoice_prefix")}
                          />
                        </div>
                        <div className="col-sm-3 col-7">
                          <UiInput
                            label="Number starts from"
                            placeholder="Number starts from"
                            type="text"
                            name="invoice_number"
                            required={true}
                            message={errors.invoice_number?.message}
                            {...register("invoice_number")}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                  <UiInput
                    lcol="2"
                    icol="10"
                    max_width="500px"

                    label="UPI ID"
                    type="text"
                    name="upi_id"
                    message={errors.upi_id?.message}
                    {...register("upi_id")}
                  />
                  <Controller
                    name="invoice_footer"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiRichText
                        lcol="2"
                        icol="10"
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        label="Invoice Footer"
                        name="invoice_footer"
                        message={errors.invoice_footer?.message}
                      />
                    )}
                  />
                  <div className="row">
                    <div className="col-sm-2">
                      <label className="form-label ">
                        Ledger Balance
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <UiToggle

                        id="invoicebal"
                        name="invoice_ledger_bal"
                        value="1"
                        title="Show ledger balance in invoice"
                        {...register("invoice_ledger_bal")}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-2">
                      <label className="form-label ">
                        SAC Code
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <UiToggle

                        id="saccode"
                        name="show_sac"
                        value="1"
                        title="Show SAC code in invoice"
                        {...register("show_sac")}
                      />
                    </div>
                  </div>


                </div>
              </div>
              <div className="card card-default mb-4">
                <div className="card-header">Receipt Settings</div>
                <div className="card-body">
                  <UiInput
                    lcol="2"
                    icol="10"
                    label="Receipt Heading"
                    type="text"
                    max_width="500px"
                    name="receipt_heading"
                    required={true}
                    message={errors.receipt_heading?.message}
                    {...register("receipt_heading")}
                  />

                  <div className="row">
                    <div className="col-sm-2">
                      <label className="form-label">
                        Receipt No.
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <UiToggle
                        id="autoreceipt"
                        name="receipt_auto_num"
                        value="1"
                        title="Auto generate receipt number"
                        {...register("receipt_auto_num")}
                      />

                      <div className={`row ${!watchAutoReceipt && 'd-none'}`}>
                        <div className="col-sm-2 col-5">
                          <UiInput
                            label="Prefix"
                            placeholder="Prefix"
                            type="text"
                            name="receipt_prefix"
                            message={errors.receipt_prefix?.message}
                            {...register("receipt_prefix")}
                          />
                        </div>
                        <div className="col-sm-3 col-7">
                          <UiInput
                            label="Number starts from"
                            placeholder="Number starts from"
                            type="text"
                            name="receipt_number"
                            required={true}
                            message={errors.receipt_number?.message}
                            {...register("receipt_number")}
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                  <Controller
                    name="receipt_footer"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiRichText
                        lcol="2"
                        icol="10"
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        label="Receipt Footer"
                        name="receipt_footer"
                        message={errors.receipt_footer?.message}
                      />
                    )}
                  />

                  <div className="row">
                    <div className="col-sm-2">
                      <label className="form-label ">
                        Ledger Balance
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <UiToggle

                        id="receiptbal"
                        name="receipt_ledger_bal"
                        value="1"
                        title="Show ledger balance in receipt"
                        {...register("receipt_ledger_bal")}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-2">
                      <label className="form-label ">
                        Remark
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <UiToggle

                        id="receiptremark"
                        name="show_remark"
                        value="1"
                        title="Show Remark in Receipt"
                        {...register("show_remark")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-default mb-4">
                <div className="card-header">Quotation Settings</div>
                <div className="card-body">
                  <UiInput
                    lcol="2"
                    icol="10"
                    label="Quotation Heading"
                    type="text"
                    max_width="500px"
                    name="quote_heading"
                    required={true}
                    message={errors.quote_heading?.message}
                    {...register("quote_heading")}
                  />

                  <div className="row">
                    <div className="col-sm-2">
                      <label className="form-label">
                        Quotation No.
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <UiToggle
                        id="autoquote"
                        name="quote_auto_num"
                        value="1"
                        title="Auto generate quotation number"
                        {...register("quote_auto_num")}
                      />

                      <div className={`row ${!watchAutoQuote && 'd-none'}`}>
                        <div className="col-sm-2 col-5">
                          <UiInput
                            label="Prefix"
                            placeholder="Prefix"
                            type="text"
                            name="quote_prefix"
                            message={errors.quote_prefix?.message}
                            {...register("quote_prefix")}
                          />
                        </div>
                        <div className="col-sm-3 col-7">
                          <UiInput
                            label="Number starts from"
                            placeholder="Number starts from"
                            type="text"
                            name="quote_number"
                            required={true}
                            message={errors.quote_number?.message}
                            {...register("quote_number")}
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                  <Controller
                    name="quote_footer"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiRichText
                        lcol="2"
                        icol="10"
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        label="Quotation Footer"
                        name="quote_footer"
                        message={errors.quote_footer?.message}
                      />
                    )}
                  />

                  <div className="row">
                    <div className="col-sm-2">
                      <label className="form-label ">
                        Show UPI QR Code
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <UiToggle

                        id="upi_qr_quote"
                        name="upi_qr_quote"
                        value="1"
                        title="Show UPI QR Code in quotation"
                        {...register("upi_qr_quote")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <input type="hidden" value={id} name="id" />
              <UiButton loading={loader["loader"]} title="Save" />
            </form>
            <OverlayLoader visible={ovloader} />
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
}
