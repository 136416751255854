import React, { useState, useEffect, useCallback } from "react";
import { instance } from "../../axios";
import {
  PageHeader,
  UiInput,
  UiButton,
  UiToggle,
  UiRSelect,
  UiTextArea,
  PageError,
  UiSelect,
  BgLoader,
} from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

export default function DscSettings() {


  const schema = yup.object().shape(
    {

      status: yup.bool(),
      days: yup.string().required('Select no. of days')


    },
    []
  );
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  useEffect(() => {
    instance
      .get("/dsc_settings")
      .then(function (response) {
        setState({ ...state, ...response.data });
      })
      .catch(function (error) { })
      .then(function () { });
  }, []);

  useEffect(() => {
    const optns = [];
    if (state.settings && state.settings.weekend_days) {
      state.settings.weekend_days.split(',').map((option) => { optns.push({ label: option, value: option }) })
    }

    reset({ ...state.settings, weekend: optns });
  }, [state]);

  var SaveSettings = (e) => {
    showLoader({ ...loader, loader: true });
    var form = document.querySelector("#dscsettingsform");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/update_dsc_settings",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader({ ...loader, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
        }
      })
      .catch(function (response) { });
  };
  const fifteenDays = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '20', '25', '30', '35', '40', '45'];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    SaveSettings();
    //reset();
  };

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }
  const weekday = ['Friday', 'Saturday', 'Sunday'];
  const weekdays = [];

  weekday.map(WeekGen);

  function WeekGen(val, index, array) {
    return weekdays.push({ label: val, value: val });
  }
  return (
    <>
      <Helmet>
        <title>
          DSC Settings | Practive
        </title>
      </Helmet>
      <div>
        {state.settings ? (
          <div>
            <PageHeader title="DSC Settings" parent="Settings" link="/settings">

            </PageHeader>

            <form id="dscsettingsform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
              <div className="card card-default mb-4">
                <div className="card-body">

                  <UiToggle
                    id="dscauto"
                    name="status"
                    title="Create DSC task automatically"
                    {...register("status")}
                  />

                  <UiSelect
                    label="Create task automatically before expiry (No. of days)"
                    name="days"
                    required={true}
                    options={fifteenDays}
                    message={errors.days?.message}
                    {...register("days")}
                  />

                </div>
              </div>



              <UiButton loading={loader["loader"]} title="Save" />
            </form>
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
}
