import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { UiSelect, UiToggle, UiInput, UiButton, UiRSelect, BgLoader, OverlayLoader } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import DataTable from 'react-data-table-component';
import { format, isValid, parse } from 'date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { rupees } from "../../helper";

export default function BillingHistory() {
  const [pstate, setPSate] = useOutletContext();

  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);


  const downloadPdf = (id, id1) => {
    showLoader(true)
    fetch('https://api.practive.in/pdf?voucher=bill&id=' + id + ':' + id1).then(response => {
      response.blob().then(blob => {

        const fileURL = window.URL.createObjectURL(blob);

        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'invoice.pdf';
        alink.click();
        showLoader(false)
      })
    })
  }

  const columns = [
    {
      name: 'Date',
      selector: row => row.date,
      cell: row => (<span className="text-dark">{row.date}</span>)

    },

    {
      name: 'Item',
      selector: row => row.item,

    },
    {
      name: 'Amount',
      selector: row => row.amount,
      cell: row => (<>{rupees(row.amount)}</>)

    },


    {
      name: 'Action',
      selector: row => row.id,
      cell: row => (<>

        <button className="btn btn-outline-primary btn-sm" onClick={() => downloadPdf(row.id, row.token)} >Download Invoice</button>

      </>

      )
    },
  ];


  const fetchData = () => {
    instance
      .get("/billing_history")
      .then(function (response) {
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });

  }


  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <OverlayLoader visible={loader}/>
      {state.items ? (
        <DataTable
          columns={columns}
          data={state.items}

        />
      ) : <BgLoader />}
    </div>
  )

}

