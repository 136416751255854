import { useState, useEffect, useCallback } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiRSelect } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cropper from 'react-easy-crop'
import getCroppedImg from '../cropImage'



const Crop = NiceModal.create(
  ({ width1, height1, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const modal = useModal();

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)

    }, [])
    const showCroppedImage = useCallback(async () => {
      try {
        const croppedImage = await getCroppedImg(
          data,
          croppedAreaPixels,
          rotation,
          width1,
          height1
        )

        modal.resolve({ resolved: true, cropped: croppedImage });
        modal.hide();
        setCroppedImage(croppedImage)
      } catch (e) {
        console.error(e)
      }
    }, [croppedAreaPixels, rotation])

    const thumb = {
      width: '100%',
      position: 'relative',
      height: '400px'
    }
    useEffect(() => {




    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);
  
    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Crop Image</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
          <div style={thumb}>
            <Cropper
              image={data}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              //cropSize={{ width: width1, height: height1 }}
              aspect={width1 / height1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <button onClick={showCroppedImage} className="btn btn-primary mt-3 w-100">Crop</button>

        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default Crop;
