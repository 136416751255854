import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { BgLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { rupees } from "../helper";
import { useNavigate, useLocation } from "react-router-dom";




const SmsBuy = NiceModal.create(
  ({ id, voucher }) => {
    const [state, setState] = useState({});
    const modal = useModal();

    let navigate = useNavigate();


    const pgModal = useModal('payment');


    const paymentModal = (e) => {
      setState({ ...state, modalb: true })
      pgModal.show({ id: e, type: 'sms' }).then((res) => {

        if (res.status == 'paid') {
          setState({ ...state, modalb: false })
          modal.hide();
          navigate(0, { replace: true })
        }
      });



    }





    useEffect(() => {


      instance
        .get("/sms_plans")
        .then(function (response) {
          setState({ ...state, ...response.data });
        })
        .catch(function (error) { })
        .then(function () { });


    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Buy SMS</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
          {state.items ? (
            <>
              {state.items.map((val, i) => (
                <div className="d-flex bg-light p-3 mb-3" key={i}>
                  <span className="flex-grow-1">{val.title}</span>
                  <b>{rupees(val.amount)}</b>
                  <button className="ms-3 btn btn-primary btn-sm" onClick={() => paymentModal(val.id)}>Buy</button>
                </div>
              ))}
            </>
          ) : <BgLoader />}
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default SmsBuy;
