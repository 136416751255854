import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, TableZero, UiAvatarGroup, UiButton, PageError, BgLoader, InLoader, UiPopover } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { rupees } from "../helper"
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import DataTable from 'react-data-table-component';
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useNavigate, useLocation } from "react-router-dom";

import { MdSave, MdClose, MdEdit } from "react-icons/md";




const RecurringServices = NiceModal.create(
  ({ title, custid, action, bgColor, note = "", data }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const [inputs, setInputs] = useState({});
    const [pricecol, hidePricecol] = useState(false);
    const [loader, showLoader] = useState(false);


    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({ ...values, [name]: value }))
    }

    const editPrice = (index) => {
      var newArr = state.recurring_services;
      newArr[index].editprice = true;
      setInputs(values => ({ ...values, [index]: state.recurring_services[index].price }))
      setState({ ...state, recurring_services: newArr });
    }

    const cancelEdit = (index) => {
      var newArr = state.recurring_services;
      newArr[index].editprice = false;

      setState({ ...state, recurring_services: newArr });
    }

    var savePrice = (cid, sid, index) => {
      var newArr = state.recurring_services;
      newArr[index].ploader = true;

      setState({ ...state, recurring_services: newArr });

      instance({
        method: "post",
        url: "/custom_price",
        data: { cust_id: cid, service: sid, price: inputs[index] },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          newArr = state.recurring_services;
          newArr[index].ploader = false;
          setState({ ...state, recurring_services: newArr });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            newArr = state.recurring_services;
            newArr[index].price = response.data.amount;
            newArr[index].editprice = false;
            setInputs(values => ({ ...values, [index]: response.data.amount }))
            setState({ ...state, recurring_services: newArr });

          }
        })
    };

    const UsersUI = (props) => {
      const { users, ...other } = props;
      var slctd = [];
      if (users) {
        users.forEach(element => {

          slctd.push(state.user_list[element]);
        });
      }
      return (
        <>
          {slctd && <UiAvatarGroup items={slctd}></UiAvatarGroup>}
        </>
      );
    }

    const recModal = useModal('recurring-package');
    const userModal = useModal('client-service-users');


    const editPackage = (id, row, cid, sts) => {
      //modal.hide();
      setState({ ...state, modalb: true })
      recModal.show({ package_id: id, data: row, cust_id: cid, status: sts }).then((res) => {
        setState({ ...state, modalb: false })
        fetchData()
      });

    }

    const showuserModal = (service, cid, sid, id) => {
      setState({ ...state, modalb: true })


      userModal.show({ service: service, cust_id: cid, service_id: sid, selected_users: id, users_arr: state.users }).then((res) => {

        if (res && res.users) {
          var arr = state.recurring_services.slice();
          arr.filter((item) => {

            if (item.id == sid) {
              item.users = res.users;
            }
          })

          setState({ ...state, recurring_services: arr, modalb: false });
        } else {
          setState({ ...state, modalb: false })
        }

      });

    }





    var disablePackage = (id, index) => {

      var newArr = state.recurring_packages;
      newArr[index].loader = true;

      setState({ ...state, recurring_packages: newArr });

      var data = new FormData();
      data.append('cust_id', custid);
      data.append('package', id);
      data.append('status', 1);
      instance({
        method: "post",
        url: "/assign_package",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {

          var newArr = state.recurring_packages;
          newArr[index].loader = false;

          setState({ ...state, recurring_packages: newArr });

          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            fetchData();
          }
        })
        .catch(function (response) { });
    };



    var activeService = (cid, sid, sts, index) => {

      var newArr = state.recurring_services;
      newArr[index].loader = true;

      setState({ ...state, recurring_services: newArr });

      instance({
        method: "post",
        url: "/assign_service",
        data: { cust_id: cid, service: sid, status: sts },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          newArr = state.recurring_services;
          newArr[index].loader = false;
          setState({ ...state, recurring_services: newArr });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            newArr = state.recurring_services;
            newArr[index].selected = response.data.selected;
            setState({ ...state, recurring_services: newArr });

          }
        })
    };

    const fetchData = () => {
      showLoader(true)
      instance
        .get("/client_recurring_services?id=" + custid)
        .then(function (response) {
          showLoader(false)
          setState({ ...state, ...response.data });
          if (response.data.ledger_perm == false) {
            hidePricecol(true)
          }

        })
        .catch(function (error) { })
        .then(function () { });

    }

    const TableTitle = (props) => {
      const { title, details } = props
      return (
        < >{title} <UiPopover msg={details} /></>
      )
    }
    const columns = [
      {
        name: 'Service',
        selector: row => row.name,
        cell: row => (<b className="text-dark">{row.name}</b>)
      },
      {
        name: <TableTitle title='Price (Excl. of Tax)' details="Client specific custom price" />,
        selector: row => row.name,
        omit: pricecol,
        cell: (row, index) => (
          <>
            {row.editprice ? (
              <div className="input-group input-group-sm flex-nowrap">
                <span className="input-group-text">₹</span>
                <input type="number" className="form-control" name={index} value={inputs[index]} onChange={handleChange} placeholder="Default Price" />
                {row.ploader ? (
                  <button className="btn btn-primary" disabled><span className="spinner-border spinner-border-sm"></span></button>
                ) : (
                  <>
                    <button className="btn btn-secondary" type="button" onClick={() => cancelEdit(index)}><MdClose /></button>
                    <button className="btn btn-primary" type="button" onClick={(e) => savePrice(custid, row.id, index)}><MdSave /></button>
                  </>
                )}
              </div>
            ) : (
              <>
                {row.price ? (
                  <>
                    {rupees(row.price)}
                    <button className="btn btn-light ms-2 btn-sm" onClick={() => editPrice(index)}><MdEdit /></button>
                  </>
                ) : (
                  <button className="btn btn-secondary btn-sm" disabled={(row.selected == 1 && row.package == 0) ? false : true} onClick={() => editPrice(index)}>Set Default Price</button>
                )}
              </>
            )}
          </>
        )
      },

      {
        name: <TableTitle title='Users' details="Client specific custom users" />,
        selector: row => row.users,
        cell: (row, index) => (

          <>
            {(row.users && row.users.length > 0) ? (
              <>
                <UsersUI users={row.users} />
                <button className="btn btn-light ms-2 btn-sm" onClick={() => showuserModal(row.name, custid, row.id, row.users)}><MdEdit /></button>
              </>
            ) : (
              <>
                <button className="btn btn-secondary btn-sm" disabled={(row.selected == 1) ? false : true} onClick={() => showuserModal(row.name, custid, row.id, row.users)}>Configure Users</button>

              </>
            )}
          </>

        )
      },
      {
        name: 'Enable',
        selector: row => row.name,
        width: '80px',
        cell: (row, index) => (
          <>
            {row.package == 1 ?
              <OverlayTrigger overlay={<Tooltip >Service assigned with package can't be disabled directly.</Tooltip>}>
                <div className="opacity-75">
                  <UiToggle
                    id={index}
                    pclass="pb-0"
                    checked={1}
                  />
                </div>
              </OverlayTrigger>
              :

              <UiToggle
                id={index}
                pclass="pb-0"
                readOnly={(row.package == 0) ? false : true}
                loading={row.loader}
                checked={row.selected}
                onChange={() => activeService(custid, row.id, row.selected, index)}
              />
            }
          </>
        )
      },
    ];





    useEffect(() => {
      fetchData();


    }, []);




    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }

    }, [location]);

    const OnHideModal = () => {
      modal.resolve({ resolved: true })
      modal.hide()
    }




    return (
      <BootstrapModal size="lg" className={state.modalb && `modalb`} {...bootstrapDialog(modal)} onHide={() => OnHideModal()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Recurring Services</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
          <div className="position-relative">

            {state.status == 'success' ? <>
              {loader == true && <InLoader />}
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Packages</Accordion.Header>
                  <Accordion.Body>
                    {state.recurring_packages ? (
                      <>
                        {state.recurring_packages.map((row, index) => (
                          <div className={`border rounded p-3 mb-3 ${row.selected == 1 ? 'bg-success bg-opacity-10' : ''}`}>
                            <div className="d-flex align-items-center"><p className="mb-0"><b>{row.name}</b></p>
                              <div className="ms-auto">
                                {row.selected == 1 ?
                                  <UiToggle
                                    id={index}
                                    pclass="pb-0"
                                    loading={row.loader}
                                    checked={1}
                                    onChange={() => disablePackage(row.id, index)}
                                  />
                                  : <UiButton onClick={() => editPackage(row.id, row, custid, 0)} className="btn btn-sm btn-outline-primary" title="Enable" />}
                              </div>
                            </div>
                            {row.selected == 1 &&
                              <div className="mt-2"><span className="me-3"><span className="text-secondary">Default Billing Price:</span> {row.price ? rupees(row.price) : '-'}</span>
                                <span className="me-3"><span className="text-secondary">Default Billing Date:</span> {row.billing_date ? row.billing_date : '-'}</span>
                                <span className="me-3"><span className="text-secondary">Auto Billing:</span> {row.auto_billing == 1 ? 'Enabled' : 'Disabled'}</span>
                                <button className="btn btn-light ms-2 btn-sm" onClick={() => editPackage(row.id, row, custid, 2)}><MdEdit /></button>
                              </div>
                            }
                          </div>
                        ))}
                      </>
                    ) : <TableZero title="Packages" />}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Services</Accordion.Header>
                  <Accordion.Body>

                    {state.recurring_services && (
                      <DataTable
                        columns={columns}
                        data={state.recurring_services}
                        noDataComponent={<TableZero title="Recurring Services" />}
                      />

                    )}

                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

            </> : state.status == 'error' ? <PageError msg={state.msg} code={state.code} /> : <BgLoader />}
          </div>

        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default RecurringServices;
