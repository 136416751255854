import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { PageHeader, UiToggle, PageError, UiAvatarGroup, UiButton, UiTagDisplay, BgLoader, UiAvatarName, UiTimeDisplay } from "../../ui";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { show, useModal } from "@ebay/nice-modal-react";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import { rupees } from "../../helper";
import { GoLinkExternal } from "react-icons/go";
import { BsTelephoneOutboundFill, BsWhatsapp } from "react-icons/bs";
import { useQueryClient } from 'react-query';



export function Details() {
  const [pstate, setPState] = useOutletContext();

  const [state, setState] = useState({});

  const BasicDetail = (props) => {
    const { label, val, ...other } = props;
    return (
      <>
        {val &&
          <div className="row mb-3">
            <div className="col-lg-4 text-muted">
              {label}
            </div>
            <div className="col-lg-8" {...other}>

            </div>
          </div>
        }
      </>
    )
  }

  const userModal = useModal('auto-billing');

  const showModal = (day, id) => {

    userModal.show({ data: day, cust_id: id }).then((res) => {
      setPState({ ...pstate, client: { ...pstate.client, auto_billing: 1 } })
    });



  }

  var disableAutoBilling = () => {
    setState({ ...state, loader: true });


    instance({
      method: "GET",
      url: "/auto_billing?id=" + pstate.client.id,
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          setPState({ ...pstate, client: { ...pstate.client, auto_billing: 0 } })

        }
      })
      .catch(function (response) { });
  };

  var allowNotification = (act) => {
    setState({ ...state, loader_n: true });
    var action = 1;
    if (act == 1) {
      action = 0;
    }

    instance({
      method: "GET",
      url: "/client_notification?id=" + pstate.client.id + "&action=" + action,
    })
      .then(function (response) {
        setState({ ...state, loader_n: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          setPState({ ...pstate, client: { ...pstate.client, notification: action } })

        }
      })
      .catch(function (response) { });
  };

  var allowLogin = (act) => {
    setState({ ...state, loader_login: true });
    var action = 1;
    if (act == 1) {
      action = 0;
    }

    instance({
      method: "GET",
      url: "/client_login_setting?id=" + pstate.client.id + "&action=" + action,
    })
      .then(function (response) {
        setState({ ...state, loader_login: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          setPState({ ...pstate, client: { ...pstate.client, login: action } })

        }
      })
      .catch(function (response) { });
  };






  return (
    <>
      <div className="row">
        <div className="col-md-6 mb-3">
          <div className="p-2 rounded bg-light border-dashed">
            <div className="row g-0">
              <div className="col-6 border-bottom">
                <div className="d-flex align-items-center p-2">
                  <span className="fs-2 lh-1">{pstate.stats.pending}</span>
                  <span className="ms-2 fw-semibold d-block text-muted">Pending Tasks</span>
                </div>
              </div>
              <div className="col-6 border-bottom  border-start">
                <div className="d-flex align-items-center p-2">
                  <span className="fs-2 lh-1">{pstate.stats.hold}</span>
                  <span className="ms-2 fw-semibold d-block text-muted">Hold Tasks</span>
                </div>
              </div>

              <div className="col-6">
                <div className="d-flex align-items-center p-2">
                  <span className="fs-2 lh-1">{pstate.stats.in_progress}</span>
                  <span className="ms-2 fw-semibold d-block text-muted">In-Progress Tasks</span>
                </div>
              </div>
              <div className="col-6 border-start">
                <div className="d-flex align-items-center p-2">
                  <span className="fs-2 lh-1">{pstate.stats.completed}</span>
                  <span className="ms-2 fw-semibold d-block text-muted">Completed Tasks</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="col mb-3">
          <div className="p-3 rounded bg-light border-dashed h-100 d-flex align-items-end">
            <div>
              <span className="fs-1 lh-1">{pstate.stats.unbilled}</span>
              <span className="mt-2 fw-semibold d-block text-muted">Unbilled Tasks</span>
            </div>
          </div>
        </div>
        {pstate.ledger_perm &&
          <div className="col mb-3">
            <div className="p-3 rounded bg-light border-dashed h-100 d-flex align-items-end">
              <div>
                <span className="fs-3 lh-1">{rupees(pstate.client.balance)}</span>
                <span className="mt-2 fw-semibold d-block text-muted">Ledger Balance</span>
              </div>
            </div>
          </div>
        }
      </div>
      <div className="card card-default">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <BasicDetail label="Status" val={pstate.client.status}><span className={`${pstate.client.status == 'Active' ? 'text-success' : 'text-danger'}`}>{pstate.client.status}</span></BasicDetail>
              <BasicDetail label="Type" val={pstate.client.type}>{pstate.client.type}</BasicDetail>
              <BasicDetail label="Contact Person" val={pstate.client.contact_person}>{pstate.client.contact_person}</BasicDetail>
              <BasicDetail label="Date of Birth" val={pstate.client.dob}>{pstate.client.dob}</BasicDetail>
              <BasicDetail label="PAN" val={pstate.client.pan}>{pstate.client.pan}</BasicDetail>
              <BasicDetail label="GSTIN" val={pstate.client.gst}>{pstate.client.gst}</BasicDetail>
              {pstate.custom_fields && pstate.custom_fields.map((val, i) => (
                <BasicDetail key={i} label={val.field_name} val={val.value}>{val.value}</BasicDetail>
              ))}

            </div>
            <div className="col-md-6">

              <BasicDetail label="Mobile No." val={pstate.client.mobile}>
                <div className="d-flex align-items-center" >
                  <span>{pstate.client.mobile}</span>
                  <OverlayTrigger overlay={<Tooltip >Make Call</Tooltip>}>
                    <a href={`tel:${pstate.client.mobile}`} target="_blank" className="ms-3"><span className="sicon"><BsTelephoneOutboundFill /></span></a>
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip >Send WhatsApp</Tooltip>}>
                    <a className="ms-2 text-success" href={`https://wa.me/${pstate.client.mobile}`} target="_blank"><span className="sicon"><BsWhatsapp /></span></a>
                  </OverlayTrigger>

                </div>
              </BasicDetail>


              <BasicDetail label="Secondary Mobile No." val={pstate.client.mobile_2}><div className="d-flex align-items-center" ><span>{pstate.client.mobile_2}</span>
                <OverlayTrigger overlay={<Tooltip >Make Call</Tooltip>}>
                  <a href={`tel:${pstate.client.mobile_2}`} target="_blank" className="ms-3"><span className="sicon"><BsTelephoneOutboundFill /></span></a>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip >Send WhatsApp</Tooltip>}>
                  <a className="ms-2 text-success" href={`https://wa.me/${pstate.client.mobile_2}`} target="_blank"><span className="sicon"><BsWhatsapp /></span></a>
                </OverlayTrigger>
              </div></BasicDetail>

              <BasicDetail label="Email" val={pstate.client.email}><div className="d-flex align-items-center" ><span>{pstate.client.email}</span>
                <OverlayTrigger overlay={<Tooltip >Send Email</Tooltip>}>
                  <a href={`mailto:${pstate.client.email}`} className="ms-3" target="_blank"><span className="sicon"><GoLinkExternal /></span></a>
                </OverlayTrigger>
              </div></BasicDetail>

              <BasicDetail label="Address" val={pstate.client.address}>{pstate.client.address}</BasicDetail>
              <BasicDetail label="City" val={pstate.client.city}>{pstate.client.city}</BasicDetail>
              <BasicDetail label="Pincode" val={pstate.client.pincode}>{pstate.client.pincode}</BasicDetail>
              <BasicDetail label="State" val={pstate.client.state}>{pstate.client.state}</BasicDetail>

            </div>
          </div>
          {pstate.can_edit && (
            <div className="mt-3">
              {pstate.client.auto_billing ? (
                <div className="d-flex">

                  <div style={{ width: '45px' }}>
                    <UiToggle
                      id="autobill"
                      pclass="pb-0"
                      checked={1}
                      loading={state.loader}
                      onChange={() => disableAutoBilling()}
                    />
                  </div>
                  <span className="me-2">Create invoice automatically every month</span>
                </div>
              ) : (

                <div className="d-flex">
                  <div style={{ width: '45px' }}>
                    <UiToggle
                      id="autobill1"
                      pclass="pb-0"
                      checked={0}
                      loading={state.loader}
                      onClick={() => showModal(pstate.client.auto_billing_day, pstate.client.id)}
                    />
                  </div>
                  <span className="me-2">Create invoice automatically every month</span> </div>
              )}
              <div className="d-flex mt-3">
                <div style={{ width: '45px' }}>
                  <UiToggle
                    id="notification_allow"
                    pclass="pb-0"
                    checked={pstate.client.notification}
                    loading={state.loader_n}
                    onChange={() => allowNotification(pstate.client.notification)}
                  />
                </div>
                <span className="me-2">Allow Notifications</span>
              </div>
              <div className="d-flex mt-3">

                <div style={{ width: '45px' }}>
                  <UiToggle
                    id="login_allow"
                    pclass="pb-0"
                    checked={pstate.client.login}
                    loading={state.loader_login}
                    onChange={() => allowLogin(pstate.client.login)}
                  />
                </div>
                <span className="me-2">Allow Login</span>
              </div>
            </div>
          )}

        </div>
      </div>
      <div>
        <div className="d-block text-secondary small">
          <div className="d-inline-flex me-3 py-2 align-items-center">
            <span className="me-2">Created by</span>
            <UiAvatarName photo={pstate.client.user_pic} name={pstate.client.user_name} />
          </div>
          <div className="d-inline-flex py-2 align-items-center">
            <span className="me-2">on</span>
            <UiTimeDisplay time={pstate.client.created_on} />
          </div>
        </div>
      </div>
    </>
  )

}

export default function ViewClient() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);
  const location = useLocation();

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader(true)
        instance
          .get("/delete_client?delete=" + id)
          .then(function (response) {
            showLoader(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['clients'])
              useStore.setState({ load_clients: false });
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }

  const fetchData = () => {
    instance
      .get("/client_dashboard?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });



      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, [id]);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        {state.client && (
          <title>
            {state.client.name} | Clients | Practive
          </title>
        )}
      </Helmet>
      <div>
        {state.client ? (
          <div>
            <PageHeader title="Client Dashboard" parent="Clients" link="/clients">
              {state.can_edit && (
                <button className="btn btn-outline-primary" onClick={() => navigate(`/client/${id}`)}>Edit</button>
              )}
              {state.can_delete && (
                <UiButton className="btn btn-outline-danger ms-2" loading={loader} onClick={() => handleDelete(id)} title="Delete" />
              )}
            </PageHeader>





            <div className="row">
              <div className="col-md-3">
                <div className="card card-default mb-4">
                  <div className="card-body">
                    {state.client.photo && (
                      <div className="border rounded p-2 w-100">
                        <img className="w-100" src={state.client.photo} />
                      </div>
                    )}
                    <h3 className="fs-4 my-3">{state.client.name}</h3>
                    {state.client.file && <p><span className="text-muted">File No.:</span> <b>{state.client.file}</b></p>}
                    {state.client.group_name && <p><span className="text-muted">Group:</span> <b>{state.client.group_name}</b></p>}
                    {state.client.users && state.client.users.length > 0 && (<>

                      <div className="mb-4">
                        <div className="d-flex border-bottom pb-2 mb-2 mt-3">
                          <div className="flex-grow-1 fs-5 fw-bold">Users</div>

                        </div>

                        <UiAvatarGroup items={state.client.users}></UiAvatarGroup>



                      </div>

                    </>)}

                    {state.client.tags && state.client.tags.length > 0 && (<>
                      <div className="d-flex border-bottom pb-2 mb-2 mt-3">
                        <div className="flex-grow-1 fs-5 fw-bold">Tags</div>

                      </div>
                      {state.client.tags.map((val, index) => (
                        <UiTagDisplay title={val.tag_name} color={val.color} key={index} />

                      ))}
                    </>)}
                  </div>
                </div>

              </div>
              <div className="col-md-9">
                <div className="tabs mb-3">
                  <NavLink to="" end className="tab-itm">Details</NavLink>
                  <NavLink to="services" className="tab-itm">Services</NavLink>

                  <NavLink to="tasks" className="tab-itm">Tasks</NavLink>

                  <NavLink to="documents" className="tab-itm">Documents</NavLink>
                  <NavLink to="ledger" className="tab-itm">Ledger</NavLink>
                  <NavLink to="doc-register" className="tab-itm">Docs. In-Out Register</NavLink>
                  <NavLink to="passwords" className="tab-itm">Passwords</NavLink>
                  <NavLink to="expenses" className="tab-itm">Expenses</NavLink>
                  <NavLink to={`/dsc-management?client=${id}::${state.client.name}`} className="tab-itm">DSC</NavLink>
                  <NavLink to={`/quotations?client=${id}::${state.client.name}`} className="tab-itm">Quotations</NavLink>
                </div>

                <Outlet context={[state, setState]} />

              </div>
            </div>


          </div>
        ) : <BgLoader />}


      </div>
    </>
  );
}
