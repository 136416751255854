import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { BgLoader, InLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const PdfPrint = NiceModal.create(
  ({ id, voucher }) => {
    const [state, setState] = useState({});
    const [loader, showLoader] = useState(false);
    const modal = useModal();



    useEffect(() => {

      //var url = 'https://api.practive.in/pdf?voucher=' + voucher + '&id=' + id;
      var url = 'https://app.practive.in/bill_print?voucher=' + voucher + '&id=' + encodeURIComponent(id);
      setState({ ...state, url: url })
      showLoader(true)
      //var xhr = new XMLHttpRequest();
      //xhr.open('GET', 'https://api.practive.in/pdf?voucher=' + voucher + '&id=' + id, true);
      //xhr.responseType = 'blob';
      //xhr.onload = function (e) {
      //if (this.status == 200) {
      //var blob = new Blob([this.response], { type: 'application/pdf' });
      //const url = URL.createObjectURL(blob);
      //setState({ ...state, url: url })
      //}
      //};
      //xhr.send();


    }, []);
    const printBtn = () => {

      window.frames["pdfprint"].focus();
      window.frames["pdfprint"].print();
    }


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Print</BootstrapModal.Title>
        </BootstrapModal.Header>
        {state.url ? (
          <>
            <BootstrapModal.Body>
              {loader && <BgLoader />}
              <iframe src={state.url} width="100%" height="600px" name="pdfprint" onLoad={() => showLoader(false)} className={`${loader == true && 'd-none'}`}></iframe>

            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <button className="btn btn-primary" onClick={() => printBtn()}>Print</button>
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </>
        ) : <BgLoader />}

      </BootstrapModal>
    );
  }
);

export default PdfPrint;
