import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UiButton, UiInput } from "../../ui";
import { setStorage } from "../../helper";
import { useStore } from "../../state.js";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import logoimg from "../../practive.svg";

import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const ForgotPassword = ({ ...props }) => {
  const recaptchaRef = React.useRef();
  const { auth, setAuth } = useStore((state) => state);
  let navigate = useNavigate();

  const schema = yup.object().shape({
    username: yup
      .string()
      .required("Username is required")
  });
  const [state, setState] = useState({});

  var ResetPass = (e) => {
    setState({ ...state, loader: true });
    var form = document.querySelector("#forgotpassfrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/reset_pass",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        } else if (response.data.status == "success") {
          Swal.fire({
            icon: "success",
            title: "Mail Sent",
            text: "Password reset link is sent to your registered email address.",
          }).then((result) => {
            navigate("/login");
          });
        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    ResetPass();
    const token1 = recaptchaRef.current.reset();
  };



  return (
    <>
    <Helmet>
      <title>
        Forgot Password? | Practive
      </title>
    </Helmet>
    <div className="container py-5 h-100">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
        <div className="mb-4 text-center">
                <img className="loginlogo" src={logoimg} />
              </div>
          <div className="card shadow-2-strong">
            <div className="card-body">
              <h3 className="mb-5">Forgot Password?</h3>
              <form id="forgotpassfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                <UiInput
                  label="Username"
                  type="text"
                  name="username"
                  message={errors.username?.message}
                  {...register("username")}
                />
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6Ld4ESkhAAAAAC9d3v4sST5JL4eDIP5qqIPtJ6zB"
                />
                <UiButton loading={state["loader"]} title="Continue" />
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
    </>
  );
};

export default ForgotPassword;
