import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiDatePicker, UiRSelect, BgLoader, InLoader,  UiActioButton, PageError, UiInput } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import { AiOutlineMore } from 'react-icons/ai'
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import { format, isValid, parse } from 'date-fns';


export default function AttendanceReport() {
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.clients);
  const setZState = useStore((state) => state.setClients);
  const [pagenum, setPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);

  var date = new Date(), y = date.getFullYear(), m = date.getMonth();
  const schema = yup.object().shape(
    {

    }
  );


  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    control,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });



  const onSubmitHandler = async (data) => {

    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate("/attendance-report?" + u, { replace: true })
    } else {
      navigate("/attendance-report?" + u)
    }


  };




  const headers = [

    { label: "User", key: "name" },
    { label: "Total Days", key: "total_days" },
    { label: "Total Hours", key: "hours" },
    { label: "Working Days", key: "working_days" },
    { label: "Weekend", key: "weekend" },
    { label: "Holidays", key: "holiday" },
    { label: "Present (1)", key: "present" },
    { label: "Overtime (1.5)", key: "overtime" },
    { label: "Half Day (0.5)", key: "half_day" },
    { label: "Paid Leave", key: "paid_leave" },
    { label: "Absent", key: "absent" },
    { label: "Leave", key: "leaves" },
    { label: "Payable Days", key: "payable_days" },

  ];




  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});

  const exportModal = useModal('export-all');
  const exportAll = () => {
    exportModal.show({ type: '/attendance_report', filter: window.location.search, header: headers, file: 'attendance-report.csv' }).then((res) => {
    });
  };

  async function fetchData(filter) {

    const { data } = await instance.get(
      "/attendance_report" + filter
    );

    var dataa = data;
    return dataa;
  }

  const defaultValues = {
    search: null,
    from: format(new Date(y,m,1), "dd-MM-yyyy"),
    to: format(new Date(y,m+1,0), "dd-MM-yyyy"),
    users: null,
    service: null,
    date: null,
  };

  const clearFilter = () => {
    reset(defaultValues)
    navigate("/attendance-report", { replace: true })
  }


  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);


  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);


  const columns = [


    {
      name: 'User',
      selector: row => row.name,
      cell: row => (<Link to={`/user/${row.id}`}><b>{row.name}</b></Link>),
      sortable: true,
      sortField: 'name',
      id: 'name',
      width: '200px'
    },

    {
      name: 'Total Days',
      selector: row => row.total_days,
      width: '150px'
    },
    {
      name: 'Total Hours',
      selector: row => row.hours,
      width: '150px'
    },
    {
      name: 'Working Days',
      selector: row => row.working_days,
      width: '150px'
    },
    {
      name: 'Weekend',
      selector: row => row.weekend,
      width: '150px',
      style: { backgroundColor: '#44bd3240' }
    },
    {
      name: 'Holidays',
      selector: row => row.holiday,
      width: '150px',
      style: { backgroundColor: '#44bd3240' }
    },
    {
      name: 'Present (1)',
      selector: row => row.present,
      width: '140px',
      style: { backgroundColor: '#44bd3240' }
    },
    {
      name: 'Overtime (1.5)',
      selector: row => row.overtime,
      width: '140px',
      style: { backgroundColor: '#44bd3240' }
    },
    {
      name: 'Half Day (0.5)',
      selector: row => row.half_day,
      width: '140px',
      style: { backgroundColor: '#44bd3240' }
    },
    {
      name: 'Paid Leave',
      selector: row => row.paid_leave,
      width: '140px',
      style: { backgroundColor: '#44bd3240' }
    },
    {
      name: 'Absent',
      selector: row => row.absent,
      width: '140px',
      style: { backgroundColor: '#f274745e' }
    },
    {
      name: 'Leave',
      selector: row => row.leaves,
      width: '140px',
      style: { backgroundColor: '#f274745e' }
    },


    {
      name: 'Payable Days',
      selector: row => row.payable_days,
      cell: row => (<b>{row.payable_days}</b>),
      width: '150px'
    },




  ];

  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate('/attendance-report?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate('/attendance-report?' + currentUrlParams.toString())
      }


    }
  };

  const handleSort = (column, sortDirection) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('sort_by', column.sortField);
    currentUrlParams.set('sort', sortDirection);
    if (location.search) {
      navigate('/attendance-report?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/attendance-report?' + currentUrlParams.toString())
    }

  };
  const toWatch = watch('to');
  const fromWatch = watch('from');

  const { data, error, isError, isLoading, isFetching } = useQuery(["attendance_report", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });



  useEffect(() => {


    const defaultValues1 = {
      search: filter.search,
      from: filter.from,
      to: filter.to,
    };
    reset(defaultValues1)

  }, [filter]);


  if (isLoading) {
    return <BgLoader />;
  }
  

  if(data.status == 'error'){
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }


  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Attendance Report | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Attendance Report" >
            {data.items &&
              <CSVLink data={data.items} filename={"attendance-report.csv"} headers={headers} className={`btn btn-outline-primary d-inline-flex me-2 ${data.items.length == 0 && 'disabled'}`} >Export</CSVLink>
            }

          </PageHeader>


          <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>

            <div className="p-3 bg-light rounded mb-4 ">

              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-6">

                      <Controller
                        name="from"
                        control={control}
                        defaultValue={format(new Date(y,m,1), "dd-MM-yyyy")}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiDatePicker
                          label="From"
                            placeholder="From"
                            onChange={onChange}
                            default_val={value}
                            maxDate={parse(toWatch, "dd-MM-yyyy", new Date())}
                            message={errors.from?.message}
                            dateFormat="dd-MM-yyyy"
                            ref={ref}
                            name={name}
                          />
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <Controller
                        name="to"
                        control={control}
                        defaultValue={format(new Date(y,m+1,0), "dd-MM-yyyy")}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiDatePicker
                            placeholder="To"
                            label="To"
                            onChange={onChange}
                            default_val={value}
                            minDate={parse(fromWatch, "dd-MM-yyyy", new Date())}
                            message={errors.to?.message}
                            dateFormat="dd-MM-yyyy"
                            ref={ref}
                            name={name}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-5">
                  <UiInput
                    label="Search"
                    type="text"

                    name="search"

                    {...register("search")}
                  />
                </div>




                <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '150px' }}>
                  <div className="d-flex justify-content-end">


                    <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                    <UiActioButton type="submit" className="fs-3" tooltip="Apply Filter" title="Go" />
                  </div>

                </div>
              </div>



            </div>

          </form>


          <div className="card card-default position-relative">
            {isFetching && <InLoader />}
            <div className="card-body fcolumn tablecard">

              <DataTable
                columns={columns}
                pagination
                striped={true}
                paginationServer
                paginationPerPage="20"
                paginationDefaultPage={pagenum}
                paginationResetDefaultPage={true}
                paginationTotalRows={data.total}
                clearSelectedRows={toggleCleared}
                onSort={handleSort}
                sortServer
                persistTableHead
                defaultSortFieldId={parsed.sort_by ? parsed.sort_by : null}
                defaultSortAsc={parsed.sort == 'desc' ? false : true}
                disabled={tableDisable}
                paginationComponentOptions={{ noRowsPerPage: true }}
                data={data.items}
                onChangePage={handlePageChange}
                noDataComponent={<TableZero title="Entries" />}
              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}
