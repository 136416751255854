import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiSelect, UiDatePicker } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const Holiday = NiceModal.create(
  ({ data }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      name: yup.string().required("Enter holiday name").max(30,'Maximum character limit is 30'),
      date: yup.string().required("Select date"),

    });
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#holidayform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_holiday",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
    useEffect(() => {


      if (data) {

        reset(data);
        setState({ ...state, editid: data.id });
      }

    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Holiday" : "New Holiday"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="holidayform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>

            <UiInput
              label="Name"
              type="text"
              required={true}
              name="name"
              message={errors.name?.message}
              {...register("name")}
            />
            <Controller
              name="date"
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <UiDatePicker
                  label="Date"
                  required={true}
                  onChange={onChange}
                  default_val={value}

                  message={errors.date?.message}
                  dateFormat="dd-MM-yyyy"
                  ref={ref}
                  name={name}
                />
              )}
            />



            {state.editid && (<input type="hidden" value={state.editid} name="id"></input>)}




          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default Holiday;
