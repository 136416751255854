import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { Toastcontent, UiDatePicker, UiButton } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const TaskDueDate = NiceModal.create(
  ({ task_id, duedate }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
     // day: yup.string().required("Select day of month"),

    });



    


    const {
      register,
      handleSubmit,
      control,
      getValues,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#taskddform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_task_duedate",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task"/>, { type: "error"});
          }
          if (response.data.status == "success") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task"/>, { type: "success"});
            modal.resolve({ resolved: true, duedate1:getValues("due_date") });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };





    useEffect(() => {
      reset({due_date:duedate})
    }, [duedate]);

  
    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    

    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Due Date</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="taskddform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <BootstrapModal.Body>
          
        

          <Controller
                  name="due_date"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiDatePicker
                      label="Due Date"
                      onChange={onChange}
                      default_val={value}
                      message={errors.due_date?.message}
                      dateFormat="dd-MM-yyyy"
                      ref={ref}
                      name={name}
                    />
                  )}
                />



            {task_id && (<input type="hidden" value={task_id} name="id"></input>)}





        </BootstrapModal.Body>
        <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default TaskDueDate;
