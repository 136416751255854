import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiDatePicker, UiInput, UiButton, UiTextArea, UiRSelect, UiToggle, BgLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const SubtaskModal = NiceModal.create(
  ({ id, st_id }) => {
    const [state, setState] = useState({
      inputValue: '',
      value: [],
    });
    const modal = useModal();

    const schema = yup.object().shape({
      title: yup.string().required("Enter task title"),

    });
    const {
      register,
      handleSubmit,
      control,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveCF();
    };
    var SaveCF = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#subtaskform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_subtask",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };




    const fetchData = () => {
      var id = st_id ? st_id : 'new';

      instance
        .get("/get_subtask?id=" + id)
        .then(function (response) {
          if (response.data.status == 'success') {
            setState({ ...state, ...response.data });

            reset(response.data.item)
          } else {
            toast(response.data.msg, { type: "error" });
          }


        })
        .catch(function (error) { })
        .then(function () { });

    }

    const watch_workflow = watch("workflow");


    useEffect(() => {

      fetchData();
    }, []);
    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);



    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{st_id ? "Edit Scheduled Subtask" : "New Subtask"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        {state.status ? (
          <form id="subtaskform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <BootstrapModal.Body>
              {st_id ? <span></span> : <>

                <label className="form-label">
                  Subtask Scheduling
                </label>
                <UiToggle
                  id="subtask_schedule"
                  name="workflow"
                  title="Create Subtask Upon Completion of Previous Subtask"
                  {...register("workflow")}
                />
              </>
              }

              <UiInput
                required={true}
                label="Title"
                type="text"
                name="title"
                message={errors.title?.message}
                {...register("title")}
              />


              <UiTextArea
                label="Description"
                name="description"
                {...register("description")}
              />



              <Controller
                name="users[]"
                control={control}
                render={({ field }) => (

                  <UiRSelect
                    {...field}
                    className=""
                    label="Users"
                    isMulti
                    message={errors.users?.message}

                    options={state.users}
                  />

                )}
              />

              {watch_workflow == 1 ?
                <>
                  <label className="form-label">
                    Due Date
                  </label>
                  <div style={{ width: "300px" }} className="d-flex align-items-center">
                    <span>Creation Date + </span>
                    <div style={{ width: "60px" }} className="mx-2">
                      <UiInput
                        type="number"
                        className="mb-0"
                        name={`due_date_days`}
                        parentClass="mb-0"
                        message={errors.due_date_days?.message}

                        {...register(`due_date_days`)}

                      />
                    </div>
                    <span> Days</span>
                  </div>
                </>
                :
                <Controller
                  name="due_date"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiDatePicker
                      label="Due Date"
                      onChange={onChange}
                      default_val={value}
                      message={errors.due_date?.message}
                      dateFormat="dd-MM-yyyy"
                      ref={ref}
                      name={name}
                    />
                  )}
                />
              }

              <UiInput type="hidden" name="task_id" value={id} {...register("task_id")} />
              <UiInput type="hidden" name="id" value={st_id} {...register("id")} />


            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Save" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>
        ) : <BgLoader />}
      </BootstrapModal>
    );
  }
);

export default SubtaskModal;
