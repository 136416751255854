import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiRSelect, BgLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const Passwords = NiceModal.create(
  ({ title, custid, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const modal = useModal();

    const schema = yup.object().shape({
      login: yup.string().required("Enter login username/email").max(60,'Maximum character limit is 60'),
      password: yup.string().required("Enter password").max(60,'Maximum character limit is 60'),
      portal_id: yup.object().required("Select login portal"),

    });
    const {
      register,
      handleSubmit,
      control,
      getValues,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      handleSave();
      //reset();
    };

    const userModal = useModal('portal');


    const addPortal = (row) => {
      //modal.hide();
      setState({ ...state, modalb: true })
      userModal.show({ data: row }).then((res) => {
        setState({ ...state, modalb: false, portals: [...state.portals, res.saved] })
        reset({ ...getValues(), portal_id: res.saved })
      });

    }


    var handleSave = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#passwordfrm");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_password_list",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };

    const fetchData = () => {
      instance
        .get("/get_update_password")
        .then(function (response) {
          if (response.data.status == 'success') {
            setState({ ...state, ...response.data });
          } else {
            toast(response.data.msg, { type: "error" });
          }


        })
        .catch(function (error) { })
        .then(function () { });

    }


    useEffect(() => {
      fetchData();


    }, []);

    useEffect(() => {
      if (data && state.portals) {
        const filtered = state.portals.reduce((a, o) => (o.value == data.portal_id && a.push(o), a), [])

        reset({ login: data.login, password: data.password, portal_id: filtered[0] });
        setState({ ...state, editid: data.id });
      }

    }, [state.status]);


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);



    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Password" : "New Password"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        {state.status ? (
        <form id="passwordfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>

            <Controller
              name="portal_id"
              control={control}
              render={({ field }) => (

                <UiRSelect
                required={true}
                  options={state.portals}
                  className=""
                  message={errors.portal_id?.message}
                  label="Select Portal"
                  addnew={1}
                  handleNew={() => addPortal()}
                  {...field}
                />

              )}
            />
            <UiInput
            required={true}
              label="Username"
              type="text"
              name="login"
              message={errors.login?.message}
              {...register("login")}
            />
            <UiInput
            required={true}
              label="Password"
              type="text"
              name="password"
              message={errors.password?.message}
              {...register("password")}
            />

            <input type="hidden" value={custid} name="cust_id" />

            {state.editid && (<input type="hidden" value={state.editid} name="id"></input>)}





          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>

        </form>
        ):<BgLoader/>}
      </BootstrapModal>
    );
  }
);

export default Passwords;
