import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiSelect, UiTagInput } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const ServiceCustomField = NiceModal.create(
  ({ idd, subtitle, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({
      value: [],
    });
    const modal = useModal();
    const schema = yup.object().shape({
      field_name: yup.string().required("Enter field name").max(30,'Maximum character limit is 30'),
      field_type: yup.string().required("Select field type"),
      options: yup.array().when('field_type', {
        is: 'Select',
        then: yup.array().min(1, 'Enter options for select field')
        .of(
          yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required(),
          })).required("Enter options for select field")
      }),
      
      status: yup.bool(),
    });
    const {
      register,
      handleSubmit,
      control,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const watchType = watch("field_type"); // you can supply default value as second argument

    const onSubmitHandler = async (data) => {
      SaveCF();
      //reset();
    };
    var SaveCF = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#updateservicecustfiendfrm");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_service_custom_field",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
    useEffect(() => {


      if (data) {
        const optns = [];
        if (data.options) {
        data.options.split(',').map((option) => { optns.push({label:option,value:option})})
        }


    
     
        reset({ field_name: data.field_name,field_type: data.field_type,options:optns });
        setState({ ...state, editid: data.id });
      } 

    }, []);

 

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    return (
      <BootstrapModal {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Custom Field" : "New Custom Field"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="updateservicecustfiendfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <BootstrapModal.Body>
         
            <UiInput
             required={true}
              label="Field Name"
              type="text"
              name="field_name"
              message={errors.field_name?.message}
              {...register("field_name")}
            />

            <UiSelect
             required={true}
              name="field_type"
              label="Field Type"
              message={errors.field_type?.message}
              options={["Input", "Select"]}
              {...register("field_type")}
            />
            {watchType == 'Select' &&
              <Controller
              name="options[]"
              control={control}
              render={({ field }) => (

                <UiTagInput
                {...field}
                className="ss"
                required={true}
                message={errors.options?.message}
                label="Options"
              placeholder="Type something and press enter..."
            

            />

              )}
            />
            }

       
            {state.editid && (<input type="hidden" value={state.editid} name="id"></input>)}
            <input type="hidden" value={idd} name="service"></input>
         


            

         
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default ServiceCustomField;
