import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, Toastcontent, UiButton, PageError, UiRSelect, BgLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const ClientServiceUsers = NiceModal.create(
  ({ service, cust_id, service_id, selected_users, users_arr }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      // day: yup.string().required("Select day of month"),

    });






    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#clientserviceusersform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/client_service_users",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "error" });
            modal.resolve({ resolved: true, users: response.data.users });
            modal.hide();
          }
          if (response.data.status == "success") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "success" });
            modal.resolve({ resolved: true, users: response.data.users });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };






    useEffect(() => {
      if (users_arr && selected_users) {
        var slctd = [];
        users_arr.forEach(element => {
          if (selected_users.includes(element['value'].toString())) {

            slctd.push(element);
          }
          reset({ users: slctd });
        });
      }
    }, [selected_users]);



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);




    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{service}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="clientserviceusersform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>



            <Controller
              name="users[]"
              control={control}
              render={({ field }) => (

                <UiRSelect
                  {...field}
                  className=""
                  isMulti
                  message={errors.users?.message}
                  label="Users"
                  options={users_arr}
                  closeMenuOnSelect={false}

                />

              )}
            />


            {cust_id ? <input type="hidden" name="cust_id" value={cust_id} /> : null}
            {service_id ? <input type="hidden" name="service" value={service_id} /> : null}



          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default ClientServiceUsers;
