import React, { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";
import { UiModal, UiInput, PageHeader, UiTFind, BgLoader, TableZero, UiButton } from "../../ui";
import { Helmet } from "react-helmet";
import { AiOutlinePrinter, AiOutlineUsergroupAdd, AiOutlineUsb } from 'react-icons/ai'
import { VscBellDot, VscBell } from 'react-icons/vsc'
import { IoReceiptOutline } from 'react-icons/io5'
import { CgWebsite } from 'react-icons/cg'
import { HiOutlineReceiptRefund, HiOutlineDocumentDuplicate, HiOutlineCash } from 'react-icons/hi'
import { RiUserSettingsLine } from 'react-icons/ri'
import { MdOutlineEditCalendar, MdAppSettingsAlt, MdBusiness, MdOutlineHolidayVillage, MdOutlineGroups } from 'react-icons/md'
import { BsInputCursorText, BsLink45Deg } from 'react-icons/bs'
import { BiCategoryAlt, BiUserCircle, BiTag, BiExport } from 'react-icons/bi'
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TbUserExclamation } from 'react-icons/tb'
import { useStore } from "../../state.js";
import {GoPackage}  from 'react-icons/go'


export default function Settings() {

  const [state, setState] = useState({});
  const admin_state = useStore((state) => state.admin);

  const tallyModal = useModal('export-tally');
  const showTallyModal = () => {
    tallyModal.show().then((res) => {
    });
  }


  

  const SettingItem = (props) => {
    const { icon, iconclass, link, title, action, perm, description, color, desc, ...other } = props;
    const color1 = color + 20;
    return (
      <>
        {other && ((admin_state.is_admin && perm == 'is_admin' && admin_state.is_admin == 1) || perm !== 'is_admin') &&
          <div className="col-sm-6 col-lg-4">
            <Link to={link ? link : ''} onClick={action} className="text-decoration-none">
              <div className="row mb-4">
            <div className="col-3">
                <div className={`ratio ratio-1x1 rounded bg-opacity-25  ${iconclass && ('bg-' + iconclass + ' text-' + iconclass)}`} style={{ color: color, backgroundColor: color1 }}><span style={{ fontSize: '2.5rem' }} className="justify-content-center d-flex align-items-center">{icon}</span>
                </div>
                </div>
                <div className="col-9 pt-1">
                  <span className="text-dark fw-semibold fs-5">{title}</span>
                  <span className="d-block text-secondary text-truncate2" title={description}>{description}</span>
                </div>
              </div>
            </Link>

          </div>
        }
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>
          Settings | Practive
        </title>
      </Helmet>
      <div>
        <PageHeader title="Settings" >

        </PageHeader>
        <div className="card card-default">
          <div className="card-body">
            <div className="row">
              <SettingItem icon={<BiTag />} title="Tags" link="/tags" color="#F44336" description="Use tags to manage your client and tasks e.g. Urgent, Important."></SettingItem>
              <SettingItem icon={<MdOutlineGroups />} title="Client Settings" link="/client-settings" color="#2196F3" description="Manage client settings and manage custom fields to add more details about clients."></SettingItem>
              <SettingItem icon={<BsLink45Deg />} title="Portals List" link="/portals" color="#FF9800" description="Manage portals or websites e.g. GST, Income Tax."></SettingItem>
              <SettingItem icon={<BiCategoryAlt />} title="Services" link="/services" color="#9C27B0" description="Add or edit the services e.g. GST-3B, Audit."></SettingItem>
              <SettingItem icon={<GoPackage />} title="Packages" link="/packages" color="#795548" description="Add or edit the packages e.g. GST Yearly Package, Complete Accountancy Package."></SettingItem>
              <SettingItem icon={<BiUserCircle />} title="Users" link="/users" color="#009688" description="Add or edit the users, user roles, password and more." perm="is_admin"></SettingItem>
              <SettingItem icon={<RiUserSettingsLine />} title="User Roles" link="/user-roles" color="#FFC107" description="Create or edit the user roles and allow or restrict control of the dashboard to users." perm="is_admin"></SettingItem>
              <SettingItem icon={<IoReceiptOutline />} title="Billing Settings" link="/billing-settings" color="#E91E63" description="Customize or create your invoice and receipt formatting." perm="is_admin"></SettingItem>
              <SettingItem icon={<VscBellDot />} title="General Notifications" link="/general-notifications" color="#00BCD4" description="Schedule general messages, WhatsApp and email timing and more." perm="is_admin"></SettingItem>
              <SettingItem icon={<VscBell />} title="Task Notifications" link="/task-notifications" color="#8BC34A" description="Schedule and apply conditions for task reminders." perm="is_admin"></SettingItem>
              <SettingItem icon={<AiOutlineUsergroupAdd />} title="Client Groups" link="/groups" color="#3F51B5" description="Add, edit or delete the client’s group."></SettingItem>
              <SettingItem icon={<HiOutlineReceiptRefund />} title="Expense Categories" link="/expense-categories" color="#4CAF50" description="Manage your expenses using categories e.g. Stationery, Legal expenses. "></SettingItem>
              <SettingItem icon={<MdOutlineEditCalendar />} title="Attendance Settings" link="/attendance-settings" color="#FF5722" description="You can set weekend days for attendance." perm="is_admin"></SettingItem>
              <SettingItem icon={<AiOutlineUsb />} title="DSC Settings" link="/dsc-settings" color="#00BCD4" description="Change automatic task creation settings for DSC (Digital Signature Certificate)" perm="is_admin"></SettingItem>
              <SettingItem icon={<MdOutlineHolidayVillage />} title="Holidays List" link="/holidays" color="#2196F3" description="Create or change the holidays list for attendance."></SettingItem>
              <SettingItem icon={<HiOutlineDocumentDuplicate />} title="Document Types" link="/document-types" color="#673AB7" description="Add or delete document type e.g. Income Tax Return, Client's PAN card."></SettingItem>
              <SettingItem icon={<HiOutlineCash />} title="Payment Modes" link="/payment-modes" color="#FF9800" description="Add or delete payment modes for receipt and expense vouchers. e.g. Bank, Cheque., Cash."></SettingItem>
              <SettingItem icon={<CgWebsite />} title="Website Settings" link="/website-settings" color="#03A9F4" description="Configure your website settings e.g. Logo, Service, Contact details and more." perm="is_admin"></SettingItem>
              <SettingItem icon={<AiOutlinePrinter />} title="Website Flyer" link="/website-promo" color="#3F51B5" description="Print the website flyer and use it for branding your company." perm="is_admin"></SettingItem>
              <SettingItem icon={<MdAppSettingsAlt />} title="Communication Settings" link="/notification-settings" color="#795548" description="Set your email and SMS settings e.g. Email server, Buy SMS and more." perm="is_admin"></SettingItem>
              <SettingItem icon={<TbUserExclamation />} title="User Notifications" link="/user-notifications" color="#009688" description="Update user notifications settings and push notification settings." perm="is_admin"></SettingItem>
              <SettingItem icon={<BiExport />} title="Export Data to Tally" action={()=>showTallyModal()} color="#FFC107" description="Export invoices and receipts data to Tally accounting software." perm="is_admin"></SettingItem>
              <SettingItem icon={<MdBusiness />} title="My Account" link="/account" color="#607D8B" description="View your plan details, billing history, upgrade plan and more." perm="is_admin"></SettingItem>




            </div>

          </div>
        </div>
      </div>
    </>
  );
}
