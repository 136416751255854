import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiRSelect, UiInput, UiButton, UiDateRangePicker, BgLoader, UiTimePicker } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { isAfter, format, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';

import { useNavigate, useLocation } from "react-router-dom";




const AddAttendance = NiceModal.create(
  ({ data, id, bulk }) => {

    const [state, setState] = useState({});
    const [loader, setLoader] = useState(true);
    const modal = useModal();

    const schema = yup.object().shape({

      bulk: yup.bool(),

      reason: yup.string().nullable().max(60, 'Maximum character limit is 60'),


      attendance: yup.string().nullable().required('Select attendance type'),
      start: yup.string().nullable().when('end', {
        is: (value) => value?.length,
        then: (rule) => rule.required("Select check-in time")
      }),

      end: yup.string().nullable().when('end', {
        is: (value) => value?.length,
        then: (rule) => rule.test(
          'empty-check',
          'Check in time must be earlier than check out time',
          val => (isAfter(parse(val, "HH:mm", new Date()), parse(starttime, "HH:mm", new Date()))) == true
        )
      }),


      bulk_user: yup.array().nullable().when('bulk', {
        is: true,
        then: (rule) => rule.required("Select user")
      }),

      date_range: yup.string().nullable().when('bulk', {
        is: true,
        then: (rule) => rule.required("Select date range")
      }),

    }, [['end', 'end']]);
    const {
      register,
      handleSubmit,
      control,
      getValues,
      resetField,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      handleSave(data);
      //reset();
    };


    const starttime = watch('start')
    const endtime = watch('end')

    const attendance_type = watch('attendance')

    var handleSave = (e) => {
      setState({ ...state, loader: true });


      instance({
        method: "post",
        url: "/update_attendance",
        data: e,
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };

    const fetchData = () => {

      instance
        .get("/bulk_attendance")
        .then(function (response) {

          setState({ ...state, ...response.data });

          setLoader(false)
        })
        .catch(function (error) { })
        .then(function () { });
    }


    useEffect(() => {

      if (bulk) {
        reset({ bulk: 1 })
        fetchData()
      } else if (data) {
        reset({ start: data.in_time, end: data.out_time, attendance: data.title, reason: data.reason, bulk: 0 });
        setLoader(false)
      }



    }, []);


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);





    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Attendance</BootstrapModal.Title>
        </BootstrapModal.Header>
        {loader == true ? <BgLoader /> :
          <form id="attendanceform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <BootstrapModal.Body>

              <>
                {bulk && <>
                  <Controller
                    name="bulk_user"
                    control={control}
                    render={({ field }) => (

                      <UiRSelect
                        {...field}
                        required={true}
                        options={state.users}
                        isMulti={true}
                        hideSelectedOptions={false}
                        className=""
                        message={errors.bulk_user?.message}
                        label="User"
                      />

                    )}
                  />
                  {state.past_attendance == 1 ?
                    <Controller
                      name="date_range"
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRangePicker
                          label="Date"
                          required={true}
                          onChange={onChange}
                          value={value}
                          message={errors.date_range?.message}
                          dateFormat="dd-mm-yyyy"

                          ref={ref}
                          name={name}
                        />
                      )}
                    /> :
                    <input type="hidden" defaultValue="Today" name="date_range"  {...register("date_range")}></input>
                  }
                </>
                }

                <>
                  <div className="mb-3 mt-2 row">
                    <div className="col">
                      <input type="radio" className="btn-check" value="Present" name="attendance" id="att1" autoComplete="off" {...register("attendance")} />
                      <label className="btn btn-outline-success w-100" htmlFor="att1">Present</label>
                    </div>
                    <div className="col">
                      <input type="radio" className="btn-check" value="Absent" name="attendance" id="att2" autoComplete="off" {...register("attendance")} />
                      <label className="btn btn-outline-danger w-100" htmlFor="att2">Absent</label>
                    </div>
                  </div>
                  <div className="mb-3 mt-2 row">
                    <div className="col">
                      <input type="radio" className="btn-check" value="Overtime" name="attendance" id="att3" autoComplete="off" {...register("attendance")} />
                      <label className="btn btn-outline-success w-100" htmlFor="att3">Overtime</label>
                    </div>
                    <div className="col">
                      <input type="radio" className="btn-check" value="Leave" name="attendance" id="att4" autoComplete="off" {...register("attendance")} />
                      <label className="btn btn-outline-danger w-100" htmlFor="att4">Leave</label>
                    </div>
                  </div>
                  <div className="mb-3 mt-2 row">
                    <div className="col">
                      <input type="radio" className="btn-check" value="Half Day" name="attendance" id="att5" autoComplete="off" {...register("attendance")} />
                      <label className="btn btn-outline-success w-100" htmlFor="att5">Half Day</label>
                    </div>
                    <div className="col">
                      <input type="radio" className="btn-check" value="Paid Leave" name="attendance" id="att6" autoComplete="off" {...register("attendance")} />
                      <label className="btn btn-outline-danger w-100" htmlFor="att6">Paid Leave</label>
                    </div>
                  </div>
                </>


                <small className="text-danger">{errors.attendance?.message}</small>


                {attendance_type && (attendance_type == 'Present' || attendance_type == 'Half Day' || attendance_type == 'Overtime') &&
                  <div className="row">
                    <div className="col-6">
                      <Controller
                        name="start"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiTimePicker
                            label="Check In Time"
                            onChange={onChange}
                            default_val={value}
                            message={errors.start?.message}

                            minTime={parse("00:00", "HH:mm", new Date())}
                            maxTime={parse(endtime, "HH:mm", new Date())}

                            ref={ref}
                            name={name}
                          />
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <Controller
                        name="end"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiTimePicker
                            label="Check Out Time"
                            onChange={onChange}
                            default_val={value}
                            message={errors.end?.message}
                            minTime={parse(starttime, "HH:mm", new Date())}
                            maxTime={parse("23:59", "HH:mm", new Date())}

                            ref={ref}
                            name={name}
                          />
                        )}
                      />
                    </div>

                  </div>
                }





                {attendance_type && (attendance_type == 'Leave' || attendance_type == 'Paid Leave') &&
                  <UiInput
                    label="Reason"
                    type="text"
                    name="reason"
                    message={errors.reason?.message}
                    {...register("reason")}
                  />

                }
                <input type="hidden" value={0} name="check_in_out"  {...register('check_in_out')} />
                <input type="hidden" name="bulk" defaultValue={bulk}  {...register('bulk')} />
                <input type="hidden" value={id} name="user" {...register('user')} />
                {data && data.date && <input type="hidden" value={data.date} name="date" {...register('date')} />}




              </>

            </BootstrapModal.Body>
            <BootstrapModal.Footer>

              <UiButton loading={state["loader"]} title="Save" />

              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>

          </form>
        }
      </BootstrapModal>
    );
  }
);

export default AddAttendance;
