import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiSelect, UiPopover, UiDatePicker, Toastcontent, UiButton, UiInput, UiToggle, BgLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useNavigate, useLocation } from "react-router-dom";




const ClientRecurringPackage = NiceModal.create(
  ({ package_id, data, cust_id, status }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({

      auto_billing: yup.bool(),
      billing_date: yup.string().nullable().when(['auto_billing', 'billing_date'], {
        is: (auto_billing, billing_date) => auto_billing === true || (billing_date && billing_date.length > 0),
        then: yup.string().nullable().required('Select billing date')
      }),
      billing_amount: yup.string().nullable().when(['auto_billing', 'billing_amount'], {
        is: (auto_billing, billing_amount) => auto_billing === true || (billing_amount && billing_amount.length > 0),
        then: yup.string().nullable().matches(/^[0-9]+$/, "Enter default billing amount").required('Enter default billing amount')
      }),
    }, [['billing_date', 'billing_date'], ['billing_amount', 'billing_amount']]);
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
      watch,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#clientpackageform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/assign_package",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "success" });
            modal.resolve({ resolved: true, group: response.data.group });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };






    useEffect(() => {
      if (data) {
        reset({ billing_amount: data.price, billing_date: data.billing_date, auto_billing: data.auto_billing == 1 ? true : false });
      }
      if (cust_id) {
        var custids = cust_id.toString().split(",");
        setState({ ...state, cust_ids: custids })
      }

    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    const watchAutobilling = watch("auto_billing");

    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{(data && data.name) ? data.name : "Package Setting"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="clientpackageform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>






            <div className="row">
              <div className="col">

                <Controller
                  name="billing_date"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiDatePicker
                      label="Billing Date"
                      required={watchAutobilling ? true : false}
                      onChange={onChange}
                      default_val={value}

                      message={errors.billing_date?.message}
                      dateFormat="dd-MM-yyyy"
                      ref={ref}
                      name={name}
                    />
                  )}
                />

              </div>
              <div className="col">



                <UiInput
                  label="Default Billing Price"
                  type="text"
                  required={watchAutobilling ? true : false}
                  icon="inr"
                  name="billing_amount"
                  {...register("billing_amount")}
                  message={errors.billing_amount?.message}
                />

              </div>
            </div>

            <UiToggle
              id="autobilling"
              name="auto_billing"
              title="Create invoice automatically"
              {...register("auto_billing")}
            />

            {status == 0 &&
            <div className="d-flex">
                <UiToggle
                  id="generatetasks"
                  name="generate_tasks"
                  title="Generate tasks for previous periods"
                  disabled={state.cust_ids && state.cust_ids.length > 1}
                  {...register("generate_tasks")}
                />
                <UiPopover msg="This feature is available only for single client." />
                </div>
            }

            <input type="hidden" value={cust_id} name="cust_id"></input>
            <input type="hidden" value={package_id} name="package"></input>
            <input type="hidden" value={status} name="status"></input>




          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default ClientRecurringPackage;
