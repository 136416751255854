import { useState, useEffect, useRef, useLayoutEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { OverlayLoader, UiRSelect, UiButton, BgLoader, InLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { rupees } from "../helper";
import { useNavigate, useLocation } from "react-router-dom";
import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";



import { Helmet } from "react-helmet";

const PGOnly = NiceModal.create(
  ({ id, type }) => {
    const [state, setState] = useState({});
    const [inner_loader, setInnerLoader] = useState(false);
    const [loaddiv, setLoaddiv] = useState(1);
    const modal = useModal();

    const parent = useRef(null);
    let cashfree = new cashfreeSandbox.Cashfree(id);


const pgForm = () => {
  let parent1 = document.getElementById("drop_in_container");

      if (parent1) {
        cashfree.drop(parent1, {
          onSuccess: function (data) {
            if (data.order.status == 'PAID') {
              setInnerLoader(true)
              instance
                .get("/verify_payment?id=" + data.order.orderId)
                .then(function (response) {
                  setInnerLoader(false)
                  if (response.data.status == "error") {
                    toast(response.data.msg, { type: "error" });
                  }
                  if (response.data.status == "success") {
                    modal.resolve({ resolved: true, status: 'paid', token:response.data.token });
                    modal.hide();
                  }
                })

            }
          },
          onFailure: function (data) {
            if (data.transaction.txStatus == 'FAILED') {
              toast(data.transaction.txMsg, { type: "error" });
            }
          },
          components: [
            "card",
            "netbanking",
            "app",
            "upi",
          ],
          style: {
            //to be replaced by the desired values
            backgroundColor: "#ffffff",
            color: "#1A73E8",
            fontFamily: "Noto Sans",
            fontSize: "14px",
            errorColor: "#ff0000",
            theme: "light"
          },
        });
      }
}




    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);



    return (
      <>
        <Helmet>
        </Helmet>
        <BootstrapModal {...bootstrapDialog(modal)} onEntered={()=>pgForm()} >
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Payment</BootstrapModal.Title>
          </BootstrapModal.Header>

          <BootstrapModal.Body>
            <div className="position-relative">
            <OverlayLoader visible={inner_loader ? true : false} />
              <div ref={parent} id="drop_in_container" >

              </div>
            </div>

          </BootstrapModal.Body>

        </BootstrapModal>
      </>
    );
  }
);

export default PGOnly;
