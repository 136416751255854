import React, { useState, useEffect, useRef } from "react";
import { instance } from "../../axios";
import { rupees, setStorage, getStorage } from "../../helper";
import { UiSelect, UiToggle, UiDatePicker, PageError, UiButton, BgLoader, UiInput, InLoader } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import DataTable from 'react-data-table-component';
import { format, isValid, parse } from 'date-fns';
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { GoPlus, GoPrimitiveDot } from "react-icons/go";
import { useStore } from "../../state.js";

export default function ClientLedger() {
  const [pstate, setPSate] = useOutletContext();
  const admin_state = useStore((state) => state.admin);
  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);
  const [showItems, setShowItems] = useState(getStorage('show_invoice_items') == 1 ? true : false);
  let componentRef = useRef();

  let navigate = useNavigate();
  const schema = yup.object().shape(
    {
      from: yup.string().required('Select date from'),
      to: yup.string().required('Select date to'),

    }
  );


  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    fetchData(data);
  };


  const headers = [
    { label: "Date", key: "date" },
    { label: "Particulars", key: "type" },
    { label: "Debit", key: "debit" },
    { label: "Credit", key: "credit" },
    { label: "Balance", key: "balance" },

  ];
  const columns = [
    {
      name: 'Date',
      selector: row => row.date,
      cell: row => (<b className="text-dark">{row.date}</b>),
      width: '100px'

    },
    {
      name: 'Particular',
      selector: row => row.type,
      cell: row => (<div className="w-100"><p className="m-0"><span className="me-1">{row.type}</span> {row.type == 'Invoice' ? <Link to={`/view-invoice/${row.id}`}> #{row.invoice_id}</Link> : row.type == 'Receipt' && <Link to={`/view-receipt/${row.id}`}> #{row.receipt_id}</Link>}</p>
        {(row.items && showItems == true) && row.items.map((item, index) => <p key={index} className="mb-1 small">{item[0]}{item[1] ? ` | ${item[1]}` : ''}</p>)}
      </div>),
      minWidth: '180px'
    },
    {
      name: 'Debit',
      selector: row => row.debit,
      cell: row => (<>{rupees(row.debit)}</>),
      width: '130px'
    },
    {
      name: 'Credit',
      selector: row => row.credit,
      cell: row => (<>{rupees(row.credit)}</>),
      width: '130px'
    },
    {
      name: 'Balance',
      selector: row => row.balance,
      cell: row => (<>{rupees(row.balance)}</>),
      width: '130px'
    }
  ];


  const toWatch = watch('to');
  const fromWatch = watch('from');

  let { id1 } = useParams();

  useEffect(() => {
    fetchData(null);
  }, []);



  var fetchData = (e) => {


    showLoader(true)
    instance({
      method: "post",
      url: "/ledger?id=" + pstate.client.id,
      data: e,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        showLoader(false)

        setState({ ...state, ...response.data });


      })
      .catch(function (response) { });
  };



  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      {state.items ?
        <div className="card card-default">
          {loader && <InLoader />}


          {state.items && state.items.length > 0 ? (
            <>
              <div className="card-body position-relative">
                <div className="row">

                  <div className="col-lg-7">
                    <form id="clientledgerfilterfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                      <div className="row g-1 g-sm-3">
                        <div className="col">
                          <Controller
                            name="from"
                            control={control}
                            defaultValue={state.pre && state.pre.from}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState,
                            }) => (
                              <UiDatePicker
                                placeholder="From"
                                onChange={onChange}
                                default_val={value}
                                maxDate={parse(toWatch, "dd-MM-yyyy", new Date())}
                                message={errors.from?.message}
                                dateFormat="dd-MM-yyyy"
                                ref={ref}
                                name={name}
                              />
                            )}
                          />
                        </div>
                        <div className="col">
                          <Controller
                            name="to"
                            defaultValue={state.pre && state.pre.to}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState,
                            }) => (
                              <UiDatePicker
                                placeholder="To"
                                onChange={onChange}
                                default_val={value}
                                minDate={parse(fromWatch, "dd-MM-yyyy", new Date())}
                                message={errors.to?.message}
                                dateFormat="dd-MM-yyyy"
                                ref={ref}
                                name={name}
                              />
                            )}
                          />
                        </div>
                        <div className="col" style={{ maxWidth: '50px', minWidth: '50px', padding: '0px' }}>
                          <UiButton loading={state["loader"]} className="w-100" title="Go" />
                        </div>

                      </div>
                    </form>
                  </div>
                  <div className="col-lg-5 d-flex justify-content-end">
                    <div className="me-2">
                      <CSVLink className="btn btn-outline-primary" data={state.items && state.items.length > 0 ? showItems ? state.csv : state.items : []} filename={"ledger.csv"} headers={headers}>Export</CSVLink>



                    </div>
                    <div className="me-2">
                      <ReactToPrint
                        trigger={() => <button className="btn btn-outline-primary">Print</button>}
                        content={() => componentRef.current}
                        
                      />
                    </div>
                    <div>
                      <DropdownMenu
                        trigger={({ triggerRef, isSelected, ...props }) => (
                          <OverlayTrigger overlay={<Tooltip >Create Invoice/Receipt</Tooltip>}><button className="btn btn-primary"
                            {...props}

                            ref={triggerRef}
                          ><GoPlus className="fs-4" /></button></OverlayTrigger>

                        )}
                      >
                        <DropdownItemGroup>
                          <DropdownItem onClick={() => navigate('/invoice/new?client=' + pstate.client.id + '::' + encodeURIComponent(pstate.client.name))}><div className="d-flex">Create Invoice</div></DropdownItem>
                          <DropdownItem onClick={() => navigate('/receipt/new?client=' + pstate.client.id + '::' + encodeURIComponent(pstate.client.name))}><div className="d-flex">Create Receipt</div></DropdownItem>

                        </DropdownItemGroup>
                      </DropdownMenu>
                    </div>
                  </div>
                </div>


              </div>
              <div className="card-body pt-0 tablecard">

                <UiToggle
                  id="show_items"
                  name="invoice_items"
                  title="Show Invoice Items"
                  onChange={(e) => {
                    setStorage("show_invoice_items", e.target.checked ? 1 : 0);
                    setShowItems(e.target.checked ? true : false)
                  }}
                  checked={showItems}
                />

                <div className="ledgertable" ref={componentRef} >
                  <div className="d-none d-print-block text-center bg-white mb-0 p-3">
                    <h1>{admin_state.user.company}</h1>
                    <hr></hr>
                    <h2>{pstate.client.name}</h2>
                    {state.period && <p>{state.period.from} - {state.period.to}</p>}
                  </div>
                 
                  <DataTable

                    columns={columns}
                    data={state.items}
                   
                  />

                </div>
              </div>

            </>
          ) : state.items && state.items.length == 0 ? (
            <div className="card-body position-relative"><span className="text-muted">No Transactions!</span></div>
          ) : <BgLoader />}
        </div>

        : <BgLoader />}
    </>
  )

}

