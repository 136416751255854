import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiDatePicker, UiSelect, UiTextArea, UiInput, UiButton, UiRSelect, UiToggle } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";
import { format, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';



const DocReturn = NiceModal.create(
  ({ id, entrydate, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const modal = useModal();

    const schema = yup.object().shape({
      date: yup.string().required("Enter date"),
     

    });
    const {
      register,
      handleSubmit,
      control,
      getValues,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      handleSave();
      //reset();
    };




    var handleSave = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#docreturnform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/return_document",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);




    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Return Document</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="docreturnform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <BootstrapModal.Body>
          
       

                <Controller
                  name="date"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiDatePicker
                    required={true}
                      label="Returned on"
                      onChange={onChange}
                      default_val={value}
                      message={errors.date?.message}
                      minDate={parse(entrydate, "dd-MM-yyyy", new Date())}
                      dateFormat="dd-MM-yyyy"
                      ref={ref}
                      name={name}
                    />
                  )}
                />
           



            <input type="hidden" defaultValue={id} name="id" {...register("id")} />
        


            
         
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
        <UiButton loading={state["loader"]} title="Mark as Returned" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>

      </BootstrapModal>
    );
  }
);

export default DocReturn;
