import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiTextArea, UiSelect, TableZero, UiInput, UiButton, UiRichText, UiToggle, BgLoader, PageError, InLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation } from "react-router-dom";
import { MdAttachFile } from "react-icons/md"


const SendWhatsapp = NiceModal.create(
  ({ id, data }) => {
    const [state, setState] = useState({});
    const [loader, showLoader] = useState(false);
    const modal = useModal();



    const schema = yup.object().shape({

      send_to: yup.string().nullable().matches(/^[0-9]+$/, "Enter 10 digit mobile number")
      .min(10, "Enter 10 digit mobile number")
      .max(10, "Enter 10 digit mobile number"),

      message: yup.string().nullable().required("Message is required").max(800, 'Maximum character limit is 800'),


    });
    var handleSave = (e) => {
      var url = "https://wa.me/91"+e.send_to+"?text="+encodeURIComponent(e.message);
      
      window.open(url, '_blank');
    };
    const {
      register,
      handleSubmit,
      setError,
      watch,
      resetField,
      clearErrors,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      handleSave(data);
    };


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);




    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Send WhatsApp</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="sendwafrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>

            <UiInput
              label="Mobile No."
              required={true}
              type="text"
              name="send_to"
              defaultValue={data.client_mobile}
              message={errors.send_to?.message}
              {...register("send_to")}
            />
            <UiTextArea
              required={true}
              label="Message"
              name="message"
              rows={7}
              defaultValue={data.wa_body}
              message={errors.message?.message}
              {...register("message")}
            />


          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Send" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default SendWhatsapp;
