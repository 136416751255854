import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiTFind, BgLoader, InLoader, UiAvatarGroup, PageError } from "../../ui";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import { Helmet } from "react-helmet";
import { useQuery, useQueryClient, select } from "react-query";

import { GoPlus } from "react-icons/go";

export default function UserRoles() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()


  const [state, setState] = useState({});
  const [filteredItems, setFilteredItems] = useState({});
  const [loader, showLoader] = useState({});



  const fetchData = () => {
    showLoader(true)
    instance
      .get("/user_roles")
      .then(function (response) {
        showLoader(false)
        setState({ ...state, ...response.data });
        const filteredItems1 = response.data.user_roles.filter(
          item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(filteredItems1);
      })
      .catch(function (error) { })
      .then(function () { });

  }





  const [filterText, setFilterText] = React.useState('');


  const columns = [
    {
      name: 'Name',
      sortable: true,
      selector: row => row.name,
      cell: row => (<Link to={row.current ? '/profile' : `/user-role/${row.id}`}><b>{row.name}</b></Link>)
    },
    {
      name: 'Assigned Users',
      minWidth: '160px',
      selector: row => row.users,
      cell: row => (<div><UiAvatarGroup items={row.users}></UiAvatarGroup> </div>)
    },

  ];

  useEffect(() => {
    if (state.user_roles) {
      const filteredItems1 = state.user_roles.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);


  useEffect(() => {
    fetchData();
  }, []);

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }



  return (
    <>
      <Helmet>
        <title>
          User Roles | Practive
        </title>
      </Helmet>
      <div>
        {state.user_roles ?
          <div>

            <PageHeader title="User Roles" >
              <UiButton className="btn btn-primary" onClick={() => navigate("/user-role/new")} title="New" icon="add"></UiButton>

            </PageHeader>

            <div className="card card-default position-relative">
              {loader && <InLoader />}
              <div className="card-body">

                <div className="">
                  <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
                </div>
              </div>
              <div className="card-body pt-0 tablecard">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  noDataComponent={<TableZero title="User Roles" />}
                />

              </div>
            </div>
          </div>
          : <BgLoader />}
      </div>
    </>
  );
}
