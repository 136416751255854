import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiRSelect, BgLoader, InLoader, UiDateRange,  UiActioButton, PageError, UiInput, UiSelect } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { rupees } from "../../helper";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { TodoItem } from "../../todo-item";

export default function ToDos() {
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.clients);
  const setZState = useStore((state) => state.setClients);
  const [pagenum, setPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);


  const schema = yup.object().shape(
    {
      //search: yup.string(),

    }
  );


  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });



  const onSubmitHandler = async (data) => {
    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate("/todos?" + u, { replace: true })
    } else {
      navigate("/todos?" + u)
    }


  };





  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setTableDisable(true)
        instance
          .get("/update_todo?delete=" + id)
          .then(function (response) {
            setTableDisable(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['todos'])
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }
           
          })

      }
    })


  }




  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});



  async function fetchData(filter) {

    const { data } = await instance.get(
      "/todos" + filter
    );

    var dataa = data;
    return dataa;
  }

  const defaultValues = {
    search: null,
    due_date: null,
    date: null,
      user: null,
      category: null,
  };

  const clearFilter = () => {
    reset(defaultValues)
    navigate("/todos", { replace: true })
  }

  const conditionalRowStyles = [
    {
      when: row => row,
      style: {
        
      border:'none'
      },
    }
  ];

  const completeTodo = (todo, act) => {
    setTableDisable(true)
    instance
      .get("/complete_todo?action=" + act + "&todo=" + todo)
      .then(function (response) {
        setTableDisable(false)
        if (response.data.status == 'success') {
          queryClient.invalidateQueries(['todos'])
        } else if (response.data.status == 'error') {
          toast(response.data.msg, { type: "error" });
        }



      })
      .catch(function (error) { })
      .then(function () { });

  }
  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);


  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);

  const userModal = useModal('todo');


  const showAddModal = (row) => {
    userModal.show({ data: row}).then((res) => {
      queryClient.invalidateQueries(['todos'])
    });
  }

  const viewtodoModal = useModal('view-todo');


  const ViewToDo = (row) => {
    viewtodoModal.show({ data: row }).then((res) => {
      if (res.resolved == true) {
        queryClient.invalidateQueries(['todos'])
      }

    });
  }


  const columns = [
    {
      name: 'Date',
      selector: row => row.date,
         cell: row => (<TodoItem val={row} key='1' onComplete={completeTodo} onDelete={handleDelete} onView={ViewToDo} onEdit={showAddModal}/>)
    }
  ];






  async function fetchData1(id) {

    const { data } = await instance.get(
      "/filters?q="+id
    );
    var dataa = data;
    return dataa;
  }
  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate('/todos?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate('/todos?' + currentUrlParams.toString())
      }


    }
  };



  const { data, error, isError, isLoading, isFetching } = useQuery(["todos", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });

  const { data: f_data, isLoading: f_is_loading } = useQuery(["filters",'U'], () => fetchData1('U'), {
    keepPreviousData: true,
  });
  const clearSelection = () => {
    setToggleCleared(!toggleCleared)
    setState1({ selected: [] })
  }
  useEffect(() => {

    if (f_data) {
      const defaultValues1 = {
         search: filter.search,
         date: filter.date,
         due_date: filter.due_date,
         category: filter.category,
        user: f_data.users && filter.user ? f_data.users.filter(({ value }) => filter.user.split(',').includes(value.toString())) : null,
      };
      reset(defaultValues1)
    }
  }, [f_data, filter]);


  if (isLoading) {
    return <BgLoader />;
  }
  if(data.status == 'error'){
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          To-Do | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="To-Do" >
         
            <UiButton className="btn btn-primary" onClick={() => showAddModal()} title="New" icon="add"></UiButton>

          </PageHeader>


          <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            {f_is_loading == true && <InLoader />}
            {f_data && (
            <div className="p-3 bg-light rounded mb-4 ">

              <div className="row">


              <div className="col">
                  <label className="form-label">Creation Date</label>
                  <Controller
                    name="date"
                    control={control}
                    defaultValue="All Time"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDateRange

                        onUpdate={onChange}
                        value={value}
                        ref={ref}
                        name={name}

                      />
                    )}
                  />
                </div>
                
                <div className="col-md-3">
                  <label className="form-label">Due Date</label>
                  <Controller
                    name="due_date"
                    control={control}
                    defaultValue="All Time"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDateRange

                        onUpdate={onChange}
                        value={value}
                        ref={ref}
                        name={name}

                      />
                    )}
                  />
                </div>

                <div className="col-md-3">
                  <UiSelect
                    label="Category"
                    options={['Today','Upcoming','Completed']}

                    name="category"

                    {...register("category")}
                  />
                </div>


       


                <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  <UiInput
                    label="Search"
                    type="text"

                    name="search"

                    {...register("search")}
                  />
                </div>

                <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  <Controller
                    name="user[]"
                    control={control}
                    render={({ field }) => (

                      <UiRSelect
                        defaultValue={null}
                        options={f_data.users}
                        className=""
                        isMulti
                        message={errors.users?.message}
                        label="User"
                        {...field}
                      />

                    )}
                  />
                </div>


                <div className={`col ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  </div>


                <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '200px' }}>
                  <div className="d-flex justify-content-end">
                    {zstate.more_filter == true ? (
                      <UiActioButton type="button" className="fs-3 text-dark" title="Less" tooltip="Hide More Filter" action={() => setZState({ ...zstate, more_filter: false })} />
                    ) : (

                      <UiActioButton type="button" className="fs-3 text-dark" title="More" tooltip="More Filter" action={() => setZState({ ...zstate, more_filter: true })} />
                    )}

                    <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                    <UiActioButton type="submit"  className="fs-3" tooltip="Apply Filter" title="Go" />
                  </div>

                </div>
              </div>



            </div>
            )}
          </form>

      
          <div className="card card-default position-relative">
            {isFetching && <InLoader />}
            <div className="card-body tablecard">

              <DataTable

                columns={columns}
           
                pagination
                paginationServer
                paginationPerPage="20"
                paginationDefaultPage={pagenum}
                paginationResetDefaultPage={true}
                paginationTotalRows={data.total}
                noTableHead={true}
                disabled={tableDisable}
                paginationComponentOptions={{ noRowsPerPage: true }}
                data={data.items}
                onChangePage={handlePageChange}
                noDataComponent={<TableZero title="To-Do" />}
                conditionalRowStyles={conditionalRowStyles}
              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}
