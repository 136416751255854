import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { PageHeader, UiToggle, UiTimeDisplay, UiAvatarName, UiButton, PageError, BgLoader, OverlayLoader } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Helmet } from "react-helmet";
import { useQueryClient } from 'react-query';
import { rupees } from "../../helper";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { MdOutlineAutorenew } from "react-icons/md";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



export default function ViewPackage() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  const [vloader, showVLoader] = useState(false);
  const [bg_loader, setBGLoader] = useState(false);



  const clientPackageModal = useModal('client-package');

  const showPackageModal = (id) => {

    clientPackageModal.show({ id: id }).then((res) => {
      fetchData()
    });



  }


  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Client package and all tasks related to this package will be deleted. You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader({ ...loader, delete: true })
        instance
          .get("/delete_client_package?delete=" + id)
          .then(function (response) {
            showLoader({ ...loader, delete: false })
            if (response.data.status == 'success') {

              queryClient.invalidateQueries(['client_packages'])
              queryClient.invalidateQueries(['tasks'])
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }



  const handlePackage = (id, action) => {
    var msg;

    if (action == 'add_task') {
      msg = 'All tasks related to services assigned in this package will be added in this package. ';
    } else if (action == 'remove_task') {
      msg = 'All tasks of this package will be removed from this package.';
    } else if (action == 'generate_task') {
      msg = 'All tasks of this package will be generated.';
    }
    Swal.fire({
      title: 'Are you sure?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Continue'
    }).then((result) => {
      if (result.isConfirmed) {
        showVLoader(true)
        instance
          .get("/package_task_action?package=" + id + "&action=" + action)
          .then(function (response) {
            showVLoader(false)
            if (response.data.status == 'success') {

              fetchData()
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }




  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/view_package?id=" + id)
      .then(function (response) {
        setBGLoader(false)

        setState({ ...state, ...response.data });

      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, []);







  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }




  return (
    <>
      <Helmet>
        {state.package && (
          <title>
            {state.package.title} | Packages | Practive
          </title>
        )}

      </Helmet>
      <div>
        <OverlayLoader visible={vloader} />
        {state.package ? (
          <>

            <PageHeader title='Package Dashboard' parent="Packages" link="/client-packages" stacked={true}>
              {state.can_edit == true && <>
                <DropdownMenu
                  trigger={({ triggerRef, isSelected, ...props }) => (
                    <button className="btn btn-outline-primary"
                      {...props}
                      ref={triggerRef}
                    >Bulk Action</button>

                  )}
                >
                  <DropdownItemGroup>
                    <DropdownItem onClick={() => handlePackage(id, 'add_task')}><div className="d-flex">Add Existing Tasks</div></DropdownItem>
                    <DropdownItem onClick={() => handlePackage(id, 'remove_task')}><div className="d-flex">Remove Package Tasks</div></DropdownItem>
                    <DropdownItem onClick={() => handlePackage(id, 'generate_task')}><div className="d-flex">Generate Tasks</div></DropdownItem>

                  </DropdownItemGroup>
                </DropdownMenu>

                <OverlayTrigger overlay={state.package.is_billed == 1 ? <Tooltip >Can't edit billed package</Tooltip> : <div></div>} >
                  <div className="ms-2">
                    <button className="btn btn-outline-primary" onClick={() => showPackageModal(id)} disabled={state.package.is_billed == 1 ? true : false}>Edit</button>
                  </div>
                </OverlayTrigger>
              </>
              }

              {state.can_delete == true && (
                <OverlayTrigger overlay={state.package.is_billed == 1 ? <Tooltip >Can't delete billed package</Tooltip> : <div></div>} >
                  <div className="ms-2">
                    <UiButton className="btn btn-outline-danger" loading={loader.delete} onClick={() => handleDelete(id)} title="Delete" disabled={state.package.is_billed == 1 ? true : false} />
                  </div>
                </OverlayTrigger>
              )}
            </PageHeader>





            {bg_loader == true ? <BgLoader /> : (
              <>
                <div className="card card-default mb-4">
                  <div className="card-body">
                    <div className="d-lg-flex">
                      <div className="me-3">
                        <h3><span className="me-2" >{state.package.title}</span>{state.package.period && <span className="bg-light badge text-dark">{state.package.period}</span>}</h3>

                      </div>
                      <div className="pt-1">
                        {state.package.is_billed == 1 ?
                          <><span className="badge fs-6 Billed me-2">Billed</span></>
                          : <><span className="badge fs-6 Unbilled me-2">Unbilled</span>
                            {state.package.auto_billing == 1 &&
                              <OverlayTrigger overlay={<Tooltip >An invoice for the amount of {rupees(state.package.billing_amount)} will be automatically generated on {state.package.billing_date}.</Tooltip>} >
                                <span className="border badge text-dark fs-6"><MdOutlineAutorenew className="text-success" /> Auto-Invoice</span>
                              </OverlayTrigger>
                            }
                          </>
                        }


                      </div>


                    </div>





                    {state.package.name && <div className="row mt-2"><div className="col" style={{ maxWidth: '100px', minWidth: '100px' }}><span className="text-secondary" >Client:</span></div><div className="col"><span className="d-flex align-items-center"><Link to={`/view-client/${state.package.cust_id}`}><b>{state.package.name}</b></Link> </span></div></div>}

                    <div className="row mt-2">
                      <div className="col" style={{ maxWidth: '100px', minWidth: '100px' }}><span className="text-secondary" >Invoice:</span></div>
                      <div className="col">
                        <span className="d-flex align-items-center">
                          {state.package.is_billed == 1 ?
                            <><Link to={`/view-invoice/${state.package.invoice_id}`}>View Invoice</Link></>
                            :
                            <>
                              <Link to={`/invoice/new?client=${state.package.cust_id}::${encodeURIComponent(state.package.name)}`}>Create Invoice</Link>
                            </>
                          }

                        </span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col" style={{ maxWidth: '100px', minWidth: '100px' }}><span className="text-secondary" >Billing Date:</span></div>
                      <div className="col">
                        <span className="d-flex align-items-center">
                          {state.package.billing_date ? state.package.billing_date : '-'}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col" style={{ maxWidth: '100px', minWidth: '100px' }}><span className="text-secondary" >Billing Price:</span></div>
                      <div className="col">
                        <span className="d-flex align-items-center">
                          {state.package.billing_amount > 0 ? rupees(state.package.billing_amount) : '-'}
                        </span>
                      </div>
                    </div>






                  </div>
                </div>
                <div className="card card-default">
                  <div className="card-body">
                    {state.columns &&
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Service</th>
                              {state.columns.map((val, i) => (
                                <th key={i}>{val.period}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {state.items && state.items.map((val, i) => (
                              <tr key={i}>
                                <td>{val.name}</td>
                                {val.periods.map((val1, i1) => (
                                  <td key={i1} className="text-center" colSpan={val1.frequency == 'Yearly' ? 12 : val1.frequency == 'Quarterly' ? 3 : 0}>
                                    {val1.val.status && <Link to={`/view-task/${val1.val.id}`}><span className={`badge ${val1.val.status}`}>{val1.val.status}</span></Link>}
                                  </td>
                                ))}
                              </tr>

                            ))}
                          </tbody>
                        </table>
                      </div>
                    }
                  </div>
                </div>

                <div>
                  <div className="d-block text-secondary small">
                    <div className="d-inline-flex me-3 py-2 align-items-center">
                      <span className="me-2">Created by</span>
                      <UiAvatarName photo={state.package.user_pic} name={state.package.user_name} />
                    </div>
                    <div className="d-inline-flex py-2 align-items-center">
                      <span className="me-2">on</span>
                      <UiTimeDisplay time={state.package.created_on} />
                    </div>
                  </div>
                </div>

              </>
            )}
          </>

        ) : <BgLoader />}


      </div>
    </>
  );
}
