import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiSelect, UiButton, UiRSelect, BgLoader, InLoader, UiDateRange, UiActioButton, UiActionDropDown, UiInput, PageError, UiAvatar, UiClientSelect } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getStorage, setStorage, updateFilterUrl } from "../../helper";
import { Link, useLocation } from "react-router-dom"
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { Table } from 'antd';



export default function DocCollectionReq() {
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.clients);
  const setZState = useStore((state) => state.setClients);
  const [pagenum, setPage] = useState(1);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [initialLoad, setInitLoad] = useState(false);


  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: getStorage('perpage') ? [20, 50, 100].includes(parseInt(getStorage('perpage'))) ? parseInt(getStorage('perpage')) : 20 : 20,
      showSizeChanger: true,
      pageSizeOptions: [20, 50, 100],
    },

  });

  const schema = yup.object().shape(
    {

    }
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    formState,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });



  const onSubmitHandler = async (data) => {

    setSelectedRowKeys([])
    setPage(1)
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    
    navigate(updateFilterUrl(obj, 'doc-requests', 1), { replace: location.search ? true : false })


  };




  const handleDelete = (id) => {
    var tid = id.toString();

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setTableDisable(true)
        instance
          .get("/delete_doc_req?id=" + tid)
          .then(function (response) {
            setTableDisable(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['doc-requests'])
              clearSelection()
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })




  }



  const headers = [


    { label: "Date", key: "date" },
    { label: "Client", key: "name" },
    { label: "Task", key: "task" },
    { label: "Message", key: "subject" },
    { label: "Uploaded Documents", key: "docs" },
    { label: "Status", key: "status" },



  ];

  const [state, setState] = useState({});
  const [filter, setFilter] = useState({});


  async function fetchData(filter) {

    const { data } = await instance.get(
      "/doc_requests" + filter
    );

    var dataa = data;
    return dataa;
  }

  const defaultValues = {
    search: null,
    client: null,
    date: null,
    upload_date: null,
    status: null,


  };

  const clearFilter = () => {


    setSelectedRowKeys([])
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });


    reset(defaultValues)
    navigate(updateFilterUrl({}, 'doc-requests', 1), { replace:true })
  }
  const docreqModal = useModal('doc-collection');


  const requestDoc = (id, row) => {
    setState({ ...state, modalb: true })
    docreqModal.show({ data: row, id: id }).then((res) => {
      queryClient.invalidateQueries(['doc-requests'])
    });

  }


  const doclistModal = useModal('doc-request');


  const showDocs = (id, period) => {
    setState({ ...state, modalb: true })
    doclistModal.show({ period: period, id: id }).then((res) => {
      queryClient.invalidateQueries(['doc-requests'])
    });

  }



  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);


  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: parseInt(parsed.page),
        },
      });
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);


  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: 'date',
      sortOrder: parsed.sort_by == 'date' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'date' && parsed.sort == 'desc' ? 'descend' : '',
      width: '120px',
    },
    {
      title: 'Client ',
      dataIndex: 'name',
      sorter: 'name',
      sortOrder: parsed.sort_by == 'name' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'name' && parsed.sort == 'desc' ? 'descend' : '',
      render: (text, row) => (<a className="text-truncate fw-bold c-pointer d-block" onClick={() => showDocs(row.id, data.upload_date)} title={row.name}>{row.name}</a>),
    },
    {
      title: 'Task ',
      dataIndex: 'task',
      sorter: 'task',
      sortOrder: parsed.sort_by == 'task' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'task' && parsed.sort == 'desc' ? 'descend' : '',
      render: (text, row) => (<div className="text-truncate d-block"><span title={row.task}>{row.task}</span></div>),
    },

    {
      title: 'Message',
      dataIndex: 'subject',
      render: (text, row) => (<div className="d-flex"><span className="text-truncate" title={row.subject}>{row.subject}</span></div>),
    },
    {
      title: 'Documents',
      dataIndex: 'docs',
      width:'100px',
    },
    

    {
      title: 'Status',
      dataIndex: 'status',
      sorter: 'status',
      width:'100px',
      sortOrder: parsed.sort_by == 'status' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'status' && parsed.sort == 'desc' ? 'descend' : '',
      render: (text, row) => (<><span className={`${row.status == 'Closed' ? 'text-success' : 'text-warning'}`}>{row.status}</span></>)
    },
    {
      title: 'Created by',
      dataIndex: 'user',
      width:'100px',
      render: (text, row) => (<UiAvatar name={row.user} photo={row.photo} />),
    },
    {
      title: '',
      fixed: 'right',
      width: '40px',
      render: (text, row) => (
        <div className="tableaction1">
          <UiActionDropDown newclassName="btn tablebtn" >
            <DropdownItem isDisabled={row.can_edit == 1 ? false : true} onClick={() => requestDoc(row.id)} >Edit</DropdownItem>
            <DropdownItem className="text-danger" isDisabled={row.can_delete == 1 ? false : true} onClick={() => handleDelete(row.id)} >Delete</DropdownItem>

          </UiActionDropDown>
        </div>


      )
    },

  ];






  async function fetchData1(id) {

    const { data } = await instance.get(
      "/filters?q=" + id
    );
    var dataa = data;
    return dataa;
  }


  useEffect(() => {

    var set_perpage = 0;
    if (location.search) {
      const parsed = queryString.parse(location.search);
      if (parsed.perpage) {
        if ([20, 50, 100].includes(parseInt(parsed.perpage))) {
          setInitLoad(true)
        } else {
          setStorage('perpage', 20)
          set_perpage = 1;
        }

      } else {
        set_perpage = 1;
      }

    } else {
      set_perpage = 1;
    }

    if (set_perpage == 1) {
 

      navigate(updateFilterUrl(window.location.search, 'doc-requests', 1), { replace: true })
    }

  }, [location.search]);

  const { data, error, isError, isLoading, isFetching } = useQuery(["doc-requests", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
    enabled: initialLoad
  });

  const { data: f_data, isLoading: f_is_loading } = useQuery(["filters", 'D'], () => fetchData1('D'), {
    keepPreviousData: true,
  });
  const clearSelection = () => {
    setSelectedRowKeys([])
  }
  useEffect(() => {

    if (f_data) {
      const defaultValues1 = {
        search: filter.search,
        date: filter.date,
        upload_date: filter.upload_date,

        status: filter.class,
        client: filter.client && ({ value: parseInt(filter.client.split('::')[0]), label: filter.client.split('::')[1] }),

      };
      reset(defaultValues1)
    }
  }, [f_data, filter]);


  useEffect(() => {
    if (data) {
      const parsed = queryString.parse(location.search);
      var setpage = 1;
      if (parsed.page) {
        setpage = parseInt(parsed.page);
      }
      if (parsed.page && parseInt(parsed.page) > 1 && data.items.length == 0) {
        let currentUrlParams = new URLSearchParams(window.location.search);

        setpage = Math.ceil(data.total / parseInt(tableParams.pagination.pageSize));
        setPage(setpage)

        currentUrlParams.set('page', setpage);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.total,
            current: setpage,
          },
        });

        navigate('/doc-requests?' + currentUrlParams.toString(), { replace: true })
      } else {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.total,
            current: setpage,
          },
        });
      }
    }

  }, [data]);


  const handleTableChange = (pagination, filters, sorter) => {
    var direction = ''

    if (sorter.order == 'ascend') {
      direction = 'asc';
    } else if (sorter.order == 'descend') {
      direction = 'desc';
    }
    var pg = pagination.current
    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);

    if (sorter.order) {
      currentUrlParams.set('sort_by', sorter.field);
      currentUrlParams.set('sort', direction);
    } else {
      currentUrlParams.delete('sort_by');
      currentUrlParams.delete('sort');
    }

    currentUrlParams.set('page', pg);
    var perpage = pagination.pageSize
    currentUrlParams.set('perpage', perpage);
    setStorage('perpage', perpage)
    if (location.search) {
      navigate('/doc-requests?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/doc-requests?' + currentUrlParams.toString())
    }

    setTableParams({
      pagination,
      filters,
      ...sorter,
    });


  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };



  if (isLoading) {
    return <BgLoader />;
  }
  if (!data) {
    return (
      <BgLoader />
    )
  }
  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Document Collection | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Document Collection" >
            {(data.items && data.access.export == 1) &&
              <CSVLink data={data.items} filename={"doc-collection.csv"} headers={headers} className={`btn btn-outline-primary d-inline-flex me-2 ${data.items.length == 0 && 'disabled'}`} >Export</CSVLink>
            }
            <UiButton className="btn btn-primary" onClick={() => requestDoc('new')} title="New" icon="add" disabled={data.access.add == 1 ? false : true}></UiButton>

          </PageHeader>


          <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            {f_is_loading == true && <InLoader />}
            {f_data && (
              <div className="p-3 bg-light rounded mb-4 ">

                <div className="row">


                  <div className="col-md-3">
                    <label className="form-label">Creation Date</label>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>

                  <div className="col-md-3">
                    <label className="form-label">Upload Date</label>
                    <Controller
                      name="upload_date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>





                  <div className={`col`}>
                    <Controller
                      name="client"
                      control={control}
                      render={({ field }) => (

                        <UiClientSelect
                          {...field}

                          className=""
                          message={errors.client?.message}
                          label="Client"
                        />

                      )}
                    />


                  </div>





                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiSelect
                      label="Status"
                      options={['Open', 'Closed']}
                      //defaultValue={val.value}
                      name="status"

                      {...register('status')}
                    />
                  </div>
                  

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiInput
                      label="Search"
                      type="text"

                      name="search"

                      {...register("search")}
                    />
                  </div>


                  <div className={`col ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  </div>

                  <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '200px' }}>
                    <div className="d-flex justify-content-end">
                      {zstate.more_filter == true ? (
                        <UiActioButton type="button" className="fs-3 text-dark" title="Less" tooltip="Hide More Filter" action={() => setZState({ ...zstate, more_filter: false })} />
                      ) : (

                        <UiActioButton type="button" className="fs-3 text-dark" title="More" tooltip="More Filter" action={() => setZState({ ...zstate, more_filter: true })} />
                      )}

                      <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                      <UiActioButton type="submit" className="fs-3" tooltip="Apply Filter" title="Go" />
                    </div>

                  </div>
                </div>



              </div>
            )}
          </form>

          {selectedRowKeys && selectedRowKeys.length > 0 && (
            <div className="bulkaction">
              <div>

                <button className="btn btn-outline-danger btn-sm me-2" disabled={data.access.delete ? false : true} onClick={() => handleDelete(selectedRowKeys)} >Delete</button>
                <button className="btn btn-secondary btn-sm me-2" onClick={() => clearSelection()}>Clear Selection <div className="badge bg-primary">{selectedRowKeys.length}</div></button>

              </div>
            </div>
          )}
          <div className="card card-default position-relative">
            {isFetching && <InLoader />}
            <div className="card-body tablecard">



              <Table
                locale={{
                  emptyText: (<TableZero title="Requests" />)
                }}

                size="small"
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={data.items}
                pagination={{ ...tableParams.pagination, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                loading={{ indicator: <BgLoader />, spinning: tableDisable }}
                rowSelection={rowSelection}
                onChange={handleTableChange}
                rowClassName={(sa) => sa.cancel == 1 && 'cancelled-row'}
                scroll={{
                  x: 960,

                }}
              />



            </div>
          </div>
        </div>
      </div>
    </>
  );
}
