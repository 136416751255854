import React, { useState, useEffect, useCallback } from "react";
import { instance } from "../../axios";
import {
  PageHeader,
  UiInput,
  UiButton,
  UiToggle,
  UiRichText,
  UiTextArea,
  UiRSelect,
  UiImageUpload,
  PageError,
  BgLoader,
} from "../../ui";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useQuery, useQueryClient, select } from "react-query";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { MdOutlineDragHandle } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import Select, { components } from "react-select";
import { Helmet } from "react-helmet";

const DragHandle = sortableHandle(() => <span className="h2 pe-3 mb-0 text-secondary"><MdOutlineDragHandle /></span>);

const SortableItem = sortableElement((value) => {
  return (
    <div className="d-flex align-items-center bg-light p-3 rounded mb-2 " >
      <DragHandle />
      <div className="w-100 d-flex row" {...value} />
    </div>
  )
});


const SortableContainer = sortableContainer(({ children }) => {
  return <div className="">{children}</div>;
});




export default function WebsiteSettings() {
  const [show, setShow] = useState(false);
  const [logo, setLogo] = useState(null);
  const [modal, setModal] = useState();
  const queryClient = useQueryClient();
  const [entry, setEntry] = useState([]);
  const schema = yup.object().shape(
    {
      company: yup.string().required("Company name is required").max(60, 'Maximum character limit is 60'),
      about_h1: yup.string().required("This is required field").max(45, 'Maximum character limit is 45'),
      home_h1: yup.string().required("This is required field").max(150, 'Maximum character limit is 150'),
      address: yup.string().required("This is required field").max(300, 'Maximum character limit is 300'),
      mobile: yup.string().required("This is required field").max(30, 'Maximum character limit is 30'),
      about_content: yup.string().required("This is required field").max(2000, 'Maximum character limit is 2000'),

      domain_type: yup.string(),
      pg: yup.string(),

      custom_domain: yup.string().nullable()
        .when("domain_type", {
          is: 'custom',
          then: (rule) =>
            rule
              .matches(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/, "Enter valid domain name")
              .required("This is required field"),
        }),

      sub_domain: yup.string().nullable()
        .when("domain_type", {
          is: 'subdomain',
          then: (rule) =>
            rule
              .matches(/^[a-zA-Z0-9]+$/, "Enter alphanumeric characters only")
              .required("This is required field"),
        }),

        razorpay_key: yup.string().nullable()
        .when("pg", {
          is: 'Razorpay',
          then: (rule) =>
            rule
              .required("This is required field"),
        }),

        razorpay_secret: yup.string().nullable()
        .when("pg", {
          is: 'Razorpay',
          then: (rule) =>
            rule
              .required("This is required field"),
        }),

        phonepe_id: yup.string().nullable()
        .when("pg", {
          is: 'Phonepe',
          then: (rule) =>
            rule
              .required("This is required field"),
        }),

        phonepe_salt: yup.string().nullable()
        .when("pg", {
          is: 'Phonepe',
          then: (rule) =>
            rule
              .required("This is required field"),
        }),

        phonepe_index: yup.string().nullable()
        .when("pg", {
          is: 'Phonepe',
          then: (rule) =>
            rule
              .required("This is required field"),
        }),

      service: yup.array().of(
        yup.object().shape({
          title: yup.string().nullable().required('This is required field').max(35, 'Maximum character limit is 35'),
          description: yup.string().nullable().required('This is required field').max(100, 'Maximum character limit is 100'),
          icon: yup.object().nullable().required('Icon required'),
        })
      ),

    },
    []
  );
  const [state, setState] = useState({ count: 0 });
  const [loader, showLoader] = useState({});
  useEffect(() => {
    instance
      .get("/website_settings")
      .then(function (response) {
        setState({ ...state, ...response.data });
        //setEntry(response.data.settings.service);
        reset(response.data.settings)
      })
      .catch(function (error) { })
      .then(function () { });
  }, []);



  var SaveSettings = (e) => {
    showLoader({ ...loader, loader: true });

    instance({
      method: "post",
      url: "/update_website_settings",
      data: e,
      //headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader({ ...loader, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setError,
    setValue,
    clearErrors,
    watch,
    unregister,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    SaveSettings(data);
    //reset();
  };
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "service", // unique name for your Field Array
  });


  const onSortEnd = ({ oldIndex, newIndex }) => {

    //var newsort = arrayMove(entry, oldIndex, newIndex);
    //setEntry(newsort)
    //console.log(newsort)
    //setValue('service.0.title', '1');
    //reset({...getValues, service:newsort})
    move(oldIndex, newIndex)
  };


  const insertEntry = () => {

    append()

  }


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  const w_domain_type = watch('domain_type')
  const w_pg = watch('pg')


  const checkSubDomain = (val) => {
    instance
      .get("/check_subdomain?val=" + val)
      .then(function (response) {
        if (response.data.status == 'error') {
          setError('sub_domain', { message: response.data.msg })
        } else {
          clearErrors('sub_domain');
        }
      })

  }



  return (
    <div>
      {state.settings ? (

        <div>
          <Helmet>
            <title>
              Website Settings | Practive
            </title>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css" integrity="sha512-SzlrxWUlpfuzQ+pcUCosxcglQRNAq/DZjVsC0lE40xsADsfeQoEypE+enwcOiGjk/bSuGGKHEyjSoQ1zVisanQ==" crossorigin="anonymous" referrerpolicy="no-referrer" />
          </Helmet>

          <PageHeader title="Website Settings" parent="Settings" link="/settings">

          </PageHeader>



          <form onSubmit={handleSubmit(onSubmitHandler)} noValidate>


            <div className="card card-default mb-4">
              <div className="card-header">General Settings</div>
              <div className="card-body">
                <Controller
                  name="logo"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiImageUpload
                      lcol="2"
                      icol="10"
                      label="Logo"
                      name="logo"
                      file={value}
                      setFile={onChange}
                      onClear={() => onChange(null)}
                      width={300}
                      height={300}
                      ratio="ratio-1x1"
                    />
                  )}
                />

                <UiInput
                  lcol="2"
                  icol="10"
                  required={true}
                  label="Company Name"
                  type="text"
                  name="company"
                  message={errors.company?.message}
                  {...register("company")}
                />

                <UiInput
                  lcol="2"
                  icol="10"
                  label="Theme Color"
                  type="color"
                  required={true}
                  className="form-control-color"
                  name="color"
                  message={errors.color?.message}
                  {...register("color")}
                />


              </div>
            </div>


            <div className="card card-default mb-4">
              <div className="card-header">Domain Setting</div>
              <div className="card-body">
                <div className="row mb-3">
                  <label className="form-label col-sm-2">
                    Domain Type
                  </label>
                  <div className="col-sm-10 d-flex flex-column flex-sm-row">
                    <div className="form-check me-3 mb-2 mb-sm-0">
                      <input type="radio" className="form-check-input" value="subdomain" name="domain_type" id="success-outlined" {...register("domain_type")} />
                      <label className="form-check-label" htmlFor="success-outlined">Practive sub-domain</label>
                    </div>
                    <div className="form-check">
                      <input type="radio" className="form-check-input" value='custom' name="domain_type" id="danger-outlined"  {...register("domain_type")} />
                      <label className="form-check-label" htmlFor="danger-outlined">Your own domain</label>
                    </div>

                  </div>
                </div>



                {w_domain_type == 'subdomain' ?

                  <div className="row">
                    <label className="form-label pt-sm-2 col-sm-2">
                      Sub-domain
                      <span className="text-red font-bold text-lg">*</span>
                    </label>
                    <div className="col-sm-10 d-flex">
                      <UiInput

                        required={true}
                        type="text"
                        name="sub_domain"
                        message={errors.sub_domain?.message}
                        {...register("sub_domain", { onBlur: (e) => { checkSubDomain(e.target.value) } })}
                      />
                      <span className="mt-2 ms-2">.practive.link</span>
                    </div>
                  </div>
                  :
                  <div className="row">
                    <label className="form-label pt-sm-2 col-sm-2">
                      Domain
                      <span className="text-red font-bold text-lg">*</span>
                    </label>
                    <div className="col-sm-10 ">
                      <UiInput

                        required={true}
                        type="text"
                        name="custom_domain"
                        message={errors.custom_domain?.message}
                        {...register("custom_domain")}
                      />

                      <div className="alert alert-secondary">
                        <b>NOTE</b>
                        <p>Please edit the following DNS settings in your domain:</p>
                        <ul>
                          <li>Your A record should point to Practive's IP address, which is
                            <code> 65.1.35.78 </code></li>
                          <li>Your <code>www</code> CNAME record should point to
                            <code> site.practive.link </code>
                          </li>
                        </ul>
                        <div>It may take several hours to effect changes in your DNS.</div>
                      </div>
                    </div>
                  </div>
                }



              </div>
            </div>


            <div className="card card-default mb-4">
              <div className="card-header">Payment Gateway Setting</div>
              <div className="card-body">

                <div className="row mb-3">
                  <label className="form-label col-sm-2">
                    Payment Gateway
                  </label>
                  <div className="col-sm-10 d-flex">
                    <div className="form-check me-3">
                      <input type="radio" className="form-check-input" value="Razorpay" name="pg" id="rz_pg" {...register("pg")} />
                      <label className="form-check-label" htmlFor="rz_pg">RazorPay</label>
                    </div>
                    <div className="form-check me-3">
                      <input type="radio" className="form-check-input" value='Phonepe' name="pg" id="pp_pg"  {...register("pg")} />
                      <label className="form-check-label" htmlFor="pp_pg">PhonePe</label>
                    </div>
                    <div className="form-check">
                      <input type="radio" className="form-check-input" value='' name="pg" id="disable_pg"  {...register("pg")} />
                      <label className="form-check-label" htmlFor="disable_pg">Disable</label>
                    </div>

                  </div>
                </div>

                <div className={`${w_pg == 'Razorpay' ? '' : 'd-none'}`}>
                  <UiInput
                    lcol="2"
                    icol="10"
                    label="Key"
                    required={true}
                    type="text"
                    name="razorpay_key"
                    message={errors.razorpay_key?.message}
                    {...register("razorpay_key")}
                  />

                  <UiInput
                    lcol="2"
                    icol="10"
                    label="Secret"
                    type="password"
                    required={true}
                    name="razorpay_secret"
                    message={errors.razorpay_secret?.message}
                    {...register("razorpay_secret")}
                  />
                </div> 
                <div className={`${w_pg == 'Phonepe' ? '' : 'd-none'}`}>
                  <UiInput
                    lcol="2"
                    icol="10"
                    label="Merchant ID"
                    type="text"
                    required={true}
                    name="phonepe_id"
                    message={errors.phonepe_id?.message}
                    {...register("phonepe_id")}
                  />

                  <UiInput
                    lcol="2"
                    icol="10"
                    label="Salt Key"
                    type="password"
                    required={true}
                    name="phonepe_salt"
                    message={errors.phonepe_salt?.message}
                    {...register("phonepe_salt")}
                  />

                  <UiInput
                    lcol="2"
                    required={true}
                    icol="10"
                    label="Salt Key Index"
                    type="text"
                    name="phonepe_index"
                    message={errors.phonepe_index?.message}
                    {...register("phonepe_index")}
                  />
                </div> 
                {w_pg != '' &&
                  <div className="alert alert-info">Note: The receipt will be created automatically after successful payment. (Receipt will be created only if the 'Auto generate receipt number' option is enabled.)</div>
                }

              </div>
            </div>

            <div className="card card-default mb-4">
              <div className="card-header">Client Login Area</div>
              <div className="card-body">
                
                <UiToggle
                  id="docdirectory"
                  name="doc_directory"
                  title="Show Task Document Directory"
                  {...register("doc_directory")}
                />
                <UiToggle
                  id="custdocdirectory"
                  name="cust_doc_list"
                  title="Show Client Documents List"
                  {...register("cust_doc_list")}
                />
                <UiToggle
                  id="task_list"
                  name="task_list"
                  title="Show Task List (Assignment)"
                  {...register("task_list")}
                />
                <UiToggle
                  id="ledger"
                  name="ledger"
                  title="Show Ledger"
                  {...register("ledger")}
                />
                <UiToggle
                  id="online_pay"
                  name="online_pay"
                  title="Show Online Payment Option"
                  {...register("online_pay")}
                />
              </div>
            </div>

            <div className="card card-default mb-4">
              <div className="card-header">Home Section</div>
              <div className="card-body">
                <Controller
                  name="home_banner"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiImageUpload
                      lcol="2"
                      icol="10"
                      label="Home Banner"
                      name="home_banner"
                      file={value}
                      setFile={onChange}
                      onClear={() => onChange(null)}
                      width={1900}
                      height={1267}
                      ratio="ratio-16x9"
                    />
                  )}
                />
                <UiInput
                  lcol="2"
                  icol="10"
                  required={true}
                  label="Heading"
                  type="text"
                  name="home_h1"
                  message={errors.home_h1?.message}
                  {...register("home_h1")}
                />


              </div>
            </div>


            <div className="card card-default mb-4">
              <div className="card-header">Contact Details</div>
              <div className="card-body">


                <UiTextArea
                  lcol="2"
                  icol="10"
                  required={true}
                  label="Company Address"
                  name="address"
                  message={errors.address?.message}
                  {...register("address")}
                />

                <UiInput
                  lcol="2"
                  icol="10"
                  required={true}
                  label="Phone No."
                  type="text"
                  name="mobile"
                  message={errors.mobile?.message}
                  {...register("mobile")}
                />

                <UiInput
                  lcol="2"
                  icol="10"
                  label="WhatsApp No."
                  type="text"
                  name="whatsapp"
                  message={errors.whatsapp?.message}
                  {...register("whatsapp")}
                />

                <UiInput
                  lcol="2"
                  icol="10"
                  label="Email Address"
                  type="email"
                  name="email"
                  message={errors.email?.message}
                  {...register("email")}
                />


              </div>
            </div>

            <div className="card card-default mb-4">
              <div className="card-header">About Section</div>
              <div className="card-body">
                <UiInput
                  lcol="2"
                  icol="10"
                  required={true}
                  label="Heading"
                  type="text"
                  name="about_h1"
                  message={errors.about_h1?.message}
                  {...register("about_h1")}
                />



                <Controller
                  name="about_content"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiRichText
                      lcol="2"
                      icol="10"
                      required={true}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      label="About Content"
                      name="about_content"
                      message={errors.about_content?.message}
                    />
                  )}
                />
              </div>
            </div>



            <div className="card card-default mb-4">
              <div className="card-header">Service Section</div>
              <div className="card-body">

                <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                  {fields.map((value, i) => (
                    <SortableItem key={i} index={i}  >
                      <div className="col-md-2" >

                        <Controller
                          name={`service.[${i}].icon`}
                          control={control}
                          render={({ field }) => (

                            <UiRSelect
                              {...field}
                              //defaultValue={value.icon}
                              options={state.icons}
                              className="ss"
                              required={true}
                              getOptionLabel={e => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <i className={`fa-sharp fa-solid ${e.value}`}></i>
                                  <span style={{ marginLeft: 5 }}>{e.label}</span>
                                </div>
                              )}
                              message={errors.service?.[i]?.icon?.message}
                              label="Icon"

                            />

                          )}
                        />

                      </div>
                      <div className="col-md-4" >
                        <UiInput
                          placeholder="Title"
                          required={true}
                          label="Title"
                          type="text"
                          className="mb-0"
                          name={`service.[${i}].title`}
                          //parentClass="mb-0"
                          message={errors.service?.[i]?.title?.message}

                          defaultValue={value.title}
                          {...register(`service.[${i}].title`)}
                        />

                      </div>

                      <div className="flex-grow-1 col-md-4" >
                        <UiInput
                          placeholder="Description"
                          label="Description"
                          required={true}
                          type="text"
                          className="mb-0"
                          name={`service.[${i}].description`}
                          //parentClass="mb-0"
                          message={errors.service?.[i]?.description?.message}

                          defaultValue={value.description}
                          {...register(`service.[${i}].description`)}
                        />

                      </div>
                      <div className="d-flex align-items-center col-md-2">
                        <a className="btn btn-outline-danger" onClick={() => remove(i)} >Delete</a>
                      </div>
                    </SortableItem>
                  ))}


                </SortableContainer>
                <div className="text-center">
                  <UiButton type="button" className="btn btn-secondary" onClick={() => insertEntry()} title="Add Service" icon="add"></UiButton>
                </div>
              </div>
            </div>


            <div className="card card-default mb-4">
              <div className="card-header">Policies</div>
              <div className="card-body">



                <Controller
                  name="privacy_policy"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiRichText
                      lcol="2"
                      icol="10"

                      onChange={onChange}
                      value={value}
                      ref={ref}
                      label="Privacy Policy"
                      name="privacy_policy"
                      message={errors.privacy_policy?.message}
                    />
                  )}
                />

                <Controller
                  name="terms_conditions"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiRichText
                      lcol="2"
                      icol="10"
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      label="Terms & Conditions"
                      name="terms_conditions"
                      message={errors.terms_conditions?.message}
                    />
                  )}
                />

                <Controller
                  name="refund_policy"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiRichText
                      lcol="2"
                      icol="10"
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      label="Refund Policy"
                      name="refund_policy"
                      message={errors.refund_policy?.message}
                    />
                  )}
                />
              </div>
            </div>

            <UiButton loading={loader["loader"]} title="Save" />
          </form>
        </div>
      ) : <BgLoader />}
    </div>
  );
}
