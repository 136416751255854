import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, Toastcontent, UiButton, UiInput, UiRSelect, BgLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const ClientServicePrice = NiceModal.create(
  ({ service, cust_id, service_id, price }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      price: yup.string().nullable().when('price', {
        is: (value) => value?.length,
        then: (rule) => rule
          .matches(/^[0-9.]+$/, "Enter amount")
          .min(0, 'Negative value not allowed')


      })

    }, [['price', 'price']]);






    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#clientservicepriceform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/custom_price",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "error" });
            modal.resolve({ resolved: true, users: response.data.users });
            modal.hide();
          }
          if (response.data.status == "success") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "success" });
            modal.resolve({ resolved: true, users: response.data.users });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };






    useEffect(() => {
      if (price) {

        reset({ price: price });

      }
    }, [price]);



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);




    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{service}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="clientservicepriceform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>

            <UiInput
              label="Price"
              type="text"
              icon="inr"
              name="price"
              {...register("price")}
              message={errors.price?.message}
            />




            {cust_id ? <input type="hidden" name="cust_id" value={cust_id} /> : null}
            {service_id ? <input type="hidden" name="service" value={service_id} /> : null}



          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default ClientServicePrice;
