import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, PageError,  BgLoader, UiRSelect } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const TaskTags = NiceModal.create(
  ({ task_id }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      // day: yup.string().required("Select day of month"),

    });






    const colourStyles: StylesConfig<ColourOption, true> = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        if (data.color == undefined || data.color == null) {
          data.color = '#000000';
        }
        const color = chroma(data.color);

        return {
          ...styles,
          backgroundColor: isDisabled
            ? undefined
            : isSelected
              ? data.color
              : isFocused
                ? color.alpha(0.1).css()
                : undefined,
          color: isDisabled
            ? '#ccc'
            : isSelected
              ? chroma.contrast(color, 'white') > 2
                ? 'white'
                : 'black'
              : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? data.color
                : color.alpha(0.3).css()
              : undefined,
          },
        };
      },
      multiValue: (styles, { data }) => {
        if (data.color == undefined || data.color == null) {
          data.color = '#000000';
        }
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: color.alpha(0.1).css(),
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
          backgroundColor: data.color,
          color: 'white',
        },
      }),
    };


    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#tasktagsform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_task_tags",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true, tags: response.data.tags });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };




    const fetchData = () => {
      instance
        .get("/task_tags?id=" + task_id)
        .then(function (response) {
          setState({ ...state, ...response.data });
          if (response.data.status == 'success') {

            var slctd = response.data.tags.filter((x) => x.selected == 1);
            reset({ tags: slctd });
          } else {
            toast(response.data.msg, { type: "error" });
          }


        })
        .catch(function (error) { })
        .then(function () { });

    }

    useEffect(() => {
      fetchData();
    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    return (
      <BootstrapModal {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Tags</BootstrapModal.Title>
        </BootstrapModal.Header>
        {state.status == 'success' ? (
          <form id="tasktagsform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <BootstrapModal.Body>




              <Controller
                name="tags[]"
                control={control}
                render={({ field }) => (

                  <UiRSelect
                    {...field}
                    isMulti
                    className=""
                    message={errors.tags?.message}
                    label="Tags"
                    options={state.tags}
                    styles={colourStyles}


                  />

                )}
              />



              {task_id && (<input type="hidden" value={task_id} name="id"></input>)}



            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Save" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>
        ) : state.status == 'error' ? <PageError msg={state.msg} code={state.code} /> : <BgLoader />}
      </BootstrapModal>
    );
  }
);

export default TaskTags;
