import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { UiSelect, UiInput, UiButton, PageHeader, UiRSelect, UiDatePicker, PageError, UiToggle, BgLoader, InLoader, UiClientSelect } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default function Receipt() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [bg_loader, setBGLoader] = useState(false);
  const [grandTotal, setGrandTotal] = useState(null);


  const schema = yup.object().shape({

    date: yup.string().nullable().required("Select receipt date"),
    mode: yup.object().nullable().required("Select payment mode"),
    client: yup.object().nullable().required("Select client"),
    receipt_id: yup.string().nullable().required("Receipt number is required").max(20, 'Maximum character limit is 20'),
    remark: yup.string().nullable().max(100, 'Maximum character limit is 100'),
    entity: yup.object().nullable().required("Select billing entity"),

    total: yup.string().nullable().typeError('Amount must be a number')
      .min(0, 'Negative value not allowed')
      .required('Received amount is required'),

    tds: yup.string().nullable().when('tds', {
      is: (value) => value?.length,
      then: (rule) => rule
        .matches(/^[0-9.]+$/, "Enter TDS amount")
        .min(0, 'Negative value not allowed')

    }),

    discount: yup.string().nullable().when('discount', {
      is: (value) => value?.length,
      then: (rule) => rule
        .matches(/^[0-9.]+$/, "Enter discount amount")
        .min(0, 'Negative value not allowed')

    }),


    invoice: yup.array().of(
      yup.object().shape({
        due: yup.string(),
        amount: yup.string().nullable().when('amount', {
          is: (value) => value?.length,
          then: (rule) => rule
            .matches(/^[0-9.]+$/, "Enter amount")
            .min(0, 'Negative value not allowed')
            .test(
              'More than due amount',
              'Payment amount must not be greater than due amount',
              function (value) {
                const { due } = this.parent;
                if (value > parseFloat(due)) {
                  return false;
                } else {
                  return true
                }
              }
            )

        })
      }, [["amount", "amount"]])
    ),

  }, [["tds", "tds"], ["discount", "discount"]]);



  var handleSave = (e) => {
    setState({ ...state, loader: true });


    instance({
      method: "post",
      url: "/update_receipt",
      data: e,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['receipts'])
          toast(response.data.msg, { type: "success" });
          if (response.data.id) {
            navigate("/view-receipt/" + response.data.id)
          }

        }
      })
      .catch(function (response) { });
  };


  const {
    register,
    handleSubmit,
    setError,
    watch,
    getValues,
    resetField,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave(data);
  };









  const fetchData = () => {
    instance
      .get("/get_receipt?id=" + id)
      .then(function (response) {
        setState({ ...state, invoices: [], balance: null, used: null, available: null, ...response.data });
        setGrandTotal(response.data.receipt.grand_total)
        if (response.data.status == 'success') {


          if (parsed.client) {
            reset({ ...response.data.receipt, client: { value: parsed.client.split('::')[0], label: parsed.client.split('::')[1] } })
          } else {
            reset(response.data.receipt)
          }
        } else {
          toast(response.data.msg, { type: "error" });
        }

      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {
    //setState({ ...state, balance: 0, invoices: [], used:0, available:0 })
    fetchData();
    //reset({ step: { 5: '7' } })
  }, [location]);

  useEffect(() => {
    reset(state.client)
  }, [state.client]);


  const paymentModeModal = useModal('payment-mode');

  const addPaymentMode = () => {
    paymentModeModal.show().then((res) => {
      setState({ ...state, modes: [...state.modes, res.mode] })
      reset({ ...getValues(), mode: res.item })
    });
  }
  const [loadedOptions, setOptions] = useState({});


  const client_w = watch('client');
  const entityWatch = watch("entity");
  const totalWatch = watch("total");
  const tdsWatch = watch("tds");
  const discountWatch = watch("discount");


  useEffect(() => {
    if (entityWatch) {

      setState({ ...state, receipt: { ...state.receipt, receipt_id: entityWatch.receipt_id } })
      resetField('receipt_id', { defaultValue: entityWatch.receipt_id })

    }
  }, [entityWatch]);

  useEffect(() => {

    var total = 0;
    var tds = 0;
    var discount = 0;

    if (parseFloat(totalWatch) > 0) {
      total = parseFloat(totalWatch)
    }

    if (parseFloat(tdsWatch) > 0) {
      tds = parseFloat(tdsWatch)
    }

    if (parseFloat(discountWatch) > 0) {
      discount = parseFloat(discountWatch)
    }
    setGrandTotal(parseFloat(total + tds + discount).toFixed(2))
  }, [totalWatch, tdsWatch, discountWatch]);

  useEffect(() => {
    if (grandTotal) {
      var used = 0;
      if (state.used >= 0) {
        used = parseFloat(state.used)
      }
      setState({ ...state, available: parseFloat(grandTotal) - used })


    }
  }, [grandTotal]);



  useEffect(() => {

    if (client_w == null) {
      setOptions({})
      setState({ ...state, balance: null })
    }
    if (client_w && client_w.value) {
      setBGLoader(true)

      instance
        .get("/get_balance?id=" + client_w.value + "&rid=" + id)
        .then(function (response) {
          setBGLoader(false)

          setState({ ...state, balance: response.data.balance, invoices: response.data.items, used: response.data.used, available: parseFloat(grandTotal) - response.data.used })
          //resetField("invoice", { defaultValue: response.data.items })
        })
    }
  }, [client_w]);

  const updateAmount = (id, val) => {

    var arr = state.invoices.slice();
    arr.filter(x => x.id == id)[0].amount = val;


    var sum = 0;


    for (let i = 0; i < arr.length; i++) {
      var amtt = parseFloat(arr[i]['amount']);
      if (amtt > 0) {
        sum += amtt;
      }

    }
    setState({ ...state, used: sum, invoices: arr, available: grandTotal - sum })
  }
  const autoApply = () => {
    if (grandTotal) {
      var available = parseFloat(grandTotal);
      var arr = state.invoices.slice();
      var payments = 0;
      for (let i = 0; i < arr.length; i++) {
        var amtt = parseFloat(arr[i]['due']);
        var payment = 0;
        if (amtt > 0) {
          if (available >= amtt) {
            payment = amtt;
          } else {
            payment = available;
          }
          available = available - payment;
        }
        arr[i].amount = payment;
        payments += payment;
      }

      setState({ ...state, used: payments, invoices: arr, available: available })
      resetField("invoice", { defaultValue: arr })

    }

  }


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }




  return (
    <>
      <Helmet>
        <title>
          {id == 'new' ? 'New' : 'Edit'} Receipt | Practive
        </title>
      </Helmet>
      <div>
        {state.status ? (
          <div>
            <PageHeader title={`${id == 'new' ? 'New' : 'Edit'} Receipt`} parent="Receipts" link="/receipts">

            </PageHeader>


            <form onSubmit={handleSubmit(onSubmitHandler)} noValidate={"novalidate"}>

              <div className="card card-default mb-4">

                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <Controller
                        name="entity"
                        control={control}

                        render={({ field }) => (

                          <UiRSelect

                            required={true}
                            {...field}
                            options={state.entities}
                            className=""
                            isDisabled={id == 'new' ? false : true}
                            message={errors.entity?.message}
                            label="Billing Entity"

                          />

                        )}
                      />
                    </div>
                    <div className="col-6 col-md-3">
                      <Controller
                        name="date"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiDatePicker
                            label="Date"
                            required={true}
                            onChange={onChange}
                            default_val={value}
                            message={errors.date?.message}
                            dateFormat="dd-MM-yyyy"
                            ref={ref}
                            name={name}
                          />
                        )}
                      />
                    </div>
                    <div className="col-6 col-md-3">
                      <UiInput
                        label="Receipt No."
                        required={true}
                        name="receipt_id"
                        {...register("receipt_id")}
                        message={errors.receipt_id?.message}
                        disabled={state.receipt.receipt_id ? true : false}
                      />
                    </div>
                    <div className="col-md-3">
                   
                      <Controller
                        name="mode"
                        control={control}
                        render={({ field }) => (

                          <UiRSelect
                            {...field}
                            required={true}
                            options={state.modes}
                            className=""
                            message={errors.mode?.message}
                            label="Payment Mode"
                            addnew={1}
                            handleNew={() => addPaymentMode()}

                          />

                        )}
                      />
                    </div>
                  </div>



                  <input type="hidden" defaultValue={id} name="id" {...register("id")} />



                  <div className="row">
                    <div className="col-12 col-md-3">

                      <Controller
                        name="client"
                        control={control}
                        render={({ field }) => (

                          <UiClientSelect
                            {...field}
                            required={true}
                            parentClass="mb-1"
                            className=""
                            message={errors.client?.message}
                            label="Client"

                          />

                        )}
                      />
                      <p className="mb-3">{state.balance && <>Ledger balance is {rupees(state.balance)}</>}</p>


                    </div>
                    <div className="col-12 col-md-3">
                      <UiInput
                        label="Received Amount"
                        type="text"
                        required={true}
                        icon="inr"
                        name="total"
                        {...register("total")}
                        message={errors.total?.message}
                      />
                    </div>

                    <div className="col-12 col-md-3">
                      <UiInput
                        label="TDS Amount"
                        type="text"
                        icon="inr"
                        name="tds"
                        {...register("tds")}
                        message={errors.tds?.message}
                      />
                    </div>

                    <div className="col-12 col-md-3">
                      <UiInput
                        label="Discount"
                        type="text"
                        icon="inr"
                        name="discount"
                        {...register("discount")}
                        message={errors.discount?.message}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <div style={{ maxWidth: '600px' }}>
                        <UiInput
                          label="Remark"
                          type="text"
                          name="remark"
                          {...register("remark")}
                          message={errors.remark?.message}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-5">
                      <div className="bg-light p-3 rounded">

                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fw-bold">Total Amount</div>
                          <UiInput icon="inr" className="border-0" value={grandTotal} parentClass="mb-0" readOnly />
                        </div>
                      </div>
                    </div>


                  </div>

                  {state.invoices && (<div className="position-relative">
                    {bg_loader == true && <InLoader />}
                    <div className="d-md-flex justify-content-between mb-2 mt-4">
                      <h2 className="fs-4">Settle invoices</h2>
                      <div><span className="text-secondary">Available Amount:</span> {rupees(state.available)} <UiButton className="btn btn-outline-primary btn-sm ms-3" type="button" onClick={() => autoApply()} title="Auto Apply" />
                      </div>
                    </div>
                    <div className="table-responsive bg-light p-2 rounded">
                      <table className="table table-bordered align-middle">
                        <thead>
                          <tr className="text-secondary text-uppercase fw-semibold">
                            <th style={{ minWidth: '100px' }}>Invoice Date</th>
                            <th>Invoice No.</th>
                            <th>Total Amount</th>
                            <th>Due Amount</th>
                            <th width="200px" style={{ minWidth: '150px' }}>Payment</th>
                          </tr>
                        </thead>
                        {state.invoices && state.invoices.length > 0 ? (



                          <tbody>
                            {state.invoices && state.invoices.map((val, i) => (
                              <tr key={i}>
                                <td>
                                  {val.date}
                                </td>


                                <td>
                                  {val.invoice_id}
                                </td>
                                <td>
                                  {rupees(val.total)}
                                </td>
                                <td>
                                  {rupees(val.due)}
                                </td>

                                <td>
                                  <UiInput icon="inr" name={`invoice[${i}].amount`} {...register(`invoice.[${i}].amount`)} parentClass="mb-0" message={errors.invoice?.[i]?.amount?.message} defaultValue={val.amount} onChange={(e) => updateAmount(val.id, e.target.value)} />
                                  <input type="hidden" name={`invoice[${i}].id`} {...register(`invoice.[${i}].id`)} value={val.id} />
                                  <input type="hidden" name={`invoice[${i}].due`} {...register(`invoice.[${i}].due`)} value={val.due} />
                                </td>
                              </tr>
                            ))}
                          </tbody>


                        ) : <>
                          <tbody>
                            <tr>
                              <td colSpan="5" className="text-center bg-light py-4">There are no invoices</td>
                            </tr>
                          </tbody>

                        </>}
                        <tfoot>
                          <tr>
                            <td colSpan="4" className="fw-bold text-end">
                              Total amount settled
                            </td>
                            <td className="fw-bold">
                              {rupees(state.used)}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      {state.available < 0 && <div className="alert alert-danger">The settled amount must not be greater than receipt amount.</div>}
                    </div>

                  </div>)}


                </div>
              </div>






              <UiButton loading={state["loader"]} title="Save" />
            </form>

          </div>

        ) : <BgLoader />}
      </div>
    </>
  );
}
