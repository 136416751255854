import React, { useState, useEffect } from "react";
import { Outlet, useLocation, NavLink, Link, useNavigate } from "react-router-dom";
import { OverlayLoader, Avatar } from './ui'
import { AiOutlineUser, AiOutlineSearch, AiOutlineClockCircle, AiOutlineQuestionCircle, AiOutlineBell } from 'react-icons/ai';
import { IoIosArrowForward } from 'react-icons/io';
import { MdOutlineTask, MdOutlineEditCalendar, MdPhone, MdWhatsapp, MdMenuBook, MdOutlineEmail, MdOutlineSpaceDashboard, MdOutlineAccountBox, MdMenu, MdAdd } from 'react-icons/md';
import { BiReceipt, BiCategoryAlt, BiImport, BiTimer } from 'react-icons/bi';
import { BsGear, BsSend, BsFiles } from 'react-icons/bs';
import { HiOutlineReceiptTax, HiOutlineCurrencyRupee, HiOutlineReceiptRefund, HiOutlineDocumentReport } from 'react-icons/hi';
import { GoPlus, GoTasklist, GoPackage } from 'react-icons/go';
import { instance } from "./axios";
import { useStore } from "./state.js";
import logoimg from "./practive.svg";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { setStorage, getStorage } from "./helper";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FiGlobe, FiExternalLink } from 'react-icons/fi'
import { requestForToken, onMessageListener, unSubscribe } from './firebase';
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { AutoComplete, ConfigProvider, Badge } from 'antd';
import { el } from "date-fns/locale";


const { Option } = AutoComplete;


const Layout = () => {
  const [state, setState] = useState({});
  const [fullLoader, showFLoader] = useState(false);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const [is_update, setUpdates] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [menuhide, setMenuHide] = useState(getStorage('hide_menu') ? true : false);
  const { auth, setAuth } = useStore((state) => state);
  const { timerLoading, setTimerLoading } = useStore((state) => state);
  const setZState = useStore((state) => state.setAdmin);

  const notiCount = useStore((state) => state.noti_count)


  const [options, setOptions] = useState([]);
  const handleSearch = (value) => {
    setSearchQuery(value);
    if (value.length > 2) {
      setSearching(true)
      instance
        .get("/fetch_clients?s=" + value + "&active=1")
        .then(function (response) {
          setOptions(response.data.items)
          setSearching(false)
          if (response.data.items.length == 0) {
            setNotFound(true)
          } else {
            setNotFound(false)
          }
        })
    } else if (value.length == 0) {
      setOptions([])
      setNotFound(false)

      setSearching(false)
    } else {
      if (searchQuery && !searchQuery.startsWith(value)) {
        setOptions([])
      }
      //  setOptions([])
      setNotFound(false)
    }
  };


  let activeStyle = {
    textDecoration: "underline",
  };

  const [notification, setNotification] = useState({ title: '', body: '' });
  function ToastDisplay(xx) {
    return (
      <div>
        <p><b>{xx?.title}</b></p>
        <p className="mb-0">{xx?.body}</p>
      </div>
    );
  };

  const sendNotiModal = useModal('send-notifications');
  const showSendNotifications = () => {
    sendNotiModal.show().then((res) => {
    });
  }

  const timerModal = useModal('timesheet');

  const showTimer = (e) => {
    timerModal.show().then((res) => {

      if (res.resolved == true) {

      }
    });
  }


  const whatsnewmodal = useModal('whatsnew');

  const showWhatsnew = (e) => {
    setUpdates(false)
    whatsnewmodal.show().then((res) => {


      if (res.resolved == true) {

      }
    });
  }
  const notifmodal = useModal('app-notifications');

  const showNotifications = (e) => {
    notifmodal.show().then((res) => {


      if (res.resolved == true) {

      }
    });
  }

  useEffect(() => {
    if (notification?.title) {

      toast(ToastDisplay(notification), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    }
  }, [notification])


  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log('failed: ', err));





  useEffect(() => {

    instance
      .get("/admin")
      .then(function (response) {
        setState({ ...state, ...response.data })
        setZState(response.data)
        useStore.setState({ ctu: response.data.ctu })
        if (response.data.user.is_running == 1) {
          setTimerLoading(true)
        } else {
          setTimerLoading(false)
        }

        if (response.data.new_updates) {
          setUpdates(true)
        } else {
          setUpdates(false)
        }
      })


  }, []);


  const location = useLocation();

  useEffect(() => {

    setShow(false)
  }, [location]);


  const getNavLinkClass = (path, arr) => {
    if (path) {
      return location.pathname.search(path) > 0
        ? " show"
        : "";
    } else if (arr) {
      return arr.includes(location.pathname)
        ? " show"
        : "";
    }
  };

  const Logout = () => {
    showFLoader(true)
    instance
      .get("/logout")
      .then(function (response) {
        showFLoader(false)
        setStorage("token", '');
        setStorage("rtoken", '');
        setStorage("gfctkn", '');
        setStorage("tkntime", '');
        unSubscribe()
        setAuth(null);
      })
      .catch(function (error) { })
      .then(function () { });

  }

  const QuickAddBtn = (props) => {
    const { action, title, ...other } = props;
    return (
      <OverlayTrigger overlay={<Tooltip >New {title}</Tooltip>}>

        <button className="btn menuaddnewbtn" onClick={action}><MdAdd /></button>
      </OverlayTrigger>
    );
  }

  const menuShowHide = () => {
    if (menuhide == true) {
      setMenuHide(false)
      setStorage('hide_menu', false)
    } else {
      setMenuHide(true)
      setStorage('hide_menu', true)
    }
  }


  return (
    <>
      <header className="navbar navbar-light sticky-top bg-light flex-md-nowrap py-2 shadow-sm px-3">

        <button className="btn btn-default d-lg-none" onClick={() => setShow(true)}><MdMenu className="fs-1" /></button>

        <div className="d-flex align-items-center">
          <button className="btn btn-secondary me-2 d-none d-lg-inline" onClick={() => menuShowHide(true)}><MdMenu className="fs-2" /></button>
          <a className="navbar-brand col-md-2 me-0 fs-6" onClick={() => navigate("/")} style={{ width: '100px' }}>
            <img style={{ maxWidth: '100px' }} src={logoimg} />
          </a>

          <div className="d-none d-lg-flex border-primary border-opacity-50 border rounded-1 me-1" style={{ height: '40px', marginLeft: '102px' }}>
            <div className="bg-white px-2 d-flex align-items-center rounded-start" style={{ marginRight: '-4px' }}><AiOutlineSearch size={20} /></div>
            <ConfigProvider
              theme={{
                token: {
                  "lineWidth": 0,
                }

              }}
            >
              <AutoComplete
                style={{
                  width: 260,
                }}
                onSearch={handleSearch}
                placeholder="Search Clients"
                notFoundContent={isSearching ? 'Loading...' : isNotFound ? 'No results found' : 'Enter at least 3 characters to search'}
                // options={options}
                allowClear={true}
                value={searchQuery}
                loading={isSearching}
                size="large"
                onSelect={(e, a) => { navigate("/view-client/" + a.value); setSearchQuery(''); setOptions([]) }}
              >
                {options?.map((val, i) => (
                  <Option key={i} value={val.value} label={val.label} secondary={val.secondary_text}>{val.label}{val.secondary_text &&
                    <small className="d-block">{val.secondary_text}</small>
                  }</Option>
                ))}
              </AutoComplete>
            </ConfigProvider>
          </div>
        </div>

        <div className="btn-group align-items-center">

          {state.trial_left ?
            <div className="d-none d-lg-inline-block p-2 bg-warning bg-opacity-25 rounded me-3 text-secondary">
              <BiTimer className="fs-4 text-dark me-1" /> Your trial ends in <span className="fw-bold text-warning">{state.trial_left} days</span>. <a className="ms-1 c-pointer" onClick={() => navigate('/pricing/renew')}>Upgrade</a>
            </div>
            : <>

              {state.user &&
                <div className="d-none d-lg-inline-block pe-3 me-3 border-end">
                  {state.user.company}
                </div>
              }
            </>
          }
          <Badge className="d-none d-lg-flex me-3" dot={is_update}>
            <button className={`btn btn-sm ${is_update ? 'btn-outline-primary' : 'btn-secondary'}`} onClick={() => showWhatsnew()}>What's new</button>
          </Badge>
          
          <div className="d-none d-lg-inline-block position-relative me-3">

            <a
              style={{ cursor: 'pointer' }}
              onClick={() => showTimer()}
            ><OverlayTrigger placement="bottom" overlay={<Tooltip style={{position:"fixed"}}>My Running Timer</Tooltip>}><div>
              <AiOutlineClockCircle className={`fs-1 text-secondary ${timerLoading && 'timeronicon'}`} /></div></OverlayTrigger>
            </a>

          </div>
          <div className="d-none d-lg-inline-block position-relative me-3">

            <a
              style={{ cursor: 'pointer' }}
              onClick={() => showNotifications()}
            ><OverlayTrigger placement="bottom" overlay={<Tooltip style={{position:"fixed"}}>Notifications</Tooltip>}>
              <div>
              <AiOutlineBell className={`fs-1 text-secondary`} />{notiCount > 0 && <div className="iconbadge bg-danger">{notiCount}</div>}</div>
              </OverlayTrigger>
            </a>

          </div>

          <div className="d-none d-lg-inline-block position-relative me-3">

            <a
              style={{ cursor: 'pointer' }}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            > <OverlayTrigger placement="bottom" overlay={<Tooltip style={{position:"fixed"}}>Support</Tooltip>}><div>
              <AiOutlineQuestionCircle className="fs-1 text-secondary" /></div>
              </OverlayTrigger>
            </a>

            <ul className="dropdown-menu dropdown-menu-end">


              {state.support && <>
                <li>
                  <a className="dropdown-item d-flex align-items-center" target="_blank" href="https://help.practive.in">
                    <div className="me-2"><MdMenuBook className="fs-3 text-secondary" /></div> <div>Visit Help Center</div>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item d-flex align-items-center" target="_blank" href={`tel:${state.support.mobile}`}>
                    <div className="me-2"><MdPhone className="fs-3 text-secondary" /></div> <div>{state.support.mobile}<br></br><small className="text-secondary">10AM to 6PM (Monday to Saturday)</small></div>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item d-flex align-items-center" target="_blank" href={`https://wa.me/91${state.support.whatsapp}`}>
                    <div className="me-2"><MdWhatsapp className="fs-3 text-secondary" /></div> <div>+91 {state.support.whatsapp}<br></br><small className="text-secondary">10AM to 6PM (Monday to Saturday)</small></div>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item d-flex align-items-center" target="_blank" href={`mailto:${state.support.email}`}>
                    <div className="me-2"><MdOutlineEmail className="fs-3 text-secondary" /></div> <div>{state.support.email}</div>
                  </a>
                </li>
              </>}
            </ul>
          </div>
          <a
            style={{ cursor: 'pointer' }}
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {state.user &&
              <Avatar name={state.user.name} src={state.user.photo} className="bg-secondary" size="30" />
            }
          </a>
          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <NavLink className="dropdown-item" to="/profile">
                Profile
              </NavLink>
            </li>
            {state.is_admin == 1 &&
              <li>
                <NavLink className="dropdown-item" to="/refer">
                  Refer & Earn<span className="badge bg-warning ms-2">New</span>
                </NavLink>
              </li>
            }

            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <button className="dropdown-item" onClick={() => Logout()}>
                Logout
              </button>
            </li>
          </ul>
        </div>
      </header>

      <div className="container-fluid">

        <div className={`row ${menuhide == true ? 'menuhidden' : ''}`}>
          <Offcanvas show={show} onHide={() => setShow(false)} responsive="lg" className="bg-light" >
            <Offcanvas.Header closeButton className="py-2">
              <Offcanvas.Title><img style={{ maxWidth: '90px' }} src={logoimg} /></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0 overflow-hidden">

              {state.user &&
                <nav
                  id="sidebarMenu"
                  className="d-md-block bg-light sidebar overflow-hidden"
                >
                  <div className="position-sticky  sidebar-sticky overflow-hidden">

                    <SimpleBar style={{ maxHeight: 'calc(100dvh - 48px)', overflowX: 'hidden' }}  >

                      <div className="pt-3 px-3">

                        <div className="menu-itm">
                          <NavLink to="/" >
                            <span className="menu-icon">
                              <MdOutlineSpaceDashboard />
                            </span>
                            <span className="menu-title">Dashboard</span>
                          </NavLink>
                        </div>

                        <div className="menu-itm">
                          <NavLink to="/tasks" >
                            <span className="menu-icon">
                              <MdOutlineTask />
                            </span>
                            <span className="menu-title">Tasks</span>

                          </NavLink>
                          <QuickAddBtn title="Task" action={(e) => navigate("/task/new")}></QuickAddBtn>
                        </div>
                        <div className="menu-itm">
                          <NavLink to="/todos"><span className="menu-icon">
                            <GoTasklist />
                          </span>
                            <span className="menu-title">To-Do</span></NavLink>
                        </div>
                        <div className="menu-itm">
                          <NavLink to="/clients" >
                            <span className="menu-icon">
                              <AiOutlineUser />
                            </span>
                            <span className="menu-title">Clients</span>
                          </NavLink>
                          <QuickAddBtn title="Client" action={(e) => navigate("/client/new")}></QuickAddBtn>
                        </div>
                        <div className="menu-itm">
                          <NavLink to="/client-packages" >
                            <span className="menu-icon">
                              <GoPackage />
                            </span>
                            <span className="menu-title">Client Packages <div><span className="badge bg-warning ms-2">New</span></div></span>

                            
                          </NavLink>
                        </div>
                        {state.masters == 1 &&
                          <div className="menu-itm">
                            <NavLink to="/services"><span className="menu-icon">
                              <BiCategoryAlt />
                            </span>
                              <span className="menu-title">Services & Packages</span></NavLink>
                          </div>
                        }
                        <div className="menu-itm">
                          <NavLink to="/invoices"><span className="menu-icon">
                            <HiOutlineCurrencyRupee />
                          </span>
                            <span className="menu-title">Invoices</span></NavLink>
                          <QuickAddBtn title="Invoice" action={(e) => navigate("/invoice/new")}></QuickAddBtn>
                        </div>
                        <div className="menu-itm">
                          <NavLink to="/receipts">
                            <span className="menu-icon">
                              <BiReceipt />
                            </span>
                            <span className="menu-title">Receipts</span>
                          </NavLink>
                          <QuickAddBtn title="Receipt" action={(e) => navigate("/receipt/new")}></QuickAddBtn>
                        </div>
                        <div className="menu-itm">
                          <NavLink to="/quotations">
                            <span className="menu-icon">
                              <HiOutlineReceiptTax />
                            </span>
                            <span className="menu-title">Quotations</span>
                          </NavLink>
                          <QuickAddBtn title="Quotation" action={(e) => navigate("/quotation/new")}></QuickAddBtn>
                        </div>

                        <div className="menu-itm">
                          <NavLink to="/expenses"><span className="menu-icon">
                            <HiOutlineReceiptRefund />
                          </span>
                            <span className="menu-title">Expenses</span></NavLink>
                          <QuickAddBtn title="Expense" action={(e) => navigate("/expense/new")}></QuickAddBtn>
                        </div>

                        <div className="menu-itm">
                          <NavLink to="/attendance"><span className="menu-icon">
                            <MdOutlineEditCalendar />
                          </span>
                            <span className="menu-title">Attendance</span></NavLink>
                        </div>


                        <div className="main-menu-item menu-itm">

                          <a className="menu-link has-treeview" data-bs-toggle="collapse" data-bs-target="#collapsedoc">
                            <span className="menu-icon">
                              <BsFiles />
                            </span>
                            <span className="menu-title">Documents & DSC</span>
                            <span className="menu-icon1">
                              <IoIosArrowForward />
                            </span>
                          </a>


                          <ul className={'nav-treeview collapse  ' + getNavLinkClass(null, ['/documents-register', '/dsc-management', '/doc-requests'])} id="collapsedoc">
                            <li><NavLink to="/documents-register">Doc. In-Out</NavLink></li>
                            <li><NavLink to="/dsc-management">DSC Management</NavLink></li>
                            <li><NavLink to="/doc-requests">Doc. Collection</NavLink></li>

                          </ul>
                        </div>
                        {state.is_admin == 1 &&
                          <>

                            <div className="main-menu-item menu-itm">

                              <a className="menu-link has-treeview" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                                <span className="menu-icon">
                                  <HiOutlineDocumentReport />
                                </span>
                                <span className="menu-title">Reports</span>
                                <span className="menu-icon1">
                                  <IoIosArrowForward />
                                </span>
                              </a>


                              <ul className={'nav-treeview collapse  ' + getNavLinkClass("-report", [])} id="collapseOne">

                                <li><NavLink to="/tasks-report">Tasks Report</NavLink></li>
                                <li><NavLink to="/services-report">Services Report</NavLink></li>
                                <li><NavLink to="/clients-report">Clients Report</NavLink></li>
                                <li><NavLink to="/passwords-report">Passwords Report</NavLink></li>
                                <li><NavLink to="/dsc-report">DSC Report</NavLink></li>

                                <li><NavLink to="/invoices-report">Invoices Report</NavLink></li>
                                <li><NavLink to="/receipts-report">Receipts Report</NavLink></li>
                                <li><NavLink to="/expenses-report">Expenses Report</NavLink></li>
                                <li><NavLink to="/unbilled-tasks-report">Unbilled Tasks Report</NavLink></li>
                                <li><NavLink to="/unbilled-packages-report">Unbilled Packages Report</NavLink></li>
                                <li><NavLink to="/client-outstanding-report">Client Outstanding Report</NavLink></li>
                                <li><NavLink to="/employees-report">Employees Report</NavLink></li>
                                <li><NavLink to="/attendance-report">Attendance Report</NavLink></li>
                                <li><NavLink to="/employee-wise-time-report">Employee Wise Time Report</NavLink></li>
                                <li><NavLink to="/client-wise-time-report">Client Wise Time Report</NavLink></li>

                                <li><NavLink to="/timelog-report">Timelog Report</NavLink></li>

                              </ul>
                            </div>
                            <div className="menu-itm">
                              <NavLink to="/users"><span className="menu-icon">
                                <MdOutlineAccountBox />
                              </span>
                                <span className="menu-title">Users</span></NavLink>
                            </div>


                          </>
                        }
                        {state.send_noti == 1 &&
                          <div className="menu-itm">
                            <NavLink to="/send-notifications"><span className="menu-icon"><BsSend /></span>

                              <span className="menu-title">Send Notifications</span>
                            </NavLink>
                          </div>
                        }
                        {state.masters == 1 &&
                          <>
                            <div className="border-top w-100 my-2"></div>
                            <div className="menu-itm ">
                              <NavLink to="/settings"><span className="menu-icon">
                                <BsGear />
                              </span>
                                <span className="menu-title">Settings</span></NavLink>
                            </div>
                          </>
                        }

                        <div className="border-top w-100 my-2"></div>
                        <div className="menu-itm ">
                          <a href={`http://${state.user.url}`} target="_blank"><span className="menu-icon">
                            <FiGlobe />
                          </span>
                            <span className="menu-title">Your Website<FiExternalLink className="ms-2 text-primary" /></span>
                          </a>
                        </div>

                      </div>


                    </SimpleBar>
                  </div>
                </nav>
              }
            </Offcanvas.Body>
          </Offcanvas>
          <main className="ms-sm-auto px-md-4 maincontainer">
            <section className="content d-inline-block w-100">
              <div className="container-fluid mb-4">
                {state.is_active == 0 && <div className="alert alert-danger mt-4">{state.is_trial == 1 ? 'Your trial is expired.' : 'Your subscription has expired.'} {state.is_trial == 1 ? <Link to="/pricing/renew" className="btn btn-primary btn-sm ms-2">Buy Plan</Link> : <Link to="/account" className="btn btn-primary btn-sm ms-2">Renew Now</Link>}</div>}
                {state.user &&
                  <Outlet context={[state, setState]} />
                }
              </div>
            </section>
          </main>
        </div>
      </div>
      {fullLoader == true && <OverlayLoader visible={true} />}
    </>
  );
};

export default Layout;
