import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { TableZero, PageError, UiButton, UiTFind, PageHeader, InLoader, BgLoader } from "../../ui";
import { useNavigate, useParams } from "react-router-dom";
import { Link, NavLink } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { Helmet } from "react-helmet";
import { GoPlus } from "react-icons/go";

export default function Services() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()

  const userModal = useModal('add-service');

  const [state, setState] = useState({});
  const [filteredItems, setFilteredItems] = useState({});
  const [loader, showLoader] = useState(false);
  const showAddModal = (row) => {

    userModal.show({ data: row }).then((res) => {
      queryClient.invalidateQueries(['services']);
      navigate("/service/" + res.sid);
    });
  }

  const importDataModal = useModal('import-data');

  const importServices = () => {
    importDataModal.show({ type: 'services', title: 'Services', singular: 'Service' }).then((res) => {
      fetchData()
    });
  }


  const fetchData = () => {
    showLoader(true)
    instance
      .get("/services")
      .then(function (response) {
        showLoader(false)
        setState({ ...state, ...response.data });
        const filteredItems1 = response.data.services.filter(
          item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(filteredItems1);
      })
      .catch(function (error) { })
      .then(function () { });

  }
  const [filterText, setFilterText] = React.useState('');

  useEffect(() => {
    if (state.services) {
      const filteredItems1 = state.services.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);
  const columns = [
    {
      name: 'Service',
      selector: row => row.name,
      minWidth: '200px',
      //sortable: true,
      cell: row => (<Link to={`/service/${row.id}`}><b className="me-2">{row.name}</b> {row.gst_return && <span className="badge bg-success">GST: {row.gst_return}</span>}{row.dsc && <span className="badge bg-secondary">DSC Service</span>}</Link>)
    },
    {
      name: 'Recurring',
      selector: row => row.frquency,
      sortable: true,
      width: '160px',
      cell: row => (<>{row.frequency}</>)
    },
    {
      name: 'Assigned Clients',
      selector: row => row.assigned_clients,
      sortable: true,
      width: '200px',
      cell: row => (<>{row.frequency && <Link to={`/clients?service=${row.id}`}>{row.assigned_clients}</Link>}</>)
    },
    {
      name: 'Status',
      sortable: true,
      width: '100px',
      selector: row => row.status,
      cell: row => (
        <>{row.status == 1 ? <span className="text-success">Active</span> : <span className="text-muted">Inactive</span>}</>
      )
    },


  ];

  useEffect(() => {
    fetchData();
  }, []);

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      <Helmet>
        <title>
          Services | Practive
        </title>
      </Helmet>
      <div>
        {state.services ?
          <div>
            <PageHeader title="Services">
              <UiButton className="btn btn-outline-primary me-2" onClick={() => importServices()} title="Import" ></UiButton>
              <UiButton className="btn btn-primary" onClick={() => showAddModal()} title="New" icon="add"></UiButton>

            </PageHeader>

            <div className="tabs mb-3">

              <NavLink to="/services" end className="tab-itm">Services</NavLink>
              <NavLink to="/packages" className="tab-itm">Packages</NavLink>
            </div>

            <div className="card card-default position-relative">
              {loader && <InLoader />}
              <div className="card-body">

                <div className="">
                  <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
                </div>
              </div>
              <div className="card-body pt-0 tablecard">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  noDataComponent={<TableZero title="Services" />}
                />
              </div>
            </div>

          </div>
          : <BgLoader />}
      </div>
    </>
  );
}
