import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { TableZero, UiDateRange, UiActioButton, PageError, UiActionDropDown, UiRSelect, BgLoader, UiInput, InLoader, UiButton } from "../../ui";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { useQuery, useQueryClient, select } from "react-query";
import { MdVerified } from 'react-icons/md'
import { CSVLink, CSVDownload } from "react-csv";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import queryString from 'query-string';
import { useStore } from "../../state.js";
import { FiUsers } from 'react-icons/fi'
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { rupees } from "../../helper";


export default function ClientExpenses() {
  const [pstate, setPSate] = useOutletContext();
  let { id1 } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()

  const parsed = queryString.parse(location.search);

  const [state, setState] = useState({ page: 1 });
  const [pagenum, setPage] = useState(1);

  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);


  const schema = yup.object().shape(
    {


    }
  );

  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);




  const handleDelete = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setTableDisable(true)
        instance
          .get("/delete_expense?id=" + tid)
          .then(function (response) {
            setTableDisable(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['client_expenses'])
              queryClient.invalidateQueries(['expenses'])

              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })




  }

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    formState,
    resetField,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });


  const defaultValues = {
    date: null,
    search: null,
    category: null,

  };

  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);

  const onSubmitHandler = async (data) => {
    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate(location.pathname + '?' + u, { replace: true })
    } else {
      navigate(location.pathname + "?" + u)
    }


  };


  async function fetchData(filter) {
    let currentUrlParams = new URLSearchParams(filter);
    currentUrlParams.set('client', pstate.client.id + '::user');

    const { data } = await instance.get(
      "/expenses?" + currentUrlParams.toString()
    );
    var dataa = data;
    return dataa;
  }


  const columns = [
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
      sortField: 'date',
      id: 'date',
      width: '100px',
      cell: row => (<span>{row.date}</span>)
    },
    {
      name: 'Category',
      sortable: true,
      sortField: 'category',
      id: 'category',
      selector: row => row.category,
      cell: row => (<Link to={`/view-expense/${row.id}`}><b>{row.category}</b></Link>),
    },
    {
      name: 'Mode',
      sortable: true,
      sortField: 'mode',
      id: 'mode',
      selector: row => row.mode,
      width: '140px'

    },
    {
      name: 'Notes',
      selector: row => row.notes,
      cell: row => (<span className="text-truncate" title={row.notes}>{row.notes}</span>),
    },



    {
      name: 'Amount',
      sortable: true,
      sortField: 'amount',
      id: 'amount',
      selector: row => row.amount,
      cell: row => (<span>{rupees(row.amount)}</span>)
    },
    {
      name: 'Billing',
      sortable: true,
      sortField: 'is_billed',
      id: 'is_billed',
      selector: row => row.is_billed,
      cell: row => (<span className={`badge ${row.is_billed}`}>{row.is_billed}</span>)
    },
    {
      name: '',
      width: (window.innerWidth > 768 ? '0px' : 'auto'),
      selector: row => row.id,
      cell: row => (
        <div className="tableaction">
          <UiActionDropDown >
            <DropdownItem isDisabled={row.can_edit == 1 ? false : true} onClick={() => navigate('/expense/' + row.id)} >Edit</DropdownItem>
            <DropdownItem className="text-danger" isDisabled={row.can_delete == 1 ? false : true} onClick={() => handleDelete([row])} >Delete</DropdownItem>

          </UiActionDropDown>
        </div>


      )
    },


  ];


  async function fetchData1(id) {

    const { data } = await instance.get(
      "/filters?q=" + id
    );
    var dataa = data;
    return dataa;
  }





  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate(location.pathname + '?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate(location.pathname + '?' + currentUrlParams.toString())
      }


    }
  };

  const handleSort = (column, sortDirection) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('sort_by', column.sortField);
    currentUrlParams.set('sort', sortDirection);
    if (location.search) {
      navigate(location.pathname + '?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate(location.pathname + '?' + currentUrlParams.toString())
    }

  };


  const { data, error, isError, isLoading, isFetching } = useQuery(["client_expenses", pstate.client.id, location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });


  const { data: f_data, isLoading: f_is_loading } = useQuery(["filters", 'E'], () => fetchData1('E'), {
    keepPreviousData: true,
  });



  useEffect(() => {

    if (f_data) {
      const defaultValues1 = {
        search: filter.search,
        date: filter.date,
        category: f_data.expense_categories && filter.category ? f_data.expense_categories.filter(({ value }) => filter.category.split(',').includes(value.toString())) : null,
      };
      reset(defaultValues1)
    }
  }, [f_data, filter]);





  const clearFilter = () => {
    reset(defaultValues)
    navigate(location.pathname, { replace: true })
  }


  if (isLoading) {
    return <BgLoader />;
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }
  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }

  return (
    <div className="card card-default">
      <div className="card-body">

        <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          {f_is_loading == true && <InLoader />}
          {f_data && (
            <div className="p-3 bg-light rounded ">

              <div className="row">

                <div className="col">
                  <label className="form-label">Date</label>
                  <Controller
                    name="date"
                    control={control}
                    defaultValue="All Time"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDateRange

                        onUpdate={onChange}
                        value={value}
                        ref={ref}
                        name={name}

                      />
                    )}
                  />
                </div>
                <div className="col-md-3">
                  <UiInput
                    label="Search"
                    type="text"

                    name="search"

                    {...register("search")}
                  />
                </div>


                <div className={`col-md-3`}>
                  <Controller
                    name="category[]"
                    control={control}
                    render={({ field }) => (

                      <UiRSelect
                        defaultValue={null}
                        options={f_data.expense_categories}
                        className=""
                        isMulti

                        label="Category"
                        {...field}
                      />

                    )}
                  />

                </div>







                <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '220px' }}>
                  <div className="d-flex justify-content-end">


                    <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                    <UiActioButton type="submit" loading={state["loaderss"]} className="fs-3" tooltip="Apply Filter" title="Go" />
                    <UiButton className="btn btn-primary ms-2" onClick={() => navigate('/expense/new?client=' + pstate.client.id + '::' + encodeURIComponent(pstate.client.name))} title="New" icon="add"></UiButton>
                  </div>

                </div>
              </div>



            </div>
          )}
        </form>
      </div>
      <div className="card-body pt-0 tablecard">
        {isFetching == true && <InLoader />}
        <DataTable
          columns={columns}
          pagination
          paginationServer
          paginationPerPage="20"
          paginationDefaultPage={pagenum}
          paginationResetDefaultPage={true}
          paginationTotalRows={data.total}
          clearSelectedRows={toggleCleared}
          onSort={handleSort}
          sortServer
          persistTableHead
          defaultSortFieldId={parsed.sort_by ? parsed.sort_by : null}
          defaultSortAsc={parsed.sort == 'desc' ? false : true}
          disabled={tableDisable}
          paginationComponentOptions={{ noRowsPerPage: true }}
          data={data.items}
          onChangePage={handlePageChange}
          noDataComponent={<TableZero title="Expenses" />}
        />

      </div>
    </div>
  )

}

