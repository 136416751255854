import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { UiSelect, PageHeader, UiInput, UiButton, PageError, BgLoader, OverlayLoader } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import { Helmet } from "react-helmet";


export default function UserRole() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [loader, showLoader] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required("Enter role name").max(45, 'Maximum character limit is 45'),


  });
  var handleSave = (e) => {
    setState({ ...state, loader: true });


    instance({
      method: "post",
      url: "/update_user_role",
      data: e,

    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['user-roles'])
          toast(response.data.msg, { type: "success" });
        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave(data);
  };


  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader(true)
        instance
          .get("/update_user_role?delete=" + id)
          .then(function (response) {
            showLoader(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['user-roles']);
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }

  const fetchData = () => {
    instance
      .get("/user_role?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data })
        if (response.data.status == 'success') {

          reset({ name: response.data.name })
        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {
    fetchData();
    

  }, []);
  const xx = watch();




  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  const three = [{ value: 1, label: "All" }, { value: 2, label: "Own" }, { value: 3, label: "None" }];
  const two = [{ value: 1, label: "Yes" }, { value: 3, label: "No" }];

  return (
    <>
      <Helmet>
        <title>
          {id == 'new' ? 'New' : 'Edit'} User Role | Practive
        </title>
      </Helmet>
      <div>
        {state.status ? (
          <div>

            <PageHeader title={`${id == 'new' ? 'New' : 'Edit'} User Role`} parent="User Roles" link="/user-roles">
              {id != 'new' && state.can_edit == true && (
                <button className="btn btn-outline-danger" onClick={() => handleDelete(id)}>Delete</button>
              )}

            </PageHeader>




           
            <form id="userrolefrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <fieldset disabled={state.can_edit == true ? false : true}>
              <div className="card card-default mb-4">
                <div className="card-body">
                  <UiInput
                    lcol="3"
                    icol="9"
                    parentClass="mb-0"
                    label="Role Name"
                    required={true}
                    type="text"
                    name="name"
                    message={errors.name?.message}
                    {...register("name")}
                  />
                </div>
              </div>

              <div className="row">
                {state.roles && state.roles.map((val, i) => (
                  <div className="col-md-6" key={i}>
                    <div className="card card-default mb-4" >
                      <div className="card-header">{val.title}</div>
                      <div className="card-body">
                        {val.perm.map((val1, i1) => (
                          <div className={`mb-3 ${val1.sub == 1 && 'ms-4'}`} key={i1}>
                            {val1.sub == 1 ? <>

                            </> : <div className="d-flex align-items-sm-center flex-column flex-sm-row">
                              <div className="py-1">
                                <input type="checkbox" className="form-check-input" id={val1.id} name={`perm.[${val1.id}].val`} defaultChecked={val1.val} {...register(`perm.[${val1.id}].val`)} />
                                <label className="form-check-label ms-2 user-select-none " htmlFor={val1.id}>{val1.title}</label>
                                <input type="hidden" defaultValue={val1.id} name={`perm.[${val1.id}].id`} {...register(`perm.[${val1.id}].id`)} />
                              </div>
                              {val1.sub_itm &&
                                <div className="" style={{marginLeft:'27px'}}>
                                  <UiSelect
                                    parentClass="mb-0"
                                    options={['Everything', 'Self Only']}
                                    noBlank={true}
                                    className="form-select-sm"
                                    defaultValue={val1.sub_itm.val}
                                    {...register(`perm.[${val1.sub_itm.id}].val`)}
                                    name={`perm.[${val1.sub_itm.id}].val`}
                                  />
                                  <input type="hidden" defaultValue={val1.sub_itm.id} name={`perm.[${val1.sub_itm.id}].id`} {...register(`perm.[${val1.sub_itm.id}].id`)} />
                                </div>
                              }
                            </div>}
                            
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>


              <input type="hidden" value={id} name="id" {...register('id')} />
              <UiButton loading={state["loader"]} title="Save" />
              </fieldset>
            </form>



          </div>
        ) : <BgLoader />}
      </div>
      <OverlayLoader visible={loader} />
    </>
  );
}
