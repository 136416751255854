import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { UiAvatar, TableZero, UiActioButton, UiTFind, UiButton, PageError, BgLoader, InLoader } from "../../ui";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import DataTable from 'react-data-table-component';

import { useQuery, useQueryClient, select } from "react-query";

export default function ClientDocRegister() {
  const [pstate, setPSate] = useOutletContext();
  let { id } = useParams();
  const [state, setState] = useState({});
  const [filterText, setFilterText] = React.useState('');
  const queryClient = useQueryClient()
  const [filteredItems, setFilteredItems] = useState({});
  const [loader, showLoader] = useState({});
  const docModal = useModal('document-entry');


  const addDoc = (id) => {

    docModal.show({ cust_id: pstate.client.id, name: pstate.client.name, id: id }).then((res) => {
      fetchData(pstate.client.id);
    });

  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader({ ...loader, delete: true })
        instance
          .get("/delete_document_entry?id=" + id)
          .then(function (response) {
            showLoader({ ...loader, delete: false })
            if (response.data.status == 'success') {

              fetchData(pstate.client.id);

              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }


  const docreturnModal = useModal('document-return');


  const returnDoc = (id, date) => {
    docreturnModal.show({ id: id, entrydate: date }).then((res) => {
      fetchData(pstate.client.id);

    });

  }
  const columns = [
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
      cell: row => (<span>{row.date}</span>)
    },
    {
      name: 'Doc. Type',
      sortable: true,
      selector: row => row.type,
      cell: row => (<b className="text-dark">{row.type}</b>),
    },
    {
      name: 'Category',
      sortable: true,
      selector: row => row.category,
    },


    {
      name: 'Return',
      sortable: true,
      sortField: 'return_date',
      id: 'return_date',
      width: '140px',
      selector: row => row.return_date,
      cell: row => (<>
        {row.returnable == 'Yes' ? (<>{row.return_date ? (row.return_date) : (<button className="btn btn-outline-primary btn-sm" disabled={row.can_edit == 1 ? false : true} onClick={() => returnDoc(row.id, row.date)}>Mark Returned</button>)}</>) : 'Not Returnable'}</>)
    },


    {
      name: 'Location',
      sortable: true,
      selector: row => row.location,

      cell: row => (<span className="text-truncate" title={row.location}>{row.location}</span>),
    },
    {
      name: 'Notes',
      selector: row => row.notes,
      cell: row => (<span className="text-truncate" title={row.notes}>{row.notes}</span>),
    },

    {
      name: 'Created by',
      selector: row => row.user,
      cell: row => (<UiAvatar name={row.user} photo={row.photo}/>),
    },


    {
      name: '',
      width: (window.innerWidth > 768 ? '0px' : 'auto'),
      selector: row => row.id,
      cell: row => (
        <div className="tableaction">
          <UiActioButton title="Edit" disabled={row.can_edit == 1 ? false : true} action={() => addDoc(row.id)} />
          <UiActioButton title="Delete" disabled={row.can_delete == 1 ? false : true} action={() => handleDelete(row.id)} />

        </div>

      )
    },
  ];






  useEffect(() => {
    if (state.items) {
      const filteredItems1 = state.items.filter(
        item => item.type && item.type.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);


  const fetchData = (id) => {
    showLoader(true)
    instance
      .get("/client_doc_register?id=" + id)
      .then(function (response) {
        showLoader(false)
        setState({ ...state, ...response.data });
        const filteredItems1 = response.data.items.filter(
          item => item.type && item.type.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(filteredItems1);
      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {

    fetchData(pstate.client.id);
  }, []);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      {state.items ?
        <div className="card card-default position-relative">
          {loader && <InLoader />}
          <div className="card-body">

            <div className="d-flex justify-content-between">
              <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />

              <UiButton className="btn btn-primary ms-2" onClick={() => addDoc('new')} title="New" icon="add"></UiButton>
            </div>
          </div>
          <div className="card-body pt-0 tablecard">

            <DataTable
              columns={columns}
              data={filteredItems}
              noDataComponent={<TableZero title="Documents" />}
            />

          </div>
        </div>
        : <BgLoader />}
    </>
  )

}

