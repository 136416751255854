import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { TodoItem } from "../../todo-item";
import { UiSelect, UiToggle,  UiActioButton, UiButton, PageError, BgLoader, InLoader, TableZero } from "../../ui";

import { useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";



export default function TaskTodo() {
  const [pstate, setPSate] = useOutletContext();

  const [state, setState] = useState({});

  const userModal = useModal('todo');


  const showAddModal = (row) => {
    userModal.show({ data: row, taskid: pstate.task.id }).then((res) => {
      fetchData();
    });
  }

  const viewtodoModal = useModal('view-todo');


  const ViewToDo = (row) => {
    viewtodoModal.show({ data: row }).then((res) => {
      if (res.resolved == true) {
        fetchData();
      }

    });
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var arr = state.todos.slice()
        arr.filter(x => x.id == id)[0].loading = true
        setState({ ...state, todos: arr })
        instance
          .get("/update_todo?delete=" + id)
          .then(function (response) {
            if (response.data.status == 'success') {
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }
            fetchData();
          })

      }
    })


  }



  const fetchData = () => {
    setState({ ...state, loader: true })
    instance
      .get("/todo_list?task=" + pstate.task.id)
      .then(function (response) {
        setState({ ...state, loader: false })
        setState({ ...state, ...response.data });



      })
      .catch(function (error) { })
      .then(function () { });

  }


  const completeTodo = (todo, act) => {
    var arr = state.todos.slice()
    arr.filter(x => x.id == todo)[0].loading = true
    setState({ ...state, todos: arr })
    instance
      .get("/complete_todo?action=" + act + "&todo=" + todo)
      .then(function (response) {
        if (response.data.status == 'success') {
          var arr = state.todos.slice();
          arr.filter((x) => x.id == todo)[0].status = act;
          arr.filter((x) => x.id == todo)[0].loading = null;

          setState({ ...state, todos: arr });
        } else if (response.data.status == 'error') {
          toast(response.data.msg, { type: "error" });
        }



      })
      .catch(function (error) { })
      .then(function () { });

  }




  useEffect(() => {
    fetchData();
  }, []);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (<>
    {state.todos ?
      <div className="card card-default">
        <div className="card-body">

          {pstate.can_edit == true && (
            <UiButton className="btn btn-primary" onClick={() => showAddModal()} icon="add" title="New To-Do"></UiButton>
          )}
        </div>
        <div className="card-body pt-0 tablecard">
          {state.todos.length > 0 ? (
            <div className="position-relative" >
              {state.loader && <InLoader />}
              {state.todos && state.todos.map((val1, i) => (
                <TodoItem val={val1} key={i} onComplete={completeTodo} onView={ViewToDo} onEdit={showAddModal} onDelete={handleDelete} />
              ))}
            </div>
          ) : state.todos && state.todos.length == 0 && <TableZero title="To-Do" />}

        </div>
      </div>
      : <BgLoader />}
  </>
  )

}

