import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { Toastcontent, UiInput, UiButton } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const GSTclient = NiceModal.create(
  ({  }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      id: yup
      .string()
      .nullable().matches(
              /^[0-9]{2}[0-9A-Za-z]{13}$/,
              "Enter valid GST number"
            ).required("Enter GST number"),
    });






    const {
      register,
      handleSubmit,
      control,
      getValues,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#clientgstform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/gst_validate",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task" />, { type: "error" });
          }
          if (response.data.status == "success") {
           // toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task" />, { type: "success" });
            modal.resolve({ resolved: true, party_info: response.data.party_info });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };




    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);




    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Enter GST Number</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="clientgstform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>


            <UiInput
              label="GSTIN"
              type="text"
              footer_text="The client details will be autofilled."
              name="id"
              message={errors.id?.message}
              {...register(`id`)}
            />








          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Autofill" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default GSTclient;
