import React, { useState, useEffect, useCallback } from "react";
import { BsFillExclamationTriangleFill } from 'react-icons/bs'
import { instance } from "../../axios";
import queryString from 'query-string';

import { Link } from 'react-router-dom'
import { BgLoader, InLoader } from '../../ui'
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";


export default function PayProcessing() {
  let navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams();
  const parsed = queryString.parse(location.search);
  useEffect(() => {
    if (window.opener) {
      setTimeout(
        function() {
          window.opener.popupCallback(id);
          window.close();
        }, 3000);
      
    } else {
      instance
      .get("/verify_payment?id=" + id)
      .then(function (response) {
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
          if(parsed && parsed.type){
            var redirect_url = '/'
            if(parsed.type == 'signup'){
              redirect_url = '/signup'
            } else if(parsed.type == 'subscription'){
              redirect_url = '/account'
            } else if(parsed.type == 'sms'){
              redirect_url = '/notification-settings'
            }
            navigate(redirect_url, { replace: true })
          }
        }
        if (response.data.status == "success") {
          

          if(parsed && parsed.type){
            var redirect_url = '/'
            if(parsed.type == 'signup'){
              navigate('/onboarding/' + response.data.token, { replace: true })
            } else if(parsed.type == 'subscription'){
              navigate('/account', { replace: true })
            } else if(parsed.type == 'sms'){
              navigate('/notification-settings', { replace: true })
            } else {
              navigate('/', { replace: true })
            }
            
          } else {
            navigate('/onboarding/' + response.data.token, { replace: true })
          }

        }
      })
      
    }
  }, []);


  return (
    <div className="text-center mt-5">
      <BgLoader />
    </div>
  );
}
