import { useState, useEffect, useRef } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiInput, UiRSelect, UiButton, BgLoader, InLoader, OverlayLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { rupees } from "../helper";
import { useNavigate, useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";

const PG = NiceModal.create(
  ({ id, type }) => {
    const [state, setState] = useState({});
    const [inner_loader, setInnerLoader] = useState(false);
    const [ovloader, setOvLoader] = useState(false);
    const [loaddiv, setLoaddiv] = useState(1);
    const modal = useModal();

    const parent = useRef(null);

    const schema = yup.object().shape(
      {
        company: yup.string().required("Company name is required").max(60, 'Maximum character limit is 60'),
        pg: yup.string().nullable().required("Please select payment option"),

        gstin: yup
          .string()
          .nullable()
          .when("gstin", {
            is: (value) => value?.length,
            then: (rule) =>
              rule
                .matches(
                  /^[0-9]{2}[0-9A-Za-z]{13}$/,
                  "Enter valid GST number"
                )
                .required("Enter GST number"),
          }),
      },
      [["gstin", "gstin"]]
    );


    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      watch,
      getValues,
      resetField,
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      initPayment();
      //reset();
    };






    useEffect(() => {


      instance
        .get("/load_payment?type=" + type + "&id=" + id)
        .then(function (response) {
          setState({ ...state, ...response.data });
          if (response.data.default) {
            reset({ ...getValues, ...response.data.billing, duration: response.data.default })
          } else {
            reset(response.data.billing)
          }


        })
        .catch(function (error) { })
        .then(function () { });



    }, []);



    const watchduration = watch('duration');


    useEffect(() => {
      if (watchduration) {

        var subtotal = watchduration.amount;
        var subtotal1 = subtotal - state.last_plan_discount;
        var tax = Math.round(subtotal1 * state.tax_percent / 100);
        var amount = subtotal1 + tax;
        setState({ ...state, amount: amount, tax: tax, subtotal: subtotal, title: watchduration.d_title + ' ' + watchduration.title })
      }
    }, [watchduration]);


    var initPayment = () => {
      setInnerLoader(true)
      var form = document.querySelector("#pgbillingform");
      var data = new FormData(form);
      data.append('plan', id)
      data.append('type', type)
      instance({
        method: "post",
        url: "/init_transaction",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setInnerLoader(false)
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            handlePay(response.data.url)
          }
        })
        .catch(function (response) { });
    };





    const dropinConfig = {
      components: [
        "card",
        "netbanking",
        "app",
        "upi",
      ],
      onSuccess: function (data) {
        if (data.order.status == 'PAID') {
          setInnerLoader(true)
          instance
            .get("/verify_payment?id=" + data.order.orderId)
            .then(function (response) {
              setInnerLoader(false)
              if (response.data.status == "error") {
                toast(response.data.msg, { type: "error" });
              }
              if (response.data.status == "success") {
                modal.resolve({ resolved: true, status: 'paid' });
                modal.hide();
              }
            })

        }
      },
      onFailure: function (data) {
        if (data.transaction.txStatus == 'FAILED') {
          toast(data.transaction.txMsg, { type: "error" });
        }
      },
      style: {
        //to be replaced by the desired values
        backgroundColor: "#ffffff",
        color: "#1A73E8",
        fontFamily: "Noto Sans",
        fontSize: "14px",
        errorColor: "#ff0000",
        theme: "light"
      }
    }
    window.popupCallback = function (str) {
      setInnerLoader(true)
      instance
        .get("/verify_payment?id=" + str)
        .then(function (response) {
          setInnerLoader(false)
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            modal.resolve({ resolved: true, status: 'paid' });
            modal.hide();
          }
        })
    };

    const handlePay = (e) => {
      setOvLoader(true)
      const popname = window.open(e, "popname", "status=1,toolbar=0,resizable=0");

      if (popname) {
        popname.focus();
        const timer = setInterval(() => {
          if (popname.closed) {
            setOvLoader(false);
            clearInterval(timer);
          }
        }, 1000);
      } else {
        // Fallback: Redirect the current window if pop-up was blocked
        setOvLoader(false);
        window.location.href = e;
      }


   
      //const cashfree = new window.Cashfree(e);



      //cashfree.drop(parent.current, dropinConfig);
    }



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);



    return (
      <>
        <Helmet>

        </Helmet>
        <OverlayLoader visible={ovloader ? true : false} />
        <BootstrapModal {...bootstrapDialog(modal)} >
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Payment</BootstrapModal.Title>
          </BootstrapModal.Header>

          <BootstrapModal.Body>
            <div className="position-relative">
              {inner_loader && (<InLoader />)}
              {state.status == 'success' ? (
                <>
                  {!state.paymentform && (
                    <>





                      <form id="pgbillingform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>

                        <div className="mb-4 border-bottom pb-4">
                          <div className="d-flex justify-content-between mb-2">
                            <span className="text-secondary">{state.title}</span>
                            <span>{rupees(state.subtotal)}</span>
                          </div>


                          <div className="d-flex justify-content-between mb-2">
                            <span className="text-secondary">Tax ({state.tax_percent}%)</span>
                            <span>{rupees(state.tax)}</span>
                          </div>
                          <div className="d-flex justify-content-between mb-2 fw-bold">
                            <span className="text-secondary">Final Amount</span>
                            <span>{rupees(state.amount)}</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <UiInput
                              required={true}
                              label="Company Name"
                              type="text"
                              name="company"
                              message={errors.company?.message}
                              {...register("company")}
                            />

                          </div>
                          <div className="col-sm-6">

                            <UiInput

                              label="GSTIN"
                              type="text"
                              name="gstin"
                              message={errors.gstin?.message}
                              {...register("gstin")}
                            />

                          </div>
                        </div>

                        <div className="mb-4">
                          <label className="form-label">
                            Pay Using
                          </label>
                          <div className="d-flex">
                            <div className="form-check me-4">
                              <input type="radio" className="form-check-input" value="UPI" name="pg" id="upi_pg" {...register("pg")} />
                              <label className="form-check-label" htmlFor="upi_pg">UPI</label>
                            </div>
                            <div className="form-check">
                              <input type="radio" className="form-check-input" value='Others' name="pg" id="other_pg"  {...register("pg")} />
                              <label className="form-check-label" htmlFor="other_pg">Debit/Credit Card, NET Banking</label>
                            </div>
                          

                          </div>
                          {errors.pg?.message && <small className="text-danger">{errors.pg.message}</small>}
                        </div>


                        <UiButton loading={state["loader"]} className="w-100 btn-lg" title="Make Payment" />
                      </form>


                    </>
                  )}
                </>
              ) : <BgLoader />}

              <div ref={parent} id="drop_in_container" className={`${state.paymentform == true ? 'd-block' : 'd-none'}`}>

              </div>
            </div>

          </BootstrapModal.Body>

        </BootstrapModal>
      </>
    );
  }
);

export default PG;
