import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, Toastcontent, UiButton, PageError, TableZero, UiTFind, BgLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { Table } from 'antd';

import { useNavigate, useLocation } from "react-router-dom";




const AssignClients = NiceModal.create(
  ({ id, type }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filteredItems, setFilteredItems] = useState({});

    const [filterText, setFilterText] = React.useState('');

    const onSelectChange = (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: onSelectChange,
    };

    const recModal = useModal('recurring-package');


    const editPackage = (id, row, cid, sts) => {
      //modal.hide();
      setState({ ...state, modalb: true })
      recModal.show({ package_id:id, data: row, cust_id: cid, status: sts }).then((res) => {
        setState({ ...state, modalb: false })
        modal.resolve({ resolved: true });
              modal.hide();

      });

    }


    const fetchData = () => {
      instance
        .get("/get_service_clients?id=" + id + "&type=" + type)
        .then(function (response) {
          setState({ ...state, ...response.data });

          const filteredItems1 = response.data.items.filter(
            item =>
              (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
              (item.file && item.file.toLowerCase().includes(filterText.toLowerCase()))
          );
          setFilteredItems(filteredItems1);

        })
        .catch(function (error) { })
        .then(function () { });

    }

    useEffect(() => {
      if (state.items) {

        const filteredItems1 = state.items.filter(
          item =>
            (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.file && item.file.toLowerCase().includes(filterText.toLowerCase()))
        );
        setFilteredItems(filteredItems1)
      }
    }, [filterText]);



    var SaveData = () => {
      if (type == 'package') {
editPackage(id, null,selectedRowKeys.toString(),0)
      } else {
        setState({ ...state, loader: true });
        var data = new FormData();

        data.append("cust_id", selectedRowKeys.toString());

        data.append("service", id);


        data.append("status", 0);
        instance({
          method: "post",
          url: "/assign_service",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            setState({ ...state, loader: false });
            if (response.data.status == "error") {
              toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "error" });
            }
            if (response.data.status == "success") {
              toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "success" });
              modal.resolve({ resolved: true, users: response.data.users });
              modal.hide();
            }
          })
          .catch(function (response) { });
      }
    };




    const columns = [
      {
        title: 'Clients',
        dataIndex: 'name',

      },
      {
        title: 'File No.',
        dataIndex: 'file',

      }




    ];

    useEffect(() => {
      fetchData();
    }, []);


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);




    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Clients</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>

          {state.items && state.items.length > 0 ? (
            <>
              <div className="mb-2">
                <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
              </div>

              <Table
                locale={{
                  emptyText: (<TableZero title="Clients" />)
                }}
                pagination={false}
                virtual={true}
                size="small"
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={filteredItems}
                //loading={{ indicator: <BgLoader /> }}
                rowSelection={rowSelection}
                scroll={{
                  x: 450,

                }}
              />
            </>
          ) : state.items && state.items.length == 0 ? <TableZero title="Clients" /> : <BgLoader />}


        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <UiButton loading={state["loader"]} disabled={selectedRowKeys.length > 0 ? false : true} title="Save" onClick={() => SaveData()} />
          <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  }
);

export default AssignClients;
