import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiSelect, Avatar, BgLoader, UiTextArea } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { format, formatRelative, parse, setDate } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import { Link, useLocation } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdOutlineCalendarToday, MdOutlineRepeat } from "react-icons/md";
import Swal from "sweetalert2";
import { FaCheck } from 'react-icons/fa'

const ViewToDo = NiceModal.create(
  ({ data }) => {

    const [state, setState] = useState({});
    const modal = useModal();

    const formatRelativeLocale = {
      lastWeek: "'Last' eeee",
      yesterday: "'Yesterday'",
      today: "'Today'",
      tomorrow: "'Tomorrow'",
      nextWeek: "'Next' eeee",
      other: 'dd-MM-yyyy',
    };

    const locale = {
      ...enGB,
      formatRelative: (token) => formatRelativeLocale[token],
    };

    const userModal = useModal('todo');


    const showAddModal = (row, id) => {
      userModal.show({ data: row, taskid: id }).then((res) => {
        if (res.resolved == true) {
          modal.resolve({ resolved: true });
          modal.hide();
        }
      });
    }


    const handleDelete = (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          setState({ ...state, delete: true })
          instance
            .get("/update_todo?delete=" + id)
            .then(function (response) {
              setState({ ...state, delete: false })
              if (response.data.status == 'success') {
                toast(response.data.msg, { type: "success" });
                modal.resolve({ resolved: true });
                modal.hide();
              } else {
                toast(response.data.msg, { type: "error" });
              }

            })

        }
      })


    }



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>View To-Do</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>

          <p className="fs-5"><b>{data.title}</b> {data.status == 1 && <span className="ms-2 text-success" title="Completed"><FaCheck /></span>}</p>
          {data.details &&
            <p>Details<br></br><span className="text-secondary" style={{ whiteSpace: 'pre-line' }}>{data.details}</span></p>}
          <div className="">


            {data.date && (
              <span className="d-flex align-items-center me-2 mb-2">
                <MdOutlineCalendarToday className="me-1" />
                {formatRelative(parse(data.date, "dd-MM-yyyy", new Date()), new Date(), { locale })}


              </span>
            )}


          </div>
          {data.repeat_every &&
            <div className="d-flex mb-2 align-items-center ">
              <MdOutlineRepeat className="me-1" />
              Repeat every {data.repeat_interval} {data.repeat_every}
            </div>
          }

          {data.is_assign == 1 && (
            <>
              <span className="text-secondary">Assigned to</span>
              <span className="d-flex align-items-center mb-2">
                <OverlayTrigger overlay={<Tooltip >{data.name}</Tooltip>} >
                  <span className="me-1 d-flex">
                    <Avatar name={data.name} className="bg-secondary d-inline-flex " src={data.photo} size="15" />
                  </span>
                </OverlayTrigger>
                {data.name}
              </span>
            </>
          )}

          <>
            <span className="text-secondary">Created by</span>
            <span className="d-flex align-items-center mb-2">
              <OverlayTrigger overlay={<Tooltip >{data.name_1}</Tooltip>} >
                <span className="me-1 d-flex">
                  <Avatar name={data.name_1} className="bg-secondary d-inline-flex " src={data.photo_1} size="15" />
                </span>
              </OverlayTrigger>
              {data.name_1}
            </span>
          </>

          {data.task && (
            <div>
              <b>Task</b>
              <p><Link to={`/view-task/${data.task_id}`}>{data.task}</Link></p>
            </div>
          )}
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          {data.can_edit == true && (
            <button className="btn btn-outline-primary" onClick={() => showAddModal(data, data.task_id)}>Edit</button>

          )}
          {data.can_delete == true && (
            <UiButton className="btn btn-outline-danger" loading={state.delete} onClick={() => handleDelete(data.id)} title="Delete"></UiButton>

          )}

          <a className="btn btn-secondary" onClick={modal.hide}>Close</a>
        </BootstrapModal.Footer>

      </BootstrapModal>
    );
  }
);

export default ViewToDo;
