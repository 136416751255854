import {useStore} from "./state.js";
import { useNavigate } from "react-router-dom";
import { format, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';
import { unSubscribe } from "./firebase.js";

export function setStorage(key, value) {
    if (value == 0)
      localStorage.removeItem(key);
    else
      localStorage.setItem(key, value);
  }
 export function getStorage(key) {
    let val = localStorage.getItem(key);
    return (val);
  }



  export function rupees(amt) {
    if(!amt){
      amt = 0
    } else {
      amt = parseFloat(amt)
    }
    return amt.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    maximumFractionDigits: 2,
   });
    
  }

  export function HourMin(amt) {
    return minutesToHours(amt).toString().padStart(2, '0')+':'+(amt%60).toString().padStart(2, '0');
  }

  export function validateForm(e) {
    var form = e.target;
    var invalidNode = form.querySelector(':invalid');
		if (invalidNode) invalidNode.focus();
    e.preventDefault()
    e.stopPropagation()
		form.classList.add('was-validated')
    
    return form.checkValidity();
		
    
  }

  export function LoginUser(token) {
    //useStore.setState({ auth: 1 });
    //setStorage("token", token);
    

		
    
  }

  export function Logout() {
  
    setStorage("token", '');
    setStorage("rtoken", '');
    setStorage("gfctkn", '');
    setStorage("tkntime", '');
    useStore.setState({ auth: null });
    useStore.setState({ ctu: null });
    unSubscribe()
        
  }

  export function updateFilterUrl(params, url, page) {
    let currentUrlParams = new URLSearchParams(params);
  
    var perpage = 20;
    if (getStorage('perpage')) {
      perpage = getStorage('perpage')
    } else {
      setStorage('perpage', 20)
    }
    currentUrlParams.set('perpage', perpage);
  
    return ("/" + url + "?" + currentUrlParams.toString())
  }