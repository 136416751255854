import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { UiSelect, UiToggle, UiInput, PageError, UiRSelect, BgLoader, UiAvatar } from "../../ui";

import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";

import DataTable from 'react-data-table-component';


export default function TaskHistory() {
  const [pstate, setPSate] = useOutletContext();

  const [state, setState] = useState({});




  const columns = [
    {
      name: 'Activity',
      selector: row => row.activity,
      cell: row => (<b className="text-dark">{row.activity}</b>)

    },
    {
      name: 'Detail',
      selector: row => row.detail,
      cell: row => (row.detail)
    },
    {
      name: 'User',
      selector: row => row.user,
      cell: row => (<><UiAvatar name={row.user} photo={row.photo} /></>)
    },
    {
      name: 'Date & Time',
      selector: row => row.time,
      cell: row => (row.time)
    },



  ];


  const fetchData = () => {
    instance
      .get("/task_history?id=" + pstate.task.id)
      .then(function (response) {
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });

  }


  useEffect(() => {
    fetchData();
  }, []);


  if(state.status == 'error'){
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  
  return (
    <>
    {state.task_history ? (
    <div className="card card-default">
      <div className="card-body tablecard">
        

          <DataTable
            columns={columns}
            data={state.task_history}

          />
        
      </div>
    </div>
    ) : <BgLoader />}
    </>
  )

}

