import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiRSelect, TableZero } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation } from "react-router-dom";
import { date } from "yup/lib/locale";
import TabPane from "antd/es/tabs/TabPane";


const SelectTemplate = NiceModal.create(
  ({ active, type, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const modal = useModal();

    const handleChange = (key) => {
      modal.resolve({ resolved: true, newid: key });
      modal.hide();
    }


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Select Template</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
          {date && Object.entries(data).length > 0 ?
            <div className="">

              {Object.entries(data).map(([key, val]) => (
                <div className="list1">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id={`temp${key}`} checked={active == key ? true : false} onChange={() => handleChange(key)} />
                    <label className="form-check-label" for={`temp${key}`}>

                      {val.template}


                    </label>
                  </div>
                </div>
              ))}
              {type == 'sms' &&
              <div className="alert alert-info mt-3">
                Due to TRAI's DLT compliances, "Sent via Practive" text will be shown below every SMS.
              </div>
  }
            </div>
            : <TableZero title="Templates" />}
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default SelectTemplate;
