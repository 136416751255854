import React, { useState, useEffect, useCallback } from "react";
import { instance } from "../../axios";
import {
  PageHeader,
  UiInput,
  UiButton,
  UiToggle,
  UiRichText,
  UiActioButton,
  UiImageUpload,
  UiSelect,
  BgLoader,
  PageError,
  OverlayLoader
} from "../../ui";
import { useNavigate } from 'react-router'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useQuery, useQueryClient, select } from "react-query";
import { GoPlus } from 'react-icons/go'
import Swal from "sweetalert2";

export default function BillingSettings() {
  const [show, setShow] = useState(false);
  const [logo, setLogo] = useState(null);
  const [modal, setModal] = useState();
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  const [ovloader, showovLoader] = useState(false);

  const fetchData = () => {
    instance
      .get("/billing_entities")
      .then(function (response) {
        setState({ ...state, ...response.data });
      })
      .catch(function (error) { })
      .then(function () { });
  }

  useEffect(() => {
    fetchData()
  }, []);





  const markDefault = (id) => {

    showovLoader(true)
    instance
      .get("/default_billing_entity?id=" + id)
      .then(function (response) {
        showovLoader(false)
        if (response.data.status == 'success') {
          fetchData()
          toast(response.data.msg, { type: "success" });
        } else {
          toast(response.data.msg, { type: "error" });
        }


      })



  }


  const saveSetting = (task, act) => {
    var action = 1;
    if (act == 1) {
      action = 0;
    }
    showovLoader(true)
    instance
      .get("/update_billing_setting?task=" + task + "&action=" + action)
      .then(function (response) {
        showovLoader(false)
        if (response.data.status == 'success') {
          //console.log(response.data.action)
          if (task == 'bill_map') {
            setState({ ...state, settings: { ...state.settings, bill_map: response.data.action } })
          } else if (task == 'line_discount') {
            setState({ ...state, settings: { ...state.settings, line_discount: response.data.action } })
          } else if (task == 'invoice_task_desc') {
            setState({ ...state, settings: { ...state.settings, invoice_task_desc: response.data.action } })
          }

          toast(response.data.msg, { type: "success" });
        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
  }


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        <title>
          Billing Settings | Practive
        </title>
      </Helmet>
      <div>
        {state.items ? (
          <div>
            <PageHeader title="Billing Settings" parent="Settings" link="/settings">

            </PageHeader>
            <div className="card card-default mb-4">
              <div className="card-body">
                <UiToggle
                  id="bill_map"
                  name="bill_map"
                  title="Link invoices to receipts"
                  // pclass="mb-0"
                  checked={state.settings.bill_map == 1 ? true : false}
                  onChange={() => saveSetting('bill_map', state.settings.bill_map)}
                />

                <UiToggle
                  id="line_discount"
                  name="line_discount"
                  title="Enable item wise discount in invoice"
                  
                  checked={state.settings.line_discount == 1 ? true : false}
                  onChange={() => saveSetting('line_discount', state.settings.line_discount)}
                />

                <UiToggle
                  id="invoice_task_desc"
                  name="invoice_task_desc"
                  title="Enable task description in invoice"
                  pclass="mb-0"
                  checked={state.settings.invoice_task_desc == 1 ? true : false}
                  onChange={() => saveSetting('invoice_task_desc', state.settings.invoice_task_desc)}
                />
              </div>
            </div>
            <h2>Billing Entities</h2>
            <div className="row mt-3">

              {state.items.map((val, i) => (
                <div className="col-sm-6 mb-4" key={i}>
                  <div className="card card-default">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <p><span className="text-secondary">Entity Name: </span><span className="me-2">{val.title}</span> {val.is_default == 1 ? <span className="badge bg-primary">Default</span>
                            : <span className="badge bg-secondary c-pointer" onClick={() => markDefault(val.id)}>Mark as default</span>
                          }</p>
                          <span className="text-secondary">Company Name</span>
                          <h2 className="fs-4">{val.company}</h2>
                        </div>
                        <div>
                          <UiActioButton action={() => navigate(`/billing-entity/${val.id}`)} title="Settings" tooltip="Edit Settings" className="fs-2" />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-sm-6 mb-4" >
                <div className="card bg-light h-100 c-pointer" onClick={() => navigate('/billing-entity/new')}>
                  <div className="card-body d-flex align-items-center justify-content-center text-secondary">
                    <div className="text-center">
                      <p className="text-center"><GoPlus className="fs-2" /></p>
                      <span className="d-block">Add New Billing Entity</span>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <OverlayLoader visible={ovloader} />
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
}
