import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { PageHeader, UiToggle, UiTimeDisplay, UiAvatarName, UiButton, PageError, BgLoader } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Helmet } from "react-helmet";
import { useQueryClient } from 'react-query';
import { rupees } from "../../helper";

import { ReceiptTemplate } from '../../receipt'
import { Sharer } from '../../sharer'


export default function ViewReceipt() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  const [bg_loader, setBGLoader] = useState(false);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader({ ...loader, delete: true })
        instance
          .get("/delete_receipt?id=" + id)
          .then(function (response) {
            showLoader({ ...loader, delete: false })
            if (response.data.status == 'success') {

              queryClient.invalidateQueries(['receipts'])
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }



  const CancelReceipt = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Cancel it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader({ ...loader, cancel: true })
        instance
          .get("/cancel_receipt?id=" + id)
          .then(function (response) {
            showLoader({ ...loader, cancel: false })
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['receipts'])
              toast(response.data.msg, { type: "success" });
              fetchData();
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }

  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/view_receipt?id=" + id)
      .then(function (response) {
        setBGLoader(false)

        setState({ ...state, ...response.data });



      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, []);



  const printModal = useModal('print');

  const PrintReceipt = (id, num) => {
    printModal.show({ id: id + ':' + num, voucher: 'receipt' }).then((res) => {
    });


  }


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }





  return (
    <>
      <Helmet>
        {state.receipt && (
          <title>
            #{state.receipt.receipt_id} | Receipts | Practive
          </title>
        )}
      </Helmet>
      <div>
        {state.receipt ? (
          <>

            <PageHeader title={`#${state.receipt.receipt_id}`} parent="Receipts" link="/receipts" stacked={true}>
            <Sharer data={state.receipt} type="receipt"/>
              {state.can_edit == true && <>
                {state.receipt.cancel == 0 ? (
                  <>
                    <UiButton loading={loader.cancel} className="btn btn-outline-warning" onClick={() => CancelReceipt(id)} title="Cancel" />
                    <button className="btn btn-outline-primary ms-2" onClick={() => navigate(`/receipt/${id}`)}>Edit</button>
                  </>
                ) : (
                  <button className="btn btn-outline-primary ms-2" onClick={() => navigate(`/receipt/${id}`)}>Reverse Cancel</button>
                )}
              </>}
              {state.can_delete == true && (
                <UiButton className="btn btn-outline-danger ms-2" loading={loader.delete} onClick={() => handleDelete(id)} title="Delete" />
              )}
              <button className="btn btn-primary ms-2" onClick={() => PrintReceipt(id, state.receipt.receipt_id)}>Print</button>
            </PageHeader>




            {bg_loader == true ? <BgLoader /> : (
              <>

                <div className="card card-default">
                  <div className="card-body">
                  <ReceiptTemplate data={state.receipt} />
                  </div>
                </div>
                <p className="mt-3"><span className="text-secondary">Remark:</span> {state.receipt.remarks}</p>
                <div>
                  <div className="d-block text-secondary small">
                    <div className="d-inline-flex me-3 py-2 align-items-center">
                      <span className="me-2">Created by</span>
                      <UiAvatarName photo={state.receipt.user_pic} name={state.receipt.user_name} />
                    </div>
                    <div className="d-inline-flex py-2 align-items-center">
                      <span className="me-2">on</span>
                      <UiTimeDisplay time={state.receipt.created_on} />
                    </div>
                  </div>
                </div>
              </>)}

          </>
        ) : <BgLoader />}


      </div>
    </>
  );
}
