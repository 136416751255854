import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiSelect } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";

const days = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th', '20th', '21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th', 'Last Day'];


const AutoBilling = NiceModal.create(
  ({ data, cust_id }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      day: yup.string().required("Select day of month"),

    });
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#autobillform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/auto_billing",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true});
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
    useEffect(() => {


      if (data) {

        reset({ day: data});
      }

    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Auto Billing</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="autobillform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <BootstrapModal.Body>
          
            <p>Select day when you want to make automatic invoice.</p>
            <UiSelect
              name="day"
              required={true}
              label="Select Day"
              message={errors.day?.message}
              options={days}
              {...register("day")}
            />




            {cust_id && (<input type="hidden" value={cust_id} name="id"></input>)}

            <div className="alert alert-info">Note: Invoice will be created for tasks with 'Completed' status only if the 'Auto generate invoice number' option is enabled. You must set billing price in Client Dashboard → Recurring Services or Settings → Services.</div>



            
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
        <UiButton loading={state["loader"]} title="Enable" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default AutoBilling;
