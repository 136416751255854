import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiDatePicker,  UiSelect, UiTextArea, UiInput, UiButton, UiRSelect, UiToggle, BgLoader, PageError, UiClientSelect } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { format, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';
import { useNavigate, useLocation } from "react-router-dom";




const DocEntry = NiceModal.create(
  ({ id, cust_id, name, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const modal = useModal();

    const schema = yup.object().shape({
      date: yup.string().required("Enter date"),
      category: yup.string().required("Select type"),
      type: yup.object().required("Select document type"),
      client: yup.object().required("Select client"),
      location: yup.string().max(50, 'Maximum character limit is 50'),
      notes: yup.string().max(200, 'Maximum character limit is 200'),
    });
    const {
      register,
      handleSubmit,
      control,

      getValues,
      watch,
      formState: { errors },
      reset,
      resetField,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      handleSave();
      //reset();
    };




    var handleSave = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#docentryform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_document_entry",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };



    const fetchData = () => {
      instance
        .get("/get_document_entry?id=" + id)
        .then(function (response) {
          
          if (response.data.status == 'success') {
            if (cust_id) {
              var arr = { 'value': cust_id, 'label': name };
              setState({ ...state, ...response.data, default_client: arr });
              //setState({ ...state, default_client: arr })
              reset({ ...response.data.entry, client: arr })
            } else {
              setState({ ...state, ...response.data });
              reset(response.data.entry)
            }
            
          } else {
            setState({ ...state, ...response.data });
            toast(response.data.msg, { type: "error" });
          }

        })
        .catch(function (error) { })
        .then(function () { });

    }

    const addtypeModal = useModal('document-type');

    const addType = () => {
      setState({ ...state, modalb: true })
      addtypeModal.show().then((res) => {
        setState({ ...state, modalb: false, types: [...state.types, res.type] })
        reset({ ...getValues(), type: res.type })
      });

    }




    const [loadedOptions, setOptions] = useState({});


    const client_w = watch('client');
    useEffect(() => {
      if (client_w == null) {
        setOptions({})
      }
    }, [client_w]);

    useEffect(() => {
      fetchData();


    }, []);





    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);



    const watchDate = watch('date')
    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Document" : "New Document"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        {state.status == 'success' ? (
          <form id="docentryform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <BootstrapModal.Body>

              <div className="row">
                <div className="col-6">

                  <Controller
                    name="date"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDatePicker
                        label="Date"
                        required={true}
                        onChange={onChange}
                        default_val={value}
                        message={errors.date?.message}
                        dateFormat="dd-MM-yyyy"
                        ref={ref}
                        name={name}
                      />
                    )}
                  />
                </div>
                <div className="col-6">
                  <UiSelect

                    options={['Received', 'Given']}
                    required={true}

                    message={errors.category?.message}
                    label="Category"
                    {...register("category")}

                  />

                </div>
              </div>



              <input type="hidden" defaultValue={id} name="id" {...register("id")} />
              <div className="row">

                <div className="col-6">
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (

                      <UiRSelect
                        required={true}
                        {...field}
                        options={state.types}
                        className=""
                        message={errors.type?.message}
                        label="Document Type"
                        addnew={1}
                        handleNew={() => addType()}

                      />

                    )}
                  />
                </div>
                <div className="col-6" >
                  <div className={cust_id && 'd-none'}>
                    <Controller
                      name="client"
                      control={control}

                      render={({ field }) => (

                        <UiClientSelect
                          {...field}
                          required={true}
            
                          message={errors.client?.message}
                          label="Client"

                        />

                      )}
                    /></div>
                </div>
              </div>



              <UiInput
                label="Doc. Location"
                type="text"
                name="location"
                {...register("location")}
                message={errors.location?.message}
              />

              <UiToggle
                id="returnable"
                name="returnable"
                title="Is document returnable?"
                {...register("returnable")}
              />
              {state.entry && state.entry.return_date && (
                <Controller
                  name="return_date"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiDatePicker
                      label="Returned on"
                      onChange={onChange}
                      default_val={value}
                      message={errors.return_date?.message}
                      dateFormat="dd-MM-yyyy"
                      minDate={parse(watchDate, "dd-MM-yyyy", new Date())}
                      ref={ref}
                      name={name}
                    />
                  )}
                />
              )}

              <UiTextArea
                label="Notes"
                name="notes"
                message={errors.notes?.message}
                {...register("notes")}
              />



            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Save" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>
        ) : state.status == 'error' ? <PageError msg={state.msg} code={state.code} /> : <BgLoader />}
      </BootstrapModal>
    );
  }
);

export default DocEntry;
