import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiSelect, UiTextArea, UiInput, UiButton, UiRSelect, UiToggle, BgLoader, PageError, InLoader, UiClientSelect } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { format, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';
import { useNavigate, useLocation } from "react-router-dom";




const DocCollection = NiceModal.create(
  ({ id, cust_id, name, task_id, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const [loader, showLoader] = useState(false);
    const modal = useModal();

    const schema = yup.object().shape({

      client: yup.object().required("Select client"),
      task: yup.object().required("Select task"),
      subject: yup.string().required("This is a required field"),
    });
    const {
      register,
      handleSubmit,
      control,

      getValues,
      watch,
      formState: { errors },
      reset,
      resetField,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      handleSave();
      //reset();
    };




    var handleSave = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#docreqform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_doc_request",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };



    const fetchData = () => {
      instance
        .get("/get_doc_request?id=" + id)
        .then(function (response) {
          setState({ ...state, ...response.data });
          if (response.data.status == 'success') {

            reset(response.data.entry)
          } else {
            toast(response.data.msg, { type: "error" });
          }

        })
        .catch(function (error) { })
        .then(function () { });

    }


    const fetchTasks = (id) => {
      showLoader(true)
      instance
        .get("/get_tasks?id=" + id)
        .then(function (response) {
          showLoader(false)
          setState({ ...state, tasks: response.data.items });
          if (response.data.status == 'success') {

            resetField('task', { defaultValue: null })
          } else {
            toast(response.data.msg, { type: "error" });
          }

        })
        .catch(function (error) { })
        .then(function () { });

    }




    const [loadedOptions, setOptions] = useState({});
   

    const client_w = watch('client');
    useEffect(() => {
      if (client_w == null) {
        setOptions({})
      } else if (client_w && client_w.value && id == 'new' && !cust_id) {
        fetchTasks(client_w.value)
      }
    }, [client_w]);

    useEffect(() => {
      fetchData();


    }, []);





    useEffect(() => {
      if (cust_id && task_id) {
        var arr = { 'value': cust_id, 'label': name };
        var arr1 = { 'value': task_id, 'label': name };
     
        reset({ ...getValues, client: arr, task:arr1 })
      }
    }, [cust_id, task_id]);



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);




    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Doc. Collection Request" : "New Doc. Collection Request"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        {state.status == 'success' ? (
          <form id="docreqform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            {loader && <InLoader />}
            <BootstrapModal.Body>
              <UiSelect
                label="Status"
                options={['Open', 'Closed']}
                //defaultValue={val.value}
                name="status"
                noBlank={true}
                disabled={id == 'new' ? true : false}
                {...register('status')}
              />
              <div className={cust_id && 'd-none'}>
                <Controller
                  name="client"
                  control={control}

                  render={({ field }) => (

                    <UiClientSelect
                      {...field}
                      required={true}
                 
                      message={errors.client?.message}
                      label="Client"
                      isDisabled={id == 'new' ? false : true}
                    />

                  )}
                />
              </div>

              <div className={cust_id && 'd-none'}>
                <Controller
                  name="task"
                  control={control}

                  render={({ field }) => (

                    <UiRSelect
                      {...field}
                      required={true}
                      isDisabled={id == 'new' ? false : true}
                      options={state.tasks}
                      className=""
                      message={errors.task?.message}
                      label="Task"
                    />

                  )}
                />
              </div>




              <input type="hidden" defaultValue={id} name="id" {...register("id")} />

              <UiTextArea
                label="Message"
                name="subject"
                required={true}
                message={errors.subject?.message}
                {...register("subject")}
              />



            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Save" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>
        ) : state.status == 'error' ? <PageError msg={state.msg} code={state.code} /> : <BgLoader />}
      </BootstrapModal>
    );
  }
);

export default DocCollection;
