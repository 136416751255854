import React, { forwardRef, useState, useEffect } from "react";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { MdShare, MdOutlineEmail, MdWhatsapp, MdOutlineFileDownload } from 'react-icons/md';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {toast} from "react-toastify";

export function Sharer(props) {
  const emailModal = useModal('send-email');
  const waModal = useModal('send-whatsapp');


  const SendEmail = (e, a) => {

    emailModal.show({ data: e, type: a }).then((res) => {

    });
  }

  const SendWhatsapp = (e) => {

    waModal.show({ data: e }).then((res) => {

    });
  }



  const downloadPdf = (id, id1, type) => {
    const idd = toast.loading("Please wait...")

    fetch('https://api.practive.in/pdf?voucher=' + type + '&id=' + id + ':' + encodeURIComponent(id1)).then(response => {
      response.blob().then(blob => {
        toast.update(idd, { render: "Done", closeButton:true, type: "success", closeOnClick: true,autoClose:3000, isLoading: false });
        const fileURL = window.URL.createObjectURL(blob);
        
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = type.toUpperCase()+'-'+id1+'.pdf';
        alink.click();
      })
    })
  }

  return (
    <div>
      <DropdownMenu
        trigger={({ triggerRef, isSelected, ...props }) => (
          <OverlayTrigger overlay={<Tooltip >Share</Tooltip>}><button className="btn btn-secondary me-2"
            {...props}

            ref={triggerRef}
          ><MdShare className="fs-4" /></button></OverlayTrigger>

        )}
      >
        <DropdownItemGroup>
          <DropdownItem onClick={() => SendEmail(props.data, props.type)} ><div className="d-flex"><MdOutlineEmail className="sicon text-secondary me-2" /> Send Email</div></DropdownItem>
          <DropdownItem onClick={() =>SendWhatsapp(props.data)} ><div className="d-flex"><MdWhatsapp className="sicon text-secondary me-2" /> Send WhatsApp</div></DropdownItem>
          <DropdownItem onClick={() =>downloadPdf(props.data.id, props.data.voucher_id, props.type)} ><div className="d-flex"><MdOutlineFileDownload className="sicon text-secondary me-2" /> Download PDF</div></DropdownItem>

        </DropdownItemGroup>
      </DropdownMenu>
    </div>

  );
}

