import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiTFind, BgLoader, UiActioButton, InLoader, PageError } from "../../ui";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Helmet } from "react-helmet";
import { GoPlus } from "react-icons/go";

export default function Tags() {
  let navigate = useNavigate();
  const [bg_loader, setBGLoader] = useState(false);
  const userModal = useModal('user');

  const [state, setState] = useState({});
  const [filteredItems, setFilteredItems] = useState({});
  const [loader, showLoader] = useState({});
  const showAddModal = (row) => {

    userModal.show({ data: row }).then((res) => {
      fetchTags();
    });



  }

  const deleteTag = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setBGLoader(true)
        instance
          .get("/update_tag?delete=" + id)
          .then(function (response) {
            setBGLoader(false)
            if (response.data.status == 'success') {
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }

            fetchTags();

          })

      }
    })


  }

  const fetchTags = () => {
    setBGLoader(true)
    instance
      .get("/tags")
      .then(function (response) {
        setBGLoader(false)
        setState({ ...state, ...response.data });
        const filteredItems1 = response.data.tags.filter(
          item => item.tag_name && item.tag_name.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(filteredItems1);

      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchTags();
  }, []);

  const [filterText, setFilterText] = React.useState('');

  useEffect(() => {
    if (state.tags) {
      const filteredItems1 = state.tags.filter(
        item => item.tag_name && item.tag_name.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);


  const columns = [
    {
      name: 'Tag',
      sortable: true,
      selector: row => row.tag_name,
      cell: row => (<b className={`text-${row.color}`}>{row.tag_name}</b>)
    },

    {
      name: '',
      width: (window.innerWidth > 768 ? '0px' : '130px'),
      selector: row => row.id,
      cell: row => (
        <div className="tableaction">
          <UiActioButton title="Edit" action={() => showAddModal(row)} />
          <UiActioButton title="Delete" action={() => deleteTag(row.id)} />

        </div>

      )
    },
  ];


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        <title>
          Tags | Practive
        </title>
      </Helmet>
      <div>
        {state.tags ? (
          <div>
            <PageHeader title="Tags" parent="Settings" link="/settings">
              <UiButton className="btn btn-primary" onClick={() => showAddModal()} title="New" icon="add"></UiButton>

            </PageHeader>


            <div className="card card-default position-relative">
              {bg_loader == true && <InLoader />}
              <div className="card-body">


                <div className="">
                  <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
                </div>
              </div>
              <div className="card-body pt-0 tablecard">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  noDataComponent={<TableZero title="Tags" />}
                />


              </div>
            </div>
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
}
