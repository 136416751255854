import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiSelect, TableZero, UiInput, UiButton, UiRichText, UiToggle, BgLoader, PageError, InLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation } from "react-router-dom";


const StopTimer = NiceModal.create(
  ({ type, data }) => {
    const [state, setState] = useState({});
    const [loader, showLoader] = useState(false);
    const modal = useModal();



    const schema = yup.object().shape({


      notes: yup.string().nullable().required("Details is required").max(200, 'Maximum character limit is 200')


    });
    var handleSave = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#stoptimerfrm");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_timer",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {

            modal.resolve({ resolved: true });
            modal.hide();
            toast(response.data.msg, { type: "success" });
          }
        })
        .catch(function (response) { });
    };
    const {
      register,
      handleSubmit,
      setError,
      watch,
      resetField,
      clearErrors,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      handleSave();
    };


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);




    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Stop Timer</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="stoptimerfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>


            <UiInput
              label="Details"
              required={true}
              type="text"
              name="notes"
              message={errors.notes?.message}
              {...register("notes")}
            />

            <p className="mb-0 text-warning">Note: The daily time log is limited to 24 hours. If the timer exceeds this limit, only the remaining time will be saved.</p>

            <input type="hidden" name="id" value={data.id} ></input>
            <input type="hidden" name="action" value='stop' ></input>

          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Update" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default StopTimer;
