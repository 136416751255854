import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { UiSelect, UiToggle, UiActionDropDown, UiActioButton, PageError, BgLoader, TableZero, UiTimeDisplay, InLoader } from "../../ui";
import { Uploader, DocList } from "../../uploader";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import { DropdownItem } from '@atlaskit/dropdown-menu';
import Swal from "sweetalert2";
import { toast } from "react-toastify";


export default function TaskDocuments() {
  const [pstate, setPSate] = useOutletContext();
  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [loader, showLoader] = useState(false);

  const queryClient = useQueryClient()
  const docreqModal = useModal('doc-collection');


  const requestDoc = (id, row, task, client) => {
    setState({ ...state, modalb: true })
    docreqModal.show({ data: row, id: id, task_id: task, cust_id: client }).then((res) => {
      queryClient.invalidateQueries(['doc-requests'])
      fetchTaskReq()
    });

  }

  const handleDelete = (id) => {


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader(true)
        instance
          .get("/delete_doc_req?id=" + id)
          .then(function (response) {
            showLoader(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['doc-requests'])
              fetchTaskReq()
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })




  }


  const doclistModal = useModal('doc-request');


  const showDocs = (id, row) => {
    setState({ ...state, modalb: true })
    doclistModal.show({ data: row, id: id }).then((res) => {
      queryClient.invalidateQueries(['doc-requests'])
      fetchTaskReq()
    });

  }


  const fetchData = () => {

    instance
      .get("/task_files?id=" + pstate.task.id)
      .then(function (response) {
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });

  }

  const fetchTaskReq = () => {
    instance
      .get("/task_doc_requests?id=" + pstate.task.id)
      .then(function (response) {
        setState1({ ...state1, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
    fetchTaskReq()
  }, []);

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (

    <div className="row">

      <div className="col-md-6">
        <div className="card card-default mb-4">
          <div className="card-header">Upload</div>
          <div className="card-body">
            {pstate.can_edit == true ? (

              <Uploader category="task" uid={pstate.task.id} onSave={(e) => setState(prevState => ({ documents: [...prevState.documents, ...e] }))} />
            ) : <p className="text-muted">You don't have permission to upload files!</p>}
            <h3 className="fs-5 mt-5">Uploaded Files</h3>
            {state.documents && state.documents.length > 0 ? (
              <DocList category="task" setData={(e) => setState({ ...state, documents: e })} data={state.documents} can_edit={pstate.can_edit} />
            ) : state.documents && state.documents.length == 0 ? <TableZero title="Files" /> : <BgLoader />}
          </div>
        </div>
      </div>
      <div className="col-md-6">
        {pstate.task.is_subtask == 0 &&
          <div className="card card-default">
            <div className="card-header">
              <div className="d-flex justify-content-between">
                <span>Doc. Collection from Client</span>
                {pstate.can_edit == true && (
                  <UiActioButton title="Add" tooltip="Create New Request" action={() => requestDoc('new', null, pstate.task.id, pstate.task.cust_id)}></UiActioButton>
                )}
              </div>
            </div>
            <div className="card-body">
              {loader && <InLoader />}
              {state1.items && state1.items.length > 0 ? (
                <>
                  {state1.items.map((val, i) => (
                    <div key={i} className="list1">
                      <div className="d-flex justify-content-between">
                        <UiTimeDisplay time={val.date} />
                        <span><span className="text-secondary">Status: </span><span className={`${val.status == 'Closed' ? 'text-success' : 'text-warning'}`}>{val.status}</span></span>
                      </div>

                      {val.subject &&
                        <div className="mt-3">
                          <span className="text-secondary ">Message</span>
                          <p>{val.subject}</p>
                        </div>
                      }
                      <div className="d-flex justify-content-between">
                        <span><span className="text-secondary">Uploaded Documents: </span>{val.docs} {val.docs > 0 && <button className="btn btn-outline-primary btn-sm ms-2" onClick={() => showDocs(val.id)}>View</button>}</span>
                        <span>
                          <UiActionDropDown className="btn-sm">
                            <DropdownItem isDisabled={pstate.can_edit == 1 ? false : true} onClick={() => requestDoc(val.id, null, pstate.task.id, pstate.task.cust_id)} >Edit</DropdownItem>
                            <DropdownItem className="text-danger" isDisabled={pstate.can_edit == 1 ? false : true} onClick={() => handleDelete(val.id)} >Delete</DropdownItem>

                          </UiActionDropDown>
                        </span>
                      </div>
                    </div>
                  ))}
                </>
              ) : state1.items && state1.items.length == 0 ? <TableZero title="Requests" /> : <BgLoader />}
            </div>
          </div>
        }
      </div>

    </div>


  )

}

