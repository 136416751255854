import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BgLoader, UiButton, UiInput } from "../../ui";
import { useStore } from "../../state.js";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import logoimg from "../../practive.svg";



const ChangePassword = ({ ...props }) => {
  let { id } = useParams();

  const { auth, setAuth } = useStore((state) => state);
  let navigate = useNavigate();

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Please enter password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    password1: yup.string()
      .oneOf([yup.ref('password'), null], 'The password and its confirm are not the same')
  });
  const [state, setState] = useState({});

  var doChangePass = (e) => {
    setState({ ...state, loader: true });
    var form = document.querySelector("#resetpasschangefrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/change_pass",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "success") {
          Swal.fire({
            icon: "success",
            title: "Password Changed",
            text: "Your password changed successfully!",
          }).then((result) => {
            navigate("/login", { replace: true });
          });
        } else if (response.data.status == "error") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Password reset link is expired or invalid!",
          }).then((result) => {
            navigate("/login", { replace: true });
          });
        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    doChangePass();
    //reset();
  };

  useEffect(() => {
    if (id === null || id === undefined) {
      navigate("/login", { replace: true });
    } else {
      instance
        .get("/change_pass?id=" + id)
        .then(function (response) {
          if (response.data.status == "success") {
            setState({ ...state, valid: true });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Password reset link is expired or invalid!",
            }).then((result) => {
              navigate("/login", { replace: true });
            });
          }
        })
        .catch(function (error) { })
        .then(function () { });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Setup New Password | Practive</title>

      </Helmet>
      <div className="container py-5 h-100">
        {state.valid ? (
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="mb-4 text-center">
                <img className="loginlogo" src={logoimg} />
              </div>
              <div className="card shadow-2-strong">
                <div className="card-body">
                  <h3 className="mb-5">Setup New Password</h3>
                  <form id="resetpasschangefrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                    <UiInput
                      showicon={true}
                      label="New Password"
                      type="password"
                      name="password"
                      message={errors.password?.message}
                      {...register("password")}
                    />
                    <UiInput
                      showicon={true}
                      label="Confirm Password"
                      type="password"
                      name="password1"
                      message={errors.password1?.message}
                      {...register("password1")}
                    />
                    <input type="hidden" name="id" value={id} />

                    <UiButton loading={state["loader"]} title="Save" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
};

export default ChangePassword;
