import React, { forwardRef, useState, useEffect } from "react";
import { rupees } from "./helper";
import { QRCodeSVG } from 'qrcode.react';

export function QuoteTemplate(props) {

  const { data } = props

  return (

    <>

      <div className="row">
        <div className="col-sm-6">
          {data.logo && (
            <img style={{ maxWidth: '200px' }} src={data.logo} />
          )}
          <p style={{ maxWidth: '400px' }}><b className="fs-5">{data.company}</b><br></br>
            {data.company_address && data.company_address.split("\n").map(function (item) {
              return (
                <>
                  {item}
                  <br />
                </>
              )
            })}{data.company_state}
            {data.mobile && (<><br></br><b>Mobile: </b>{data.mobile}</>)}
            {data.email && (<><br></br><b>Email: </b>{data.email}</>)}
          </p>
          {data.company_gstin && (
            <p><b>GSTIN: </b>{data.company_gstin}</p>
          )}


        </div>
        <div className="col-sm-6 text-sm-end">
          <div className="text-grey-m2">
            <div className="h1 text-600">
              {data.quote_heading}
            </div>

          </div>
        </div>
      </div>



      <div className="row border-top pt-3">
        <div className="col-sm-6">
          <div>
            <p className="mb-1">To:</p>
            <span className="fw-semibold">{data.client}</span>
          </div>
          <div className="text-grey-m2">
            <p style={{ maxWidth: '400px' }}>{data.address && data.address}
              {data.city && <><br></br>{data.city} {data.pincode && <> - {data.pincode}</>}</>}
              <br></br>{data.state}
              {data.client_mobile && (<><br></br><b>Mobile: </b>{data.client_mobile}</>)}
              {data.client_email && (<><br></br><b>Email: </b>{data.client_email}</>)}
            </p>

            {data.is_gst == 1 && data.gst && (
              <div>
                <p className="mb-0"><b>GSTIN: </b>{data.gst}</p>
              </div>
            )}
          </div>
        </div>


        <div className="col-sm-6 text-sm-end">
          <div className="">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td>Quotation No.:</td>
                  <td className="text-end ms-3">
                    {data.quote_id}
                  </td>
                </tr>
                <tr>
                  <td>Quotation Date:</td>
                  <td className="text-end">
                    {data.date}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="table-responsive mt-4 mb-9">
        <table className="table mb-3">
          <thead>
            <tr className="border-bottom text-secondary text-uppercase">
              <th>Description</th>
              {data.is_gst == 1 && (<>
                <th className="text-end">Price</th>
                <th className="text-end">GST (%)</th>
                <th className="text-end">GST</th>
              </>)}
              <th className="text-end">Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.items && data.items.map((val, i) => (
              <tr key={i} >
                <td>
                  <span className="fs-6">{val.service}</span>
                  {val.description && <p className="mb-0 small">{val.description}</p>}
                </td>
                {data.is_gst == 1 && (<>
                  <td className="text-end fs-6">{rupees(val.price)}</td>
                  <td className="text-end fs-6">{val.tax_rate}%</td>
                  <td className="text-end fs-6">{rupees(val.tax)}</td>
                </>)}
                <td className="text-end fs-6">{rupees(val.amount)}</td>
              </tr>
            ))}


          </tbody>
        </table>

      </div>
      <div className="row">
        <div className="col-md-6">
          {data.total_in_words}
          {(data.upi_id && data.upi_qr_quote == 1) &&
            <div className="border p-3 mt-4" style={{ maxWidth: '400px' }}>
              <div className="d-flex" >
                <div className="col1 flex-grow-1 pe-2">
                  <p><b className="">Scan QR code to pay using any UPI app.</b><br></br><br></br>UPI ID<br></br>{data.upi_id}</p>
                </div>
                <div className="col1">
                  <QRCodeSVG value={`upi://pay?pa=${data.upi_id}&pn=${data.company}&am=${data.total}&tn=Quotation No. ${data.quote_id}`} size="100" />
                </div>
              </div>
            </div>
          }
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-end mt-3">

            <table className="table table-borderless table-sm" style={{ maxWidth: '250px' }}>
              <tbody>
                <tr>
                  <td>Subtotal:</td>
                  <td className="text-end">
                    {rupees(data.subtotal)}
                  </td>
                </tr>

                {data.igst && (
                  <tr>
                    <td>IGST:</td>
                    <td className="text-end">
                      {rupees(data.igst)}
                    </td>
                  </tr>

                )}

                {data.cgst && (
                  <tr>
                    <td>CGST:</td>
                    <td className="text-end">
                      {rupees(data.cgst)}
                    </td>
                  </tr>

                )}

                {data.sgst && (
                  <tr>
                    <td>SGST:</td>
                    <td className="text-end">
                      {rupees(data.sgst)}
                    </td>
                  </tr>

                )}

                <tr>
                  <td>Round off:</td>
                  <td className="text-end">
                    {rupees(data.round_off)}
                  </td>
                </tr>


                <tr>
                  <td className="fs-5">Total:</td>
                  <td className="text-end fs-5 fw-semibold">
                    {rupees(data.total)}
                  </td>
                </tr>
              </tbody>
            </table>

          </div>



        </div>
      </div>




      {data.tnc &&
        <div className="" style={{ maxWidth: '400px' }}>
          <b>Terms & Conditions</b>
          <p className="bill-footer" dangerouslySetInnerHTML={{
            __html: data.tnc.replace(/\n/g, "<br />")
          }}></p>
        </div>
      }
      <div className="row">
        <div className="col-md-7">
          <div className="mt-4 bill-footer" dangerouslySetInnerHTML={{
            __html: data.quote_footer
          }}>
          </div>
        </div>
        <div className="col-md-5 text-end pt-4">
          {data.sign && (
            <img style={{ maxWidth: '200px' }} src={data.sign} />
          )}
          <p style={{ marginTop: data.sign ? '10px' : '100px' }}>For {data.company}</p>
        </div>
      </div>

    </>

  )
}

