import { useState, useEffect, useRef, useLayoutEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiInput, TableZero, UiButton, BgLoader, InLoader } from "../ui";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { IoIosSend } from "react-icons/io";
import InfiniteScroll from 'react-infinite-scroll-component';

import { Helmet } from "react-helmet";


export function UpdateItem(props) {

  const { content, key1, submitFeedback } = props;
  const [input_val, setInputval] = useState('');
  const [loader, setLoader] = useState(false);
  return (
    <div key={key1} className="mb-2">
      <div className="border border-bottom-0 p-3 rounded-top">
        <div className="d-flex justify-content-between mb-1">
          <div><span className="badge text-bg-warning">{content.type}</span></div>
          <span className="text-secondary">{content.date}</span>
        </div>
        <h3>{content.title}</h3>
        {content.photo && <img src={content.photo} className="w-100 mb-2 rounded" alt="" />}
        <div className="text-secondary" dangerouslySetInnerHTML={{
            __html: content.description
          }}></div>
        {content.url && <a className={`btn btn-primary w-100 mt-3`} href={content.url} target="_blank" rel="noreferrer">{content.cta}</a>}
      </div>
      <div className="bg-light p-3 border border-top-0 rounded-bottom">
        <div className="text-center mb-3">
          <span className={`c-pointer hzoom d-inline-block mx-2 ${(content.rating && content.rating !== 'positive') && 'pgrayscale'}`} onClick={() => submitFeedback(content.id, '', 'positive')}>
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 64 64"><circle cx={32} cy={32} r={30} fill="#ffdd67"></circle><path fill="#664e27" d="M49.7 34.4c-.4-.5-1.1-.4-1.9-.4H16.2c-.8 0-1.5-.1-1.9.4C10.4 39.4 15 54 32 54s21.6-14.6 17.7-19.6"></path><path fill="#4c3526" d="M33.8 41.7c-.6 0-1.5.5-1.1 2c.2.7 1.2 1.6 1.2 2.8c0 2.4-3.8 2.4-3.8 0c0-1.2 1-2 1.2-2.8c.3-1.4-.6-2-1.1-2c-1.6 0-4.1 1.7-4.1 4.6c0 3.2 2.7 5.8 6 5.8s6-2.6 6-5.8c-.1-2.8-2.7-4.5-4.3-4.6"></path><path fill="#ff717f" d="M24.3 50.7c2.2 1 4.8 1.5 7.7 1.5c2.9 0 5.5-.6 7.7-1.5C37.6 49.6 35 49 32 49s-5.6.6-7.7 1.7"></path><path fill="#fff" d="M47 36H17.1c-2.1 0-2.1 4-.1 4h30c2 0 2-4 0-4"></path><g fill="#664e27"><circle cx={20.5} cy={23} r={5}></circle><circle cx={43.5} cy={23} r={5}></circle></g></svg>
          </span>
          <span className={`c-pointer hzoom d-inline-block mx-2 ${(content.rating && content.rating !== 'neutral') && 'pgrayscale'}`} onClick={() => submitFeedback(content.id, '', 'neutral')}>
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 64 64"><circle cx={32} cy={32} r={30} fill="#ffdd67"></circle><g fill="#664e27"><circle cx={20.5} cy={27.6} r={5}></circle><circle cx={43.5} cy={27.6} r={5}></circle><path d="M38.9 48H25.1c-1.5 0-1.5-4 0-4h13.7c1.6 0 1.6 4 .1 4"></path></g></svg>
          </span>
          <span className={`c-pointer hzoom d-inline-block mx-2 ${(content.rating && content.rating !== 'negative') && 'pgrayscale'}`} onClick={() => submitFeedback(content.id, '', 'negative')}>
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 64 64"><circle cx={32} cy={32} r={30} fill="#ffdd67"></circle><g fill="#664e27"><circle cx={20.5} cy={26.6} r={5}></circle><circle cx={43.5} cy={26.6} r={5}></circle><path d="M23 47.6c5.8-4.8 12.2-4.8 18 0c.7.6 1.3-.4.8-1.3c-1.8-3.4-5.3-6.5-9.8-6.5s-8.1 3.1-9.8 6.5c-.5.9.1 1.9.8 1.3"></path></g></svg>
          </span>
        </div>
        {content.feedback ? <p className="my-3 text-center text-success">Your feedback is submited.</p> :
          <div className="d-flex">
            <div className="flex-fill">
              <UiInput
                parentClass="mb-0"
                type="text"
                name="feedback"
                value={input_val}
                onChange={(e) => setInputval(e.target.value)}

                placeholder="Send us your feedback"
              />
            </div>
            <div className="ms-2">
              <UiButton loading={loader} className="btn btn-outline-primary" type="button" title={<IoIosSend size="18" />} disabled={input_val ? false : true} onClick={() => { setLoader(true); submitFeedback(content.id, input_val, ''); }} hideloadmsg={true}></UiButton>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
const AppUpdates = NiceModal.create(
  ({ filter, type, header, file }) => {
    const [state, setState] = useState({});
    const [u_items, setItems] = useState([]);
    const [isNextPageLoading, setisNextPageLoading] = useState(false);
    const [hasNextPage, sethasNextPage] = useState(false);
    const [itemLoader, setItemLoader] = useState(false);
    const [elmheight, setElmheight] = useState(0);
    const elementRef = useRef(null)
    const modal = useModal();




    const location = useLocation();



    const fetchItems = () => {
      setItemLoader(true)
      instance
        .get("/load_updates")
        .then(function (response) {
          setItemLoader(false)
          setState({ ...state, ...response.data })
          setItems([...response.data.items])

          if (response.data.hasMoreItems == true) {
            sethasNextPage(true)
          } else {
            sethasNextPage(false)
          }
          setElmheight(elementRef.current.clientHeight)

        })
        .catch(function (error) { })
        .then(function () { });
    }



    const loadNextPage = () => {
      setisNextPageLoading(true)
      instance
        .get("/load_updates?page=" + u_items.length)
        .then(function (response) {


          setItems([...u_items, ...response.data.items])

          setisNextPageLoading(false)
          if (response.data.hasMoreItems == true) {
            sethasNextPage(true)
          } else {
            sethasNextPage(false)
          }

        })
        .catch(function (error) { })
        .then(function () { });
    }


    const loadMoreItems = isNextPageLoading ? () => { } : loadNextPage;


    const submitFeedback = (id, feedback, rating) => {
      var formdata = new FormData();
      formdata.append("id", id);
      if (feedback) {
        formdata.append("feedback", feedback);
      }
      if (rating) {
        formdata.append("rating", rating);
      }
      instance({
        method: "post",
        url: "/submit_feedback",
        data: formdata
      })
        .then(function (response) {
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            if (rating) {
              var arr = u_items;
              arr.filter((item) => {
                if (item.id == id) {
                  item.rating = rating;
                }
              })
              setItems([...arr]);
            }

            if (feedback) {
              var arr = u_items;
              arr.filter((item) => {
                if (item.id == id) {
                  item.feedback = feedback;
                }
              })
              setItems([...arr]);
            }

          }
        })
        .catch(function (response) { });

    }

    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    useEffect(() => {

      fetchItems()
    }, []);




    return (
      <>
        <Helmet>
        </Helmet>
        <Offcanvas placement="end" {...bootstrapDialog(modal)}>
          <Offcanvas.Header className="bg-light" closeButton>
            <Offcanvas.Title>What's new</Offcanvas.Title>
          </Offcanvas.Header>


          <Offcanvas.Body id="parentelm" className="p-0" ref={elementRef}>
            <>
              {itemLoader == true && <InLoader />}
              <>

                {state.items && u_items.length > 0 ?
                  <>

                    <InfiniteScroll
                      dataLength={u_items.length}
                      next={loadMoreItems}
                      hasMore={hasNextPage}
                      height={elmheight}
                      loader={<BgLoader />}
                      scrollableTarget="parentelm"

                    >
                      <div className="p-2">
                      {u_items.map((item, index) => (
                        <UpdateItem content={item} key={index} key1={index} submitFeedback={submitFeedback} />
                      ))}
                      </div>

                    </InfiniteScroll>
                  </>
                  : state.items && u_items.length == 0 && <div className="d-flex align-items-center justify-content-center mt-3"><TableZero title="updates"></TableZero></div>
                }
              </>
            </>

          </Offcanvas.Body>

        </Offcanvas>
      </>
    );
  }
);

export default AppUpdates;
