import { useState, useEffect, useRef, useLayoutEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiInput, TableZero, UiButton, BgLoader, InLoader } from "../ui";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { IoIosSend } from "react-icons/io";
import InfiniteScroll from 'react-infinite-scroll-component';
import formatRelative from 'date-fns/formatRelative'
import enGB from 'date-fns/locale/en-GB';
import { Helmet } from "react-helmet";
import { parse } from "date-fns";
import { MdClose, MdOutlineMoreHoriz } from "react-icons/md";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';

const formatRelativeLocale = {
  lastWeek: "dd-MM-yyyy hh:mm a",
  yesterday: "'Yesterday at ' hh:mm a",
  today: "'Today at' hh:mm a",
  tomorrow: "dd-MM-yyyy hh:mm a",
  nextWeek: "dd-MM-yyyy hh:mm a",
  other: "dd-MM-yyyy hh:mm a",
};


const locale = {
  ...enGB,
  formatRelative: (token) => formatRelativeLocale[token],
};
export function NotifItem(props) {

  const { content, key1, handleAction } = props;
  return (
    <div key={key1} className="">
      <div className={`notifitm border-bottom d-flex justify-content-between align-items-center ${content.is_read == 1 ? 'bg-white' : 'bg-highlight'}`}>
        <Link to={content.url} onClick={() => handleAction(content.id, 'mark_read')} className="p-3 d-block">
          <p className="mb-0 text-dark">{content.title}</p>
          <span className={`small ${content.is_read == 1 ? 'text-secondary' : 'text-primary'}`}>{formatRelative(parse(content.created_on, "yyyy-MM-dd HH:mm:ss", new Date()), new Date(), { locale })}</span>
        </Link>
        <div className="pe-3">
          <MdClose className="sicon c-pointer text-secondary" onClick={() => handleAction(content.id, 'clear')} title="Clear"/>
        </div>

      </div>

    </div>
  )
}
const AppNotifications = NiceModal.create(
  ({ filter, type, header, file }) => {
    const [state, setState] = useState({});
    const [u_items, setItems] = useState([]);
    const [isNextPageLoading, setisNextPageLoading] = useState(false);
    const [hasNextPage, sethasNextPage] = useState(false);
    const [itemLoader, setItemLoader] = useState(false);
    const [elmheight, setElmheight] = useState(0);
    const elementRef = useRef(null)
    const modal = useModal();



    const location = useLocation();



    const fetchItems = () => {
      setItemLoader(true)
      instance
        .get("/load_notifications")
        .then(function (response) {
          setItemLoader(false)
          setState({ ...state, ...response.data })
          setItems([...response.data.items])

          if (response.data.hasMoreItems == true) {
            sethasNextPage(true)
          } else {
            sethasNextPage(false)
          }
          setElmheight(elementRef.current.clientHeight)

        })
        .catch(function (error) { })
        .then(function () { });
    }



    const loadNextPage = () => {
      setisNextPageLoading(true)
      instance
        .get("/load_notifications?page=" + u_items.length)
        .then(function (response) {


          setItems([...u_items, ...response.data.items])

          setisNextPageLoading(false)
          if (response.data.hasMoreItems == true) {
            sethasNextPage(true)
          } else {
            sethasNextPage(false)
          }

        })
        .catch(function (error) { })
        .then(function () { });
    }


    const handleAction = (id, action) => {
      setItemLoader(true)
      var formdata = new FormData();
      formdata.append("id", id);
        formdata.append("action", action);
      
      
      instance({
        method: "post",
        url: "/update_notification",
        data: formdata
      })
        .then(function (response) {
          setItemLoader(false)
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            if (action == 'mark_read_all' || action == 'clear_all') {
              fetchItems()
            } else if (action == 'mark_read') {
              var arr = u_items;
              arr.filter((item) => {
                if (item.id == id) {
                  item.is_read = 1;
                }
              })
              setItems([...arr]);
            } else if (action == 'clear') {
              var arr = u_items;
              arr.splice(arr.findIndex(e => e.id === id),1);
              setItems([...arr]);
            }


          }
        })
        .catch(function (response) { });

    }


    const loadMoreItems = isNextPageLoading ? () => { } : loadNextPage;


    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    useEffect(() => {

      fetchItems()
    }, []);




    return (
      <>
        <Helmet>
        </Helmet>
        <Offcanvas placement="end" {...bootstrapDialog(modal)}>
          <Offcanvas.Header className="bg-light" closeButton>
            <Offcanvas.Title>Notifications
              <a
                style={{ cursor: 'pointer' }}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MdOutlineMoreHoriz className="text-secondary c-pointer ms-2" />
              </a>
              <ul className="dropdown-menu dropdown-menu-start">
                <li>
                  <button className="dropdown-item" onClick={() => handleAction('all', "mark_read_all")}>
                    Mark all as read
                  </button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => handleAction('all', "clear_all")}>
                    Clear all
                  </button>
                </li>
              </ul>
            </Offcanvas.Title>
          </Offcanvas.Header>


          <Offcanvas.Body id="parentelm" className="p-0" ref={elementRef}>
            <>
              {itemLoader == true && <InLoader />}
              <>

                {state.items && u_items.length > 0 ?
                  <>

                    <InfiniteScroll
                      dataLength={u_items.length}
                      next={loadMoreItems}
                      hasMore={hasNextPage}
                      height={elmheight}
                      loader={<BgLoader />}
                      scrollableTarget="parentelm"

                    >
                      <div className="">
                        {u_items.map((item, index) => (
                          <NotifItem content={item} key1={index} handleAction={handleAction} key={index} />
                        ))}
                      </div>

                    </InfiniteScroll>
                  </>
                  : state.items && u_items.length == 0 && <div className="d-flex align-items-center justify-content-center mt-3"><TableZero title="notifications"></TableZero></div>
                }
              </>
            </>

          </Offcanvas.Body>

        </Offcanvas>
      </>
    );
  }
);

export default AppNotifications;
