import React, { useState, useEffect, useCallback } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { UiSelect, PageHeader, UiInput, UiButton, UiTextArea, UiDatePicker, UiRSelect, PageError, BgLoader, UiActioButton, TableZero, UiClientSelect } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useDropzone } from 'react-dropzone';
import { Uploader, DocList } from "../../uploader";
import { Helmet } from "react-helmet";
import { BsUpload } from 'react-icons/bs';
import queryString from 'query-string';



export default function Expense() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  //const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  const [bg_loader, setBGLoader] = useState(false);
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [myFiles, setMyFiles] = useState([])

  const onDrop = useCallback(acceptedFiles => {
    setMyFiles([...myFiles, ...acceptedFiles])
  }, [myFiles])


  const { acceptedFiles, getRootProps, fileRejections, getInputProps } = useDropzone({
    // Note how this callback is never invoked if drop occurs on the inner dropzone
    // onDrop: files => handleSave1(files),
    onDrop,
    maxSize: 5242880,
    // maxSize: 200,
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/pdf': ['.pdf'],
      'text/csv': ['.csv'],
      'application/msword': ['.doc', '.docx'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],

    }
  });


  const defaultValues = {
    date: null,
    mode: '',
    category: '',
    notes: '',
    amount: '',
    client: '',


  };




  const removeFile = file => () => {
    const newFiles = [...myFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
  }

  const removeAll = () => {
    setMyFiles([])
  }

  const files = myFiles.map(file => (
    <div className="d-flex justify-content-between list1" key={file.path}>
      <span>{file.path} </span>
      <UiActioButton action={removeFile(file)} title="Delete">Remove File</UiActioButton>
    </div>
  ))
  const schema = yup.object().shape({
    date: yup.string().required("Select date"),
    mode: yup.object().nullable().required("Select payment mode"),
    category: yup.object().required("Select expense category"),
    notes: yup.string().max(160, "Maximum character limit is 160"),
    amount: yup.string().nullable().matches(/^[0-9.]+$/, "Enter amount")
      .required('Enter amount')
  });

  var handleSave = () => {
    setState({ ...state, loader: true });
    var form = document.querySelector("#expform");
    var data = new FormData(form);
    for (const a of myFiles) {
      data.append("file[]", a);
    }
    instance({
      method: "post",
      url: "/update_expense",
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {

        setMyFiles([])


        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['expenses'])
          toast(response.data.msg, { type: "success" });

          navigate("/expenses")

        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    getValues,
    resetField,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave();
  };


  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/get_expense?id=" + id)
      .then(function (response) {
        setBGLoader(false)
        setState({ ...state, ...response.data });
        if (response.data.status == 'success') {
          if (parsed.client) {
            reset({ ...response.data.expense, client: { value: parsed.client.split('::')[0], label: parsed.client.split('::')[1] } })
          } else {
            reset(response.data.expense)
          }
        
        } else {
          toast(response.data.msg, { type: "error" });
        }

      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {
    fetchData();
  }, [location]);

  useEffect(() => {
    return () => {
      reset(defaultValues)
    }
  }, [location]);



  useEffect(() => {
    reset(state.client)
  }, [state.client]);




  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div className="alert alert-danger mt-3" role="alert" key={file.path}>
      <b>{file.path}</b><br></br>

      {errors.map(e => (
        <span key={e.code}>○ {e.code == 'file-invalid-type' && 'Invalid file type'}{e.code == 'file-too-large' && 'File is large (Max. 5MB)'} </span>
      ))}

    </div>
  ));


  const expcatModal = useModal('expense-category');

  const addCategory = () => {
    expcatModal.show().then((res) => {
      setState({ ...state, categories: [...state.categories, res.category] })
      reset({ ...getValues(), category: res.category })
    });
  }


  const paymentModeModal = useModal('payment-mode');

  const addPaymentMode = () => {
    paymentModeModal.show().then((res) => {
      setState({ ...state, modes: [...state.modes, res.mode] })
      reset({ ...getValues(), mode: res.item })
    });
  }



  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }



  return (
    <>
      <Helmet>
        <title>
          {id == 'new' ? 'New' : 'Edit'} Expense | Practive
        </title>
      </Helmet>
      <div>
        {state.status ? (
          <div>

            <PageHeader title={`${id == 'new' ? 'New' : 'Edit'} Expense`} parent="Expenses" link="/expenses">

            </PageHeader>



            {bg_loader == true ? <BgLoader /> : (
              <>
                <form id="expform" onSubmit={handleSubmit(onSubmitHandler)} noValidate={"novalidate"}>

                  <div className="card card-default mb-4">
                    <div className="card-header">
                      Basic Details
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6">

                          <Controller
                            name="date"
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState,
                            }) => (
                              <UiDatePicker
                                label="Date"
                                required={true}
                                onChange={onChange}
                                default_val={value}
                                message={errors.date?.message}
                                dateFormat="dd-MM-yyyy"
                                ref={ref}
                                name={name}
                              />
                            )}
                          />
                        </div>
                        <div className="col-sm-6">
                          <Controller
                            name="category"
                            control={control}
                            render={({ field }) => (

                              <UiRSelect
                                {...field}
                                required={true}
                                options={state.categories}
                                className=""
                                message={errors.category?.message}
                                label="Select category"
                                addnew={1}
                                handleNew={() => addCategory()}

                              />

                            )}
                          />
                        </div>
                      </div>



                      <input type="hidden" defaultValue={id} name="id" {...register("id")} />


                      <div className="row">
                        <div className="col-md-4">
                          <Controller
                            name="client"
                            control={control}
                            render={({ field }) => (

                              <UiClientSelect
                                {...field}
                                className=""
                                message={errors.client?.message}
                                label="Client"

                              />

                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <UiInput
                            label="Amount"
                            type="text"
                            required={true}
                            icon="inr"
                            name="amount"
                            {...register("amount")}
                            message={errors.amount?.message}
                          />
                        </div>


                        <div className="col-md-4">
                          <Controller
                            name="mode"
                            control={control}
                            render={({ field }) => (

                              <UiRSelect
                                {...field}
                                required={true}
                                options={state.modes}
                                className=""
                                message={errors.mode?.message}
                                label="Payment Mode"
                                addnew={1}
                                handleNew={() => addPaymentMode()}

                              />

                            )}
                          />
                        </div>
                      </div>




                      <UiTextArea
                        label="Notes"
                        name="notes"
                        message={errors.notes?.message}
                        {...register("notes")}
                      />






                    </div>
                  </div>





                  <div className="row">

                    <div className="col-sm-6">

                      <div className="card card-default mb-4">
                        <div className="card-header">
                          Upload
                        </div>
                        <div className="card-body">
                          <div className="">
                            <div {...getRootProps({ className: 'dropzone uploadbox' })} >
                              <input {...getInputProps()} />
                              <span className="fs-3 text-primary">
                                <BsUpload />
                              </span>
                              <p className="text-dark pe-none">Drop files here or click to upload.</p>
                              <small className="text-muted">Maximum File Size: 5 MB  |  File Format: CSV, XLS, XLSX, DOC, PDF, JPG</small>
                            </div>
                          </div>

                          {files && files.length > 0 && (

                            <div className="mt-3">
                              <b>Selected files</b>
                              {files}
                            </div>
                          )}
                          {fileRejectionItems}
                        </div>
                      </div>
                    </div>
                    {id != 'new' &&
                      <div className="col-sm-6">
                        <div className="card card-default mb-4">
                          <div className="card-header">
                            Uploaded Files
                          </div>
                          <div className="card-body">
                            {state.files && state.files.length > 0 ? (
                              <DocList category="expense" setData={(e) => setState({ ...state, files: e })} data={state.files} can_edit={1} />
                            ) : <TableZero title="Files" />}
                          </div>
                        </div>
                      </div>
                    }
                  </div>




                  <UiButton loading={state["loader"]} title="Save" />
                </form>
              </>)}
          </div>

        ) : <BgLoader />}
      </div>
    </>
  );
}
