import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, Toastcontent, UiButton, UiSelect } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const QuoteStatus = NiceModal.create(
  ({ quote_id, tval }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      status: yup.string().nullable().required("Select status"),

    });






    const {
      register,
      handleSubmit,
      control,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const watch_status = watch('status');

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {


      setState({ ...state, loader: true });
      var form = document.querySelector("#quotesstatusform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_quote_status",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Quote" />, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Quote" />, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });

    };


    useEffect(() => {
      reset({ status: tval })

    }, [tval]);


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);



    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Status</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="quotesstatusform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>




            <UiSelect
              required={true}
              label="Status"
              name="status"
              options={["Pending", "Accepted", "Rejected"]}
              message={errors.status?.message}
              {...register("status")}
            />



            {quote_id && (<input type="hidden" value={quote_id} name="quote"></input>)}



          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default QuoteStatus;
