import React, { useState, useEffect, useCallback } from "react";
import { instance } from "../../axios";
import {
  PageHeader,
  UiInput,
  UiButton,
  UiToggle,
  UiRSelect,
  UiTextArea,
  PageError,
  UiSelect,
  BgLoader,
} from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

export default function AttendanceSettings() {


  const schema = yup.object().shape(
    {

      


    },
    []
  );
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  useEffect(() => {
    instance
      .get("/attendance_settings")
      .then(function (response) {
        setState({ ...state, ...response.data });
      })
      .catch(function (error) { })
      .then(function () { });
  }, []);

  useEffect(() => {
    const optns = [];
    if (state.settings && state.settings.weekend_days) {
      state.settings.weekend_days.split(',').map((option) => { optns.push({ label: option, value: option }) })
    }

    reset({ ...state.settings, weekend: optns });
  }, [state]);

  var SaveSettings = (e) => {
    showLoader({ ...loader, loader: true });
    var form = document.querySelector("#attsettingsform");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/update_attendance_settings",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader({ ...loader, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    SaveSettings();
    //reset();
  };

  if(state.status == 'error'){
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }
  const weekday = ['Friday', 'Saturday', 'Sunday'];
  const weekdays = [];

  weekday.map(WeekGen);

  function WeekGen(val, index, array) {
    return weekdays.push({ label: val, value: val });
  }
  return (
    <>
      <Helmet>
        <title>
          Attendance Settings | Practive
        </title>
      </Helmet>
      <div>
        {state.settings ? (
          <div>
            <PageHeader title="Attendance Settings" parent="Settings" link="/settings">

            </PageHeader>

            <form id="attsettingsform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
              <div className="card card-default mb-4">
                <div className="card-body">

            

                  <Controller
                    name="weekend[]"
                    control={control}
                    render={({ field }) => (

                      <UiRSelect
                        {...field}
                        isMulti
                        hideSelectedOptions= {false}
                        options={weekdays}
                        className=""
                        message={errors.weekend?.message}
                        label="Weekend Days"
                      />

                    )}
                  />

                </div>
              </div>



              <UiButton loading={loader["loader"]} title="Save" />
            </form>
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
}
