import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiSelect, UiRSelect, UiDatePicker, UiClientSelect, UiButton, UiInput, UiToggle, BgLoader, UiPopover, Toastcontent } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation, Link } from "react-router-dom";




const ClientPackage = NiceModal.create(
  ({ id, client, is_bulk }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const [bg_loader, setBGLoader] = useState(false);



    const schema = yup.object().shape({
      is_bulk: yup.bool(),
      client: yup.array().min(1, 'Client is required')
        .of(
          yup.object().shape({
            label: yup.string(),
            value: yup.string().required(),
          })).required("Client is required"),
      package: yup.object().nullable().required("Select package"),
      period: yup.string().when(['package', 'is_bulk'], {
        is: (packageval, is_bulk) => packageval && packageval.freq == 'Yearly' && is_bulk != 1,
        then: (rule) => rule.required("Select year")
      }),
      auto_billing: yup.bool(),
      billing_date: yup.string().nullable().when(['auto_billing', 'billing_date'], {
        is: (auto_billing, billing_date) => auto_billing === true || (billing_date && billing_date.length > 0),
        then: yup.string().nullable().required('Select billing date')
      }),
      billing_amount: yup.string().nullable().when(['auto_billing', 'billing_amount'], {
        is: (auto_billing, billing_amount) => auto_billing === true || (billing_amount && billing_amount.length > 0),
        then: yup.string().nullable().matches(/^[0-9]+$/, "Enter default billing amount").required('Enter default billing amount')
      }),
    }, [['billing_date', 'billing_date'], ['billing_amount', 'billing_amount']]);
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
      resetField,
      watch,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData(data);
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });


      instance({
        method: "post",
        url: "/update_client_package",
        data: e,
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "success" });
            modal.resolve({ resolved: true, group: response.data.group });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };



    const fetchData = () => {
      setBGLoader(true)
      instance
        .get("/get_client_package?id=" + id)
        .then(function (response) {
          setState({ ...state, ...response.data });
          setBGLoader(false)
          if (response.data.status == 'success') {

            reset(response.data.package)
          } else {
            toast(response.data.msg, { type: "error" });
          }

        })
        .catch(function (error) { })
        .then(function () { });

    }


    useEffect(() => {


      fetchData()

    }, []);


    useEffect(() => {

      if (client) {
        var clientStr = client.toString();
        var arr;
        if (clientStr.includes(',') && clientStr.split(',').length > 1) {
          arr = clientStr.split(',');
        } else {
          arr = [clientStr];
        }

        const defaultValues1 = {
          client: (arr.map(x => ({ value: parseInt(x) }))),

        };
        reset(defaultValues1)
      }
    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    const watchpackage = watch('package');
    const watchAutobilling = watch("auto_billing");
    const watchClients = watch("client");




    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{is_bulk ? "Assign Package" : id !== 'new' ? "Edit Client Package" : "New Client Package"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        {bg_loader == true ? <BgLoader /> : (
          <form id="clientpackageform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <BootstrapModal.Body>
              <Controller
                name="client"
                control={control}
                render={({ field }) => (

                  <UiClientSelect
                    {...field}
                    required={true}
                    disabled={id == 'new' ? false : true}
                    active="1"
                    message={errors.client?.message}
                    label="Client"
                    mode='multiple'
                    maxTagCount='responsive'
                    fetch_onload={true}
                  />

                )}
              />


              <Controller
                name="package"
                control={control}
                render={({ field }) => (

                  <UiRSelect
                    {...field}
                    isDisabled={id == 'new' ? false : true}
                    required={true}
                    options={state.packages}
                    className=""
                    message={errors.package?.message}
                    footer_text={["To create package, go to Settings > ", <Link to="/packages">Packages</Link>]}
                    label="Package"
                  />

                )}
              />

              <div className={`${is_bulk ? 'd-none' : ''}`}>
                {watchpackage && watchpackage.freq == 'Yearly' && (



                  <UiSelect
                    label="Select Year"
                    disabled={id == 'new' ? false : true}
                    required={true}
                    options={state.years}
                    //defaultValue={val.value}
                    name="period"
                    message={errors.period?.message}
                    {...register('period')}
                  />


                )}

                {watchpackage && watchpackage.freq == 'Quarterly' && (
                  <>


                    <UiSelect

                      required={true}
                      label="Select Quarter"
                      options={['Apr-Jun', 'Jul-Sep', 'Oct-Dec', 'Jan-Mar']}
                      //defaultValue={val.value}
                      name="quarter"
                      message={errors.quarter?.message}
                      {...register('quarter')}
                    />

                    <Controller
                      name="year"
                      control={control}

                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDatePicker
                          label="Select Year"
                          onChange={onChange}
                          required={true}

                          default_val={value}
                          message={errors.year?.message}
                          dateFormat="yyyy"
                          showYearPicker
                          // showQuarterYearPicker
                          ref={ref}
                          name={name}
                        />
                      )}
                    />

                  </>
                )}

              </div>

              <div className="row">
                <div className="col">

                  <Controller
                    name="billing_date"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDatePicker
                        label="Billing Date"
                        required={watchAutobilling ? true : false}
                        onChange={onChange}
                        default_val={value}

                        message={errors.billing_date?.message}
                        dateFormat="dd-MM-yyyy"
                        ref={ref}
                        name={name}
                      />
                    )}
                  />

                </div>
                <div className="col">



                  <UiInput
                    label="Default Billing Price"
                    type="text"
                    required={watchAutobilling ? true : false}
                    icon="inr"
                    name="billing_amount"
                    {...register("billing_amount")}
                    message={errors.billing_amount?.message}
                  />

                </div>
              </div>

              <UiToggle
                id="autobilling"
                name="auto_billing"
                title="Create invoice automatically"
                {...register("auto_billing")}
              />

              {id == 'new' &&
                <div className="d-flex">
                  <UiToggle
                    id="generatetasks"
                    name="generate_tasks"
                    title="Generate tasks for previous periods"
                    disabled={watchClients && watchClients.length > 1}
                    {...register("generate_tasks")}
                  />
                  <UiPopover msg="This feature is available only for single client." />
                </div>
              }

              <input type="hidden" value={id} name="id" {...register("id")}></input>
              <input type="hidden" value={is_bulk ? 1 : 0} name="is_bulk" {...register("is_bulk")}></input>




            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Save" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>
        )}
      </BootstrapModal>
    );
  }
);

export default ClientPackage;
