import React, { useState, useEffect, useCallback } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { UiSelect, PageHeader, UiInput, UiButton, UiToggle, UiDatePicker, OverlayLoader, PageError, BgLoader, UiActioButton, TableZero, UiClientSelect } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useDropzone } from 'react-dropzone';
import { Uploader, DocList } from "../../uploader";
import { Helmet } from "react-helmet";
import { BsUpload } from 'react-icons/bs';
import { Table } from 'antd';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';



export function PackageSettings() {
  const [pstate, setPState] = useOutletContext();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  //const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  const [bg_loader, setBGLoader] = useState(false);
  const location = useLocation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);




  const defaultValues = {
    date: null,
    mode: '',
    category: '',
    notes: '',
    amount: '',
    client: '',


  };

  const columns = [
    {
      title: 'Service',
      dataIndex: 'name',

    }




  ];



  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const schema = yup.object().shape({
    frequency: yup.string().required("Select frequency"),
    title: yup.string().required("Enter package name"),
    default_price: yup.string().nullable().matches(/^[0-9.]+$/, "Enter amount")
      .required('Enter amount'),

    sac: yup.string().nullable().when('sac', {
      is: (value) => value?.length,
      then: (rule) => rule.matches(/^[0-9]+$/, "Enter 6 digit SAC code")
        .min(6, "Enter 6 digit SAC code")
        .max(6, "Enter 6 digit SAC code"),
    }),
  }, [['sac', 'sac']]);

  var handleSave = () => {
    setState({ ...state, loader: true });
    var form = document.querySelector("#expform");
    var data = new FormData(form);
    data.append('services', selectedRowKeys.toString())
    instance({
      method: "post",
      url: "/update_package",
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {



        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          setPState({ ...pstate, refresh: true })
          if(!pstate.package){
            navigate("/package/"+response.data.pid, { replace: true });
          }

        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    getValues,
    resetField,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave();
  };








  useEffect(() => {
    return () => {
      reset(defaultValues)
    }
  }, [location]);


  useEffect(() => {
    if (pstate.package) {
      reset(pstate.package)
    }
    if (pstate.selected) {
      setSelectedRowKeys(pstate.selected)
    }
  }, []);









  return (
    <>

      <div>
        {pstate.status ? (
          <div>





            {bg_loader == true ? <BgLoader /> : (
              <>
                <form id="expform" onSubmit={handleSubmit(onSubmitHandler)} noValidate={"novalidate"}>

                  <div className="row">
                    <div className="col-md-6">


                      <div className="card card-default mb-4">
                        <div className="card-header">
                          Basic Details
                        </div>
                        <div className="card-body">


                          <UiInput
                            label="Name"
                            type="text"
                            required={true}
                            name="title"
                            {...register("title")}
                            message={errors.title?.message}
                          />

                          <UiSelect
                            name="frequency"
                            required={true}
                            label="Frequency"
                            message={errors.frequency?.message}
                            options={["Yearly"]}
                            {...register("frequency")}
                          />





                          <input type="hidden" defaultValue={pstate.package ? pstate.package.id : 'new'} name="id" {...register("id")} />


                          <UiInput
                            label="Default Billing Amount"
                            type="text"
                            required={true}
                            icon="inr"
                            name="default_price"
                            {...register("default_price")}
                            message={errors.default_price?.message}
                          />



                          <div className="row">
                            <div className="col-6">
                              <UiInput
                                label="SAC Code"
                                type="text"
                                name="sac"
                                message={errors.sac?.message}
                                {...register("sac")}
                              />
                            </div>
                            <div className="col-6">
                              <UiSelect
                                name="tax_rate"
                                label="GST %"
                  
                                message={errors.tax_rate?.message}
                                options={["5%", "12%", "18%", "28%"]}
                                {...register("tax_rate")}
                              />
                            </div>
                          </div>




                        </div>
                      </div>

                    </div>
                    <div className="col-md-6">
                      <div className="card card-default mb-4">
                        <div className="card-header">
                          Services
                        </div>
                        <div className="card-body">

                          <SimpleBar style={{ height: 500 }}>
                            <Table
                              locale={{
                                emptyText: (<TableZero title="Services" />)
                              }}
                              pagination={false}
                              virtual={true}
                              size="small"
                              columns={columns}
                              rowKey={(record) => record.id}
                              dataSource={pstate.services}
                              //loading={{ indicator: <BgLoader /> }}
                              rowSelection={rowSelection}
                              scroll={{
                                x: 450,

                              }}
                            />
                          </SimpleBar>
                        </div>
                      </div>
                    </div>
                  </div>








                  <UiButton loading={state["loader"]} title="Save" />
                </form>
              </>)}
          </div>

        ) : <BgLoader />}
      </div>
    </>
  );
}



export default function Package() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader(true)
        instance
          .get("/update_package?delete=" + id)
          .then(function (response) {
            showLoader(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['packages'])
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }

  const fetchData = () => {
    showLoader(true)
    instance
      .get("/get_package?id=" + id)
      .then(function (response) {
        showLoader(false)
        setState({ ...state, ...response.data });
        if (response.data.status == 'success') {


        } else {
          toast(response.data.msg, { type: "error" });
        }

      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (state.refresh) {
      fetchData();
    }
  }, [state.refresh]);

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      <Helmet>

        <title>
          {state.package ? state.package.title : 'New Package'} | Packages | Practive
        </title>


      </Helmet>
      <div>
        {state.status ? (
          <div>
            <PageHeader title={`${state.package ? state.package.title : 'New Package'}`} parent="Packages" link="/packages">
              <button className="btn btn-outline-danger" onClick={() => handleDelete(id)}>Delete</button>

            </PageHeader>


            <div className="tabs mb-3">

              <NavLink to="" end className="tab-itm">Settings</NavLink>
              {state.package && <NavLink to="clients" className="tab-itm">Clients <span className="badge bg-primary rounded-pill">{state.package.assigned_clients}</span></NavLink>}

            </div>

            <Outlet context={[state, setState]} />

            <OverlayLoader visible={loader} />
          </div>
        ) : <BgLoader />}
        

      </div>
    </>

  );
}