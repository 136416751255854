import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { PageHeader, UiToggle, UiInput, UiButton, UiRSelect, BgLoader, PageError } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NavLink, useNavigate, useParams, Link, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useQueryClient } from 'react-query';



export function AccountDetails() {
  const [pstate, setPState] = useOutletContext();

  const [state, setState] = useState({});

  const BasicDetail = (props) => {
    const { label, ...other } = props;
    return (
      <>
        {other &&
          <div className="row mb-2 d-flex align-items-center">
            <div className="col-lg-4 text-secondary">
              {label}
            </div>
            <div className="col-lg-8" {...other}>

            </div>
          </div>
        }
      </>
    )
  }









  return (

    <div>
      <div className="row">
        <div className="col-md-6">
          <BasicDetail label="Name" ><b>{pstate.account.name}</b></BasicDetail>
          <BasicDetail label="Company" ><b>{pstate.account.company}</b></BasicDetail>
          <BasicDetail label="Mobile No." ><b>{pstate.account.mobile}</b></BasicDetail>
          <BasicDetail label="Email" ><b>{pstate.account.email}</b></BasicDetail>
          <BasicDetail label="Subscription Status"  >
            {pstate.account.is_active == 1 ? <span className="text-success fw-bold">Active</span> : (
              <>
                <span className="text-danger fw-bold">Expired</span><Link to="/pricing/renew" className="btn btn-primary btn-sm ms-2">Renew</Link>
              </>
            )}
          </BasicDetail>
          <BasicDetail label="Plan" >
            <b className="me-2">{pstate.account.plan}</b> {pstate.account.is_active == 1 && <div className="d-inline-block"><Link to="/pricing/renew" className="btn btn-primary btn-sm me-2">{pstate.account.is_trial == 1 ? 'Buy Plan' : 'Renew'}</Link> {pstate.account.is_trial == 0 && <Link to="/pricing/upgrade" className="btn btn-primary btn-sm me-2">Upgrade</Link>}</div>}
          </BasicDetail>
          {pstate.account.is_trial == 0 &&
          <BasicDetail label="Subscription Duration" ><b>{pstate.account.duration} months</b></BasicDetail>
}
          <BasicDetail label="Renewal Date"  ><b>{pstate.account.expire_on}</b></BasicDetail>



        </div>

      </div>


    </div>
  )

}

export default function AccountSettings() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});



  const fetchData = () => {
    instance
      .get("/account")
      .then(function (response) {
        setState({ ...state, ...response.data });
        if (response.data.status == 'success') {

        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {
    fetchData();
  }, []);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }



  return (
    <>
      <Helmet>
        <title>
          Account | Practive
        </title>
      </Helmet>
      <div>
        {state.account ? (
          <div>

            <PageHeader title="Account" parent="Settings" link="/settings">

            </PageHeader>




            <div className="tabs mb-3">
              <NavLink to="" end className="tab-itm">Details</NavLink>
              <NavLink to="billing-history" className="tab-itm">Billing History</NavLink>

            </div>
            <div className="card card-default">
              <div className="card-body">
                <Outlet context={[state, setState]} />
              </div>
            </div>
          </div>

        ) : <BgLoader />}


      </div>
    </>
  );
}
