import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { OverlayLoader, PageError, UiToggle, PageHeader, UiTFind, BgLoader, TableZero, UiButton, InLoader, UiActioButton } from "../../ui";
import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { GoPlus } from "react-icons/go";

export function CustomFields() {
  let navigate = useNavigate();

  const userModal = useModal('customfield');
  const location = useLocation();
  const [state, setState] = useState({});
  const [bg_loader, setBGLoader] = useState(false);
  const [filteredItems, setFilteredItems] = useState({});
  const [loader, showLoader] = useState({});
  const showAddModal = (row) => {

    userModal.show({ data: row }).then((res) => {
      fetchCF();
    });


  }

  const deleteTag = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setBGLoader(true)
        instance
          .get("/update_custom_field?delete=" + id)
          .then(function (response) {
            setBGLoader(false)
            if (response.data.status == 'success') {
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }

            fetchCF();

          })

      }
    })


  }

  const fetchCF = () => {
    setBGLoader(true)
    instance
      .get("/custom_fields")
      .then(function (response) {
        setBGLoader(false)
        setState({ ...state, ...response.data });
        const filteredItems1 = response.data.custom_fields.filter(
          item => item.field_name && item.field_name.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(filteredItems1);

      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchCF();
  }, []);

  const [filterText, setFilterText] = React.useState('');

  useEffect(() => {
    if (state.custom_fields) {
      const filteredItems1 = state.custom_fields.filter(
        item => item.field_name && item.field_name.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);

  const style = {

    // Adding media query..
    '@media (min-width: 500px)': {
      display: 'none',
    },
  };


  const columns = [
    {
      name: 'Custom Field',
      selector: row => row.field_name,
      sortable: true,
      cell: row => (<b className="text-dark">{row.field_name}</b>)
    },
    {
      name: 'Field Type',
      sortable: true,
      selector: row => row.field_type,
      width: '140px'
    },
    {
      name: 'Required',
      sortable: true,
      selector: row => row.required,
      width: '140px'
    },
    {
      name: 'Options',
      selector: row => row.options,
      cell: row => (<span className="text-truncate" title={row.options}>{row.options}</span>)
    },
    {
      name: '',

      width: (window.innerWidth > 768 ? '0px' : '130px'),
      selector: row => row.status,
      cell: row => (
        <div className="tableaction">
          <UiActioButton title="Edit" action={() => showAddModal(row)} />
          <UiActioButton title="Delete" action={() => deleteTag(row.id)} />

        </div>
      )
    },
  ];







  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>

      <div className="">
        {state.custom_fields ? (
          <div>

            <div className="card card-default position-relative">
              {bg_loader == true && <InLoader />}
              <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="">
                  <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
                </div>
                <UiButton className="btn btn-primary ms-2" onClick={() => showAddModal()} title="New" icon="add"></UiButton>
                </div>
              </div>
              <div className="card-body pt-0 tablecard">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  noDataComponent={<TableZero title="Custom Fields" />}
                />

              </div>
            </div>
          </div>

        ) : <BgLoader />}
      </div>
    </>
  );
}


export function ClientGenSettings() {
  const [pstate, setPState] = useOutletContext();
  const navigate = useNavigate()
  const [state, setState] = useState({});
  const schema = yup.object().shape({
    allow_dup_client: yup.bool(),

  }, []);
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmitHandler = async (data) => {
    handleSave();
  };
  var handleSave = (e) => {
    setState({ ...state, loader: true });
    var form = document.querySelector("#clientgensettingsfrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/update_client_settings",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {

          toast(response.data.msg, { type: "success" });

        }
      })
      .catch(function (response) { });
  };

  useEffect(() => {
    instance
      .get("/client_settings")
      .then(function (response) {
        setState({ ...state, ...response.data });
        reset({ ...response.data.settings });
      })
      .catch(function (error) { })
      .then(function () { });
  }, []);

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }
  return (
    <div>
      {state.settings ? (
        <form id="clientgensettingsfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>

          <div className="card card-default mb-4">
            <div className="card-body">

              <UiToggle
                id="allow_dup_client"
                name="allow_dup_client"
                title="Allow duplicates for client name"
                {...register("allow_dup_client")}
              />



            </div>

          </div>

          <UiButton loading={state["loader"]} title="Save" />
        </form>
      ) : <BgLoader />}
    </div>
  )

}

export default function ClientSettings() {
  let { id } = useParams();
  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      <Helmet>

        <title>
          Client Settings | Practive
        </title>


      </Helmet>
      <div>
        <div>
          <PageHeader title='Client Settings' parent="Settings" link="/settings">

          </PageHeader>


          <div className="tabs mb-3">

            <NavLink to="" end className="tab-itm">General Settings</NavLink>
            <NavLink to="custom-fields" className="tab-itm">Custom Fields</NavLink>
          </div>

          <Outlet context={[state, setState]} />


        </div>

        <OverlayLoader visible={loader} />

      </div>
    </>

  );
}

