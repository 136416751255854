import React, { useState, useEffect, useRef } from "react";
import { instance } from "../../axios";
import {
  PageHeader,
  UiInput,
  UiButton,
  UiToggle,
  UiRichText,
  UiTextArea,
  UiRSelect,
  PageError,
  UiSelect,
  BgLoader,
} from "../../ui";
import { QRCodeSVG } from 'qrcode.react';
import { Helmet } from "react-helmet";
import ReactToPrint from "react-to-print";
export default function BrandKit() {
  const [bg_loader, setBGLoader] = useState(false);

  const [state, setState] = useState({ count: 0 });
  const [loader, showLoader] = useState({});
  let componentRef = useRef();
  let componentRef1 = useRef();
  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/website_branding")
      .then(function (response) {
        setBGLoader(false)
        setState({ ...state, ...response.data });
        //setEntry(response.data.settings.service);
      })
      .catch(function (error) { })
      .then(function () { });
  }
  useEffect(() => {
    fetchData()
  }, []);







  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        <title>
        Website Flyer | Practive
        </title>
      </Helmet>
      <div>
        {state.settings ? (

          <div>
            {bg_loader == true ? <BgLoader /> : (
              <>

                <PageHeader title="Website Flyer" parent="Settings" link="/settings">

                </PageHeader>



                <div className="row d-flex justify-content-center">
                  <div className="col-12 col-md-6 col-lg-5 col-xl-4 mb-4">
                    <div className="card card-default mb-4">
                      <div className="card-body">
                        <div className="position-relative webbranding" ref={componentRef}>
                          <img src="https://practive.s3.ap-south-1.amazonaws.com/branding/hi.png" className="w-100" />
                          <div className="position-absolute text-white fs-5 companyname" style={{ top: '6%', left: '8%' }}>{state.settings.company}</div>
                          <div className="position-absolute text-center" style={{ width: '30%', bottom: '6%', left: '8%' }}>
                            <div className="bg-white p-2">
                              <QRCodeSVG value={`https://${state.settings.url}`} size="100%" />
                            </div>
                            <p className="text-white small mb-0 mt-2 url">{state.settings.url}</p>
                          </div>



                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                    <ReactToPrint
                      trigger={() => <button className="btn btn-primary">Print</button>}
                      content={() => componentRef.current}
                    />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                    <div className="card card-default mb-4">
                      <div className="card-body">
                        <div className="position-relative webbranding" ref={componentRef1}>
                          <img src="https://practive.s3.ap-south-1.amazonaws.com/branding/eng.png" className="w-100" />
                          <div className="position-absolute text-white fs-5 companyname" style={{ top: '6%', left: '8%' }}>{state.settings.company}</div>
                          <div className="position-absolute text-center" style={{ width: '30%', bottom: '5%', left: '8%' }}>
                            <div className="bg-white p-2">
                              <QRCodeSVG value={`https://${state.settings.url}`} size="100%" />
                            </div>
                            <p className="text-white small mb-0 mt-2 url">{state.settings.url}</p>
                          </div>



                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                    <ReactToPrint
                      trigger={() => <button className="btn btn-primary">Print</button>}
                      content={() => componentRef1.current}
                    />
                    </div>
                  </div>
                </div>


              </>
            )}
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
}
