import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { TableZero, BgLoader, UiTFind, PageError, UiButton, UiActioButton, InLoader } from "../../ui";

import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import DataTable from 'react-data-table-component';
import { format, isValid, parse } from 'date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useQuery, useQueryClient, select } from "react-query";


import { MdContentCopy } from "react-icons/md";

export default function ClientPasswords() {
  const [pstate, setPSate] = useOutletContext();

  const [state, setState] = useState({});
  const [filteredItems, setFilteredItems] = useState({});
  const [loader, showLoader] = useState({});
  const [filterText, setFilterText] = React.useState('');
  const queryClient = useQueryClient()
  const userModal = useModal('password');


  const showAddModal = (row) => {

    userModal.show({ data: row, custid: pstate.client.id }).then((res) => {
      fetchData(pstate.client.id);

    });



  }


  const importDataModal = useModal('import-data');

  const importPassword = () => {
    importDataModal.show({type:'passwords',title:'Passwords',singular:'Password'}).then((res) => {
      fetchData(pstate.client.id);
    });
  }
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader(true)
        instance
          .get("/update_password_list?delete=" + id)
          .then(function (response) {
            showLoader(false)
            if (response.data.status == 'success') {
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }

            fetchData(pstate.client.id);

          })

      }
    })


  }


  const handleShow = (id, show) => {

    const filteredItems1 = filteredItems.slice();
    if(show) {
      filteredItems1.filter(item => item.id == id)[0]['show'] = false;
    } else {
      filteredItems1.filter(item => item.id == id)[0]['show'] = true;
    }
   
    setFilteredItems(filteredItems1)
  }
  const columns = [
    {
      name: 'Login Portal',
      sortable: true,
      selector: row => row.title,
      cell: row => (<a href={row.url} target="_blank"><b>{row.title}</b></a>)

    },
    {
      name: 'Username',
      sortable: true,
      selector: row => row.login,
      cell: row => (<CopyToClipboard text={row.login}>
        <span>{row.login} <UiActioButton className="btnpulse" title="Copy"></UiActioButton></span>
      </CopyToClipboard>)
    },
    {
      name: 'Password',
      selector: row => row.password,
      cell: row => (<><CopyToClipboard text={row.password} >
        <span>{row.show ? row.password : '*********'} <UiActioButton className="btnpulse" title="Copy"></UiActioButton></span>
      </CopyToClipboard><UiActioButton  title={row.show ? 'Hide' : 'Show'} action={() => handleShow(row.id, row.show)}></UiActioButton></>)
    },

    {
      name: '',
      width: (window.innerWidth > 768 ? '80px' : '130px'),
      selector: row => row.id,
      cell: row => (<>
        {pstate.can_edit && (
          <div className="tableaction">
            {pstate.can_edit == true && <>
              <UiActioButton title="Edit" action={() => showAddModal(row)} />
              <UiActioButton title="Delete" action={() => handleDelete(row.id)} />
            </>}

          </div>

        )}
      </>
      )
    },
  ];






  useEffect(() => {
    if (state.passwords) {
      const filteredItems1 = state.passwords.filter(
        item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);


  const fetchData = (id) => {
    showLoader(true)
    instance
      .get("/password_list?id=" + id)
      .then(function (response) {
        showLoader(false)
        setState({ ...state, ...response.data });
        const filteredItems1 = response.data.passwords.filter(
          item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(filteredItems1);
      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {

    fetchData(pstate.client.id);
  }, []);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      {state.passwords ?
        <div className="card card-default">
          <div className="card-body">
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
              {pstate.can_edit && (
                <div className="mt-2 mt-sm-0" style={{minWidth:'168px'}}>
                <UiButton className="btn btn-outline-primary me-2" onClick={() => importPassword()} title="Import" ></UiButton>
                <UiButton className="btn btn-primary ms-2" onClick={() => showAddModal()} title="New" icon="add"></UiButton>
                </div>
              )}
            </div>
          </div>
          <div className="card-body pt-0 tablecard">
            <>
              {loader == true && <InLoader />}

              <DataTable
                columns={columns}
                data={filteredItems}
                noDataComponent={<TableZero title="Passwords" />}
              />

            </>

          </div>
        </div>
        : <BgLoader />}
    </>
  )

}

