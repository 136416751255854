import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, Toastcontent, UiButton, PageError, UiRSelect, BgLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const CustUsers = NiceModal.create(
  ({ cust_id }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      // day: yup.string().required("Select day of month"),

    });






    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#custusersform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_cust_users",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client"/>, { type: "error"});
          }
          if (response.data.status == "success") {
            toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client"/>, { type: "success"});
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };




    const fetchData = () => {
      instance
        .get("/cust_users?id=" + cust_id)
        .then(function (response) {
          setState({ ...state, ...response.data });
          if (response.data.status == 'success') {

            var slctd = response.data.users.filter((x) => x.selected == 1);
            reset({ users: slctd });
          } else {
            toast(response.data.msg, { type: "error" });
          }


        })
        .catch(function (error) { })
        .then(function () { });

    }

    useEffect(() => {
      fetchData();
    }, []);



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);




    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Users</BootstrapModal.Title>
        </BootstrapModal.Header>
        {state.status == 'success' ? (
          <form id="custusersform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <BootstrapModal.Body>

              {state.multi == true && (
                <UiToggle
                  id="custuserreplace"
                  name="replace"
                  title="Replace users"
                  {...register("replace")}
                />
              )}

              <Controller
                name="users[]"
                control={control}
                render={({ field }) => (

                  <UiRSelect
                    {...field}
                    className=""
                    isMulti
                    message={errors.users?.message}
                    label="Users"
                    options={state.users}



                  />

                )}
              />



              {cust_id && (<input type="hidden" value={cust_id} name="id"></input>)}



            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Save" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>
        ) : state.status == 'error' ? <PageError msg={state.msg} code={state.code} /> : <BgLoader />}
      </BootstrapModal>
    );
  }
);

export default CustUsers;
