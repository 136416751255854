import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiInput, UiSelect, TableZero, UiDatePicker, UiButton, UiRichText, UiToggle, BgLoader, PageError, InLoader, OverlayLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { rupees } from "../helper";

import { useStore } from "../state.js";
import { Table } from 'antd';


const InvoiceExpenses = NiceModal.create(
  ({ id, selected }) => {
    const [state, setState] = useState({});
    const [loader, showLoader] = useState(false);
    const modal = useModal();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);





    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    const fetchData = (id) => {
      showLoader(true)
      instance
        .get("/fetch_invoice_expenses?id=" + id + "&exclude="+selected.toString())
        .then(function (response) {
          setState({ ...state, ...response.data });
          showLoader(false)
        })
        .catch(function (error) { })
        .then(function () { });
    }


    const columns = [
      {
        title: 'Date',
        width: '60px',
        dataIndex: 'date',
        render: (text, row) => (<span className="">{row.date}</span>),


      },
      {
        title: 'Expense',
        width: '100px',
        dataIndex: 'expense',
        render: (text, row) => (<span className="">{row.title}</span>),


      },
      {
        title: 'Amount',
        width: '80px',
        dataIndex: 'amount',
        render: (text, row) => (<span className="">{rupees(row.amount)}</span>),


      },
    ]

    const onSelectChange = (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: onSelectChange,
    };

    const addExpenses = () => {
      var exp_arr = []
      state.items.forEach(element => {
        if (selectedRowKeys.includes(element.id)) {
          exp_arr.push({ 'title': element.title, 'task': '', 'id': element.id, 'gst': 0, 'period': '', 'type': 'expense', 'selected': 1, 'amount': element.amount, 'price': element.amount, 'sac': '', 'description': '', 'isSaved': 0 })

        }
      });
      
      modal.resolve({ resolved: true, data: exp_arr })
      modal.hide()
    }

    useEffect(() => {

      fetchData(id)



    }, []);

    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Unbilled Expenses</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body className="p-0">
          <div className="position-relative">


            {state.items ? (

              <>
                {loader &&
                  <InLoader />}
                <Table
                  locale={{
                    emptyText: (<TableZero title="Expenses" />)
                  }}

                  size="small"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={state.items}
                  loading={{ indicator: <BgLoader />, spinning: loader }}
                  pagination={false}
                  rowSelection={rowSelection}
                  scroll={{
                    x: 300,

                  }}
                />
              </>
            ) : <BgLoader />}
          </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <UiButton title="Add" disabled={selectedRowKeys.length > 0 ? false : true} onClick={() => addExpenses()} />
          <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
        </BootstrapModal.Footer>

      </BootstrapModal>
    );
  }
);

export default InvoiceExpenses;
