import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BgLoader, UiButton, UiInput, UiRSelect, UiSelect, UiTextArea, OverlayLoader, InLoader } from "../../ui";
import { LoginUser, setStorage } from "../../helper";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import logoimg from "../../practive.svg";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { rupees } from "../../helper"
import Range from '@atlaskit/range';
import Accordion from 'react-bootstrap/Accordion';
import { sub } from "date-fns";
import { MdDiscount } from 'react-icons/md'



const Subscribe = ({ ...props }) => {
  let navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(5);
  const [price, setPrice] = useState();
  const [coupon, setCoupon] = useState({});
  const [slabs, setSlabs] = useState([]);


  const [inner_loader, setInnerLoader] = useState(false);
  const [in_loader, setInLoader] = useState(false);
  let { id } = useParams();
  const schema = yup.object().shape(
    {
      company: yup.string().required("Company name is required").max(60, 'Maximum character limit is 60'),
      i_agree: yup.boolean().oneOf([true], 'Required'),
      name: yup.string().required("Your name is required").max(35, 'Maximum character limit is 35'),
      pg: yup.string().nullable().required("Please select payment option"),
      state: yup.string().required("Select state"),
      email: yup.string().email().required("Email address is required"),
      mobile: yup.string().nullable().matches(/^[0-9]+$/, "Enter 10 digit mobile number")
        .min(10, "Enter 10 digit mobile number")
        .max(10, "Enter 10 digit mobile number"),

      gstin: yup
        .string()
        .nullable()
        .when("gstin", {
          is: (value) => value?.length,
          then: (rule) =>
            rule
              .matches(
                /^[0-9]{2}[0-9A-Za-z]{13}$/,
                "Enter valid GST number"
              )
              .required("Enter GST number"),
        }),
    },
    [["gstin", "gstin"]]
  );

  const schema2 = yup.object().shape(
    {
      code: yup.string().required("Promo code is required").max(35, 'Maximum character limit is 35'),

    },
    []
  );
  const [state, setState] = useState({});




  var initPayment = () => {
    setInnerLoader(true)
    var form = document.querySelector("#pgbillingform");
    var data = new FormData(form);
    instance({
      method: "post",
      url: "/init_signup",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setInnerLoader(false)
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          setState({ ...state, paymentform: true })
          handlePay(response.data.url)
        }
      })
      .catch(function (response) { });
  };


  var checkCoupon = () => {
    setInnerLoader(true)
    var form = document.querySelector("#couponform");
    var data = new FormData(form);
    instance({
      method: "post",
      url: "/coupon_check",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setInnerLoader(false)
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          //setState({ ...state, paymentform: true })
          // handlePay(response.data.url)
          setCoupon({ code: response.data.code, percent: response.data.percent })

        }
      })
      .catch(function (response) { });
  };



  const processTotal = () => {
    var subtotal = price * value;
    var discount = 0;
    if (coupon.percent) {
      discount = Math.round(subtotal * coupon.percent / 100)
    }

    var subtotal1 = subtotal - discount;
    var tax = Math.round(subtotal1 * 18 / 100);
    var amount = subtotal1 + tax;
    setState({ ...state, amount: amount, tax: tax, subtotal: subtotal, discount: discount })
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    initPayment();

  };


  const {
    register: register2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({
    resolver: yupResolver(schema2),
  });
  const onSubmitHandler2 = async (data) => {
    checkCoupon();

  };




  useEffect(() => {
    var id1 = 1;
    if (id) {
      id1 = id;
    }

    instance
      .get("/signup_form")
      .then(function (response) {
        setState({ ...state, ...response.data });
        setSlabs(response.data.slabs)


      })
      .catch(function (error) { })
      .then(function () { });



  }, []);


  const watchduration = watch('duration');
  const i_agree = watch('i_agree');


  useEffect(() => {
    if (watchduration) {

      var subtotal = watchduration.amount;
      var subtotal1 = subtotal;
      var tax = Math.round(subtotal1 * state.tax_percent / 100);
      var amount = subtotal1 + tax;
      setState({ ...state, amount: amount, tax: tax, subtotal: subtotal, title: watchduration.d_title + ' ' + watchduration.title })
    }
  }, [watchduration]);

  useEffect(() => {
    processTotal()
  }, [coupon]);
  useEffect(() => {
   
    processTotal()
  }, [price]);



  useEffect(() => {
    if (slabs) {
      const prices = slabs
      let shouldSkip = false;

      prices.forEach(element => {
        if (shouldSkip) {
          return;
        }
        if (element.users >= value) {
            //console.log(element)
          setPrice(element.price)
          processTotal()
          shouldSkip = true;
          return;
        }
      });
    }
  }, [value, slabs]);

  window.popupCallback = function (str) {
    setInLoader(true)
    instance
      .get("/verify_payment?id=" + str)
      .then(function (response) {
        setInLoader(false)
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          navigate('/onboarding/' + response.data.token)
        }
      })
  };

  //const pgModal = useModal('payment-gateway');

  const handlePay = (e) => {
    //pgModal.show({ id: e, type: 'signup' }).then((res) => {
    //if (res.status == 'paid') {
    //  navigate('/onboarding/' + res.token)
    //}
    //});
    setInnerLoader(true);
    const popname = window.open(e, "popname", "status=1,toolbar=0,resizable=0");
  
  
  if (popname) {
    popname.focus();
    const timer = setInterval(() => {
      if (popname.closed) {
        setInnerLoader(false);
        clearInterval(timer);
      }
    }, 1000);
  } else {
    // Fallback: Redirect the current window if pop-up was blocked
    setInnerLoader(false);
    window.location.href = e;
  }

  
  }



  return (
    <>
      <Helmet>
        <title>
          Signup | Practive
        </title>

      </Helmet>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5 ">
            <div className="mb-4 text-center">
              <img className="loginlogo" src={logoimg} />
            </div>
            <OverlayLoader visible={inner_loader ? true : false} />
            {state.states ?
              <div className="card shadow-2-strong">
                {in_loader && (<InLoader />)}
                <div className="card-body">
                  <h3 className="mb-3">Sign up</h3>


            
                  <p className="form-label">Select required number of users</p>
                  <div className="text-center"><h5 className="text-primary">{value} users</h5></div>
                  <Range step={1} value={value} min={5} max={100} onChange={(value) => setValue(value)} />






                  <Accordion className="mb-3">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header size="sm">Promo Code</Accordion.Header>
                      <Accordion.Body>
                        {coupon && coupon.code ? (
                          <div className="d-flex justify-content-between">
                            <div><MdDiscount className="text-primary" /> Promo code "<b>{coupon.code}</b>" is applied</div>
                            <div>
                              <button className="btn btn-outline-danger btn-sm" onClick={() => setCoupon({})}>Remove</button>
                            </div>
                          </div>
                        ) : (
                          <form id="couponform" onSubmit={handleSubmit2(onSubmitHandler2)} noValidate>
                            <div className="row">
                              <div className="col-9">

                                <UiInput
                                  required={true}
                                  placeholder="Promo code"
                                  type="text"
                                  name="code"
                                  message={errors2.code?.message}
                                  {...register2("code")}
                                />


                              </div>
                              <div className="col-3">
                                <UiButton className="w-100 btn-outline-primary" title="Apply" />
                              </div>
                            </div>
                          </form>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                  <form id="pgbillingform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                    <div className="mb-4 border-bottom pb-2">
                      <div className="d-flex justify-content-between mb-2">
                        <span className="text-secondary">Practive {value} users subscription for 12 months</span>
                        <span>{rupees(state.subtotal)}</span>
                      </div>
                      {state.last_plan_discount > 0 && (
                        <div className="d-flex justify-content-between mb-2">
                          <span className="text-secondary">Previous Plan Amount</span>
                          <span>-{rupees(state.last_plan_discount)}</span>
                        </div>
                      )}
                      {state.discount > 0 && (
                        <div className="d-flex justify-content-between mb-2">
                          <span className="text-secondary">Discount</span>
                          <span>-{rupees(state.discount)}</span>
                        </div>
                      )}

                      <div className="d-flex justify-content-between mb-2">
                        <span className="text-secondary">GST ({state.tax_percent}%)</span>
                        <span>{rupees(state.tax)}</span>
                      </div>
                      <div className="d-flex justify-content-between mb-2 fw-bold">
                        <span className="text-secondary">Final Amount</span>
                        <span>{rupees(state.amount)}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <UiInput
                          required={true}
                          label="Your Full Name"
                          type="text"
                          name="name"
                          message={errors.name?.message}
                          {...register("name")}
                        />

                      </div>
                      <div className="col-sm-6">

                        <UiInput
                          required={true}
                          label="Company Name"
                          type="text"
                          name="company"
                          message={errors.company?.message}
                          {...register("company")}
                        />

                      </div>
                    </div>


                    <div className="row">
                      <div className="col-sm-6">
                        <UiInput
                          required={true}
                          label="Mobile"
                          type="text"
                          name="mobile"
                          message={errors.mobile?.message}
                          {...register("mobile")}
                        />

                      </div>
                      <div className="col-sm-6">

                        <UiInput
                          required={true}
                          label="Email"
                          type="email"
                          name="email"
                          message={errors.email?.message}
                          {...register("email")}
                        />

                      </div>
                    </div>
                    <UiTextArea
                      label="Address"
                      name="address"
                      message={errors.address?.message}
                      {...register("address")}
                    />

                    <div className="row">
                      <div className="col-sm-6">
                        <UiSelect
                          required={true}
                          label="State"
                          name="state"
                          options={state.states}
                          message={errors.state?.message}
                          {...register("state")}
                        />

                      </div>
                      <div className="col-sm-6">

                        <UiInput

                          label="GSTIN"
                          type="text"
                          name="gstin"
                          message={errors.gstin?.message}
                          {...register("gstin")}
                        />

                      </div>
                    </div>

                    <div className="mb-4">
                          <label className="form-label">
                            Pay Using
                          </label>
                          <div className="d-flex">
                            <div className="form-check me-4">
                              <input type="radio" className="form-check-input" value="UPI" name="pg" id="upi_pg" {...register("pg")} />
                              <label className="form-check-label" htmlFor="upi_pg">UPI</label>
                            </div>
                            <div className="form-check">
                              <input type="radio" className="form-check-input" value='Others' name="pg" id="other_pg"  {...register("pg")} />
                              <label className="form-check-label" htmlFor="other_pg">Debit/Credit Card, NET Banking</label>
                            </div>
                          

                          </div>
                          {errors.pg?.message && <small className="text-danger">{errors.pg.message}</small>}
                        </div>


                    <div className="mb-3">

                      <input type="checkbox" className="form-check-input" id="btn-check-1-outlined" autoComplete="off" value={1} name="i_agree" {...register("i_agree")} />
                      <label className="form-check-label ms-2 user-select-none" htmlFor="btn-check-1-outlined">I have read and agree to the <a href="https://www.practive.in/terms-conditions/" target="_blank">terms & conditions</a></label>
                      <div className="small text-danger">{errors.i_agree?.message}</div>
                    </div>

                    <input type="hidden" value={value} name="users" />
                    <input type="hidden" value={coupon && coupon.code ? coupon.code : ''} name="coupon" />
                    <UiButton loading={state["loader"]} className="w-100 btn-lg" title="Make Payment" />
                  </form>
                </div>
              </div>
              :
              <BgLoader />}


          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
