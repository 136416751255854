import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiRSelect, UiDatePicker } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { format, differenceInMinutes, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';

import { useNavigate, useLocation } from "react-router-dom";
import { HourMin, setStorage, getStorage } from "../helper";




const LogTime = NiceModal.create(
  ({ title, taskid, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const [hourSpent, setHoursSpent] = useState(null);
    const modal = useModal();

    const schema = yup.object().shape({
      time_input: yup.bool(),
      work: yup.string().required("Enter work details").max(200, 'Maximum character limit is 200'),
      
      start: yup.string().nullable().when('time_input', {
        is: (value) => value == 1,
        then: (rule) => rule.required("Select start time")
      }),

      end: yup.string().nullable().when('time_input', {
        is: (value) => value == 1,
        then: (rule) => rule.required("Select end time")
      }),

      spent_time: yup.string().when('time_input', { 
        is: (value) => value == 0,
        then: (rule) => rule.matches(/^(?:[0-9]|0[0-9]|1[0-9]|2[0-4])(?:\.[0-5]?[0-9]|:?[0-5]?[0-9])$/, 'Enter time in "HH:MM" format')
      }),

    });
    const {
      register,
      handleSubmit,
      control,
      getValues,
      resetField,
      watch,
      formState: { errors, dirtyFields },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      handleSave();
      //reset();
    };


    const starttime = watch('start')
    const endtime = watch('end')



    var handleSave = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#timelogform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_timelog",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };




    useEffect(() => {
      if (data) {
        reset(data);
        setHoursSpent(HourMin(data.hours))
      } else {
        reset({'time_input': getStorage('time_input') ? getStorage('time_input') : false})
      }

    }, []);


    const watch_input = watch("time_input");

    useEffect(() => {
      if (starttime && endtime) {
        var mins = (differenceInMinutes(parse(endtime, "HH:mm", new Date()), parse(starttime, "HH:mm", new Date())))

        if (mins < 1) {
          mins = 1440 + parseInt(mins)
        }

        setHoursSpent(HourMin(mins))
      }

    }, [starttime, endtime]);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Time" : "Log Time"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="timelogform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>


            <UiInput
              label="Work Details"
              type="text"
              required={true}
              name="work"
              message={errors.work?.message}
              {...register("work")}
            />

            {watch_input == false ?
              <>
                <div className="row">
                  <div className="col-5">

                    <UiInput
                      label="Daily Hours (HH:MM)"
                      type="text"
                      required={true}
                      name="spent_time"
                      placeholder="HH:MM"
                      message={errors.spent_time?.message}
                      {...register("spent_time")}
                    />

                  </div>
                </div>
                <a className="c-pointer text-primary" onClick={() => {resetField('time_input', { defaultValue: true }); setStorage("time_input", true)}}>Set Start & End Time</a>
              </>
              :
              <>
                <div className="row">
                  <div className="col-sm-4">
                    <Controller
                      name="start"
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDatePicker
                          label="Start Time"
                          required={true}
                          onChange={onChange}
                          default_val={value}
                          message={errors.start?.message}
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          showTimeSelect
                          //minTime={parse("00:00", "HH:mm", new Date())}
                          //maxTime={parse(endtime, "HH:mm", new Date())}
                          showTimeSelectOnly
                          timeIntervals={15}
                          ref={ref}
                          name={name}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Controller
                      name="end"
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDatePicker
                          label="End Time"
                          required={true}
                          onChange={onChange}
                          default_val={value}
                          message={errors.end?.message}
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          //minTime={parse(starttime, "HH:mm", new Date())}
                          //maxTime={parse("23:59", "HH:mm", new Date())}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}

                          ref={ref}
                          name={name}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-4">
                    <UiInput
                      label="Hour Spent"
                      type="text"
                      //value={starttime && endtime && `${minutesToHours(millisecondsToMinutes((parse(endtime, "HH:mm", new Date()) - parse(starttime, "HH:mm", new Date()))))}:${(millisecondsToMinutes((parse(endtime, "HH:mm", new Date()) - parse(starttime, "HH:mm", new Date())))) % 60}`}
                      value={hourSpent}
                      disabled="disabled"
                    />
                  </div>
                </div>
                <a className="c-pointer text-primary" onClick={() => {resetField('time_input', { defaultValue: false }); setStorage("time_input", false)}}>Set Hours</a>

              </>
            }

            <input type="hidden" value={taskid} name="task_id" />
            <input type="checkbox" className="d-none" name="time_input"  {...register("time_input")}/>

            {data && (<input type="hidden" value={data.id} name="id"></input>)}



          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default LogTime;
