import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { TableZero, PageError, UiButton, UiTFind, PageHeader, InLoader, BgLoader } from "../../ui";
import { useNavigate, useParams } from "react-router-dom";
import { Link, NavLink } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { Helmet } from "react-helmet";
import { GoPlus } from "react-icons/go";

export default function Packages() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()


  const [state, setState] = useState({});
  const [filteredItems, setFilteredItems] = useState({});
  const [loader, showLoader] = useState(false);





  const fetchData = () => {
    showLoader(true)
    instance
      .get("/packages")
      .then(function (response) {
        showLoader(false)
        setState({ ...state, ...response.data });
        const filteredItems1 = response.data.packages.filter(
          item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(filteredItems1);
      })
      .catch(function (error) { })
      .then(function () { });

  }
  const [filterText, setFilterText] = React.useState('');

  useEffect(() => {
    if (state.packages) {
      const filteredItems1 = state.packages.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);
  const columns = [
    {
      name: 'Package Name',
      selector: row => row.name,
      minWidth: '200px',
      //sortable: true,
      cell: row => (<Link to={`/package/${row.id}`}><b className="me-2">{row.name}</b></Link>)
    },
    {
      name: 'Frequency',
      selector: row => row.frquency,
      sortable: true,
      width: '160px',
      cell: row => (<>{row.frequency}</>)
    },
    {
      name: 'Assigned Clients',
      selector: row => row.assigned_clients,
      sortable: true,
      width: '200px',
      cell: row => (<>{row.frequency && <Link to={`/package/${row.id}/clients`}>{row.assigned_clients}</Link>}</>)
    },
   


  ];

  useEffect(() => {
    fetchData();
  }, []);

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      <Helmet>
        <title>
          Packages | Practive
        </title>
      </Helmet>
      <div>
        {state.packages ?
          <div>
            <PageHeader title="Packages">
              <UiButton className="btn btn-primary" onClick={() => navigate("/package/new")} title="New" icon="add"></UiButton>

            </PageHeader>

            <div className="tabs mb-3">

              <NavLink to="/services" end className="tab-itm">Services</NavLink>
              <NavLink to="/packages" className="tab-itm">Packages</NavLink>
            </div>

            <div className="card card-default position-relative">
              {loader && <InLoader />}
              <div className="card-body">

                <div className="">
                  <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
                </div>
              </div>
              <div className="card-body pt-0 tablecard">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  noDataComponent={<TableZero title="Packages" />}
                />
              </div>
            </div>

          </div>
          : <BgLoader />}
      </div>
    </>
  );
}
