import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiSelect, TableZero, UiInput, UiButton, UiRichText, UiToggle, BgLoader, PageError, InLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation } from "react-router-dom";
import {MdAttachFile} from "react-icons/md"


const SendEmail = NiceModal.create(
  ({ type, data }) => {
    const [state, setState] = useState({});
    const [loader, showLoader] = useState(false);
    const modal = useModal();



    const schema = yup.object().shape({

      send_to: yup.string().nullable().required('Email is required').email("Enter valid email address"),

      content: yup.string().nullable().required("Email content is required").max(800, 'Maximum character limit is 800'),
      email_subject: yup.string().nullable().required("Email subject is required").max(100, 'Maximum character limit is 100')


    });
    var handleSave = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#sendemailfrm");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/sharer",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            modal.hide();
            toast(response.data.msg, { type: "success" });
          }
        })
        .catch(function (response) { });
    };
    const {
      register,
      handleSubmit,
      setError,
      watch,
      resetField,
      clearErrors,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      handleSave();
    };


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);




    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Send Email</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="sendemailfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>

            <UiInput
              label="Send To"
              required={true}
              type="text"
              name="send_to"
              defaultValue={data.client_email}
              message={errors.send_to?.message}
              {...register("send_to")}
            />
            <UiInput
              label="Subject"
              required={true}
              type="text"
              defaultValue={data.mail_sub}
              name="email_subject"
              message={errors.email_subject?.message}
              {...register("email_subject")}
            />
            <Controller
              name="content"
              defaultValue={data.mail_body}
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <UiRichText
                  required={true}
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  label="Email Content"
                  placeholder="Type your message"
                  name="content"
                  message={errors.content?.message}
                />
              )}
            />
            <input type="hidden" name="id" value={data.id}></input>
            <input type="hidden" name="num" value={data.voucher_id}></input>
            <input type="hidden" name="type" value={type}></input>
            <div className="text-secondary">
              <MdAttachFile className="fs-4"/> pdf file attached
            </div>
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Send" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default SendEmail;
