import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiRSelect } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation } from "react-router-dom";



import Select, { StylesConfig } from "react-select";
const colourOptions = [
  { value: "blue", label: "Blue", color: "#2980b9" },
  { value: "purple", label: "Purple", color: "#8e44ad" },
  { value: "red", label: "Red", color: "#c0392b" },
  { value: "orange", label: "Orange", color: "#e67e22" },
  { value: "yellow", label: "Yellow", color: "#f1c40f" },
  { value: "green", label: "Green", color: "#27ae60" },
  { value: "grey", label: "Grey", color: "#495252" },
];

const Modal = NiceModal.create(
  ({ title, subtitle, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const modal = useModal();
    let navigate = useNavigate();
    const schema = yup.object().shape({
      tag_name: yup.string().required("Enter tag name").max(30,'Maximum character limit is 30'),
   
      color: yup.object().required("Select tag color"),
    });
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      SaveTag();
      //reset();
    };
    var SaveTag = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#updatetagfrm");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_tag",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true, item:response.data.item });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    useEffect(() => {

      if (data) {
        const filtered = colourOptions.reduce((a, o) => (o.value == data.color && a.push(o), a), [])
        var enabled;
     
        reset({ tag_name: data.tag_name, color: filtered[0] });
        setState({ ...state, editid: data.id });
      } else {
        reset({ });

      }

    }, []);

    const dot = (color = "transparent") => ({
      alignItems: "center",
      display: "flex",
      ":before": {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
      },
    });
    const colourStyles = {
      control: (styles) =>
        Object.assign(Object.assign({}, styles), { backgroundColor: "white" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return Object.assign(Object.assign({}, styles), {
          backgroundColor: isDisabled
            ? undefined
            : isSelected
              ? data.color
              : isFocused
                ? color.alpha(0.1).css()
                : undefined,
          color: isDisabled
            ? "#ccc"
            : isSelected
              ? chroma.contrast(color, "white") > 2
                ? "white"
                : "black"
              : data.color,
          cursor: isDisabled ? "not-allowed" : "default",
          ":active": Object.assign(Object.assign({}, styles[":active"]), {
            backgroundColor: !isDisabled
              ? isSelected
                ? data.color
                : color.alpha(0.3).css()
              : undefined,
          }),
        });
      },
      input: (styles) => Object.assign(Object.assign({}, styles), dot()),
      placeholder: (styles) =>
        Object.assign(Object.assign({}, styles), dot("#ccc")),
      singleValue: (styles, { data }) =>
        Object.assign(Object.assign({}, styles), dot(data.color)),
    };



    return (
      <BootstrapModal {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Tag" : "New Tag"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="updatetagfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>

            <UiInput
            required={true}
              label="Tag Name"
              type="text"
              name="tag_name"
              message={errors.tag_name?.message}
              {...register("tag_name")}
            />


            <Controller
              name="color"
              control={control}
              render={({ field }) => (

                <UiRSelect
                  {...field}
                  required={true}
                  options={colourOptions}
                  className=""
                  styles={colourStyles}
                  message={errors.color?.message}
                  label="Color"
                />

              )}
            />

          
            {state.editid && (<input type="hidden" value={state.editid} name="id"></input>)}




          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default Modal;
