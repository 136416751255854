import React, { useState, useEffect, useRef } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { UiActionDropDown, UiAvatarGroup, Toastcontent, PageError, UiButton, BgLoader, TableZero, InLoader, OverlayLoader, UiTFind } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import DataTable from 'react-data-table-component';
import { format, isValid, parse } from 'date-fns';
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { GoPlus, GoPrimitiveDot } from "react-icons/go";
import { useStore } from "../../state.js";
import { Table } from 'antd';


export default function PackageClients() {
  const [pstate, setPSate] = useOutletContext();
  const admin_state = useStore((state) => state.admin);
  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);
  const [loader1, showOVLoader] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeys1, setSelectedRowKeys1] = useState([]);
  const [filteredItems, setFilteredItems] = useState({});

  const [filterText, setFilterText] = React.useState('');


  let navigate = useNavigate();


  const UsersUI = (props) => {
    const { users, ...other } = props;
    var slctd = [];
    if (users) {
      users.forEach(element => {

        slctd.push(state.user_list[element]);
      });
    }
    return (
      <>
        {slctd && <UiAvatarGroup items={slctd}></UiAvatarGroup>}
      </>
    );
  }


  const onSelectChange = (newSelectedRowKeys, completerow) => {
    setSelectedRowKeys1(completerow);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };


  const columns = [
    {
      title: 'Clients',
      dataIndex: 'name',

    },
    {
      title: 'File No.',
      dataIndex: 'file',
      width: '150px',
    },
    {
      title: 'Default Billing Date',
      dataIndex: 'billing_date',
      render: (text, row) => (<>{row.billing_date && row.billing_date}</>),
      width: '180px',
    },
    {
      title: 'Billing Price',
      dataIndex: 'price',
      render: (text, row) => (<>{row.price > 0 && rupees(row.price)}</>),
      width: '180px',
    },
    {
      title: 'Auto Billing',
      dataIndex: 'auto_billing',
      render: (text, row) => (<span className={`${row.auto_billing == 1 ? 'text-success' : 'text-danger'}`}>{row.auto_billing == 1 ? 'Enabled' : 'Disabled'}</span>),
      width: '180px',
    },
    
    {
      title: '',
      fixed: 'right',
      width: '40px',
      render: (text, row) => (
        <div className="tableaction1">
          <UiActionDropDown newclassName="btn tablebtn" >
            <DropdownItem onClick={() => removeClients(row.id)} ><div className="d-flex" >Unassign Package from Clients</div></DropdownItem>
            <DropdownItem onClick={() => editPackage([row])} ><div className="d-flex">Update Package Settings</div></DropdownItem>




          </UiActionDropDown>
        </div>


      )
    },



  ];


  const clientModal = useModal('assign-clients');


  const assignClients = (id) => {

    clientModal.show({ id: id, type: 'package' }).then((res) => {
      fetchData(null);
    });

  }


  var removeClients = (id) => {
    showOVLoader(true)
    var data = new FormData();

    data.append("cust_id", id.toString());
    data.append("package", pstate.package.id);
    data.append("status", 1);
    instance({
      method: "post",
      url: "/assign_package",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showOVLoader(false)
        if (response.data.status == "error") {
          toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "success" });
          fetchData(null);
        }
      })
      .catch(function (response) { });
  };



  useEffect(() => {
    fetchData(null);
  }, []);



  var fetchData = () => {


    showLoader(true)
    instance({
      method: "get",
      url: "/get_service_clients?type=package_existing&id=" + pstate.package.id,

    })
      .then(function (response) {
        setSelectedRowKeys([])
        setSelectedRowKeys1([])
        showLoader(false)

        setState({ ...state, ...response.data });

        setPSate({ ...pstate, package: { ...pstate.package, assigned_clients: response.data.items.length } });

        const filteredItems1 = response.data.items.filter(
          item =>
            (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.file && item.file.toLowerCase().includes(filterText.toLowerCase()))
        );
        setFilteredItems(filteredItems1);

      })
      .catch(function (response) { });
  };

  useEffect(() => {
    if (state.items) {

      const filteredItems1 = state.items.filter(
        item =>
          (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.file && item.file.toLowerCase().includes(filterText.toLowerCase()))
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);





  const recModal = useModal('recurring-package');


  const editPackage = (id) => {
    var slctd = '';
    slctd = id[0];
    var ids;
    if (id.length == 1) {
      
      ids = id[0].id.toString();
    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      ids = col0.toString();

    }
    recModal.show({ package_id: pstate.package.id, data: slctd, cust_id: ids, status: 2 }).then((res) => {
      fetchData(null);

    });

  }




  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      {state.items ?
        <div className="card card-default">
          <OverlayLoader visible={loader1} />
          {loader && <InLoader />}
          <div className="card-body">
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div>
                <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
              </div>
              <div className="mt-2 mt-sm-0">
                <UiButton className="btn btn-primary me-2" onClick={() => assignClients(pstate.package.id)} title="Assign to Clients" icon="add"></UiButton>

                <DropdownMenu
                  trigger={({ triggerRef, isSelected, ...props }) => (
                    <button className="btn btn-outline-primary"
                      {...props}
                      ref={triggerRef}
                    >Bulk Action {selectedRowKeys.length > 0 && <div className="badge bg-primary">{selectedRowKeys.length}</div>}</button>

                  )}
                >
                  <DropdownItemGroup>
                    <DropdownItem onClick={() => removeClients(selectedRowKeys)} isDisabled={selectedRowKeys.length == 0}><div className="d-flex" >Unassign Package from Clients</div></DropdownItem>
                    <DropdownItem onClick={() => editPackage(selectedRowKeys1)} isDisabled={selectedRowKeys.length == 0}><div className="d-flex">Update Package Settings</div></DropdownItem>


                  </DropdownItemGroup>
                </DropdownMenu>
              </div>
            </div>

          </div>
          <div className="card-body pt-0 tablecard">

            {state.items && state.items.length > 0 ? (
              <>




                <div >

                  <Table
                    locale={{
                      emptyText: (<TableZero title="Clients" />)
                    }}
                    pagination={false}
                    virtual={true}
                    size="small"
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={filteredItems}
                    //loading={{ indicator: <BgLoader /> }}
                    rowSelection={rowSelection}
                    scroll={{
                      x: 950,

                    }}
                  />

                </div>


              </>
            ) : state.items && state.items.length == 0 ? <TableZero title="Clients" /> : <BgLoader />}
          </div>
        </div>

        : <BgLoader />}
    </>
  )

}

