import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiRSelect, TableZero, BgLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation } from "react-router-dom";



const BulkActionErrors = NiceModal.create(
  ({ type, subtitle, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const modal = useModal();
    const [loader, showLoader] = useState(false);


    const handleChange = (key) => {
      modal.resolve({ resolved: true, newid: key });
      modal.hide();
    }


    const location = useLocation();
    useEffect(() => {
      fetchData()
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    const fetchData = () => {
      showLoader(true)
      instance
        .get("/bulk_errors?type=" + type + "&id=" + data)
        .then(function (response) {
          showLoader(false)
          setState({ ...state, ...response.data });

        })
        .catch(function (error) { })
        .then(function () { });

    }


    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Bulk Action Errors</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
          {state.items ? <table className="table table-bordered">
            <thead>
              <tr>
                <th>{type}</th><th>Error</th>
              </tr>
            </thead>
            <tbody>
              {state.items.map((val, i) => (
                <tr key={i}><td>{val.name}</td><td>{val.remark}</td></tr>
              ))}
            </tbody></table>
            :
            <BgLoader />}
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default BulkActionErrors;
