import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { UiSelect, UiToggle, UiTFind, UiButton, PageError, BgLoader, InLoader, TableZero } from "../../ui";

import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { Uploader, DocList } from "../../uploader";
import { Tree } from 'antd';
import { toast } from "react-toastify";
import {MdArrowRight} from 'react-icons/md'

export default function ClientDocuments() {
  const [pstate, setPSate] = useOutletContext();
  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [dloader, showDLoader] = useState(false);
  const [docloaded, isDocLoaded] = useState(false);

  const [filelist, setFiles] = useState([]);

  const updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });




  const onLoadData = ({ key, children, type }) =>
  
    new Promise((resolve) => {
      if (children) {
        resolve();
        return;
      }
     
      instance
        .get("/doc_directory?client=" + pstate.client.id + "&type=" + type + "&key=" + key)
        .then(function (response) {

        setFiles((origin) =>updateTreeData(origin, key, response.data.dir));
          resolve();
        })



    });




  const fetchData = () => {

    instance
      .get("/client_files?id=" + pstate.client.id)
      .then(function (response) {

        
        setState({ ...state, ...response.data });
      })
      .catch(function (error) { })
      .then(function () { });

  }

  const fetchData1 = () => {
    isDocLoaded(false)
    instance
      .get("/doc_directory?client=" + pstate.client.id)
      .then(function (response) {
        isDocLoaded(true)
        setState1({ ...state1, ...response.data });
        setFiles(response.data.dir);
      })
      .catch(function (error) { })
      .then(function () { });

  }


  const downloadFile = (e) => {
    
    if (e.isLeaf == true) {
      showDLoader(true)
      instance
        .get("/download?type=task&id=" + e.id)
        .then(function (response) {
          showDLoader(false)
          if (response.data.status == 'success') {
            window.open(response.data.url, '_blank');
          } else {
            toast(response.data.msg, { type: "error" });
          }
        })
    }
  }

  useEffect(() => {
    fetchData();
    fetchData1();
  }, []);





  return (


    <div className="row">

      <div className="col-md-6">
        <div className="card card-default mb-4">
          <div className="card-header">Task Doc. Directory</div>
          {dloader && <InLoader />}
          <div className="card-body">
            {docloaded == true ?
              <>
                {filelist.length > 0 ?
                  <Tree
             
                    loadData={onLoadData}
                    showIcon
                    onSelect={(e, c) => downloadFile(c.node)}
                    treeData={filelist}
                    
                  />
                  : <TableZero title="Documents" />
                }
              </>

              : <BgLoader />}
              {state1.status == 'error' && <PageError msg={state1.msg} code={state1.code} />}
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card card-default">
          <div className="card-header">Client Documents</div>
          <div className="card-body">

            {pstate.can_edit == true ? (
              <div className="mb-3">
                <Uploader category="client" uid={pstate.client.id} onSave={(e) => setState(prevState => ({documents: [...prevState.documents, ...e]}))} />
              </div>
            ):<p className="text-muted">You don't have permission to upload files!</p>}

            {state.documents ? (
              <DocList category="client" setData={(e) => setState({ ...state, documents: e })} data={state.documents} can_edit={pstate.can_edit} />
            ) : <BgLoader />}

            {state.status == 'error' && <PageError msg={state.msg} code={state.code} />}
          </div>
        </div>
      </div>
    </div>



  )

}

