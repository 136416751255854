import { useState, useEffect, useCallback } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiSelect, UiActioButton } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { BsUpload } from 'react-icons/bs';
import { useNavigate, useLocation } from "react-router-dom";
import { useDropzone } from 'react-dropzone';

import { CSVLink, CSVDownload } from "react-csv";

const ImportEntries = NiceModal.create(
  ({ data, type, title, singular, dup_name }) => {
    const [state, setState] = useState({});
    const [loader, showLoader] = useState(false);
    const modal = useModal();
    const [myFiles, setMyFiles] = useState([])

    const removeFile = file => () => {
      const newFiles = [...myFiles]
      newFiles.splice(newFiles.indexOf(file), 1)
      setMyFiles(newFiles)
    }
    const schema = yup.object().shape({


    });

    const files = myFiles.map(file => (
      <div className="d-flex justify-content-between border p-3 rounded border align-items-center" key={file.path}>
        <span>{file.path} </span>
        <UiActioButton action={removeFile(file)} title="Delete">Remove File</UiActioButton>
      </div>
    ))

    const headers = [
      { label: "Row Number", key: "row" },
      { label: "Error", key: "msg" },


    ];

    const onDrop = useCallback(acceptedFiles => {
      setMyFiles([...myFiles, ...acceptedFiles])
      setState({ ...state, error_log: false })
    }, [myFiles])


    const { acceptedFiles, getRootProps, fileRejections, getInputProps } = useDropzone({
      // Note how this callback is never invoked if drop occurs on the inner dropzone
      // onDrop: files => handleSave1(files),
      onDrop,
      maxFiles: 1,
      maxSize: 2000000,
      multiple: false,
      accept: {
        'application/vnd.ms-excel': ['.xls', '.xlsx'],
        'text/csv': ['.csv'],

      },

    });



    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData(data);
      //reset();
    };
    var SaveData = (e) => {
      var isoverwrite = 0;
      if (e.is_overwrite) {
        isoverwrite = 1;
      }
      showLoader(true)

      var data = new FormData();

      data.append("file", myFiles[0]);
      data.append("is_overwrite", isoverwrite);

      var url = "/import_" + type;

      instance({
        method: "post",
        url: url,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          showLoader(false)
          setMyFiles([])
          setState(response.data)
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
    useEffect(() => {


      if (data) {

        reset(data);
        setState({ ...state, editid: data.id });
      }

    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <div className="alert alert-danger mt-3" role="alert" key={file.path}>
        <b>{file.path}</b><br></br>

        {errors.map(e => (
          <span key={e.code}>○ {e.code == 'file-invalid-type' && 'Invalid file type'}{e.code == 'file-too-large' && 'File is large (Max. 2MB)'} </span>
        ))}

      </div>
    ));


    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Import {title}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="holidayform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>



            {files && files.length > 0 ? (

              <div className="mb-3">
                <b className="mb-2">Selected file</b>
                {files}
                {(type == "clients" || type == "services") &&
                  <>
                    <div className="mt-3">
                      <UiToggle
                        pclass="mb-0"
                        id="updateimport"
                        name="is_overwrite"
                        title="Overwrite if already exists"
                        {...register("is_overwrite")}
                      />
                    </div>
                    {type == "clients" && <small>{dup_name == 1 ? 'File Number' : 'Client Name and File Number'} will be treated as a unique identifier.</small>}
                    {type == "services" && <small>The column "Service Name" will be treated as a unique identifier.</small>}
                  </>
                }
              </div>

            ) :
              <div>
                {state.error_log == true && (
                  <div className="alert alert-warning mb-4">

                    <span>{singular} import failed with error. Check error in this file <CSVLink data={state.errors} filename={`${type}-import-error-log.csv`} headers={headers}  >Download</CSVLink></span>
                  </div>
                )}
                <p>Download the <a href={`https://practive.s3.ap-south-1.amazonaws.com/import-${type}.xlsx`}>sample excel file</a> and prepare and upload below the excel file according to the guidelines mentioned in the sample excel file.</p>

                {type == "passwords" &&
                  <p>Note: The user must have client edit (Everything) permission to import passwords.</p>
                }

                <div {...getRootProps({ className: 'dropzone uploadbox' })} >
                  <input {...getInputProps()} />
                  <span className="fs-3 text-primary">
                    <BsUpload />
                  </span>
                  <p className="text-dark pe-none">Drop file here or click to upload.</p>
                  <small className="text-muted">Maximum File Size: 2 MB  |  File Format: CSV or XLS</small>
                </div>


              </div>
            }
            {fileRejectionItems}




          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={loader} disabled={files && files.length > 0 ? false : true} title="Import" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default ImportEntries;
