import React, { useState, useEffect, useCallback } from "react";
import { instance } from "../../axios";
import {
  PageHeader,
  UiInput,
  UiButton,
  UiToggle,
  UiRichText,
  UiTextArea,
  UiRSelect,
  PageError,
  UiSelect,
  BgLoader,
} from "../../ui";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Helmet } from "react-helmet";
import { TbExternalLink } from "react-icons/tb";
import { VscLinkExternal } from "react-icons/vsc";
import Swal from "sweetalert2";



export default function NotificationSettings() {
  const [bg_loader, setBGLoader] = useState(false);
  const schema = yup.object().shape(
    {
      email_from: yup.string().required("This is required"),

      smtp_host: yup.string().nullable()
        .when("email_from", {
          is: 'custom',
          then: (rule) =>
            rule
              .required("This is required field"),
        }),

      encryption: yup.string().nullable()
        .when("email_from", {
          is: 'custom',
          then: (rule) =>
            rule
              .required("This is required field"),
        }),

      smtp_username: yup.string().nullable()
        .when("email_from", {
          is: 'custom',
          then: (rule) =>
            rule
              .required("This is required field"),
        }),

      smtp_password: yup.string().nullable()
        .when("email_from", {
          is: 'custom',
          then: (rule) =>
            rule
              .required("This is required field"),
        }),

      from_email: yup.string().email("Enter valid email").nullable()
        .when("email_from", {
          is: 'custom',
          then: (rule) =>
            rule
              .required("This is required field"),
        }),

      from_name: yup.string().nullable()
        .when("email_from", {
          is: 'custom',
          then: (rule) =>
            rule
              .required("This is required field"),
        }),

      smtp_port: yup.string().nullable()
        .when("email_from", {
          is: 'custom',
          then: (rule) =>
            rule
              .matches(/^[0-9]+$/, "Enter valid number")
              .required("This is required field"),
        }),



    },
    []
  );
  const [state, setState] = useState({ count: 0 });
  const [loader, showLoader] = useState({});

  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/notification_settings")
      .then(function (response) {
        setBGLoader(false)
        setState({ ...state, ...response.data });
        //setEntry(response.data.settings.service);
        reset(response.data.settings)
      })
      .catch(function (error) { })
      .then(function () { });
  }
  useEffect(() => {
    fetchData()

    window.fbAsyncInit = function () {
      // JavaScript SDK configuration and setup
      window.FB.init({
        appId: '2244537659052417', // Facebook App ID
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v16.0' //Graph API version
      });
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  }, []);



  var SaveSettings = (e) => {
    showLoader({ ...loader, loader: true });

    instance({
      method: "post",
      url: "/update_notification_settings",
      data: e,
      //headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader({ ...loader, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    resetField,
    watch,
    unregister,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    SaveSettings(data);
    //reset();
  };



  const encWatch = watch('encryption');
  const emailfromWatch = watch('email_from');


  useEffect(() => {
    if (encWatch == 'SSL') {
      resetField("smtp_port", { defaultValue: '465' })
    } else if (encWatch == 'TLS') {
      resetField("smtp_port", { defaultValue: '587' })
    } else if (encWatch == 'None') {
      resetField("smtp_port", { defaultValue: '25' })
    }
  }, [encWatch]);

  const smsbuyModal = useModal('buy-sms');


  const BuySMSModal = () => {

    smsbuyModal.show({}).then((res) => {
      fetchData();
    });



  }

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }



  const connectWA = (token) => {
    setBGLoader(true)
    instance
      .get("/connect_wa?token=" + token)
      .then(function (response) {
        setBGLoader(false)
        fetchData()

      })
      .catch(function (error) { })
      .then(function () { });
  }


  const disconnectWA = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, disconnect!'
    }).then((result) => {
      if (result.isConfirmed) {

        setBGLoader(true)
        instance
          .get("/connect_wa?remove=1")
          .then(function (response) {
            setBGLoader(false)
            fetchData()

          })
          .catch(function (error) { })
          .then(function () { });
      }
    })

  }

  const syncTemplates = () => {
    setBGLoader(true)
    instance
      .get("/sync_wa_templates")
      .then(function (response) {
        setBGLoader(false)
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
        }
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }

      })
      .catch(function (error) { })
      .then(function () { });
  }




  function launchWhatsAppSignup() {
    //connectWA('accessToken')
    // Conversion tracking code
    // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: '2244537659052417', feature: 'whatsapp_embedded_signup'});

    // Launch Facebook login
    window.FB.login(function (response) {
      if (response.authResponse) {
        const accessToken = response.authResponse.accessToken;
        connectWA(accessToken)
        //Use this token to call the debug_token API and get the shared WABA's ID
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      scope: 'whatsapp_business_management',
      extras: {
        feature: 'whatsapp_embedded_signup',
        setup: {
        }
      }
    });
  }





  return (
    <>
      <Helmet>
        <title>
          Communication Settings | Practive
        </title>
      </Helmet>
      <div>
        {state.settings ? (

          <div>
            {bg_loader == true ? <BgLoader /> : (
              <>

                <PageHeader title="Communication Settings" parent="Settings" link="/settings">

                </PageHeader>



                <form onSubmit={handleSubmit(onSubmitHandler)} noValidate>

                  <div className="card card-default mb-4">
                    <div className="card-header">Email Settings</div>
                    <div className="card-body">
                      <label className="form-label">Send Email From</label>
                      <div className="mb-3 mt-2 d-flex">

                        <div className="form-check mb-3 me-3">
                          <input type="radio" className="form-check-input" value='practive' name="email_from" id="success-outlined" {...register("email_from")} />
                          <label className="form-check-label" htmlFor="success-outlined">Practive Server</label>
                        </div>
                        <div className="form-check">
                          <input type="radio" className="form-check-input" value='custom' name="email_from" id="danger-outlined"  {...register("email_from")} />
                          <label className="form-check-label" htmlFor="danger-outlined">Custom SMTP</label>
                        </div>
                      </div>
                      {emailfromWatch == 'custom' && (
                        <>

                          <UiInput
                            lcol="2"
                            icol="10"
                            label="SMTP Host"
                            type="text"
                            name="smtp_host"
                            message={errors.smtp_host?.message}
                            {...register("smtp_host")}
                          />
                          <UiSelect
                            lcol="2"
                            icol="10"
                            label="Encryption"
                            options={['SSL', 'TLS', 'None']}
                            name="encryption"
                            message={errors.encryption?.message}
                            {...register("encryption")}
                          />
                          <UiInput
                            lcol="2"
                            icol="10"
                            label="SMTP Port"
                            type="text"
                            name="smtp_port"
                            message={errors.smtp_port?.message}
                            {...register("smtp_port")}
                          />

                          <UiInput
                            lcol="2"
                            icol="10"
                            label="SMTP Username"
                            type="text"
                            name="smtp_username"
                            message={errors.smtp_username?.message}
                            {...register("smtp_username")}
                          />

                          <UiInput
                            lcol="2"
                            icol="10"
                            label="SMTP Password"
                            type="password"
                            name="smtp_password"
                            message={errors.smtp_password?.message}
                            {...register("smtp_password")}
                          />

                          <UiInput
                            lcol="2"
                            icol="10"
                            label="From Email"
                            type="email"
                            name="from_email"
                            message={errors.from_email?.message}
                            {...register("from_email")}
                          />
                          <UiInput
                            lcol="2"
                            icol="10"
                            label="From Name"
                            type="text"
                            name="from_name"
                            message={errors.from_name?.message}
                            {...register("from_name")}
                          />


                        </>
                      )}



                    </div>
                  </div>

                  <div className="card card-default mb-4">
                    <div className="card-header">SMS Settings</div>
                    <div className="card-body">
                      <span className="fs-4">SMS Credits: <b>{state.settings.sms_balance}</b></span>
                      <a className="btn btn-outline-primary btn-sm ms-3" onClick={() => BuySMSModal()}>Buy SMS</a>
                      <div className="mt-4">
                        <label className="form-label">Send SMS From</label>

                        <div className="mb-3 mt-2 d-flex">
                          <div className="form-check me-3">
                            <input type="radio" className="form-check-input" value="practive" name="sms" id="sms1" autoComplete="off" {...register("sms")} />
                            <label className="form-check-label" htmlFor="sms1">Practive Header</label>
                          </div>
                          <div className="form-check">
                            <input type="radio" className="form-check-input" value="custom" name="sms" id="sms2" autoComplete="off" disabled={state.settings.sender_id ? false : true} {...register("sms")} />
                            <label className="form-check-label" htmlFor="sms2">Your Own Header</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-default mb-4">
                    <div className="card-header"><span className="me-3">WhatsApp Business API</span><a className="btn btn-outline-primary btn-sm" href="https://www.youtube.com/watch?v=93RHHi9MlP8" target="_blank">Watch integration tutorial <VscLinkExternal /></a></div>
                    <div className="card-body">
                      {state.settings.waba_id ? (
                        <div>
                          <div className="row">
                            <div className="col-auto me-auto">
                              <b>Connected to WhatsApp Business Account ID {state.settings.waba_id}</b>
                              <p className="mb-0 mt-3">Phone Number: {state.settings.wa_phone}</p>
                              <p>Name: {state.settings.wa_name}</p>
                            </div>
                            <div className="col-auto">
                              <a className="btn btn-outline-primary btn-sm" onClick={() => syncTemplates()}>Sync Templates</a>
                            </div>
                          </div>
                          <div className="alert1 d-flex border-warning bg-warning border-info bg-opacity-25 rounded mb-4">

                            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                              <div className="mb-3 mb-md-0">

                                <div className="text-muted1 pe-7">
                                Do you want to change or disconnect the WhatsApp API phone number?
                                </div>
                              </div>
                              <a
                                className="btn btn-danger px-6 ms-2 align-self-center text-nowrap"
                                onClick={() => disconnectWA()}
                              >
                                Disconnect
                              </a>
                            </div>
                          </div>
                          <UiToggle
                            id="wa_bot"
                            name="chat_bot"
                            title="Enable WhatsApp AI Assistant"
                            {...register("chat_bot")}
                          />
                        </div>
                      )
                        :
                        <>
                          <p>Log in with Facebook to register and create your WhatsApp Business Account, add the sender you want to use, and connect with Practive as your Business Solution Provider.</p>
                          <button type="button" onClick={() => launchWhatsAppSignup()} className="btn btn-primary btn-lg" style={{ backgroundColor: '1877f2' }}>Login with Facebook</button>
                        </>}
                    </div>
                  </div>



                  <UiButton loading={loader["loader"]} title="Save" />
                </form>
              </>
            )}
          </div>
        ) : <BgLoader />
        }
      </div >
    </>
  );
}
