import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { PageHeader, PageError, TableZero, UiTFind, BgLoader, InLoader } from "../../ui";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { Helmet } from "react-helmet";

export default function GeneralNotifications() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()


  const [state, setState] = useState({});
  const [filteredItems, setFilteredItems] = useState({});
  const [loader, showLoader] = useState({});

  const fetchData = () => {
    showLoader(true)
    instance
      .get("/general_notifications")
      .then(function (response) {
        showLoader(false)
        setState({ ...state, ...response.data });
        const filteredItems1 = response.data.items.filter(
          item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(filteredItems1);
      })
      .catch(function (error) { })
      .then(function () { });

  }




  const [filterText, setFilterText] = React.useState('');


  const columns = [
    {
      name: 'Name',
      sortable: true,
      selector: row => row.title,
      cell: row => (<Link to={`/general-notification/${row.type}`}><b>{row.title}</b></Link>)
    },

    {
      name: 'SMS',
      sortable: true,
      selector: row => row.sms,
      cell: row => (<>{row.sms == 'Active' ? <span className="text-success">Active</span> : <span className="text-muted">Inactive</span>}</>)
    },
    {
      name: 'WhatsApp',
      sortable: true,
      selector: row => row.whatsapp,
      cell: row => (<>{row.whatsapp == 'Active' ? <span className="text-success">Active</span> : <span className="text-muted">Inactive</span>}</>)
    },
    {
      name: 'Email',
      sortable: true,
      selector: row => row.email,
      cell: row => (<>{row.email == 'Active' ? <span className="text-success">Active</span> : <span className="text-muted">Inactive</span>}</>)
    },



  ];



  const location = useLocation();
  const { data, error, isError, isLoading, isFetching } = useQuery(["general-notifications"], () => fetchData(), {
    select: (users) => users.filter((a) => a.title.toLowerCase().includes(filterText.toLowerCase()))
  });


  useEffect(() => {
    if (state.items) {
      const filteredItems1 = state.items.filter(
        item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);

  useEffect(() => {
    fetchData();
  }, []);

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }



  return (
    <>
      <Helmet>
        <title>
          General Notifications | Practive
        </title>
      </Helmet>
      <div>
        {state.items ?
          <div>
            <PageHeader title="General Notifications" parent="Settings" link="/settings">

            </PageHeader>



            <div className="card card-default position-relative">
              {isFetching && <InLoader />}
              <div className="card-body">


                <div className="">
                  <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
                </div>
              </div>
              <div className="card-body pt-0 tablecard">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  noDataComponent={<TableZero title="Notifications" />}
                />


              </div>
            </div>
          </div>
          : <BgLoader />}
      </div>
    </>
  );
}
