import React, { useState, useEffect, forwardRef, useRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiRSelect, BgLoader, UiDateRange, InLoader, UiSelect, UiAvatarGroup, UiActioButton, UiActionDropDown, PageError, Toastcontent, UiClientSelect, UiInput, UiPeriodSelect } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import { el } from "date-fns/locale";
import queryString from 'query-string';
import { FiUsers } from 'react-icons/fi'
import { MdVerified } from 'react-icons/md'
import { CSVLink, CSVDownload } from "react-csv";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { Table } from 'antd';
import { BsCloudCheck } from "react-icons/bs";
import { getStorage, setStorage, updateFilterUrl } from "../../helper.js";



export default function Tasks() {
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.tasks);
  const setZState = useStore((state) => state.setTasks);


  const parsed = queryString.parse(location.search);

  const [state, setState] = useState({ page: 1 });
  const [pagenum, setPage] = useState(1);
  const [initialLoad, setInitLoad] = useState(false);

  const [filter, setFilter] = useState({});


  const [tableDisable, setTableDisable] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeys1, setSelectedRowKeys1] = useState([]);


  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: getStorage('perpage') ? [20, 50, 100].includes(parseInt(getStorage('perpage'))) ? parseInt(getStorage('perpage')) : 20 : 20,
      showSizeChanger: true,
      pageSizeOptions: [20, 50, 100],
    },

  });



  const statusModal = useModal('task-status');
  const duedateModal = useModal('task-duedate');
  const userModal = useModal('taskusers');
  const verifyModal = useModal('task-verify');

  const defaultValues = {
    date: null,
    status: null,
    cust_tag: null,
    task_tag: null,
    service: null,
    is_billed: null,
    is_billable: null,
    is_verified: null,
    period: '',
    search: '',
    task_type: null,
  };






  const showstatusModal = (id) => {
    if (id.length == 1) {
      var tid = id[0].id;
      var val = id[0].status;
    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();
      var val = null;
    }
    statusModal.show({ task_id: tid, tval: val }).then((res) => {
      queryClient.invalidateQueries('tasks')
      clearSelection()
    });

  }

  const showduedateModal = (id) => {
    if (id.length == 1) {
      var tid = id[0].id;
      var val = id[0].due_date;
    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();
      var val = null;
    }
    duedateModal.show({ task_id: tid, duedate: val }).then((res) => {
      queryClient.invalidateQueries('tasks')
      clearSelection()

    });

  }


  const showuserModal = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }
    userModal.show({ task_id: tid }).then((res) => {
      queryClient.invalidateQueries('tasks')
      clearSelection()

    });

  }

  const showverifyModal = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }
    setTableDisable(true)

    instance({
      method: "GET",
      url: "/verify_task?id=" + tid + '&action=1',
    })
      .then(function (response) {
        setTableDisable(false);
        if (response.data.status == "error") {
          toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task" />, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task" />, { type: "success" });
          queryClient.invalidateQueries('tasks')
          clearSelection()

        }
      })






  }


  const handleDelete = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setTableDisable(true)
        instance
          .get("/delete_task?delete=" + tid)
          .then(function (response) {
            setTableDisable(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['tasks'])
              clearSelection()
              toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task" />, { type: "success" });
            } else {
              toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task" />, { type: "error" });
            }


          })

      }
    })




  }






  const schema = yup.object().shape(
    {
      search: yup.string(),

    }
  );

  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: parseInt(parsed.page),
        },
      });
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);



  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    formState,
    watch,
    resetField,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { isDirty } = formState;


  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);

  const onSubmitHandler = async (data) => {
    setSelectedRowKeys([])
    setSelectedRowKeys1([])
    setPage(1)
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })


    navigate(updateFilterUrl(obj, 'tasks', 1), { replace: location.search ? true : false })


  };





  async function fetchData(filter) {

    const { data } = await instance.get(
      "/tasks" + filter
    );
    var dataa = data;
    return dataa;
  }




  const headers = [
    { label: "Date", key: "date" },
    { label: "Service", key: "service" },
    { label: "Period", key: "period" },
    { label: "Client", key: "name" },
    { label: "File No.", key: "file" },
    { label: "Group", key: "group_name" },
    { label: "Due Date", key: "due_date" },
    { label: "Completed On", key: "completed_on" },
    { label: "Users", key: "users" },
    { label: "Status", key: "status" },
    { label: "Verified", key: "verified" },

  ];

  const columns = [
    {
      title: 'Date',
      width: '120px',
      dataIndex: 'date',
      sorter: 'date',
      sortOrder: parsed.sort_by == 'date' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'date' && parsed.sort == 'desc' ? 'descend' : '',
    },
    {
      title: 'Task',
      dataIndex: 'service',
      //width: '200px',
      render: (text, row) => (
        <div >
          {row.is_parent == null && <span className="d-block small text-secondary">{row.service}{row.period ? ` - ${row.period}` : ''}</span>}
          <OverlayTrigger overlay={row.task_description ? <Tooltip >{row.task_description}</Tooltip> : <></>} ><Link to={`/view-task/${row.id}`}><b>{row.is_parent == 1 ? row.service : row.title}</b></Link></OverlayTrigger>
          {row.is_parent == 1 && <span title="Task Period" className="d-block">{row.period}</span>}
        </div>),
      sorter: 'service',
      sortOrder: parsed.sort_by == 'service' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'service' && parsed.sort == 'desc' ? 'descend' : '',

    },

    {
      title: 'Client',
      //width: '260px',
      sorter: 'name',
      sortOrder: parsed.sort_by == 'name' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'name' && parsed.sort == 'desc' ? 'descend' : '',
      dataIndex: 'name',
      style: { display: 'inline-flex' },
      render: (text, row) => (<div className="">
        <span className="d-block">{row.name}</span>
        {row.file && (<span className="badge border text-primary me-2" title="File No.">{row.file}</span>)}
        {row.group_name && (<small title="User Group"><span className="text-primary"><FiUsers /></span> {row.group_name}</small>)}
      </div>)
    },

    {
      title: 'Due Date',
      width: '120px',
      dataIndex: 'due_date',
      sorter: 'due_date',
      sortOrder: parsed.sort_by == 'due_date' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'due_date' && parsed.sort == 'desc' ? 'descend' : '',
    },
    {
      title: 'Completed On',
      width: '130px',
      dataIndex: 'completed_on',
      sorter: 'completed_on',
      sortOrder: parsed.sort_by == 'completed_on' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'completed_on' && parsed.sort == 'desc' ? 'descend' : '',
    },

    {
      title: 'Users',
      width: '160px',
      dataIndex: 'users',
      render: (text, row) => (<div><UiAvatarGroup items={row.users1}></UiAvatarGroup> </div>)
    },

    {
      title: 'Status',
      width: '130px',
      dataIndex: 'status',
      sorter: 'status',
      sortOrder: parsed.sort_by == 'status' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'status' && parsed.sort == 'desc' ? 'descend' : '',
      render: (text, row) => (<div className="d-flex"><span className={`badge ${row.status}`}>{row.status}</span>
        {row.gst_file_date && <OverlayTrigger overlay={<Tooltip >GST return filed on GST portal on {row.gst_file_date}</Tooltip>} ><span className="text-primary ms-2 sicon fs-6"><BsCloudCheck /></span></OverlayTrigger>}
        {row.verified == 'Yes' && <OverlayTrigger overlay={<Tooltip >Verified</Tooltip>} ><span className="text-success ms-2 sicon fs-6"><MdVerified /></span></OverlayTrigger>}
      </div>)
    },


    {
      title: '',
      fixed: 'right',
      width: '40px',
      render: (text, row) => (
        <div className="tableaction1">
          <UiActionDropDown newclassName="btn tablebtn" >
            <DropdownItem isDisabled={row.can_edit == 1 && row.verified == 'No' ? false : true} onClick={() => showstatusModal([row])}>Change Status</DropdownItem>
            <DropdownItem isDisabled={row.can_edit == 1 && row.verified == 'No' ? false : true} onClick={() => showduedateModal([row])}>Change Due Date</DropdownItem>

            <DropdownItem isDisabled={data.access.assign == 1 && row.verified == 'No' ? false : true} onClick={() => showuserModal([row])}>Assign Users</DropdownItem>
            <DropdownItem isDisabled={data.access.verify == 1 && row.verified == 'No' && row.status == 'Completed' ? false : true} onClick={() => showverifyModal([row])}>Verify</DropdownItem>
            <DropdownItem className="text-danger" isDisabled={row.can_delete == 1 && row.invoice_number == null ? false : true} onClick={() => handleDelete([row])}>Delete</DropdownItem>




          </UiActionDropDown>
        </div>


      )
    },


  ];


  async function fetchData1(id) {

    const { data } = await instance.get(
      "/filters?q=" + id
    );
    var dataa = data;
    return dataa;
  }


  useEffect(() => {

    var set_perpage = 0;
    if (location.search) {
      const parsed = queryString.parse(location.search);
      if (parsed.perpage) {
        if ([20, 50, 100].includes(parseInt(parsed.perpage))) {
          setInitLoad(true)
        } else {
          setStorage('perpage', 20)
          set_perpage = 1;
        }

      } else {
        set_perpage = 1;
      }

    } else {
      set_perpage = 1;
    }

    if (set_perpage == 1) {
 

      navigate(updateFilterUrl(window.location.search, 'tasks', 1), { replace: true })
    }

  }, [location.search]);



  // const { data, error, isError, isLoading, isFetching } = useQuery(["tasks", zstate.page, zstate.filter], () => fetchData(zstate.page, zstate.filter), {
  const { data, error, isError, isLoading, isFetching } = useQuery(["tasks", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
    enabled: initialLoad
  });


  const { data: f_data, isLoading: f_is_loading } = useQuery(["filters", 'GTSU1'], () => fetchData1('GTSU1'), {
    keepPreviousData: true,

  });



  useEffect(() => {

    if (f_data) {
      const defaultValues1 = {
        date: filter.date,
        period: filter.period,
        search: filter.search,
        task_type: filter.task_type,
        is_billed: filter.is_billed,
        is_billable: filter.is_billable,
        is_verified: filter.is_verified,
        client: filter.client && ({ value: parseInt(filter.client.split('::')[0]), label: filter.client.split('::')[1] }),
        due_date: filter.due_date,
        completed_on: filter.completed_on,
        status: f_data.status_list && filter.status ? f_data.status_list.filter(({ value }) => filter.status.split(',').includes(value)) : null,
        cust_tag: f_data.tags && filter.cust_tag ? f_data.tags.filter(({ value }) => filter.cust_tag.split(',').includes(value.toString())) : null,
        task_tag: f_data.tags && filter.task_tag ? f_data.tags.filter(({ value }) => filter.task_tag.split(',').includes(value.toString())) : null,
        service: f_data.services && filter.service ? f_data.services.filter(({ value }) => filter.service.split(',').includes(value.toString())) : null,
        group: f_data.groups && filter.group ? f_data.groups.filter(({ value }) => filter.group.split(',').includes(value.toString())) : null,
        users: f_data.users && filter.users ? f_data.users.filter(({ value }) => filter.users.split(',').includes(value.toString())) : null,
      };
      reset(defaultValues1)
    }
  }, [f_data, filter]);


  useEffect(() => {
    if (data) {
      //setInitLoad(false)
      const parsed = queryString.parse(location.search);
      var setpage = 1;
      if (parsed.page) {
        setpage = parseInt(parsed.page);
      }
      if (parsed.page && parseInt(parsed.page) > 1 && data.items.length == 0) {
        let currentUrlParams = new URLSearchParams(window.location.search);

        setpage = Math.ceil(data.total / parseInt(tableParams.pagination.pageSize));
        setPage(setpage)

        currentUrlParams.set('page', setpage);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.total,
            current: setpage,
          },
        });

        navigate('/tasks?' + currentUrlParams.toString(), { replace: true })
      } else {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.total,
            current: setpage,
          },
        });
      }
    }

  }, [data]);


  const clearFilter = () => {

    setSelectedRowKeys([])
    setSelectedRowKeys1([])
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });

    reset(defaultValues)
    navigate(updateFilterUrl({}, 'tasks', 1), { replace:true })

  }


  if (isLoading) {
    return <BgLoader />;
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  if (!data) {
    return (
      <BgLoader />
    )
  }

  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }


  const clearSelection = () => {
    setSelectedRowKeys([])
    setSelectedRowKeys1([])
  }

  const removeFilterTag = (id) => {
    reset({ ...getValues(), [id]: null })

  }

  const handleTableChange = (pagination, filters, sorter) => {
    var direction = ''

    if (sorter.order == 'ascend') {
      direction = 'asc';
    } else if (sorter.order == 'descend') {
      direction = 'desc';
    }
    var pg = pagination.current
    
    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);

    if (sorter.order) {
      currentUrlParams.set('sort_by', sorter.field);
      currentUrlParams.set('sort', direction);
    } else {
      currentUrlParams.delete('sort_by');
      currentUrlParams.delete('sort');
    }

    currentUrlParams.set('page', pg);
    var perpage = pagination.pageSize
    currentUrlParams.set('perpage', perpage);
    setStorage('perpage', perpage)
    if (location.search) {
      navigate('/tasks?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/tasks?' + currentUrlParams.toString())
    }

    setTableParams({
      pagination,
      filters,
      ...sorter,
    });


  };
  const onSelectChange = (newSelectedRowKeys, completerow) => {
    setSelectedRowKeys1(completerow);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };




  return (
    <>
      <Helmet>
        <title>
          Tasks | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Tasks" >
            {(data.items && data.access.export == 1) &&
              <CSVLink data={data.items} filename={"tasks.csv"} headers={headers} className={`btn btn-outline-primary d-inline-flex me-2 ${data.items.length == 0 && 'disabled'}`} >Export</CSVLink>
            }
            <UiButton className="btn btn-primary" onClick={() => navigate("/task/new")} title="New" icon="add" disabled={data.access.add == 0 ? true : false}></UiButton>

          </PageHeader>
          {zstate.filter1 && (
            <>
              {Object.keys(zstate.filter).map((val, i) => (<span className="badge bg-secondary" key={i}>
                {zstate.filter[val] && (
                  <>
                    {Array.isArray(zstate.filter[val]) ? (zstate.filter[val].map(itm => itm.label)).join(', ') : zstate.filter[val]}
                    <button onClick={() => removeFilterTag(val)}>x</button>
                  </>
                )}
              </span>))}
            </>
          )}

          <form id="tasksfilterfrm" className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            {f_is_loading == true && <InLoader />}
            {f_data && (
              <div className="p-3 bg-light rounded mb-4 ">

                <div className="row">

                  <div className="col">
                    <label className="form-label">Date</label>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>



                  <div className="col-md-3">
                    <Controller
                      name="service[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          //defaultValue={null}
                          options={f_data.services}
                          className=""

                          isMulti
                          message={errors.service?.message}
                          label="Service"
                          {...field}
                        />

                      )}
                    />
                  </div>



                  <div className={`col-md-3`}>
                    <Controller
                      name="status[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect

                          options={f_data.status_list}
                          className=""
                          isMulti
                          message={errors.status?.message}
                          label="Status"
                          {...field}
                        />

                      )}
                    />
                  </div>
                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <label className="form-label">Due Date</label>
                    <Controller
                      name="due_date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>
                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <label className="form-label">Completed On</label>
                    <Controller
                      name="completed_on"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="task_tag[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.tags}
                          className=""
                          isMulti
                          message={errors.task_tag?.message}
                          label="Task Tag"
                          {...field}
                        />

                      )}
                    />
                  </div>


                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="cust_tag[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.tags}
                          className=""
                          isMulti
                          message={errors.cust_tag?.message}
                          label="Client Tag"
                          {...field}
                        />

                      )}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="group[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.groups}
                          className=""
                          isMulti
                          message={errors.group?.message}
                          label="Group"
                          {...field}
                        />

                      )}
                    />
                  </div>


                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="users[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.users}
                          className=""
                          isMulti
                          //closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          message={errors.users?.message}
                          label="User"
                          {...field}
                        />

                      )}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>

                    <Controller
                      name="client"
                      control={control}
                      render={({ field }) => (

                        <UiClientSelect
                          {...field}

                          message={errors.client?.message}
                          label="Client"
                        />

                      )}
                    />

                  </div>


                  <div className={`col-md-2 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiSelect
                      label="Is Billed"
                      options={['Yes', 'No']}
                      //defaultValue={val.value}
                      name="is_billed"
                      message={errors.is_billed?.message}
                      {...register('is_billed')}
                    />
                  </div>

                  <div className={`col-md-2 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiSelect
                      label="Is Billable"
                      options={['Yes', 'No']}
                      //defaultValue={val.value}
                      name="is_billable"
                      message={errors.is_billable?.message}
                      {...register('is_billable')}
                    />
                  </div>


                  <div className={`col-md-2 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiSelect
                      label="Is Verified"
                      options={['Yes', 'No']}
                      //defaultValue={val.value}
                      name="is_verified"
                      message={errors.is_verified?.message}
                      {...register('is_verified')}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  <label className="form-label">Period</label>
                    <Controller
                      name="period"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiPeriodSelect

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiInput
                      label="Search"
                      type="text"

                      name="search"

                      {...register("search")}
                    />
                  </div>

                  <div className={`col-md-2 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiSelect
                      label="Task Type"
                      options={['Parent Task', 'Sub Task']}
                      name="task_type"
                      message={errors.task_type?.message}
                      {...register('task_type')}
                    />
                  </div>

                  <div className={`col ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  </div>

                  <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '200px' }}>
                    <div className="d-flex justify-content-end">
                      {zstate.more_filter == true ? (
                        <UiActioButton type="button" className="fs-3 text-dark" title="Less" tooltip="Hide More Filter" action={() => setZState({ ...zstate, more_filter: false })} />
                      ) : (

                        <UiActioButton type="button" className="fs-3 text-dark" title="More" tooltip="More Filter" action={() => setZState({ ...zstate, more_filter: true })} />
                      )}

                      <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                      <UiActioButton type="submit" loading={state["loaderss"]} className="fs-3" tooltip="Apply Filter" title="Go" />
                    </div>

                  </div>
                </div>



              </div>
            )}
          </form>

          <div className="card card-default position-relative">
            {isFetching && (<InLoader />)}
            <div className="card-body tablecard">

              {selectedRowKeys && selectedRowKeys.length > 0 && (
                <div className="bulkaction">
                  <div>
                    <button className="btn btn-outline-primary btn-sm me-2" onClick={() => showstatusModal(selectedRowKeys1)} disabled={data.access.edit ? false : true}>Change Status</button>
                    <button className="btn btn-outline-primary btn-sm me-2" onClick={() => showduedateModal(selectedRowKeys1)} disabled={data.access.edit ? false : true}>Change Due Date</button>
                    <button className="btn btn-outline-primary btn-sm me-2" onClick={() => showuserModal(selectedRowKeys1)} disabled={data.access.assign ? false : true}>Assign</button>
                    <button className="btn btn-outline-primary btn-sm me-2" onClick={() => showverifyModal(selectedRowKeys1)} disabled={data.access.verify ? false : true}>Verify</button>
                    <button className="btn btn-outline-danger btn-sm me-2" onClick={() => handleDelete(selectedRowKeys1)} disabled={data.access.delete ? false : true}>Delete</button>
                    <button className="btn btn-secondary btn-sm me-2" onClick={() => clearSelection()}>Clear Selection <div className="badge bg-primary">{selectedRowKeys.length}</div></button>

                  </div>
                </div>
              )}






              <Table
                locale={{
                  emptyText: (<TableZero title="Tasks" />)
                }}

                size="small"
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={data.items}
                pagination={{ ...tableParams.pagination, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                loading={{ indicator: <BgLoader />, spinning: tableDisable }}
                rowSelection={rowSelection}
                onChange={handleTableChange}
                rowClassName={(sa) => sa.cancel == 1 && 'cancelled-row'}
                scroll={{
                  x: 960,

                }}
              />

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
