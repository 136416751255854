import React, { forwardRef, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { InLoader } from "./ui";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import { useDropzone } from 'react-dropzone';
import { instance } from "./axios";
import { toast } from "react-toastify";
import { BsUpload, BsInfoCircle } from 'react-icons/bs';
import { AiOutlineFile } from 'react-icons/ai';
import { MdOutlineVisibility, MdOutlineVisibilityOff, MdFileDownload, MdOutlineDelete } from 'react-icons/md';
import enGB from 'date-fns/locale/en-GB';
import formatRelative from 'date-fns/formatRelative'
import Swal from "sweetalert2";
import { parse } from 'date-fns';
import { TiFlowChildren } from "react-icons/ti";
import { Link } from "react-router-dom";


export function Uploader(props) {
  const { onSave, uid, category, ...other } = props;
  const [error, setError] = useState([]);
  const [state, setState] = useState({});

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };


  const processUpload = (a) => new Promise((resolve, reject) => {
    var formData = new FormData();
    if (category == 'task') {
      formData.append("task_id", uid);
    } else if (category == 'client') {
      formData.append("cust_id", uid);
    }


    formData.append("file[]", a);


    setState({ ...state, loader: true })
    instance({
      method: "post",
      url: "/upload_file",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false })
        
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
          if (response.data.errors) {
            //setError([...error, ...response.data.errors]);
            setError(prevState => [...prevState, ...response.data.errors])
          }
        }
        if (response.data.status == "success") {

          toast(response.data.msg, { type: "success" });
          //setState({ ...state, documents: [...state.documents, ...response.data.files] })
          onSave(response.data.files)
          
        }
        //const timeout = setTimeout(() => {
    
          //resolve()
          //clearTimeout(timeout)
        //}, 2500)
        resolve()
      })
      .catch(function (response) { toast(response.message, { type: "error" }); setState({ ...state, loader: false }) });
  })

  var handleSave = (e) => {
    //setState({ ...state, loader: true });
    setError([]);
    if (e.length == 0)
      return;
      let promise = Promise.resolve()
    for (const a of e) {
      

        promise = promise.then(() => processUpload(a))
      
    }
  };

  const { getRootProps, fileRejections, getInputProps } = useDropzone({
    // Note how this callback is never invoked if drop occurs on the inner dropzone
    onDrop: files => handleSave(files),
    maxSize: 10485760,
    maxFiles:10,
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/pdf': ['.pdf'],
      'text/csv': ['.csv'],
      'text/json': ['.json'],
      'text/txt': ['.txt'],
      'application/vnd.ms-powerpoint': ['.ppt','.pptx'],
      'application/zip': ['.zip'],
      'text/xml': ['.xml'],
      'application/msword': ['.doc', '.docx'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    }
  });


  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div className="alert alert-danger" role="alert" key={file.path}>
      <b>{file.path}</b><br></br>

      {errors.map(e => (
        <span key={e.code}>○ {e.code == 'file-invalid-type' && 'Invalid file type'}{e.code == 'file-too-large' && 'File is large (Max. 10MB)'}{e.code == 'too-many-files' && 'Too many files (Max. 10 files at once)'} </span>
      ))}

    </div>
  ));



  return (
    <div>
      <div className="position-relative">
        {state.loader && <InLoader />}
        <div {...getRootProps({ className: 'dropzone uploadbox' })} >
          <input {...getInputProps()} />
          <span className="fs-3 text-primary">
            <BsUpload />
          </span>
          <p className="text-dark pe-none">Drop files here or click to upload.</p>
          <small className="text-muted">Maximum File Size: 10 MB (Max. 10 files at once)  |  File Format: CSV, XLS, XLSX, DOC, PPT, PPTX, PDF, JPG, JSON, XML, ZIP</small>
        </div>
      </div>
      {fileRejectionItems && <div className="mt-3">{fileRejectionItems}</div>}
      {error && error.length > 0 && error.map((val, index) => (
        <div className="alert alert-danger" role="alert" key={index}>
          <b>{val.file}</b><br></br>

          {val.msg.map((val1, index1) => (
            <span key={index1}>○ {val1} </span>
          ))}

        </div>
      ))}
    </div>
  );
}


export function DocList(props) {
  const { setData, category, data, can_edit, ...other } = props;
  const formatRelativeLocale = {
    lastWeek: "'Last' eeee",
    yesterday: "'Yesterday at ' hh:mm a",
    today: "'Today at' hh:mm a",
    tomorrow: "'Tomorrow at ' hh:mm a",
    nextWeek: "'Next' eeee",
    other: 'dd-MM-yyyy hh:mm a',
  };

  const locale = {
    ...enGB,
    formatRelative: (token) => formatRelativeLocale[token],
  };
  const [state, setState] = useState({});
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        var arr = data.slice()
        arr.filter(x => x.id == id)[0].loading = true;
        setData(arr)
        instance
          .get("/delete_file?type=" + category + "&id=" + id)
          .then(function (response) {
            if (response.data.status == 'success') {

              var arr = data;
              arr.filter(x => x.id == id)[0].loading = false;
              var arr1 = arr.filter(x => x.id !== id);
              setData(arr1)
              toast(response.data.msg, { type: "success" });

            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }



  const downloadFile = (id) => {
    var arr = data.slice()
    arr.filter(x => x.id == id)[0].loading = true;
    setData(arr)
    instance
      .get("/download?type=" + category + "&id=" + id)
      .then(function (response) {

        arr.filter(x => x.id == id)[0].loading = false;
        setData(arr)
        if (response.data.status == 'success') {
          window.open(response.data.url, '_blank');
        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
  }

  const hideFile = (id, action, action1) => {
    var arr = data.slice()
    arr.filter(x => x.id == id)[0].loading = true;
    setData(arr)
    instance
      .get("/hide_file?action=" + action + "&id=" + id)
      .then(function (response) {
        if (response.data.status == 'success') {
          toast(response.data.msg, { type: "success" });
          var arr = data.slice()
          arr.filter(x => x.id == id)[0].loading = false;
          arr.filter(x => x.id == id)[0].hide = action1;
          setData(arr)
        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
  }
  return (
    <>
      {data && data.length > 0 && (
        <div className="">

          {data.map((val, index) => (
            <div key={index} className={`list1 d-flex align-items-center ${val.loading ? 'opacity-25' : ''}`}>
              <div className="flex-grow-1"><AiOutlineFile /> <span className="text-break">{val.doc}</span> {val.subtask == 1 && <OverlayTrigger overlay={<Tooltip >{val.title}</Tooltip>} ><span className="badge fs-6 text-bg-light mt-1"><Link to={`/view-task/${val.task_id}`}><TiFlowChildren/></Link></span></OverlayTrigger>}

              </div>
              <div className="d-flex" style={{ height: '28px' }}>
                {val.time &&
                  <OverlayTrigger overlay={<Popover >
                    <Popover.Header as="h3">Details</Popover.Header>
                    <Popover.Body>
                      <strong>Uploaded on: </strong> {formatRelative(parse(val.time, "yyyy-MM-dd HH:mm:ss", new Date()), new Date(), { locale })}
                    </Popover.Body>
                  </Popover>}>
                    <span className="p-1 ms-2"><BsInfoCircle /></span>
                  </OverlayTrigger>
                }
                {can_edit == true && category == 'task' && (
                  <>
                    {val.hide == 1 ? (
                      <OverlayTrigger overlay={<Tooltip >Show file to client</Tooltip>}>

                        <button className="btn btn-secondary btn-sm ms-2" onClick={() => hideFile(val.id, 'show', 0)}><MdOutlineVisibility /></button>

                      </OverlayTrigger>

                    ) : (
                      <OverlayTrigger overlay={<Tooltip >Hide file for client</Tooltip>}>

                        <button className="btn btn-secondary btn-sm ms-2" onClick={() => hideFile(val.id, 'hide', 1)}><MdOutlineVisibilityOff /></button>

                      </OverlayTrigger>


                    )}

                  </>
                )}
                <OverlayTrigger overlay={<Tooltip >Download file</Tooltip>}>
                  <a className="btn btn-secondary btn-sm ms-2" onClick={() => downloadFile(val.id)}><MdFileDownload /></a>
                </OverlayTrigger>

                {can_edit == true && (
                  <OverlayTrigger overlay={<Tooltip >Delete file</Tooltip>}>
                    <a className="btn btn-outline-danger btn-sm ms-2" onClick={() => handleDelete(val.id)}><MdOutlineDelete /></a>
                  </OverlayTrigger>

                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}