import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { UiSelect, UiToggle, UiButton, UiActioButton, PageError, BgLoader, TableZero } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import TabPane from "antd/es/tabs/TabPane";
import DataTable from 'react-data-table-component';

export default function ClientServices() {
  const [pstate, setPSate] = useOutletContext();

  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [bg_loader, setBGLoader] = useState(false);
  const userModal = useModal('recurring-services');


  const showAddModal = (row) => {

    userModal.show({ data: row, custid: pstate.client.id }).then((res) => {
      fetchData();
    });


  }

  const clientPackageModal = useModal('client-package');

  const showPackageModal = (id, custid) => {

    clientPackageModal.show({ id: id, client: custid }).then((res) => {
      fetchData();
    });



  }


  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/get_client_services?id=" + pstate.client.id)
      .then(function (response) {
        setBGLoader(false)
        setState({ ...state, ...response.data });

        fetchPackages();
      })
      .catch(function (error) { })
      .then(function () { });

  }

  const fetchPackages = () => {
    instance
      .get("/client_packages?id=" + pstate.client.id)
      .then(function (response) {
        setState1({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });

  }


  useEffect(() => {
    fetchData();

  }, []);

  const columns = [

    {
      name: 'Package',
      selector: row => row.title,
      cell: row => (<><Link to={`/view-package/${row.id}`}>{row.title}</Link></>),
      minWidth: '180px'
    },
    {
      name: 'Period',
      selector: row => row.period,
      cell: row => (<>{row.period}</>),
      width: '130px'
    },
    {
      name: 'Billing Status',
      selector: row => row.is_billed,
      cell: row => (<span className={`badge ${row.is_billed}`}>{row.is_billed}</span>),
      width: '130px'
    }
  ];

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <div>


      {state.status ? (

        <>
          {bg_loader == true ? <BgLoader /> : (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="card card-default mb-4">
                    <div className="card-header">Availed Services</div>
                    <div className="card-body">
                      <SimpleBar style={{ height: 300 }}>
                        {state.availed_services && state.availed_services.length > 0 ? (
                          <>
                            {state.availed_services.map((val, index) => (
                              <Link className="d-block list1" to={`/view-client/${pstate.client.id}/tasks?service=${val.id}`} key={index}>{val.name}</Link>
                            ))}
                          </>
                        ) : (
                          <span className="text-muted">There are no availed services</span>
                        )}
                      </SimpleBar>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-default mb-4">
                    <div className="card-header"><div className="d-flex justify-content-between">
                      <span>Recurring Services</span>
                      {pstate.can_edit && <UiActioButton title="Edit" tooltip="Set recurring services" action={() => showAddModal()}></UiActioButton>}
                    </div></div>
                    <div className="card-body">
                      <SimpleBar style={{ height: 300 }}>
                        {state.recurring_services && state.recurring_services.length > 0 ? (
                          <>
                            {state.recurring_services.map((val, index) => (
                              <div className=" d-flex justify-content-between list1" key={index}><span>{val.name}</span>
                                {val.task &&
                                  <OverlayTrigger overlay={<Tooltip >{val.status}</Tooltip>} >
                                    <Link to={`/view-task/${val.task_id}`} className={`badge ${val.status}`}>{val.task}</Link>
                                  </OverlayTrigger>

                                }
                              </div>

                            ))}
                          </>
                        ) : (
                          <span className="text-muted">There are no recurring services</span>
                        )}
                      </SimpleBar>

                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-default">
                <div className="card-header"><div className="d-flex justify-content-between">
                  <span>Client Packages</span>
                  {pstate.can_edit && <UiButton className="btn btn-primary btn-sm align-self-center" disabled={state1.packages ? false : true} onClick={() => showPackageModal('new', pstate.client.id)} title="New" icon="add"></UiButton>}
                </div></div>
                <div className="card-body">

                  {state1.status ?
                    <>
                      {state1.status == 'error' ? <PageError msg={state1.msg} code={state1.code} /> : ''}
                      {state1.packages &&


                        <>
                          {state1.packages.length > 0 ? (
                            <DataTable

                              columns={columns}
                              data={state1.packages}
                            />
                          ) : <TableZero title="Packages" />}
                        </>
                      }
                    </>
                    : <BgLoader />}
                </div>
              </div>
            </>
          )}
        </>
      ) : <BgLoader />}
    </div>
  )

}

