import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiDatePicker, UiSelect, UiTextArea, UiInput, UiButton, UiRSelect, UiToggle, BgLoader, PageError, UiClientSelect } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { format, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';
import { useNavigate, useLocation } from "react-router-dom";




const DscEntry = NiceModal.create(
  ({ id, cust_id, name, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const modal = useModal();

    const schema = yup.object().shape({
      date: yup.string().required("Enter issue date"),
      expiry: yup.string().required("Enter expiry date"),
      class: yup.string().required("Select class"),
      client: yup.object().required("Select client"),
      password: yup.string().max(40, 'Maximum character limit is 40'),
      notes: yup.string().max(200, 'Maximum character limit is 200'),
    });
    const {
      register,
      handleSubmit,
      control,

      getValues,
      watch,
      formState: { errors },
      reset,
      resetField,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      handleSave();
      //reset();
    };




    var handleSave = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#dscentryform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_dsc",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };



    const fetchData = () => {
      instance
        .get("/get_dsc?id=" + id)
        .then(function (response) {
          setState({ ...state, ...response.data });
          if (response.data.status == 'success') {

            reset(response.data.entry)
          } else {
            toast(response.data.msg, { type: "error" });
          }

        })
        .catch(function (error) { })
        .then(function () { });

    }






    const [loadedOptions, setOptions] = useState({});

    const client_w = watch('client');
    useEffect(() => {
      if (client_w == null) {
        setOptions({})
      }
    }, [client_w]);

    useEffect(() => {
      fetchData();


    }, []);


    useEffect(() => {
      if (cust_id) {
        var arr = { 'value': cust_id, 'label': name };
        setState({ ...state, default_client: arr })
        reset({ ...getValues, client: arr })
      }
    }, [cust_id]);



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);



    const dateWatch = watch('date')
    const expiryWatch = watch('expiry')
    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit DSC" : "New DSC"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        {state.status == 'success' ? (
          <form id="dscentryform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <BootstrapModal.Body>
              <div className={cust_id && 'd-none'}>



                <Controller
                  name="client"
                  control={control}

                  render={({ field }) => (

                    <UiClientSelect
                      {...field}
                      required={true}
                   
                      message={errors.client?.message}
                      label="Client"

                    />

                  )}
                /></div>
              <div className="row">
                
                <div className="col-6">
                  <UiSelect

                    options={['Class 1', 'Class 2', 'Class 3']}
                    required={true}

                    message={errors.class?.message}
                    label="Class"
                    {...register("class")}

                  />

                </div>
                <div className="col-6">

                <UiInput
                      label="Password"
                      type="text"
                      name="password"
                      {...register("password")}
                    />
                </div>
              </div>



              <input type="hidden" defaultValue={id} name="id" {...register("id")} />
              <div className="row">

                <div className="col-6">
                  <Controller
                    name="date"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDatePicker
                        label="Issue Date"
                        required={true}
                        onChange={onChange}
                        maxDate={parse(expiryWatch, "dd-MM-yyyy", new Date())}
                        default_val={value}
                        message={errors.date?.message}
                        dateFormat="dd-MM-yyyy"
                        ref={ref}
                        name={name}
                      />
                    )}
                  />
                </div>
                <div className="col-6" >
                  <Controller
                    name="expiry"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDatePicker
                        label="Expiry Date"
                        required={true}
                        onChange={onChange}
                        minDate={parse(dateWatch, "dd-MM-yyyy", new Date())}
                        default_val={value}
                        message={errors.expiry?.message}
                        dateFormat="dd-MM-yyyy"
                        ref={ref}
                        name={name}
                      />
                    )}
                  />
                </div>
              </div>





              <UiTextArea
                label="Notes"
                name="notes"
                message={errors.notes?.message}
                {...register("notes")}
              />



            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Save" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>
        ) : state.status == 'error' ? <PageError msg={state.msg} code={state.code} /> : <BgLoader />}
      </BootstrapModal>
    );
  }
);

export default DscEntry;
