import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken } from 'firebase/messaging';
import { setStorage, getStorage } from './helper'
import { instance } from "./axios";
import { getTime, addHours, addDays } from 'date-fns'


const firebaseConfig = {
  apiKey: "AIzaSyCM1waEGAy9E6Cd1sj62rFwSOOB3SIdIgc",
  authDomain: "practive-95455.firebaseapp.com",
  projectId: "practive-95455",
  storageBucket: "practive-95455.appspot.com",
  messagingSenderId: "567925167062",
  appId: "1:567925167062:web:58d28261553a887422ca77"
};

initializeApp(firebaseConfig);



//...

const messaging = getMessaging();


export const requestForToken = (setTokenFound) => {
  return getToken(messaging, { vapidKey: 'BOxo6xN8IdvoyEnndwk1I37gGs-D1hQ4ghUWYdYS2EUJfTBb-6cb-yBi8sojLjdX_XISKf_LYgW2I7R7_4WlQ9k' })
    .then((currentToken) => {
      if (currentToken) {
        if(setTokenFound){
        setTokenFound(true);
        }
        const fcmtoken = getStorage('gfctkn');
        if (!getStorage('gfctkn')) {
          setStorage('gfctkn', currentToken);
        }
        if (!getStorage('tkntime')) {
          setStorage('tkntime', 87878);
        }

        if ((currentToken !== fcmtoken) || (getTime(new Date()) > getStorage('tkntime'))) {

     

          var data = new FormData();
          data.append('token', currentToken)

          instance({
            method: "post",
            url: "/fcm_reg",
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response) {


              if (response.data.status == "success") {
                setStorage('gfctkn', currentToken);
                if (response.data.remember == 1) {
                  setStorage('tkntime', getTime(addDays(new Date(), 30)));
                } else {
                  setStorage('tkntime', getTime(addHours(new Date(), 8)));
                }
              }
            })
            .catch(function (response) { });


         // console.log('current token for client: ', currentToken);
        }

        // Perform any other neccessary action with the token
      } else {
        if(setTokenFound){
        setTokenFound(false);
        }
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      if(setTokenFound){
        setTokenFound(false);
        }
      console.log('An error occurred while retrieving token. ', err);
    });
};






export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      //console.log("payload", payload)
      resolve(payload);
    });
  });

  export const unSubscribe = () => 
  new Promise((resolve) => {
    deleteToken(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });