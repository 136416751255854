import React, { useState, useEffect, useCallback } from "react";
import { instance } from "../../axios";
import {
  PageHeader,
  UiInput,
  UiButton,
  UiToggle,
  UiRSelect,
  UiTextArea,
  PageError,
  UiSelect,
  BgLoader,
} from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { requestForToken } from "../../firebase";


export default function UserNotifications() {


  const schema = yup.object().shape(
    {

      task_push: yup.bool(),
      task_email: yup.bool(),


    },
    []
  );
  const [isTokenFound, setTokenFound] = useState();

  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  useEffect(() => {


    instance
      .get("/user_notifications")
      .then(function (response) {
        setState({ ...state, ...response.data });
        reset({ ...response.data.settings })
      })
      .catch(function (error) { })
      .then(function () { });
  }, []);



  var SaveSettings = (e) => {
    showLoader({ ...loader, loader: true });
    var form = document.querySelector("#usernotisettingsform");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/update_user_notif",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader({ ...loader, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    SaveSettings();
    //reset();
  };

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        <title>
          User Notifications | Practive
        </title>
      </Helmet>
      <div>
        {state.settings ? (
          <div>
            <PageHeader title="User Notifications" parent="Settings" link="/settings">

            </PageHeader>

            <form id="usernotisettingsform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
              <div className="card card-default mb-4">
                <div className="card-body">


                  <div className="alert alert-primary">
                    Push Notification: {isTokenFound == true ? <b className="text-success">Enabled</b> : isTokenFound == false && <><b className="text-danger">Disabled</b> </>} <button type="button" className="btn btn-primary btn-sm ms-2" onClick={() => requestForToken(setTokenFound)}>Subscribe / Check Status</button>
                  </div>
                  <UiToggle
                    id="taskassignpush"
                    name="task_push"
                    title="Send push notification when a task assigned to the user"
                    {...register("task_push")}
                  />
                  <UiToggle
                    id="taskassignemail"
                    name="task_email"
                    title="Send email when a task assigned to the user"
                    {...register("task_email")}
                  />



                </div>
              </div>



              <UiButton loading={loader["loader"]} title="Save" />
            </form>
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
}
