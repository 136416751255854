import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, Toastcontent, UiButton, UiSelect } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const TaskStatus = NiceModal.create(
  ({ task_id, tval }) => {
    const [state, setState] = useState({});
    const [task_count, setTaskCount] = useState(0);
    const modal = useModal();
    const schema = yup.object().shape({
      status: yup.string().nullable().required("Select status"),

    });






    const {
      register,
      handleSubmit,
      control,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const watch_status = watch('status');

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {

      var x = ((task_id.toString()).split(',')).length
      if (watch_status == 'Completed' && x > 20) {
        toast('Max. 20 tasks can be marked Completed at once.', { type: "error" });

      } else {
        setState({ ...state, loader: true });
        var form = document.querySelector("#tasksstatusform");
        var data = new FormData(form);

        instance({
          method: "post",
          url: "/update_task_status",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            setState({ ...state, loader: false });
            if (response.data.status == "error") {
              toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task" />, { type: "error" });
            }
            if (response.data.status == "success") {
              toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Task" />, { type: "success" });
              modal.resolve({ resolved: true });
              modal.hide();
            }
          })
          .catch(function (response) { });
      }
    };


    useEffect(() => {
      reset({ status: tval })

    }, [tval]);


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);



    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Status</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="tasksstatusform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>




            <UiSelect
              required={true}
              label="Status"
              name="status"
              options={["Pending", "Hold", "In-Progress", "Completed"]}
              message={errors.status?.message}
              {...register("status")}
            />



            {task_id && (<input type="hidden" value={task_id} name="task"></input>)}



          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default TaskStatus;
