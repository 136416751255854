import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiDatePicker, BgLoader, InLoader, PageError, UiActioButton, UiActionDropDown, UiInput } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { rupees } from "../../helper";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


export default function Holidays() {
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.clients);
  const setZState = useStore((state) => state.setClients);
  const [pagenum, setPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);
  const userModal = useModal('holiday');

  const showAddModal = (row) => {

    userModal.show({ data: row }).then((res) => {
      queryClient.invalidateQueries(['holidays'])
    });

  }
  const schema = yup.object().shape(
    {
      search: yup.string(),

    }
  );


  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });



  const onSubmitHandler = async (data) => {

    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate("/holidays?" + u, { replace: true })
    } else {
      navigate("/holidays?" + u)
    }


  };







  const handleDelete = (id) => {


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setTableDisable(true)
        instance
          .get("/update_holiday?delete=" + id)
          .then(function (response) {
            setTableDisable(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['holidays'])

              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })




  }




  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});



  async function fetchData(filter) {

    const { data } = await instance.get(
      "/holidays" + filter
    );

    var dataa = data;
    return dataa;
  }

  const defaultValues = {
    search: null,
    year: null,
  };

  const clearFilter = () => {
    reset(defaultValues)
    navigate("/holidays", { replace: true })
  }


  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);


  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);


  const columns = [
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
      width: '130px',
      sortField: 'date',
      id: 'date',
      cell: row => (<b className="text-dark">{row.date}</b>)
    },
    {
      name: 'Name',
      sortable: true,
      sortField: 'name',
      id: 'name',
      selector: row => row.name,
    },

    {
      name: '',
      width: (window.innerWidth > 768 ? '0px' : '130px'),
      selector: row => row.id,
      cell: row => (
        <div className="tableaction">
          <UiActioButton title="Edit" action={() => showAddModal(row)} />
          <UiActioButton title="Delete" action={() => handleDelete(row.id)} />

        </div>


      )
    },

  ];



  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate('/holidays?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate('/holidays?' + currentUrlParams.toString())
      }


    }
  };

  const handleSort = (column, sortDirection) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('sort_by', column.sortField);
    currentUrlParams.set('sort', sortDirection);
    if (location.search) {
      navigate('/holidays?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/holidays?' + currentUrlParams.toString())
    }

  };


  const { data, error, isError, isLoading, isFetching } = useQuery(["holidays", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });



  useEffect(() => {


    const defaultValues1 = {
      search: filter.search,
      year: filter.year,

    };
    reset(defaultValues1)

  }, [filter]);


  if (isLoading) {
    return <BgLoader />;
  }
  if(data.status == 'error'){
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Holidays | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Holidays" >

            <UiButton className="btn btn-primary" onClick={() => showAddModal()} title="New" icon="add"></UiButton>

          </PageHeader>


          <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>


            <div className="p-3 bg-light rounded mb-4 ">

              <div className="row">


                <div className="col-md-3">
                  <Controller
                    name="year"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDatePicker
                        label="Year"
                        onChange={onChange}
                        default_val={value}

                        message={errors.year?.message}
                        showYearPicker
                        dateFormat="yyyy"
                        ref={ref}
                        name={name}
                      />
                    )}
                  />
                </div>
                <div className="col">
                  <UiInput
                    label="Search"
                    type="text"

                    name="search"

                    {...register("search")}
                  />
                </div>










                <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '160px' }}>
                  <div className="d-flex justify-content-end">


                    <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                    <UiActioButton type="submit" className="fs-3" tooltip="Apply Filter" title="Go" />
                  </div>

                </div>
              </div>



            </div>

          </form>


          <div className="card card-default position-relative">
            {isFetching && <InLoader />}
            <div className="card-body tablecard">

              <DataTable
                columns={columns}

                pagination
                paginationServer
                paginationPerPage="20"
                paginationDefaultPage={pagenum}
                paginationResetDefaultPage={true}
                paginationTotalRows={data.total}

                onSort={handleSort}
                sortServer
                persistTableHead
                defaultSortFieldId={parsed.sort_by ? parsed.sort_by : null}
                defaultSortAsc={parsed.sort == 'desc' ? false : true}
                disabled={tableDisable}
                paginationComponentOptions={{ noRowsPerPage: true }}
                data={data.items}
                onChangePage={handlePageChange}
                noDataComponent={<TableZero title="Holidays" />}

              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}
