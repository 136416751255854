import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { PageHeader, UiToggle, UiTimeDisplay, UiAvatarName, UiButton, PageError, BgLoader, UiStatusChange } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Helmet } from "react-helmet";
import { useQueryClient } from 'react-query';
import { rupees } from "../../helper";
import { QuoteTemplate } from '../../quote'
import { Sharer } from '../../sharer'



export default function ViewQuote() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  const [vloader, showVLoader] = useState(false);
  const [bg_loader, setBGLoader] = useState(false);
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader({ ...loader, delete: true })
        instance
          .get("/delete_quote?id=" + id)
          .then(function (response) {
            showLoader({ ...loader, delete: false })
            if (response.data.status == 'success') {

              queryClient.invalidateQueries(['quotations'])
              navigate("/quotations");
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }

  const UpdateStatus = (s_val) => {
    showLoader({ ...loader, status: true });

    var data = new FormData();
    data.append('quote', state.quote.id);
    data.append('status', s_val);

    instance({
      method: "post",
      url: "/update_quote_status",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader({ ...loader, status: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          fetchData();
          queryClient.invalidateQueries(['quotations'])
        }
      })
      .catch(function (response) { });
  }



  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/view_quote?id=" + id)
      .then(function (response) {
        setBGLoader(false)

        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, []);



  const printModal = useModal('print');


  const PrintQuote = (id, num) => {

    printModal.show({ id: id + ':' + num, voucher: 'quote' }).then((res) => {

    });


  }



  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }




  return (
    <>
      <Helmet>
        {state.quote && (
          <title>
            #{state.quote.quote_id} | Quotations | Practive
          </title>
        )}

      </Helmet>
      <div>
        {state.quote ? (
          <>

            <PageHeader title={`#${state.quote.quote_id}`} parent="Quotations" link="/quotations" stacked={true}>
              <Sharer data={state.quote} type="quote" />
              {state.can_edit == true && <>

                <button className="btn btn-outline-primary" onClick={() => navigate(`/quotation/${id}`)}>Edit</button>

              </>
              }

              {state.can_delete == true && (
                <UiButton className="btn btn-outline-danger ms-2" loading={loader.delete} onClick={() => handleDelete(id)} title="Delete" />
              )}
              <button className="btn btn-primary ms-2" onClick={() => PrintQuote(id, state.quote.quote_id)}>Print</button>
            </PageHeader>





            {bg_loader == true ? <BgLoader /> : (
              <>

                <div className="card card-default mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <p className="small mb-1 text-secondary">Status</p>
                        <UiStatusChange sts={['Pending', 'Accepted', 'Rejected']} status={state.quote.status} onUpdate={(e) => UpdateStatus(e)} loading={loader['status']} disabled={state.can_edit == true ? false : true} />
                      </div>
                      <div className="col">
                        <ul className='timeline'>
                          {state.timeline.map((val, i) => (
                            <li className={`${val.done == 1 && 'active'}`} key={i}>
                              {val.status}
                              <span className="small d-block text-muted">{val.date}</span>
                            </li>
                          ))}


                        </ul>
                      </div>
                    </div>


                  </div>
                </div>
                <div className="card card-default">
                  <div className="card-body">

                    <QuoteTemplate data={state.quote} />

                  </div>
                </div>
                <div>
                  <div className="d-block text-secondary small">
                    <div className="d-inline-flex me-3 py-2 align-items-center">
                      <span className="me-2">Created by</span>
                      <UiAvatarName photo={state.quote.user_pic} name={state.quote.user_name} />
                    </div>
                    <div className="d-inline-flex py-2 align-items-center">
                      <span className="me-2">on</span>
                      <UiTimeDisplay time={state.quote.created_on} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>

        ) : <BgLoader />}


      </div>
    </>
  );
}
