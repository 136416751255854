import { useState, useEffect, useRef, useLayoutEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { OverlayLoader, UiRSelect, UiButton, BgLoader, InLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { CSVLink, CSVDownload } from "react-csv";
import { useNavigate, useLocation } from "react-router-dom";



import { Helmet } from "react-helmet";

const ExportAll = NiceModal.create(
  ({ filter, type, header, file }) => {
    const [state, setState] = useState({});
    const [loader, showLoader] = useState(false);
    const [inner_loader, setInnerLoader] = useState(false);
    const [exp_type, setExpType] = useState(1);
    const modal = useModal();
    const downloadLink = useRef()

    const exportAll = () => {
      showLoader(true)
      var filter1 = filter;
      if(exp_type == 1){
        filter1 = '';
      } 
      let currentUrlParams = new URLSearchParams(filter1);
      currentUrlParams.set('page', 'all');
      instance

        .get(type + "?" + currentUrlParams.toString())
        .then(function (response) {
          showLoader(false)
          if (response.data.status == 'error') {
            toast(response.data.msg, { type: "error" });

          } else {
            setState(response.data)


          }


        })


    };

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    useEffect(() => {
      if (state.items) {
        downloadLink.current.link.click()
        modal.resolve({ resolved: true });
        modal.hide();
      }

    }, [state.items]);



    return (
      <>
        <Helmet>
        </Helmet>
        <BootstrapModal {...bootstrapDialog(modal)} >
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Export</BootstrapModal.Title>
          </BootstrapModal.Header>

          <BootstrapModal.Body>
            <div className="position-relative">
              <OverlayLoader visible={inner_loader ? true : false} />
            
              <div className="mb-4">
                <label className="form-label">Export</label>

                <div className="mb-3 mt-2">
                  <div className="form-check mb-2">
                    <input type="radio" className="form-check-input" value={1} name="export" id="val1" autoComplete="off" checked={exp_type == 1 ? true : false} onChange={(e)=>setExpType(e.target.value)} />
                    <label className="form-check-label" htmlFor="val1">Export all</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" className="form-check-input" value={0} name="export" id="val2" autoComplete="off" checked={exp_type == 0 ? true : false} onChange={(e)=>setExpType(e.target.value)} />
                    <label className="form-check-label" htmlFor="val2">Export filtered data</label>
                  </div>
                </div>
              </div>
              <p className="mb-0 text-secondary">Note: You can export only 25,000 entries at once. </p>
              <CSVLink data={state.items ? state.items : []} filename={file} headers={header} className="d-none" ref={downloadLink} >Export</CSVLink>

            </div>

          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={loader} title="Export" onClick={() => exportAll()} />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </BootstrapModal>
      </>
    );
  }
);

export default ExportAll;
