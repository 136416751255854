import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { PageHeader, UiButton, TableZero, UiTFind, BgLoader, InLoader, PageError } from "../../ui";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import { Helmet } from "react-helmet";
import { useQuery, useQueryClient, select } from "react-query";

import { GoPlus } from "react-icons/go";

export default function TaskNotifications() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()


  const [state, setState] = useState({});
  const [filteredItems, setFilteredItems] = useState({});
  const [loader, showLoader] = useState({});



  const fetchData = () => {
    showLoader(true)
    instance
      .get("/task_notifications")
      .then(function (response) {
        showLoader(false)
        setState({ ...state, ...response.data });
        const filteredItems1 = response.data.items.filter(
          item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(filteredItems1);
      })
      .catch(function (error) { })
      .then(function () { });

  }

  const [filterText, setFilterText] = React.useState('');
  useEffect(() => {
    if (state.items) {
      const filteredItems1 = state.items.filter(
        item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFilteredItems(filteredItems1)
    }
  }, [filterText]);

  const columns = [
    {
      name: 'Name',
      selector: row => row.title,
      sortable: true,
      cell: row => (<Link to={`/task-notification/${row.id}`}><b>{row.title}</b></Link>)
    },
    {
      name: 'Send on',
      selector: row => row.send_on,
      sortable: true,

    },

    {
      name: 'SMS',
      sortable: true,
      selector: row => row.sms,
      cell: row => (<>{row.sms == 'Active' ? <span className="text-success">Active</span> : <span className="text-muted">Inactive</span>}</>)
    },
    {
      name: 'WhatsApp',
      sortable: true,
      selector: row => row.whatsapp,
      cell: row => (<>{row.whatsapp == 'Active' ? <span className="text-success">Active</span> : <span className="text-muted">Inactive</span>}</>)

    },
    {
      name: 'Email',
      sortable: true,
      selector: row => row.email,
      cell: row => (<>{row.email == 'Active' ? <span className="text-success">Active</span> : <span className="text-muted">Inactive</span>}</>)

    },


  ];


  useEffect(() => {
    fetchData();
  }, []);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }




  return (
    <>
      <Helmet>
        <title>
          Task Notifications | Practive
        </title>
      </Helmet>
      <div>
        {state.items ?
          <div>

            <PageHeader title="Task Notifications" parent="Settings" link="/settings">
              <UiButton className="btn btn-primary" onClick={() => navigate("/task-notification/new")} title="New" icon="add"></UiButton>

            </PageHeader>

            <div className="card card-default position-relative">
              {loader && <InLoader />}
              <div className="card-body">



                <div className="">
                  <UiTFind filterText={filterText} setFilterText={(e) => setFilterText(e)} />
                </div>
              </div>
              <div className="card-body pt-0 tablecard">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  noDataComponent={<TableZero title="Notifications" />}
                />


              </div>
            </div>
          </div>
          : <BgLoader />}
      </div>
    </>
  );
}
